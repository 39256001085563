import axios from 'axios';

export default function useRequest() {
  const sendData = async (vin = null, odometer = null, plate = null, firstRegistrationDate = null, token = null) => {
    if (!vin) {
      return {
        status: 'error',
        message: 'VIN is empty'
      };
    }

    try {
      const response = await axios.post('/api/startUp', {
        vin: vin,
        odometer: odometer,
        plate: plate,
        firstRegistrationDate: firstRegistrationDate,
        token: token,
      });
      
      return response.data;
    } catch (err) {
      console.error('Error during request:', err);
      return {
        status: 'error',
        message: 'Request failed',
        details: err.message
      };
    }
  };

  return {
    sendData
  }
}
