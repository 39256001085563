export const fr = {
  "fast_and_free": "RAPIDE ET GRATUIT",
  "vehicle_valuation": "ÉVALUATION DE VÉHICULE",
  "valuation": "ÉVALUATION",
  "vin": "VIN",
  "want_to_know_valuation": "Voulez-vous connaître la valeur de votre voiture ou vérifier la valeur d'un véhicule avant de l'acheter ? Utilisez notre évaluation de véhicule gratuite ! Il vous suffit d'entrer le VIN pour obtenir une évaluation précise et fiable. Avec quelques étapes supplémentaires, nous pouvons fournir une analyse encore plus détaillée. C'est simple, rapide et gratuit ! N'attendez pas – vérifiez la valeur de votre voiture maintenant !",
  "plate": "Numéro d'immatriculation",
  "mileage": "Kilométrage",
  "accord_mileage_desc": "Les véhicules avec un faible kilométrage sont généralement moins usés, c'est pourquoi ils atteignent souvent des prix plus élevés sur le marché des voitures d'occasion.",
  "damage_history": "Historique des accidents",
  "damage_history_desc": "Les voitures qui ont été impliquées dans des accidents ont généralement une valeur inférieure en raison de dommages structurels potentiels.",
  "count_of_owners": "Nombre de propriétaires précédents",
  "count_of_owners_desc": "Les véhicules ayant eu moins de propriétaires peuvent être évalués plus haut car ils sont généralement mieux entretenus.",
  "body_color": "Couleur de la carrosserie",
  "body_color_desc": "Les couleurs populaires peuvent augmenter la valeur du véhicule, tandis que les couleurs inhabituelles peuvent la diminuer.",
  "technical_condition": "État technique",
  "technical_condition_desc": "L'état mécanique et technique du véhicule, y compris l'état du moteur, de la transmission et d'autres composants, a un impact significatif sur le prix.",
  "fuel_type": "Type de carburant",
  "fuel_type_desc": "Les tendances actuelles et les préférences du marché peuvent rendre certains types de moteurs plus désirables que d'autres.",
  "vehicle_equipment": "Équipement de la voiture",
  "vehicle_equipment_desc": "Des caractéristiques supplémentaires telles que des systèmes de navigation, des intérieurs en cuir, des systèmes audio de haute qualité peuvent augmenter la valeur du véhicule.",
  "current_market_prices": "Prix du marché actuel",
  "current_market_prices_desc": "Les prix du marché fluctuent en fonction de l'offre et de la demande pour un modèle de véhicule donné, affectant son évaluation finale.",
  "how_to_valuate_vehicle_before_sell": "Comment évaluer un véhicule avant d'acheter/vendre ?",
  "prod_year": "Année de production",
  "first_reg_date": "Date de première immatriculation",
  "contact": "Contact",
  "privacy_policy": "Politique de confidentialité",
  "regulations": "Conditions générales",
  "valuation_desc": "L'évaluation du véhicule a été préparée sur la base de l'expérience approfondie de nos experts du marché automobile. La procédure d'évaluation utilise l'intelligence artificielle (IA) pour comparer les tendances changeantes des prix des véhicules sur les marchés européen et américain. L'évaluation prend en compte des paramètres tels que l'âge et le kilométrage du véhicule. Notre évaluation prend également en compte les dernières questions automobiles et sociales telles que le manque de pièces ou de composants et l'inflation, qui affectent les prix des véhicules d'occasion.",
  "min_price": "Prix minimum",
  "med_price": "Prix moyen",
  "max_price": "Prix maximum",
  "we_have_report_for_you": "Nous avons un rapport pour ce véhicule !",
  "incident_history": "Historique des incidents",
  "odometer_reading": "Lecture du kilométrage",
  "vehicles_inspection": "Inspections de véhicule",
  "archive_photos": "Photos d'archive",
  "check_now": "VOIR LE RAPPORT",
  "unfortunately_not_found_desc": "Malheureusement, nous n'avons pas pu trouver l'année de production de ce véhicule, veuillez l'entrer dans le champ ci-dessus.",
  "type_odometer": "Entrez le kilométrage actuel du véhicule en kilomètres pour une meilleure estimation de sa valeur.",
  "check_another_vin": "Évaluer un autre véhicule",
  "what_can_affet_the_price_of_vehicle": "Qu'est-ce qui peut affecter le prix de votre véhicule ?",
  "calcualtor_of_vehicle": "Calculateur de valeur de voiture en ligne",
  "type_plate": "Entrez le numéro d'immatriculation du véhicule.",
  "type_first_reg_date": "Entrez la date de la première immatriculation du véhicule. Vous pouvez la trouver dans le document d'immatriculation au champ B.",
  "price_odometer": "Prix",
  "price_year": "Prix",
  "year": "Année",
  "regulations_admission": "<p>Ce règlement définit les règles d'utilisation du site Web exploité sous le nom VinValuation à l'adresse internet www.vinValuation.com (ci-après dénommé le Site). Ce règlement contient les règles d'utilisation du Site. Si vous n'acceptez pas l'application de ce règlement ou de l'une de ses dispositions, vous devez immédiatement quitter ce Site.</p><p>Le nom complet de notre société est Car Data Experts LTD. Unité 4E, enterprise court, Farfield park, Rotherham S63 5DB. Numéro d'enregistrement des sociétés : 10327199. Vous pouvez nous contacter par e-mail à : <a href=mailto:{email}>{email}</a></p><p>Le principal objectif du Site est de fournir à ses utilisateurs la possibilité d'entrer des données sur des véhicules volés ou endommagés dans la base de données Car Data (Données du véhicule) et de partager ces données avec d'autres personnes utilisant cette base de données.</p><p>Les services disponibles sur le Site sont destinés aux particuliers, aux personnes morales et à d'autres entités ayant un domicile ou un bureau enregistré dans l'Union européenne ou aux États-Unis d'Amérique.</p><p>Les services sur le Site sont exclusivement destinés aux personnes ayant au moins 18 ans.</p><p>Les règles de traitement des données personnelles et d'utilisation des fichiers appelés cookies sont présentées dans notre Politique de confidentialité disponible sur le Site.</p><p>Pour utiliser le Site, l'utilisateur doit utiliser un appareil avec accès à Internet et un navigateur Web Internet Explorer 9.0 ou un programme similaire. Pour utiliser certaines fonctionnalités du Site, il est nécessaire d'utiliser les logiciels Java et Java Script, ainsi que de permettre l'utilisation de cookies. Nous ne sommes pas responsables de l'absence d'accès aux données publiées sur notre Site en raison du fait que l'appareil de l'utilisateur ne répond pas aux exigences techniques spécifiées ci-dessus.</p>',",
  "regulations_definition": "<p>Dans ce règlement, les termes définis ci-dessous auront les significations suivantes :</p><p>Données sur les véhicules désigne les données concernant les véhicules volés ou endommagés et les circonstances du vol ou des dommages créées par les utilisateurs du site dans la base de données Car Data et mises à la disposition d'autres personnes utilisant cette base de données.</p><p>Consommateur désigne toute personne physique agissant à des fins non liées à des activités commerciales ou professionnelles.</p><p>Base de données désigne notre base de données Car Data sur l'état technique et l'historique des véhicules.</p><p>Numéro VIN désigne le numéro d'identification du véhicule (Vehicle Identification Number), utilisé par l'industrie automobile pour identifier une instance spécifique d'un véhicule à moteur, d'un tracteur, d'une moto, d'un scooter et d'un cyclomoteur, tel que défini dans la norme ISO 3833.</p>',",
  "regulations_license_desc": "<p>Sous réserve d'autres dispositions, tous les droits de propriété intellectuelle sur le site et sur les matériaux contenus sur le site nous appartiennent ou appartiennent à nos concédants de licence. Sous réserve de la licence ci-dessous, tous les droits de propriété intellectuelle sont réservés.</p> <p>Les utilisateurs peuvent consulter, télécharger des fichiers pour mise en cache, uniquement pour leur propre usage, sous réserve des exceptions indiquées ci-dessous et dans d'autres parties des conditions de service.</p><p>Les actions suivantes sont particulièrement interdites :</p><p>a. distribution de matériaux obtenus à partir du site (y compris la distribution sur un autre site web) ;</p><p>b. vente, location, prêt, don, transfert ou octroi de sous-licences concernant le site et les matériaux situés sur le site ;</p><p>c. représentation publique de tout matériel disponible sur le site ;</p><p>d. reproduction, duplication, copie ou utilisation d'une autre manière des matériaux collectés sur le site à des fins commerciales ;</p><p>e. altération ou modification de toute autre manière des matériaux situés sur le site ;</p><p>f. distribution de matériaux obtenus à partir du site sans obtenir notre consentement écrit explicite pour ce qui précède.</p>",
  "regulations_use_page": "<p>1. Il est interdit d'utiliser le Site de manière nuisible ou qui affecte négativement la capacité d'utiliser le Site ; ou de toute autre manière qui est illégale, illicite, nuisible, ou liée à tout but ou action illégale, illicite ou nuisible. Nous nous réservons tous les droits sur toutes les informations et matériaux, y compris graphiques et autres, disponibles sur le Site.</p><p>2. Il est interdit d'utiliser le Site pour copier, utiliser, stocker, transmettre, envoyer, publier ou distribuer des matériaux contenant des logiciels espions, virus, chevaux de Troie, vers, enregistreurs de frappe, rootkits, ou tout autre logiciel ou dispositif nuisible, ou d'y faire référence.</p><p>3. Toute collecte de données systématique ou automatisée à partir du Site ou de données liées au Site (y compris l'utilisation des techniques suivantes : scraping, data mining, extraction de données et récolte de données) est interdite sans notre consentement écrit.</p><p>4. Il est interdit d'utiliser le Site pour transmettre ou envoyer des informations commerciales non sollicitées.</p><p>5. Il est interdit d'utiliser le Site à des fins de marketing sans notre consentement écrit.</p>",
  "regulations_limited_access": "<p>1. L'accès à certaines ressources du Site est restreint. Nous nous réservons le droit de limiter l'accès à d'autres ressources du Site ou de restreindre l'accès à l'ensemble du Site, sous réserve des droits des utilisateurs découlant d'accords précédemment conclus.</p><p>2. Dans le cas où l'utilisateur obtient un nom d'utilisateur ou un nom d'utilisateur et un mot de passe de notre part permettant d'accéder à des ressources restreintes sur le Site ou à d'autres contenus ou services à accès restreint, l'utilisateur est tenu de sécuriser le mot de passe et le nom d'utilisateur afin qu'ils ne soient pas divulgués à un tiers.</p>",
  "regulations_user_posted": "<p>1. Le contenu généré par l'utilisateur ne doit pas être illégal, violer les principes de coexistence sociale, la Netiquette ou d'autres coutumes établies, ni enfreindre les droits de tiers, ni donner lieu à des réclamations ou actions en justice contre l'utilisateur, nous ou tout tiers (dans tous les cas, en vertu de toute loi applicable).</p><p>2. Il est interdit de publier tout contenu sur le Site qui est ou a été l'objet de litiges en cours ou menacés ou de procédures similaires.</p><p>3. Nous nous réservons le droit de modifier ou de supprimer tout contenu ou matériel publié sur le Site, stocké ou publié là.</p><p>4. Sous réserve des dispositions de ces Conditions et de la loi applicable concernant le contenu et autres matériaux publiés sur le Site par l'utilisateur, nous ne sommes pas obligés de surveiller le contenu et les matériaux publiés ou publiés sur le Site.</p><p>5. Toute donnée publiée par l'utilisateur sur le Site doit également être conservée sur ses propres appareils pour une protection supplémentaire contre la perte.</p>",
  "regulations_placing_data_in_db": "<p>1. L'utilisateur fournit des Données de Véhicule dans la Base de Données en utilisant le formulaire publié sur le Site.</p><p>2. L'utilisateur est responsable de l'exactitude des Données de Véhicule par rapport à l'état actuel des choses. En cas de changement, l'utilisateur doit rapidement mettre à jour les Données de Véhicule fournies dans la Base de Données.</p><p>3. Après avoir soumis les Données de Véhicule à la Base de Données, l'utilisateur reçoit un e-mail de confirmation à l'adresse qu'il a fournie, confirmant le placement de ces données dans la Base de Données.</p><p>4. Les Données de Véhicule sont placées dans la Base de Données et mises à la disposition d'autres entités utilisant cette base de données, afin d'obtenir des informations sur un véhicule spécifique identifié par son numéro VIN.</p><p>5. Si de nouvelles informations concernant les Données de Véhicule apparaissent dans la Base de Données, nous essaierons d'informer l'utilisateur qui a soumis les Données de Véhicule via l'adresse e-mail fournie.</p><p>6. Les Données de Véhicule peuvent être retirées de la Base de Données en nous contactant via le formulaire de contact sur le Site ou en écrivant à l'adresse e-mail fournie dans l'Introduction aux Conditions Générales.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Pour éviter tout doute, sur la base de l'accord utilisateur pour la fourniture de données sur les véhicules dans la base de données, nous ne sommes pas obligés de localiser le véhicule auquel les données sur le véhicule se rapportent.</p><p>2. Dans le cas des utilisateurs qui ne sont pas des consommateurs, dans la mesure permise par la loi applicable, nous ne faisons aucune déclaration, ne fournissons aucune garantie concernant la qualité, excluons toute responsabilité et ne faisons aucune déclaration concernant les normes de service fournies sur le site et l'utilisation du site (en particulier, nous excluons toutes les garanties, ne faisons aucune déclaration prévue par la loi applicable concernant la qualité satisfaisante, l'adéquation à un but, ou l'exercice de la diligence et des compétences requises).</p><p>3. En cas de problèmes techniques sur le site ou de constatation d'informations incomplètes ou obsolètes fournies par nos soins, ainsi que de toute plainte concernant le site (plaintes), vous devez nous en informer rapidement en utilisant le formulaire sur la page de contact ou en nous écrivant à l'adresse e-mail indiquée ci-dessus dans l'introduction aux termes et conditions. Nous nous efforçons de répondre rapidement.</p>',",
  "regulations_liability": "<p>1. Aucune disposition de ces Conditions (a) n'exclut ou ne limite notre responsabilité pour des dommages causés par une faute intentionnelle ou une fausse déclaration intentionnelle ; (b) ne limite notre responsabilité ou celle de l'utilisateur d'une manière qui serait contraire à la loi applicable ; ou (c) n'exclut notre responsabilité ou celle de l'utilisateur si cette responsabilité ne peut être exclue en vertu de la loi applicable ; en particulier, les Conditions n'excluent pas notre responsabilité ou celle de l'utilisateur pour des dommages causés par une faute intentionnelle.</p><p>2. Les exclusions et limitations de responsabilité prévues dans les Conditions :</p><p> a. sont soumises aux limitations spécifiées au paragraphe 1 ci-dessus ;</p><p> b. se rapportent à toute responsabilité pouvant découler des Conditions ou de la Politique de Confidentialité en lien avec l'objet des Conditions et de la Politique de Confidentialité, y compris la responsabilité contractuelle, délictuelle ou similaire ;</p><p> c. ne s'appliquent pas aux Consommateurs.</p><p>3. Dans le cas des utilisateurs non-consommateurs, dans la mesure permise par la loi applicable :</p><p> a. nous ne sommes pas responsables des dommages de quelque nature que ce soit, sauf si le dommage a été causé par notre faute intentionnelle. La garantie et toute responsabilité découlant d'institutions similaires à la garantie sont par la présente exclues ;</p><p> b. nous ne sommes pas responsables de toute perte ou dommage à des données, bases de données ou logiciels, qu'ils soient les nôtres ou ceux de l'utilisateur.</p><p>4. Nous ne sommes pas responsables des dommages survenant en lien avec un événement ou des événements échappant à notre contrôle.</p>",
  "regulations_relief": "<p>Les utilisateurs qui ne sont pas des Consommateurs nous indemnisent contre toute perte, dommage, compensation, coût, responsabilité et dépense (y compris les coûts liés à toute procédure et toute somme versée par nous à un tiers en tant que règlement) liés à toute violation par l'utilisateur de ces Conditions Générales, et sont tenus de nous rembourser les coûts engagés par nous comme mentionné ci-dessus.</p>",
  "regulations_violations": "<p>Sous réserve de tous nos autres droits découlant de ces Conditions, en cas de violation de ces Conditions par l'utilisateur de quelque manière que ce soit, nous pouvons prendre les mesures que nous jugeons appropriées pour répondre à la violation, ce qui peut inclure la suspension de l'accès de l'utilisateur au Site, l'empêchement de l'utilisateur d'accéder au Site, le blocage de l'accès au Site pour une adresse IP spécifique, contacter le fournisseur d'accès Internet de l'utilisateur pour bloquer l'accès au Site, ou engager des poursuites judiciaires.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Les règlements peuvent être modifiés en plaçant sa nouvelle version sur le Site. Si l'utilisateur nous fournit ses données personnelles, en particulier son adresse e-mail, et si nous lui fournissons des services pendant le changement de ces Règlements, nous l'informerons de tout changement à ces Règlements par e-mail.</p><p>2. Dans le cas des utilisateurs qui ne sont pas des Consommateurs, un changement de nos coordonnées ne constitue pas un changement de ces Règlements.</p><p>3. En cas de changement de l'adresse e-mail fournie lors de l'inscription, les utilisateurs sont tenus de mettre à jour cette adresse sur le Site.</p>",
  "regulations_transfer": "<p>Les utilisateurs ne peuvent pas transférer, partager avec d'autres ou autrement disposer de leurs droits et obligations découlant de ces Règlements, ni encourir des obligations concernant ces droits et obligations.</p>",
  "regulations_severability_clause": "<p>Si une disposition de ces Règlements est jugée invalide ou inefficace par un tribunal ou une autre autorité compétente, les dispositions restantes des Règlements resteront contraignantes. Si une disposition invalide ou inefficace serait valide après la suppression de sa partie, cette partie sera considérée comme supprimée, et la partie restante de la disposition sera considérée comme valide.</p>",
  "regulations_exclusion_parts": "<p>Les Règlements définissent exclusivement la relation entre nous et les utilisateurs du Site et ne confèrent aucun droit aux tiers ni ne les autorisent à invoquer l'une de ses dispositions ou à exiger l'application de ces dispositions. L'application des dispositions des Règlements ne nécessite pas le consentement d'un tiers.</p>",
  "regulations_scope_contract": "<p>Ce Règlement, ainsi que notre Politique de Confidentialité et les prix des services indiqués sur le Site, constitue un accord entre nous et l'utilisateur concernant l'utilisation du Site et remplace tout règlement précédent concernant l'utilisation du Site.</p>",
  "regulations_governing_law": "<p>1. Ce règlement est soumis au droit polonais, et en ce qui concerne les questions non régies par le règlement, les dispositions de la loi du 23 avril 1964, le Code civil, la loi du 30 mai 2014, sur les droits des consommateurs (en relation avec les consommateurs), et la loi du 18 juillet 2002, sur la fourniture de services électroniques s'appliqueront.</p><p>2. Dans le cas des utilisateurs qui ne sont pas des consommateurs, tout litige découlant du règlement sera soumis à la compétence exclusive des tribunaux polonais.</p><p>3. Dans le cas des utilisateurs qui sont des consommateurs, dont le lieu de résidence est situé en Pologne au moment de la conclusion de l'accord pour la fourniture de données sur les véhicules, tout litige lié à ce règlement ou à la politique de confidentialité sera soumis à la compétence exclusive des tribunaux polonais.</p>",
  "back": "Retour",
  "definition": "Définitions",
  "admission": "Introduction",
  "license_use_page": "Licence d'utilisation du site et des matériaux disponibles sur celui-ci",
  "use_page": "Utilisation du site",
  "limited_access": "Accès limité",
  "user_posted_content": "Contenu publié par l'utilisateur",
  "placing_vehicle_data_in_db": "Saisie des données du véhicule dans la base de données",
  "disclaimer_of_liability": "Avertissement",
  "relief_from_obligations": "Libération des obligations",
  "violations_of_regulations": "Violations des règlements",
  "transfer": "Cession",
  "severability_clause": "Clause salvatrice",
  "exclusion_third_parts": "Exclusion des droits des tiers",
  "scope_of_contract": "Champ d'application de l'accord",
  "changing_of_provisions_regulations": "Modification des dispositions des règlements",
  "governing_law_and_jurisdiction": "Droit applicable et juridiction",
  "limited_and_warranties_complaints": "Représentations et garanties limitées et dépôt de plaintes",
  "main_tip": "Utilisez notre site Web pour évaluer les voitures en ligne en utilisant le numéro VIN. De cette façon, vous découvrirez rapidement et avec précision combien vaut votre voiture. Il vous suffit d'entrer le numéro VIN de votre voiture, et nous vous aiderons à évaluer sa valeur. N'attendez pas, vérifiez-le maintenant !",
  "enter_vin_number": "Entrer le VIN",
  "meta_title": "Calculateur d'évaluation de voiture en ligne - évaluez rapidement et avec précision votre voiture.",
  "meta_desc": "Évaluation de voiture en ligne, évaluez rapidement et avec précision la valeur de votre voiture. Notre calculateur fournira le prix pour l'année modèle vérifiée du véhicule et légèrement plus anciennes et plus récentes pour comparaison.",
  "h1_title": "L'évaluation de voiture en ligne nécessite uniquement le VIN",
  "check_valuation": "Vérifiez l'évaluation !",
  "next": "Suivant",
  "help": "Aide",
  "no_data_valuation": "Pas de données pour l'évaluation",
  "check_if_stolen": "Vérifiez si volé",
  "decode_vin_number": "Décodez le numéro VIN",
  "additional_links": "Liens supplémentaires",
  "check_vin_correct": "Vérifiez la validité du numéro VIN",
  "how_to_vin_valuation": "Comment évaluer un véhicule avant d'acheter/vendre ?",
  "calculator_valuation": "Calculateur d'évaluation de voiture en ligne",
  "calculator_valuation_desc": "Il existe de nombreuses méthodes pour estimer la valeur d'une voiture ; la plus rapide et relativement précise consiste à la vérifier sur un site comme vinvaluation. Notre algorithme propriétaire, soutenu par l'intelligence artificielle (IA), agrège des données provenant de milliers d'annonces en ligne, analysant précisément l'âge, la marque, l'équipement et l'état des véhicules proposés. Sur cette base, nous estimons combien la voiture que vous vérifiez devrait valoir. Vous recevez l'évaluation en quelques instants sans quitter votre domicile, complètement gratuitement. Nous avons seulement besoin de quelques paramètres tels que le numéro VIN ou la date d'immatriculation du véhicule. En réponse, vous recevrez non seulement l'évaluation de l'année que vous vérifiez, mais aussi de plusieurs modèles voisins, plus jeunes et plus anciens. Cela vous donne une vue d'ensemble complète pour savoir, par exemple, s'il vaut encore la peine d'en être propriétaire ou si vous ne perdrez pas trop et qu'il serait préférable de la vendre dès maintenant.",
  "you_get_valuation_for_free": "<strong>Vous recevez l'évaluation</strong> en quelques instants sans quitter votre domicile, complètement gratuitement. Nous avons seulement besoin de quelques paramètres tels que le numéro VIN ou la date d'immatriculation du véhicule.",
  "in_response_you_receive_not_only_valuation": "En réponse, vous recevrez non seulement l'évaluation de l'année que vous vérifiez, mais aussi plusieurs modèles voisins, plus jeunes et plus anciens. Cela vous donne une vue d'ensemble complète pour savoir, par exemple, s'il vaut encore la peine d'en être propriétaire ou si vous ne perdrez pas trop et qu'il serait bon de la vendre dès maintenant.",
  "compare_market_price": "Comparez les prix sur le marché",
  "what_we_offer_free": "Ce que nous offrons gratuitement, vous pouvez bien sûr le faire manuellement. Parcourez <strong>les annonces sur Internet</strong> ou visitez quelques <strong>concessions automobiles</strong> pour découvrir les prix actuels des véhicules proposés.",
  "if_you_are_not_sure": "Si vous n'êtes pas sûr des informations nécessaires pour évaluer une voiture, vous pouvez contacter un expert en automobile ou vous adresser à un concessionnaire ou à une concession automobile. Ils pourront également vous aider à estimer la valeur de votre voiture. Cependant, gardez à l'esprit que le prix proposé par le concessionnaire ou le revendeur sera probablement significativement inférieur. Ils doivent également réaliser un bénéfice en achetant la voiture chez vous. Peu importe la méthode que vous choisissez, rappelez-vous que l'évaluation de la voiture n'est qu'un prix approximatif. La valeur finale du véhicule dépend de nombreux facteurs, tels que l'état technique, l'équipement, l'historique d'entretien et l'impression générale que la voiture laisse à un acheteur potentiel. Il est important de vérifier soigneusement l'état de la voiture avant de la vendre ou de l'acheter. Lorsque vous prévoyez d'acheter une voiture, n'oubliez pas de vérifier l'historique du véhicule.",
  "regardless_of_which_method": "Peu importe la méthode que vous choisissez, rappelez-vous que l'évaluation de la voiture n'est qu'un prix approximatif. La valeur finale du véhicule dépend de nombreux facteurs, tels que l'état technique, l'équipement, l'historique d'entretien et l'impression générale que la voiture laisse à un acheteur potentiel. Il est important de vérifier soigneusement l'état de la voiture avant de la vendre ou de l'acheter.",
  "check_the_valuation": "Vérifiez l'historique du véhicule",
  "you_be_may_interested": "Vous pourriez également être intéressé par :",
  "check": "VÉRIFIER",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Obtenez des informations de base sur votre véhicule gratuitement",
  "get_basic_infotmation_about_vehicle_desc": "telles que la marque, le modèle, l'année de fabrication et les spécifications techniques en entrant le VIN dans le champ de recherche. Pour des données plus détaillées, telles que l'historique des accidents, le kilométrage et le statut légal du véhicule, le site propose également l'option d'acheter un rapport VIN complet.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Vérifiez plusieurs bases de données de voitures volées,",
  "stolen_cars_desc": "en utilisant des outils de recherche avancés. Il suffit d'entrer le VIN ou le numéro d'immatriculation du véhicule, et le système recherchera dans une vaste base de données pour vérifier si la voiture a été signalée comme volée.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Vérifiez la validité du VIN.",
  "vin_correct_desc": "l'outil vérifie si le VIN est conforme aux normes actuelles et contient toutes les informations nécessaires. Cela garantit que le VIN n'a pas été falsifié ou mal enregistré.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "L'histoire de l'automobile de A à Z en un seul endroit.",
  "easy_vin_desc": "Vérifiez vos connaissances sur le véhicule qui vous intéresse et évitez les mauvaises surprises lors de l'achat d'une nouvelle voiture. Vérifiez le numéro VIN quand vous le souhaitez. Les rapports sont générés 24 heures sur 24, 7 jours sur 7.",
  "find_out_the_value_of_your_car_desc": "Vous souhaitez connaître la valeur de votre voiture ou vérifier la valeur de votre véhicule avant de l'acheter ? Profitez de notre évaluation gratuite de votre véhicule ! Avec un numéro VIN et quelques étapes supplémentaires, nous pouvons fournir une analyse détaillée. C'est simple, rapide et gratuit ! N'attendez pas, vérifiez la valeur de votre voiture maintenant !",
  "enter_te_vin_number": "Entrez le numéro VIN à 17 chiffres",
  "enter_mileage": "Fournir le kilométrage du véhicule",
  "enter_regplate": "Entrez le numéro de plaque d'immatriculation",
  "tip": "Fait amusant :",
  "odometer_readings": "Kilométrage du véhicule",
  "number_of_regplate": "Numéro de plaque d'immatriculation",
  "valuation_incoming": "La citation est en cours de chargement",
  "pl_country": "Pologne",
  "en_country": "Grande-Bretagne",
  "de_country": "Allemagne",
  "cs_country": "La République tchèque",
  "lt_country": "Lituanie",
  "fr_country": "France",
  "es_country": "Espagne",
  "ro_country": "Roumanie",
  "nl_country": "Pays-Bas",
  "hu_country": "Hongrie",
  "bg_country": "Bulgarie",
  "da_country": "Danemark",
  "el_country": "Grèce",
  "it_country": "Italie",
  "no_country": "Norvège",
  "pt_country": "Portugal",
  "sk_country": "Slovaquie",
  "sr_country": "Serbie",
  "sv_country": "Suède",
  "tr_country": "Turquie",
  "ar_country": "Arabie Saoudite",
  "fact_model_t": "La première voiture produite en série fut la Ford modèle T, qui sortit de la chaîne de montage en 1908.",
  "fact_bugatti": "La voiture la plus chère du monde est la Bugatti La Voiture Noire, qui a coûté environ 18 millions de dollars.",
  "fact_v8": "Le moteur V8 a été créé en 1902 et sa conception était révolutionnaire pour l'industrie automobile.",
  "fact_vw_beetle": "Le modèle de voiture le plus longtemps produit est la Volkswagen Coccinelle, produite en continu pendant 65 ans, de 1938 à 2003.",
  "fact_wipers": "Les premiers essuie-glaces de voiture ont été inventés par Mary Anderson en 1903 après avoir remarqué que les conducteurs devaient sortir de leur voiture pour essuyer leurs vitres.",
  "fact_lamborghini": "Lamborghini produisait initialement des tracteurs et ne commença que plus tard à produire des voitures de sport de luxe.",
  "fact_popular_color": "La couleur de voiture la plus populaire au monde est le blanc, choisie par pas moins de 39 % des acheteurs.",
  "fact_electric_car": "La première voiture électrique a été créée en 1832, bien avant l’invention des voitures à moteur à combustion interne.",
  "fact_crank_start": "Avant l'invention du démarreur électrique, les voitures démarraient à l'aide d'une manivelle, ce qui était assez dangereux et pouvait causer des blessures graves.",
  "fact_ssc_tuatara": "La vitesse la plus rapide jamais atteinte par une voiture de série a été réalisée par la SSC Tuatara à 532,93 km/h.",
  "error_message": "Une erreur interne s'est produite. Veuillez réessayer ultérieurement.",
  "no_data_alert": "Aucune donnée disponible pour ce véhicule.",
};

