export const sk = {
  "fast_and_free": "RÝCHLO A ZADARMO",
  "vehicle_valuation": "OCENENIE VOZIDLA",
  "valuation": "OCENENIE",
  "vin": "VIN",
  "want_to_know_valuation": "Chcete vedieť hodnotu svojho auta alebo skontrolovať hodnotu vozidla pred jeho zakúpením? Využite naše bezplatné ocenenie vozidla! Stačí zadať VIN a získať presné a spoľahlivé ocenenie. S niekoľkými ďalšími krokmi môžeme poskytnúť ešte podrobnejšiu analýzu. Je to jednoduché, rýchle a zadarmo! Nečakajte – skontrolujte hodnotu svojho auta teraz!",
  "plate": "Evidenčné číslo",
  "mileage": "Najazdené kilometre",
  "accord_mileage_desc": "Vozidlá s nízkym počtom najazdených kilometrov sú zvyčajne menej opotrebované, a preto často dosahujú vyššie ceny na trhu s ojazdenými autami.",
  "damage_history": "História nehôd",
  "damage_history_desc": "Autá, ktoré sa zúčastnili nehôd, zvyčajne majú nižšiu hodnotu kvôli potenciálnemu štrukturálnemu poškodeniu.",
  "count_of_owners": "Počet predchádzajúcich majiteľov",
  "count_of_owners_desc": "Vozidlá, ktoré mali menej majiteľov, môžu mať vyššiu hodnotu, pretože sú zvyčajne lepšie udržiavané.",
  "body_color": "Farba karosérie",
  "body_color_desc": "Obľúbené farby môžu zvýšiť hodnotu vozidla, zatiaľ čo nezvyčajné farby ju môžu znížiť.",
  "technical_condition": "Technický stav",
  "technical_condition_desc": "Mechanický a technický stav vozidla, vrátane stavu motora, prevodovky a ďalších komponentov, má významný dopad na cenu.",
  "fuel_type": "Typ paliva",
  "fuel_type_desc": "Súčasné trendy a preferencie na trhu môžu robiť určité typy motorov žiadanejšími ako iné.",
  "vehicle_equipment": "Výbava auta",
  "vehicle_equipment_desc": "Dodatočné funkcie ako navigačné systémy, kožené interiéry, kvalitné audio systémy môžu zvýšiť hodnotu vozidla.",
  "current_market_prices": "Súčasné trhové ceny",
  "current_market_prices_desc": "Trhové ceny kolíšu na základe dopytu a ponuky pre daný model vozidla, čo ovplyvňuje jeho konečné ocenenie.",
  "how_to_valuate_vehicle_before_sell": "Ako oceniť vozidlo pred kúpou/predajom?",
  "prod_year": "Rok výroby",
  "first_reg_date": "Dátum prvej registrácie",
  "contact": "Kontakt",
  "privacy_policy": "Zásady ochrany osobných údajov",
  "regulations": "Podmienky a ustanovenia",
  "valuation_desc": "Ocenenie vozidla bolo pripravené na základe rozsiahlych skúseností našich odborníkov na automobilový trh. Postup ocenenia využíva umelú inteligenciu (AI) na porovnávanie meniacich sa trendov v cenách vozidiel na európskych a amerických trhoch. Ocenenie zohľadňuje parametre ako vek vozidla a najazdené kilometre. Naše ocenenie tiež zohľadňuje najnovšie automobilové a spoločenské otázky, ako je nedostatok dielov alebo komponentov a inflácia, ktoré ovplyvňujú ceny ojazdených vozidiel.",
  "min_price": "Minimálna cena",
  "med_price": "Priemerná cena",
  "max_price": "Maximálna cena",
  "we_have_report_for_you": "Máme správu pre toto vozidlo!",
  "incident_history": "História incidentov",
  "odometer_reading": "Prečítanie najazdených kilometrov",
  "vehicles_inspection": "Kontroly vozidla",
  "archive_photos": "Archívne fotografie",
  "check_now": "ZOBRAZIŤ SPRÁVU",
  "unfortunately_not_found_desc": "Žiaľ, nemohli sme nájsť rok výroby tohto vozidla, prosím zadajte ho do poľa vyššie.",
  "type_odometer": "Zadajte aktuálny počet najazdených kilometrov vozidla v kilometroch pre lepší odhad jeho hodnoty.",
  "check_another_vin": "Oceniť ďalšie vozidlo",
  "what_can_affet_the_price_of_vehicle": "Čo môže ovplyvniť cenu vášho vozidla?",
  "calcualtor_of_vehicle": "Online kalkulačka hodnoty vozidla",
  "type_plate": "Zadajte evidenčné číslo vozidla.",
  "type_first_reg_date": "Zadajte dátum prvej registrácie vozidla. Nájdete ho v registračnom dokumente v poli B.",
  "price_odometer": "Cena",
  "price_year": "Cena",
  "year": "Rok",
  "regulations_admission": "<p>Tento predpis definuje pravidlá používania webovej stránky prevádzkovanej pod názvom VinValuation na internetovej doméne www.vinValuation.com (ďalej len Stránka). Tento predpis obsahuje pravidlá používania Stránky. Ak nesúhlasíte s uplatnením tohto predpisu alebo akoukoľvek jeho časťou, mali by ste okamžite opustiť túto Stránku.</p><p>Celé meno našej spoločnosti je Car Data Experts LTD. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Číslo spoločnosti: 10327199. Môžete nás kontaktovať e-mailom na: <a href=mailto:{email}>{email}</a></p><p>Hlavným účelom Stránky je poskytnúť jej používateľom možnosť zadať údaje o ukradnutých alebo poškodených vozidlách do databázy Car Data (Údaje o vozidlách) a zdieľať tieto údaje s inými jednotlivcami, ktorí používajú túto databázu.</p><p>Služby dostupné na Stránke sú určené pre jednotlivcov, právnické osoby a iné subjekty, ktoré majú bydlisko alebo registrovanú kanceláriu v Európskej únii alebo Spojených štátoch amerických.</p><p>Služby na Stránke sú určené výhradne pre jednotlivcov, ktorí majú aspoň 18 rokov.</p><p>Pravidlá spracovania osobných údajov a používania súborov nazývaných cookies sú uvedené v našej Zásadách ochrany osobných údajov dostupných na Stránke.</p><p>Na používanie Stránky musí používateľ použiť zariadenie s prístupom na internet a webový prehliadač Internet Explorer 9.0 alebo podobný program. Na používanie niektorých funkcií Stránky je potrebné používať softvér Java a Java Script, ako aj povoliť používanie cookies. Nie sme zodpovední za nedostatok prístupu k údajom zverejneným na našej Stránke z dôvodu, že zariadenie používateľa nespĺňa vyššie uvedené technické požiadavky.</p>',",
  "regulations_definition": "<p>V tejto regulácii majú nižšie definované pojmy nasledujúce významy:</p><p>Údaje o vozidle znamenajú údaje o ukradnutých alebo poškodených vozidlách a okolnostiach krádeže alebo poškodenia vytvorené používateľmi webovej stránky v databáze údajov o vozidlách a sprístupnené iným osobám, ktoré používajú túto databázu.</p><p>Spotrebiteľ znamená akúkoľvek fyzickú osobu konajúcu na účely nesúvisiace s podnikaním alebo profesionálnymi činnosťami.</p><p>Databáza znamená našu databázu údajov o vozidlách týkajúcu sa technického stavu a histórie vozidiel.</p><p>VIN číslo znamená identifikačné číslo vozidla (Vehicle Identification Number), používané automobilovým priemyslom na identifikáciu konkrétneho exemplára motorového vozidla, traktora, motocykla, skútra a mopeda, ako je definované v norme ISO 3833.</p>',",
  "regulations_license_desc": "<p>Okrem iných ustanovení sú všetky práva duševného vlastníctva k webovej stránke a k materiálom obsiahnutým na webovej stránke vlastníctvom nás alebo našich licencovateľov. S výhradou nižšie uvedenej licencie sú všetky práva duševného vlastníctva vyhradené.</p> <p>Používatelia môžu prezerať, sťahovať súbory na cache, výlučne pre svoju vlastnú potrebu, s výhradou výnimiek uvedených nižšie a v iných častiach Podmienok služby.</p><p>Nasledujúce sú osobitne zakázané:</p><p>a. distribúcia materiálov získaných z webovej stránky (vrátane distribúcie na inej webovej stránke);</p><p>b. predaj, prenájom, požičiavanie, darovanie, prevod alebo udeľovanie sublicencií týkajúcich sa webovej stránky a materiálov umiestnených na webovej stránke;</p><p>c. verejné vystúpenie akýchkoľvek materiálov dostupných na webovej stránke;</p><p>d. reprodukovanie, duplikovanie, kopírovanie alebo inak používanie materiálov zhromaždených na webovej stránke na obchodné účely;</p><p>e. zmena alebo inak modifikovanie akýchkoľvek materiálov umiestnených na webovej stránke;</p><p>f. distribúcia materiálov získaných z webovej stránky bez získania nášho výslovného písomného súhlasu na vyššie uvedené.</p>",
  "regulations_use_page": "<p>1. Je zakázané používať stránku akýmkoľvek spôsobom, ktorý jej škodí alebo negatívne ovplyvňuje schopnosť používať stránku; alebo akýmkoľvek iným spôsobom, ktorý je nezákonný, protiprávny, škodlivý alebo súvisí s akýmkoľvek nezákonným, protiprávnym alebo škodlivým účelom alebo činnosťou. Vyhradzujeme si všetky práva na všetky informácie a materiály, vrátane grafických a iných, dostupných na stránke.</p><p>2. Je zakázané používať stránku na kopírovanie, používanie, uchovávanie, prenášanie, zasielanie, publikovanie alebo distribúciu akýchkoľvek materiálov, ktoré obsahujú akýkoľvek spyware, vírus, trójskeho koňa, červ, logger stlačených klávesov, rootkit alebo iný škodlivý softvér alebo zariadenia, alebo na ne odkazovať.</p><p>3. Akékoľvek systematické alebo automatizované zhromažďovanie údajov zo stránky alebo údajov súvisiacich so stránkou (vrátane používania nasledujúcich techník: scraping, data mining, data extraction a data harvesting) je zakázané bez nášho písomného súhlasu.</p><p>4. Je zakázané používať stránku na prenášanie alebo zasielanie nevyžiadanej komerčnej informácie.</p><p>5. Je zakázané používať stránku na akékoľvek marketingové účely bez nášho písomného súhlasu.</p>",
  "regulations_limited_access": "<p>1. Prístup k určitým zdrojom stránky je obmedzený. Vyhradzujeme si právo obmedziť prístup k iným zdrojom stránky alebo obmedziť prístup k celej stránke, s ohľadom na práva používateľov vyplývajúce z predtým uzavretých dohôd.</p><p>2. V prípade, že používateľ získa od nás používateľské meno alebo používateľské meno a heslo, ktoré umožňuje prístup k obmedzeným zdrojom na stránke alebo inému obsahu alebo službám s obmedzeným prístupom, je používateľ povinný zabezpečiť heslo a používateľské meno tak, aby nebolo zverejnené žiadnej tretej strane.</p>",
  "regulations_user_posted": "<p>1. Obsah vytvorený používateľom nesmie byť nezákonný, porušovať zásady sociálneho spolunažívania, netiketu alebo iné zavedené zvyky, ani porušovať práva tretích strán, ani vyvolávať akékoľvek nároky alebo právne kroky proti používateľovi, nám alebo akejkoľvek tretej strane (v každom prípade, podľa akéhokoľvek platného práva).</p><p>2. Je zakázané zverejňovať akýkoľvek obsah na stránke, ktorý je alebo bol predmetom akýchkoľvek prebiehajúcich alebo hroziacich súdnych sporov alebo podobných konaní.</p><p>3. Vyhradzujeme si právo upraviť alebo odstrániť akýkoľvek obsah alebo materiál zverejnený na stránke, uložený alebo publikovaný tam.</p><p>4. S ohľadom na ustanovenia týchto podmienok a platného práva týkajúceho sa obsahu a iných materiálov zverejnených na stránke používateľom, nie sme povinní monitorovať obsah a materiály zverejnené alebo publikované na stránke.</p><p>5. Akékoľvek údaje zverejnené používateľom na stránke by mali byť tiež uchovávané na ich vlastných zariadeniach pre dodatočnú ochranu pred stratou.</p>",
  "regulations_placing_data_in_db": "<p>1. Používateľ poskytuje údaje o vozidle v databáze pomocou formulára zverejneného na stránke.</p><p>2. Používateľ je zodpovedný za presnosť údajov o vozidle v súvislosti so súčasným stavom. V prípade akýchkoľvek zmien by mal používateľ okamžite aktualizovať údaje o vozidle poskytnuté v databáze.</p><p>3. Po odoslaní údajov o vozidle do databázy dostane používateľ potvrdzovací e-mail na adresu, ktorú poskytol, potvrdzujúci umiestnenie týchto údajov v databáze.</p><p>4. Údaje o vozidle sú umiestnené v databáze a sprístupnené iným subjektom používajúcim túto databázu, aby získali informácie o konkrétnom vozidle identifikovanom jeho VIN číslom.</p><p>5. Ak sa v databáze objavia nové informácie týkajúce sa údajov o vozidle, pokúsime sa informovať používateľa, ktorý údaje o vozidle odoslal, prostredníctvom e-mailovej adresy, ktorú poskytol.</p><p>6. Údaje o vozidle môžu byť stiahnuté z databázy kontaktovaním nás prostredníctvom kontaktného formulára na stránke alebo písaním na e-mailovú adresu uvedenú v úvode k podmienkam a pravidlám.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Pre vylúčenie pochybností, na základe používateľskej dohody o poskytovaní údajov o vozidlách v databáze, nie sme povinní lokalizovať vozidlo, na ktoré sa údaje o vozidle vzťahujú.</p><p>2. V prípade používateľov, ktorí nie sú spotrebiteľmi, v rozsahu povolenom platným právom, neposkytujeme žiadne vyhlásenia, neposkytujeme žiadne záruky týkajúce sa kvality, vylučujeme zodpovednosť a neposkytujeme žiadne vyhlásenia týkajúce sa štandardov služieb poskytovaných na stránke a používania stránky (najmä vylučujeme všetky záruky, neposkytujeme žiadne vyhlásenia podľa platného práva týkajúce sa uspokojivej kvality, vhodnosti na účel alebo vykonávania náležitej starostlivosti a zručnosti).</p><p>3. V prípade, že si všimnete technické problémy na stránke alebo si všimnete neúplné alebo zastarané informácie poskytnuté nami, ako aj akékoľvek sťažnosti týkajúce sa stránky (sťažnosti), mali by ste nás okamžite informovať pomocou formulára na stránke Kontakt alebo nám napísať na e-mailovú adresu uvedenú vyššie v Úvode k podmienkam a pravidlám. Snažíme sa odpovedať promptne.</p>',",
  "regulations_liability": "<p>1. Žiadne ustanovenie v týchto podmienkach (a) nevylučuje ani neobmedzuje našu zodpovednosť za škodu spôsobenú úmyselnou chybou alebo úmyselným uvedením v omyl; (b) neobmedzuje našu alebo používateľovu zodpovednosť akýmkoľvek spôsobom, ktorý by bol v rozpore s platným právom; alebo (c) nevylučuje našu alebo používateľovu zodpovednosť, ak takáto zodpovednosť nemôže byť vylúčená podľa platného práva; najmä, podmienky nevylučujú našu alebo používateľovu zodpovednosť za škody spôsobené úmyselnou chybou.</p><p>2. Vylúčenia a obmedzenia zodpovednosti uvedené v podmienkach:</p><p> a. sú podrobené obmedzeniam uvedeným v odseku 1 vyššie;</p><p> b. sa týkajú akejkoľvek zodpovednosti, ktorá môže vzniknúť na základe podmienok alebo zásad ochrany osobných údajov v súvislosti s predmetom podmienok a zásad ochrany osobných údajov, vrátane zmluvnej, deliktné alebo podobnej zodpovednosti;</p><p> c. sa nevzťahujú na spotrebiteľov.</p><p>3. V prípade používateľov, ktorí nie sú spotrebiteľmi, do tej miery, ako to umožňuje platné právo:</p><p> a. nie sme zodpovední za žiadne škody akéhokoľvek druhu, pokiaľ škoda nebola spôsobená našou úmyselnou chybou. Záruka a akákoľvek zodpovednosť vyplývajúca z podobných inštitúcií k záruke sú týmto vylúčené;</p><p> b. nie sme zodpovední za žiadnu stratu alebo škodu na akýchkoľvek údajoch, databázach alebo softvéri, či už našich alebo používateľových.</p><p>4. Nie sme zodpovední za žiadne škody vznikajúce v súvislosti s udalosťou alebo udalosťami, ktoré sú mimo našu kontrolu.</p>",
  "regulations_relief": "<p>Používatelia, ktorí nie sú spotrebiteľmi, nás odškodňujú za akékoľvek straty, škody, náhrady, náklady, zodpovednosti a výdavky (vrátane nákladov súvisiacich s akýmikoľvek konaniami a akýchkoľvek súm zaplatených nami akejkoľvek tretej strane ako vyrovnanie) súvisiace s akýmkoľvek porušením týchto podmienok a súhlasov zo strany používateľa a sú povinní nám uhradiť náklady, ktoré sme vynaložili, ako je uvedené vyššie.</p>",
  "regulations_violations": "<p>So všetkými našimi ďalšími právami vyplývajúcimi z týchto podmienok, v prípade porušenia týchto podmienok používateľom akýmkoľvek spôsobom, môžeme prijať také opatrenia, ktoré považujeme za vhodné na reakciu na porušenie, čo môže zahŕňať pozastavenie prístupu používateľa na stránku, zabránenie používateľovi v prístupe na stránku, blokovanie prístupu na stránku pre konkrétnu IP adresu, kontaktovanie poskytovateľa internetu používateľa na blokovanie prístupu na stránku alebo podniknutie právnych krokov.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Predpisy môžu byť zmenené zverejnením ich novej verzie na stránke. Ak nám používateľ poskytne svoje osobné údaje, najmä svoju e-mailovú adresu, a ak mu poskytujeme služby počas zmeny týchto predpisov, budeme ho informovať o akýchkoľvek zmenách týchto predpisov prostredníctvom e-mailu.</p><p>2. V prípade používateľov, ktorí nie sú spotrebiteľmi, zmena našich kontaktných údajov nepredstavuje zmenu týchto predpisov.</p><p>3. V prípade zmeny e-mailovej adresy poskytnutej počas registrácie sú používatelia povinní aktualizovať túto adresu na stránke.</p>",
  "regulations_transfer": "<p>Používatelia nesmú prenášať, zdieľať s inými alebo inak nakladať so svojimi právami a povinnosťami vyplývajúcimi z týchto predpisov, ani sa zaväzovať k povinnostiam týkajúcim sa týchto práv a povinností.</p>",
  "regulations_severability_clause": "<p>Ak je akékoľvek ustanovenie týchto predpisov súdom alebo iným príslušným orgánom považované za neplatné alebo neúčinné, zostávajú ostatné ustanovenia predpisov záväzné. Ak by bolo neplatné alebo neúčinné ustanovenie platné po odstránení jeho časti, táto časť sa považuje za odstránenú a zostávajúca časť ustanovenia sa považuje za platnú.</p>",
  "regulations_exclusion_parts": "<p>Predpisy výlučne definujú vzťah medzi nami a používateľmi stránky a neprenášajú žiadne práva tretím stranám ani ich neautorizujú, aby sa odvolávali na akékoľvek z jej ustanovení alebo požadovali vynútenie týchto ustanovení. Vynútenie ustanovení predpisov nevyžaduje súhlas žiadnej tretej strany.</p>",
  "regulations_scope_contract": "<p>Tento predpis spolu s našou politikou ochrany osobných údajov a cenami služieb uvedenými na stránke predstavuje dohodu medzi nami a používateľom o používaní stránky a nahrádza akékoľvek predchádzajúce predpisy týkajúce sa používania stránky.</p>",
  "regulations_governing_law": "<p>1. Tento predpis sa riadi poľským právom a v záležitostiach, ktoré nie sú upravené týmto predpisom, sa použijú ustanovenia zákona z 23. apríla 1964, Občianskeho zákonníka, zákona z 30. mája 2014, o právach spotrebiteľov (v súvislosti so spotrebiteľmi) a zákona z 18. júla 2002, o poskytovaní elektronických služieb.</p><p>2. V prípade používateľov, ktorí nie sú spotrebiteľmi, sa akékoľvek spory vyplývajúce z tohto predpisu podrobia výhradnej jurisdikcii poľských súdov.</p><p>3. V prípade používateľov, ktorí sú spotrebiteľmi a ktorých miesto bydliska sa nachádza v Poľsku v čase uzatvorenia zmluvy o poskytovaní údajov o vozidle, sa akékoľvek spory súvisiace s týmto predpisom alebo Zásadami ochrany osobných údajov podrobia výhradnej jurisdikcii poľských súdov.</p>",
  "back": "Návrat",
  "definition": "Definície",
  "admission": "Úvod",
  "license_use_page": "Licencia na používanie stránky a materiálov dostupných na nej",
  "use_page": "Používanie stránky",
  "limited_access": "Obmedzený prístup",
  "user_posted_content": "Obsah zverejnený používateľom",
  "placing_vehicle_data_in_db": "Zadávanie údajov o vozidle do databázy",
  "disclaimer_of_liability": "Zrieknutie sa zodpovednosti",
  "relief_from_obligations": "Uvoľnenie z povinností",
  "violations_of_regulations": "Porušenia predpisov",
  "transfer": "Postúpenie",
  "severability_clause": "Zachovávacia klauzula",
  "exclusion_third_parts": "Vylúčenie práv tretích strán",
  "scope_of_contract": "Rozsah zmluvy",
  "changing_of_provisions_regulations": "Zmena ustanovení predpisov",
  "governing_law_and_jurisdiction": "Riadne právo a jurisdikcia",
  "limited_and_warranties_complaints": "Obmedzené vyhlásenia a záruky a podávanie sťažností",
  "main_tip": "Použite našu webovú stránku na hodnotenie áut online pomocou VIN čísla. Takto rýchlo a presne zistíte, koľko vaše auto stojí. Stačí zadať VIN číslo vášho auta a my vám pomôžeme posúdiť jeho hodnotu. Nečakajte, skontrolujte to teraz!",
  "enter_vin_number": "Zadajte VIN",
  "meta_title": "Online kalkulačka hodnotenia áut - rýchlo a presne posúďte svoje auto.",
  "meta_desc": "Online hodnotenie áut, rýchlo a presne posúďte hodnotu svojho auta. Naša kalkulačka poskytne cenu pre kontrolovaný modelový rok vozidla a o niečo staršie a mladšie pre porovnanie.",
  "h1_title": "Online hodnotenie áut vyžaduje iba VIN",
  "check_valuation": "Skontrolujte hodnotenie!",
  "next": "Ďalej",
  "help": "Pomoc",
  "no_data_valuation": "Žiadne údaje na hodnotenie",
  "check_if_stolen": "Skontrolujte, či je ukradnuté",
  "decode_vin_number": "Dekódujte VIN číslo",
  "additional_links": "Ďalšie odkazy",
  "check_vin_correct": "Skontrolujte platnosť VIN čísla",
  "how_to_vin_valuation": "Ako oceniť vozidlo pred kúpou/predajom?",
  "calculator_valuation": "Online kalkulačka hodnotenia áut",
  "calculator_valuation_desc": "Existuje mnoho metód na odhad hodnoty auta; najrýchlejšia a relatívne presná je skontrolovať to na webovej stránke ako vinvaluation. Náš proprietárny algoritmus, podporovaný umelou inteligenciou (AI), agreguje údaje z tisícov online inzerátov, presne analyzujúc vek, značku, vybavenie a stav ponúkaných vozidiel. Na základe toho odhadujeme, koľko by malo auto, ktoré kontrolujete, stáť. Odhad dostanete za pár okamihov bez toho, aby ste opustili domov, úplne zadarmo. Potrebujeme len niekoľko parametrov, ako je VIN číslo alebo dátum registrácie vozidla. V odpovedi dostanete nielen odhad roka, ktorý kontrolujete, ale aj niekoľko blízkych, mladších a starších modelov. To vám poskytuje úplný prehľad o tom, či je napríklad stále výhodné byť jeho vlastníkom, alebo ak príliš veľa nestratíte a bolo by lepšie ho predať práve teraz.",
  "you_get_valuation_for_free": "<strong>Dostanete odhad</strong> za pár okamihov bez toho, aby ste opustili domov, úplne zadarmo. Potrebujeme len niekoľko parametrov, ako je VIN číslo alebo dátum registrácie vozidla.",
  "in_response_you_receive_not_only_valuation": "V odpovedi dostanete nielen odhad roka, ktorý kontrolujete, ale aj niekoľko blízkych, mladších a starších. To vám poskytuje úplný prehľad o tom, či je napríklad stále výhodné byť jeho vlastníkom, alebo ak príliš veľa nestratíte a bolo by dobré ho predať práve teraz.",
  "compare_market_price": "Porovnajte ceny na trhu",
  "what_we_offer_free": "To, čo ponúkame zadarmo, môžete samozrejme urobiť aj manuálne. Prezrite si <strong>inzeráty na internete</strong> alebo navštívte niekoľko <strong>autosalónov</strong>, aby ste zistili aktuálne ceny ponúkaných vozidiel.",
  "if_you_are_not_sure": "Ak si nie si istý, aké informácie sú potrebné na ocenenie auta, môžeš kontaktovať odborníka na ocenenie áut alebo sa obrátiť na predajcu alebo autosalón. Tí ti tiež pomôžu odhadnúť hodnotu tvojho auta. Maj na pamäti, že cena, ktorú ponúkne autosalón alebo predajca, bude pravdepodobne výrazne nižšia. Musia tiež získať zisk z nákupu auta od teba. Bez ohľadu na to, ktorú metódu si vyberieš, pamätaj, že ocenenie auta je len približná cena. Konečná hodnota vozidla závisí od mnohých faktorov, ako sú technický stav, výbava, servisná história a celkový dojem, ktorý auto zanecháva na potenciálneho kupca. Je dôležité dôkladne skontrolovať stav auta pred jeho predajom alebo kúpou. Pri plánovaní kúpy auta nezabudni skontrolovať históriu vozidla.",
  "regardless_of_which_method": "Bez ohľadu na to, ktorú metódu si vyberieš, pamätaj, že ocenenie auta je len približná cena. Konečná hodnota vozidla závisí od mnohých faktorov, ako sú technický stav, výbava, servisná história a celkový dojem, ktorý auto zanecháva na potenciálneho kupca. Je dôležité dôkladne skontrolovať stav auta pred jeho predajom alebo kúpou.",
  "check_the_valuation": "Skontroluj históriu vozidla",
  "you_be_may_interested": "Môže ťa tiež zaujímať:",
  "check": "SKONTROLUJ",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Získaj základné informácie o svojom vozidle zadarmo",
  "get_basic_infotmation_about_vehicle_desc": "ako je značka, model, rok výroby a technické špecifikácie zadaním VIN do vyhľadávacieho poľa. Pre podrobnejšie údaje, ako je história nehôd, počet najazdených kilometrov a právny stav vozidla, stránka tiež ponúka možnosť zakúpiť si úplnú správu o VIN.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Skontroluj viaceré databázy ukradnutých áut,",
  "stolen_cars_desc": "použitím pokročilých vyhľadávacích nástrojov. Stačí zadať VIN alebo registračné číslo vozidla a systém prehľadá rozsiahlu databázu, aby skontroloval, či bolo auto nahlásené ako ukradnuté.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Skontroluj platnosť VIN.",
  "vin_correct_desc": "nástroj overuje, či VIN spĺňa aktuálne normy a obsahuje všetky potrebné informácie. To zabezpečuje, že VIN nebol falšovaný ani nesprávne zaznamenaný.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "História auta od A po Z na jednom mieste.",
  "easy_vin_desc": "Overte si svoje znalosti o vozidle, o ktoré máte záujem a ušetrite sa pred nepríjemnými prekvapeniami pri kúpe nového auta. Skontrolujte si VIN číslo kedykoľvek budete chcieť. Prehľady sa generujú 24 hodín denne, 7 dní v týždni.",
  "find_out_the_value_of_your_car_desc": "Chcete poznať hodnotu svojho auta alebo skontrolovať hodnotu svojho vozidla pred kúpou? Využite naše bezplatné ocenenie vozidla! S číslom VIN a niekoľkými krokmi navyše vám môžeme poskytnúť podrobnú analýzu. Je to jednoduché, rýchle a zadarmo! Nečakajte a skontrolujte hodnotu svojho auta už teraz!",
  "enter_te_vin_number": "Zadajte 17-miestne číslo VIN",
  "enter_mileage": "Poskytnite najazdené kilometre vozidla",
  "enter_regplate": "Zadajte ŠPZ",
  "tip": "Zábavný fakt:",
  "odometer_readings": "Počet najazdených kilometrov vozidla",
  "number_of_regplate": "ŠPZ",
  "valuation_incoming": "Cenová ponuka sa načítava",
  "pl_country": "Poľsko",
  "en_country": "Veľká Británia",
  "de_country": "Nemecko",
  "cs_country": "Česká republika",
  "lt_country": "Litva",
  "fr_country": "Francúzsko",
  "es_country": "Španielsko",
  "ro_country": "Rumunsko",
  "nl_country": "Holandsko",
  "hu_country": "Maďarsko",
  "bg_country": "Bulharsko",
  "da_country": "Dánsko",
  "el_country": "Grécko",
  "it_country": "Taliansko",
  "no_country": "Nórsko",
  "pt_country": "Portugalsko",
  "sk_country": "Slovensko",
  "sr_country": "Srbsko",
  "sv_country": "Švédsko",
  "tr_country": "Türkiye",
  "ar_country": "Saudská Arábia",
  "fact_model_t": "Prvým sériovo vyrábaným automobilom bol Ford Model T, ktorý zišiel z montážnej linky v roku 1908.",
  "fact_bugatti": "Najdrahším autom na svete je Bugatti La Voiture Noire, ktoré stálo približne 18 miliónov dolárov.",
  "fact_v8": "Motor V8 bol vytvorený už v roku 1902 a jeho dizajn bol pre automobilový priemysel revolučný.",
  "fact_vw_beetle": "Najdlhšie vyrábaným modelom auta je Volkswagen Beetle, ktorý sa vyrába nepretržite 65 rokov, od roku 1938 do roku 2003.",
  "fact_wipers": "Prvé stierače predného skla vynašla Mary Anderson v roku 1903 po tom, čo si všimla, že vodiči musia vystúpiť z auta, aby si utreli okná.",
  "fact_lamborghini": "Lamborghini spočiatku vyrábalo traktory a až neskôr začalo vyrábať luxusné športové autá.",
  "fact_popular_color": "Najobľúbenejšou farbou auta na svete je biela, ktorú volí až 39 % kupujúcich.",
  "fact_electric_car": "Prvý elektromobil vznikol v roku 1832, dávno pred vynálezom áut so spaľovacím motorom.",
  "fact_crank_start": "Pred vynájdením elektrického štartéra sa autá štartovali pomocou kľuky, čo bolo dosť nebezpečné a mohlo spôsobiť vážne zranenia.",
  "fact_ssc_tuatara": "Najvyššiu rýchlosť, akú kedy sériový automobil dosiahol, dosiahol SSC Tuatara 532,93 km/h.",
  "error_message": "Vyskytla sa interná chyba. Skúste to znova neskôr.",
  "no_data_alert": "Nie sú dostupné údaje pre toto vozidlo.",

};

