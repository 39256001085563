export const sv = {
  "fast_and_free": "SNABB OCH GRATIS",
  "vehicle_valuation": "FORDONSVÄRDERING",
  "valuation": "VÄRDERING",
  "vin": "VIN",
  "want_to_know_valuation": "Vill du veta värdet på din bil eller kontrollera värdet på ett fordon innan du köper det? Använd vår gratis fordonsvärdering! Ange bara VIN för att få en exakt och pålitlig värdering. Med några ytterligare steg kan vi ge en ännu mer detaljerad analys. Det är enkelt, snabbt och gratis! Vänta inte – kontrollera värdet på din bil nu!",
  "plate": "Registreringsnummer",
  "mileage": "Miltal",
  "accord_mileage_desc": "Fordon med lågt miltal är vanligtvis mindre slitna, vilket är anledningen till att de ofta uppnår högre priser på begagnatmarknaden.",
  "damage_history": "Olyckshistorik",
  "damage_history_desc": "Bilar som har varit inblandade i olyckor har vanligtvis ett lägre värde på grund av potentiella strukturella skador.",
  "count_of_owners": "Antal tidigare ägare",
  "count_of_owners_desc": "Fordon som har haft färre ägare kan värderas högre eftersom de vanligtvis är bättre underhållna.",
  "body_color": "Karossfärg",
  "body_color_desc": "Populära färger kan öka värdet på fordonet, medan ovanliga färger kan minska det.",
  "technical_condition": "Teknisk skick",
  "technical_condition_desc": "Det mekaniska och tekniska skicket på fordonet, inklusive motorns, växellådans och andra komponenters skick, har en betydande inverkan på priset.",
  "fuel_type": "Bränsletyp",
  "fuel_type_desc": "Nuvarande trender och marknadspreferenser kan göra vissa typer av motorer mer eftertraktade än andra.",
  "vehicle_equipment": "Biltillbehör",
  "vehicle_equipment_desc": "Ytterligare funktioner som navigationssystem, läderinteriörer, högkvalitativa ljudsystem kan öka värdet på fordonet.",
  "current_market_prices": "Nuvarande marknadspriser",
  "current_market_prices_desc": "Marknadspriser fluktuerar baserat på efterfrågan och utbudet för en given fordonsmodell, vilket påverkar dess slutliga värdering.",
  "how_to_valuate_vehicle_before_sell": "Hur värderar man ett fordon innan köp/försäljning?",
  "prod_year": "Tillverkningsår",
  "first_reg_date": "Datum för första registrering",
  "contact": "Kontakt",
  "privacy_policy": "Integritetspolicy",
  "regulations": "Villkor",
  "valuation_desc": "Fordonsvärderingen har förberetts baserat på den omfattande erfarenheten hos våra experter på bilmarknaden. Värderingsproceduren använder artificiell intelligens (AI) för att jämföra föränderliga trender i fordonspriser på europeiska och amerikanska marknader. Värderingen tar hänsyn till parametrar som fordonets ålder och miltal. Vår värdering tar också hänsyn till de senaste bil- och samhällsfrågorna som bristen på delar eller komponenter och inflation, som påverkar priserna på begagnade fordon.",
  "min_price": "Minimipris",
  "med_price": "Genomsnittligt pris",
  "max_price": "Maximipris",
  "we_have_report_for_you": "Vi har en rapport för detta fordon!",
  "incident_history": "Händelsehistorik",
  "odometer_reading": "Miltalsavläsning",
  "vehicles_inspection": "Fordoninspektioner",
  "archive_photos": "Arkivbilder",
  "check_now": "VISA RAPPORT",
  "unfortunately_not_found_desc": "Tyvärr kunde vi inte hitta tillverkningsåret för detta fordon, vänligen ange det i fältet ovan.",
  "type_odometer": "Ange det aktuella miltalet för fordonet i kilometer för en bättre uppskattning av dess värde.",
  "check_another_vin": "Värdera ett annat fordon",
  "what_can_affet_the_price_of_vehicle": "Vad kan påverka priset på ditt fordon?",
  "calcualtor_of_vehicle": "Online bilvärderingskalkylator",
  "type_plate": "Ange fordonets registreringsnummer.",
  "type_first_reg_date": "Ange datum för fordonets första registrering. Du kan hitta det i registreringsdokumentet i fält B.",
  "price_odometer": "Pris",
  "price_year": "Pris",
  "year": "År",
  "regulations_admission": "<p>Denna förordning definierar reglerna för användning av webbplatsen som drivs under namnet VinValuation på internetdomänen www.vinValuation.com (hädanefter kallad Webbplatsen). Denna förordning innehåller reglerna för användning av Webbplatsen. Om du inte godkänner tillämpningen av denna förordning eller någon av dess bestämmelser, bör du omedelbart lämna denna Webbplats.</p><p>Det fullständiga namnet på vårt företag är Car Data Experts LTD. Enhet 4E företagsdomstol, Farfield park, Rotherham S63 5DB. Företagsregistreringsnummer: 10327199. Du kan kontakta oss via e-post på: <a href=mailto:{email}>{email}</a></p><p>Syftet med Webbplatsen är att ge sina användare möjlighet att ange data om stulna eller skadade fordon i Car Data-databasen (Fordon Data) och att dela denna data med andra individer som använder denna databas.</p><p>Tjänsterna som finns tillgängliga på Webbplatsen är avsedda för individer, juridiska personer och andra enheter som har en bostad eller registrerat kontor inom Europeiska unionen eller Förenta staterna.</p><p>Tjänsterna på Webbplatsen riktar sig uteslutande till individer som är minst 18 år gamla.</p><p>Reglerna för behandling av personuppgifter och användning av filer som kallas cookies presenteras i vår integritetspolicy som finns tillgänglig på Webbplatsen.</p><p>För att använda Webbplatsen måste användaren använda en enhet med internetåtkomst och en webbläsare Internet Explorer 9.0 eller ett liknande program. För att använda vissa funktioner på Webbplatsen är det nödvändigt att använda Java och Java Script-programvara, samt att tillåta användning av cookies. Vi ansvarar inte för bristande tillgång till data som publicerats på vår Webbplats på grund av att användarens enhet inte uppfyller de tekniska krav som anges ovan.</p>',",
  "regulations_definition": "<p>I denna förordning ska de nedan definierade termerna ha följande betydelser:</p><p>Köretagsdata avser data om stulna eller skadade fordon och omständigheterna kring stöld eller skada som skapats av användare av webbplatsen i databasen för Fordonsdata och som görs tillgängliga för andra personer som använder denna databas.</p><p>Konsument avser varje fysisk person som agerar för ändamål som inte är relaterade till affärs- eller yrkesverksamhet.</p><p>Databas avser vår databas för Fordonsdata om det tekniska tillståndet och historiken för fordon.</p><p>VIN-nummer avser fordonets identifieringsnummer (Vehicle Identification Number), som används av bilindustrin för att identifiera en specifik instans av ett motorfordon, traktor, motorcykel, skoter och moped, enligt ISO 3833-standarden.</p>',",
  "regulations_license_desc": "<p>Med förbehåll för andra bestämmelser äger vi eller våra licensgivare alla immateriella rättigheter till webbplatsen och till de material som finns på webbplatsen. Med förbehåll för licensen nedan förbehålls alla immateriella rättigheter.</p> <p>Användare får visa, ladda ner filer för cachelagring, enbart för eget bruk, med förbehåll för de undantag som anges nedan och i andra delar av användarvillkoren.</p><p>Följande är särskilt förbjudet:</p><p>a. distribution av material som erhållits från webbplatsen (inklusive distribution på en annan webbplats);</p><p>b. försäljning, uthyrning, utlåning, gåva, överföring eller beviljande av underlicenser angående webbplatsen och material som finns på webbplatsen;</p><p>c. offentlig framförande av något material som finns på webbplatsen;</p><p>d. reproduktion, duplicering, kopiering eller på annat sätt användning av material som samlats in på webbplatsen för kommersiella syften;</p><p>e. ändra eller på annat sätt modifiera något material som finns på webbplatsen;</p><p>f. distribuera material som erhållits från webbplatsen utan att få vårt uttryckliga skriftliga samtycke för ovanstående.</p>",
  "regulations_use_page": "<p>1. Det är förbjudet att använda webbplatsen på något sätt som är skadligt för den eller negativt påverkar möjligheten att använda webbplatsen; eller på något annat sätt som är olagligt, otillåtet, skadligt, eller relaterat till något olagligt, otillåtet eller skadligt syfte eller handling. Vi förbehåller oss alla rättigheter till all information och material, inklusive grafiska och andra, som finns tillgängliga på webbplatsen.</p><p>2. Det är förbjudet att använda webbplatsen för att kopiera, använda, lagra, överföra, skicka, publicera eller distribuera något material som innehåller spyware, virus, trojaner, maskar, tangentbordsloggare, rootkits eller annan skadlig programvara eller enheter, eller hänvisa till dem.</p><p>3. All systematisk eller automatiserad datainsamling från webbplatsen eller data relaterad till webbplatsen (inklusive användning av följande tekniker: scraping, datamining, datautvinning och datainsamling) är förbjuden utan vårt skriftliga samtycke.</p><p>4. Det är förbjudet att använda webbplatsen för att överföra eller skicka oönskad kommersiell information.</p><p>5. Det är förbjudet att använda webbplatsen för några marknadsföringsändamål utan vårt skriftliga samtycke.</p>",
  "regulations_limited_access": "<p>1. Åtkomst till vissa resurser på webbplatsen är begränsad. Vi förbehåller oss rätten att begränsa åtkomst till andra resurser på webbplatsen eller att begränsa åtkomst till hela webbplatsen, med förbehåll för användarnas rättigheter som uppstår från tidigare ingångna avtal.</p><p>2. Om användaren får ett användarnamn eller ett användarnamn och lösenord från oss som ger åtkomst till begränsade resurser på webbplatsen eller annat innehåll eller tjänster med begränsad åtkomst, är användaren skyldig att säkra lösenordet och användarnamnet så att det inte avslöjas för någon tredje part.</p>",
  "regulations_user_posted": "<p>1. Användargenererat innehåll får inte vara olagligt, bryta mot principer för social samexistens, netikett eller andra etablerade sedvänjor, inte heller kränka några tredje parts rättigheter, eller ge upphov till några krav eller rättsliga åtgärder mot användaren, oss eller någon tredje part (i vilket fall som helst, enligt tillämplig lag).</p><p>2. Det är förbjudet att publicera något innehåll på webbplatsen som är eller har varit föremål för pågående eller hotande rättsliga processer eller liknande förfaranden.</p><p>3. Vi förbehåller oss rätten att ändra eller ta bort något innehåll eller material som publicerats på webbplatsen, lagrats eller publicerats där.</p><p>4. Med förbehåll för bestämmelserna i dessa villkor och tillämplig lag angående innehåll och annat material som publicerats på webbplatsen av användaren, är vi inte skyldiga att övervaka det innehåll och material som publicerats eller publicerats på webbplatsen.</p><p>5. Alla data som publiceras av användaren på webbplatsen bör också sparas på deras egna enheter för ytterligare skydd mot förlust.</p>",
  "regulations_placing_data_in_db": "<p>1. Användaren tillhandahåller fordonsdata i databasen med hjälp av formuläret som publicerats på webbplatsen.</p><p>2. Användaren ansvarar för noggrannheten av fordonsdata i förhållande till det aktuella tillståndet. Vid eventuella förändringar bör användaren omedelbart uppdatera de fordonsdata som tillhandahållits i databasen.</p><p>3. Efter att ha skickat fordonsdata till databasen får användaren ett bekräftelsemail på den angivna adressen, som bekräftar placeringen av dessa data i databasen.</p><p>4. Fordonsdata placeras i databasen och görs tillgängliga för andra enheter som använder denna databas, för att få information om ett specifikt fordon identifierat med sitt VIN-nummer.</p><p>5. Om ny information angående fordonsdata dyker upp i databasen, kommer vi att försöka informera användaren som skickade in fordonsdata via den angivna e-postadressen.</p><p>6. Fordonsdata kan dras tillbaka från databasen genom att kontakta oss via kontaktformuläret på webbplatsen eller genom att skriva till den angivna e-postadressen i inledningen till villkoren.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. För att undvika tvivel, baserat på användaravtalet för att tillhandahålla fordonsdata i databasen, är vi inte skyldiga att lokalisera det fordon som fordonsdatan avser.</p><p>2. I fallet med användare som inte är konsumenter, i den utsträckning som tillåts av tillämplig lag, gör vi inga uttalanden, ger inga garantier angående kvalitet, utesluter ansvar och gör inga uttalanden angående servicenivåerna som tillhandahålls på webbplatsen och användningen av webbplatsen (i synnerhet utesluter vi alla garantier, gör inga uttalanden som tillhandahålls av tillämplig lag angående tillfredsställande kvalitet, lämplighet för ändamålet eller utövande av omsorg och skicklighet).</p><p>3. Om du märker tekniska problem på webbplatsen eller märker ofullständigheter eller föråldrad information som tillhandahålls av oss, samt eventuella klagomål angående webbplatsen (klagomål), bör du omedelbart meddela oss med hjälp av formuläret på kontaktsidan eller genom att skriva till oss på den e-postadress som anges ovan i inledningen till villkoren. Vi strävar efter att svara snabbt.</p>',",
  "regulations_liability": "<p>1. Ingen bestämmelse i dessa villkor (a) utesluter eller begränsar vårt ansvar för skada orsakad av avsiktligt fel eller avsiktlig vilseledning; (b) begränsar vårt eller användarens ansvar på något sätt som skulle strida mot tillämplig lag; eller (c) utesluter vårt eller användarens ansvar om sådant ansvar inte kan uteslutas enligt tillämplig lag; särskilt utesluter villkoren inte vårt eller användarens ansvar för skador orsakade av avsiktligt fel.</p><p>2. Uteslutningar och begränsningar av ansvar som anges i villkoren:</p><p> a. är föremål för de begränsningar som anges i punkt 1 ovan;</p><p> b. rör allt ansvar som kan uppstå enligt villkoren eller sekretesspolicyn i samband med ämnet för villkoren och sekretesspolicyn, inklusive avtalsenligt, skadeståndsrättsligt eller liknande ansvar;</p><p> c. gäller inte för konsumenter.</p><p>3. I fallet med icke-konsumentanvändare, i den utsträckning som tillämplig lag tillåter:</p><p> a. vi är inte ansvariga för några skador av något slag om inte skadan orsakades av vårt avsiktliga fel. Garanti och allt ansvar som uppstår från liknande institutioner till garanti utesluts härmed;</p><p> b. vi är inte ansvariga för någon förlust eller skada på några data, databaser eller programvara, oavsett om de tillhör oss eller användaren.</p><p>4. Vi är inte ansvariga för några skador som uppstår i samband med en händelse eller händelser utanför vår kontroll.</p>",
  "regulations_relief": "<p>Användare som inte är konsumenter ska hålla oss skadeslösa mot eventuella förluster, skador, ersättningar, kostnader, ansvar och utgifter (inklusive kostnader relaterade till eventuella rättsliga förfaranden och eventuella belopp som betalats av oss till tredje part som en uppgörelse) relaterade till eventuella överträdelser av dessa villkor av användaren, och är skyldiga att ersätta oss för de kostnader som vi ådragit oss som nämnts ovan.</p>",
  "regulations_violations": "<p>Med förbehåll för alla våra andra rättigheter som följer av dessa villkor, i händelse av en överträdelse av dessa villkor av användaren på något sätt, kan vi vidta sådana åtgärder som vi anser lämpliga för att svara på överträdelsen, vilket kan inkludera att stänga av användarens åtkomst till webbplatsen, förhindra användaren från att få åtkomst till webbplatsen, blockera åtkomst till webbplatsen för en specifik IP-adress, kontakta användarens internetleverantör för att blockera åtkomst till webbplatsen, eller vidta rättsliga åtgärder.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Reglerna kan ändras genom att den nya versionen publiceras på webbplatsen. Om användaren tillhandahåller oss sina personuppgifter, särskilt sin e-postadress, och om vi tillhandahåller tjänster till dem under ändringen av dessa regler, kommer vi att informera dem om eventuella ändringar av dessa regler via e-post.</p><p>2. När det gäller användare som inte är konsumenter utgör en ändring av vår kontaktinformation ingen ändring av dessa regler.</p><p>3. Vid en ändring av den e-postadress som angavs vid registreringen är användarna skyldiga att uppdatera denna adress på webbplatsen.</p>",
  "regulations_transfer": "<p>Användare får inte överföra, dela med andra eller på annat sätt avhända sig sina rättigheter och skyldigheter som följer av dessa regler, inte heller ådra sig skyldigheter avseende dessa rättigheter och skyldigheter.</p>",
  "regulations_severability_clause": "<p>Om någon bestämmelse i dessa regler befinns vara ogiltig eller ineffektiv av en domstol eller annan behörig myndighet, ska de återstående bestämmelserna i reglerna förbli bindande. Om en ogiltig eller ineffektiv bestämmelse skulle vara giltig efter att dess del tagits bort, ska den delen anses vara borttagen, och den återstående delen av bestämmelsen ska anses vara giltig.</p>",
  "regulations_exclusion_parts": "<p>Reglerna definierar exklusivt förhållandet mellan oss och användarna av webbplatsen och ger inga rättigheter till tredje part, inte heller auktoriserar dem att åberopa någon av dess bestämmelser eller kräva verkställighet av dessa bestämmelser. Verkställigheten av bestämmelserna i reglerna kräver inte samtycke från någon tredje part.</p>",
  "regulations_scope_contract": "<p>Dessa regler, tillsammans med vår integritetspolicy och de tjänstepriser som anges på webbplatsen, utgör ett avtal mellan oss och användaren angående användningen av webbplatsen och ersätter eventuella tidigare regler avseende användningen av webbplatsen.</p>",
  "regulations_governing_law": "<p>1. Denna förordning omfattas av polsk lag, och i frågor som inte regleras av förordningen ska bestämmelserna i lagen den 23 april 1964, civilkoden, lagen den 30 maj 2014, om konsumenträttigheter (i förhållande till konsumenter), och lagen den 18 juli 2002, om tillhandahållande av elektroniska tjänster tillämpas.</p><p>2. För användare som inte är konsumenter ska eventuella tvister som uppstår enligt förordningen omfattas av polska domstolars exklusiva jurisdiktion.</p><p>3. För användare som är konsumenter, vars bostadsort ligger i Polen vid tidpunkten för ingåendet av avtalet om tillhandahållande av fordonsdata, ska eventuella tvister relaterade till denna förordning eller sekretesspolicyn omfattas av polska domstolars exklusiva jurisdiktion.</p>",
  "back": "Återvänd",
  "definition": "Definitioner",
  "admission": "Introduktion",
  "license_use_page": "Licens att använda webbplatsen och de material som finns tillgängliga på den",
  "use_page": "Användning av webbplatsen",
  "limited_access": "Begränsad åtkomst",
  "user_posted_content": "Innehåll som publiceras av användaren",
  "placing_vehicle_data_in_db": "Inmatning av fordonsdata i databasen",
  "disclaimer_of_liability": "Ansvarsfriskrivning",
  "relief_from_obligations": "Friskrivning från skyldigheter",
  "violations_of_regulations": "Överträdelser av förordningarna",
  "transfer": "Överföring",
  "severability_clause": "Salvatorisk klausul",
  "exclusion_third_parts": "Uteslutning av tredje parts rättigheter",
  "scope_of_contract": "Avtalets omfattning",
  "changing_of_provisions_regulations": "Ändring av bestämmelserna i förordningarna",
  "governing_law_and_jurisdiction": "Tillämplig lag och jurisdiktion",
  "limited_and_warranties_complaints": "Begränsade representationer och garantier samt inlämning av klagomål",
  "main_tip": "Använd vår webbplats för att utvärdera bilar online med hjälp av VIN-nummer. På så sätt får du snabbt och exakt veta hur mycket din bil är värd. Ange bara bilens VIN-nummer, så hjälper vi dig att bedöma dess värde. Vänta inte, kolla nu!",
  "enter_vin_number": "Ange VIN",
  "meta_title": "Online bilvärderingskalkylator - bedöm snabbt och exakt din bil.",
  "meta_desc": "Online bilvärdering, bedöm snabbt och exakt värdet på din bil. Vår kalkylator kommer att ge priset för den kontrollerade modellåren av fordonet och något äldre och yngre för jämförelse.",
  "h1_title": "Online bilvärdering kräver endast VIN",
  "check_valuation": "Kontrollera värderingen!",
  "next": "Nästa",
  "help": "Hjälp",
  "no_data_valuation": "Inga data för värdering",
  "check_if_stolen": "Kontrollera om den är stulen",
  "decode_vin_number": "Avkoda VIN-nummer",
  "additional_links": "Ytterligare länkar",
  "check_vin_correct": "Kontrollera VIN-nummer giltighet",
  "how_to_vin_valuation": "Hur värderar man ett fordon innan köp/försäljning?",
  "calculator_valuation": "Online bilvärderingskalkylator",
  "calculator_valuation_desc": "Det finns många metoder för att uppskatta värdet på en bil; den snabbaste och relativt exakta är att kolla på en webbplats som vinvaluation. Vår proprietära algoritm, som stöds av artificiell intelligens (AI), aggregerar data från tusentals onlineannonser och analyserar noggrant ålder, märke, utrustning och skick på de fordon som erbjuds. Baserat på detta uppskattar vi hur mycket bilen du kontrollerar borde vara värd. Du får värderingen på några ögonblick utan att lämna hemmet, helt gratis. Vi behöver bara några parametrar som VIN-nummer eller datum för fordonsregistrering. Som svar får du inte bara värderingen för det år du kontrollerar utan också för flera närliggande, yngre och äldre modeller. Detta ger dig full insikt i om det till exempel fortfarande är värt att vara ägare eller om du inte kommer att förlora för mycket och det skulle vara bättre att sälja den just nu.",
  "you_get_valuation_for_free": "<strong>Du får värderingen</strong> på några ögonblick utan att lämna hemmet helt gratis. Vi behöver bara några parametrar som VIN-nummer eller datum för fordonsregistrering.",
  "in_response_you_receive_not_only_valuation": "Som svar får du inte bara värderingen för det år du kontrollerar utan också flera närliggande, yngre och äldre. Detta ger dig full insikt i om det till exempel fortfarande är värt att vara ägare eller om du inte kommer att förlora för mycket och det skulle vara bra att sälja den just nu.",
  "compare_market_price": "Jämför priser på marknaden",
  "what_we_offer_free": "Vad vi erbjuder gratis kan du naturligtvis göra manuellt. Bläddra i <strong>annonser på internet</strong> eller besök några <strong>bilhandlare</strong> för att ta reda på de aktuella priserna på erbjudna fordon.",
  "if_you_are_not_sure": "Om du är osäker på vilken information som behövs för att värdera en bil kan du kontakta en bilvärderare eller nå ut till en återförsäljare eller bilhandlare. De kan också hjälpa dig att uppskatta värdet på din bil. Tänk dock på att det pris som erbjuds av bilhandlaren eller återförsäljaren sannolikt kommer att vara betydligt lägre. De behöver också göra en vinst på att köpa bilen av dig. Oavsett vilken metod du väljer, kom ihåg att bilvärderingen endast är ett ungefärligt pris. Det slutliga värdet på fordonet beror på många faktorer, såsom tekniskt skick, utrustning, servicehistorik och det övergripande intryck bilen gör på en potentiell köpare. Det är viktigt att noggrant kontrollera bilens skick innan du säljer eller köper den. När du planerar att köpa en bil, kom ihåg att kontrollera fordonets historia.",
  "regardless_of_which_method": "Oavsett vilken metod du väljer, kom ihåg att bilvärderingen endast är ett ungefärligt pris. Det slutliga värdet på fordonet beror på många faktorer, såsom tekniskt skick, utrustning, servicehistorik och det övergripande intryck bilen gör på en potentiell köpare. Det är viktigt att noggrant kontrollera bilens skick innan du säljer eller köper den.",
  "check_the_valuation": "Kontrollera fordonets historia",
  "you_be_may_interested": "Du kanske också är intresserad av:",
  "check": "KONTROLLERA",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Få grundläggande information om ditt fordon gratis",
  "get_basic_infotmation_about_vehicle_desc": "såsom märke, modell, tillverkningsår och tekniska specifikationer genom att ange VIN i sökfältet. För mer detaljerad data, såsom olyckshistorik, körsträcka och juridisk status för fordonet, erbjuder sidan också möjligheten att köpa en fullständig VIN-rapport.",
  "stolen_cars": "StulnaBilar",
  "stolen_cars_title": "Kontrollera flera databaser med stulna bilar,",
  "stolen_cars_desc": "med hjälp av avancerade sökverktyg. Ange bara VIN eller registreringsnummer för fordonet, så kommer systemet att söka i en stor databas för att kontrollera om bilen har rapporterats stulen.",
  "vin_correct": "VINKorrekt",
  "vin_correct_title": "Kontrollera giltigheten av VIN.",
  "vin_correct_desc": "verktyget verifierar om VIN uppfyller aktuella standarder och innehåller all nödvändig information. Detta säkerställer att VIN inte har förfalskats eller registrerats felaktigt.",
  "easy_vin": "enkelVIN",
  "easy_vin_title": "Bilens historia från A till Ö på ett ställe.",
  "easy_vin_desc": "Verifiera din kunskap om fordonet du är intresserad av och rädda dig själv från obehagliga överraskningar när du köper en ny bil. Kontrollera VIN-numret när du vill. Rapporter genereras 24 timmar om dygnet, 7 dagar i veckan.",
  "find_out_the_value_of_your_car_desc": "Vill du veta värdet på din bil eller kontrollera värdet på din bil innan du köper den? Dra nytta av vår kostnadsfria fordonsvärdering! Med ett VIN-nummer och några extra steg kan vi ge en detaljerad analys. Det är enkelt, snabbt och gratis! Vänta inte - kolla värdet på din bil nu!",
  "enter_te_vin_number": "Ange ett 17-siffrigt VIN-nummer",
  "enter_mileage": "Ange fordonets körsträcka",
  "enter_regplate": "Ange registreringsnummer",
  "tip": "Kul fakta:",
  "odometer_readings": "Fordonets körsträcka",
  "number_of_regplate": "Registreringsnummer",
  "valuation_incoming": "Citatet laddas",
  "pl_country": "Polen",
  "en_country": "Storbritannien",
  "de_country": "Tyskland",
  "cs_country": "Tjeckien",
  "lt_country": "Litauen",
  "fr_country": "Frankrike",
  "es_country": "Spanien",
  "ro_country": "Rumänien",
  "nl_country": "Nederländerna",
  "hu_country": "Ungern",
  "bg_country": "Bulgarien",
  "da_country": "Danmark",
  "el_country": "Grekland",
  "it_country": "Italien",
  "no_country": "Norge",
  "pt_country": "Portugal",
  "sk_country": "Slovakien",
  "sr_country": "Serbien",
  "sv_country": "Sverige",
  "tr_country": "Turkiet",
  "ar_country": "saudi-arabien",
  "fact_model_t": "Den första masstillverkade bilen var Ford Model T, som rullade av löpande bandet 1908.",
  "fact_bugatti": "Den dyraste bilen i världen är Bugatti La Voiture Noire, som kostade cirka 18 miljoner dollar.",
  "fact_v8": "V8-motorn skapades redan 1902 och dess design var revolutionerande för bilindustrin.",
  "fact_vw_beetle": "Den längst producerade bilmodellen är Volkswagen Beetle, tillverkad kontinuerligt i 65 år, från 1938 till 2003.",
  "fact_wipers": "De första vindrutetorkarna för bilar uppfanns av Mary Anderson 1903 efter att hon märkte att förare var tvungna att gå ut ur sina bilar för att torka av sina rutor.",
  "fact_lamborghini": "Lamborghini tillverkade först traktorer och började först senare tillverka lyxiga sportbilar.",
  "fact_popular_color": "Den populäraste bilfärgen i världen är vit, vald av så många som 39 % av köparna.",
  "fact_electric_car": "Den första elbilen skapades 1832, långt före uppfinningen av bilar med förbränningsmotorer.",
  "fact_crank_start": "Innan den elektriska startmotorn uppfanns startades bilar med en vev, vilket var ganska farligt och kunde orsaka allvarliga skador.",
  "fact_ssc_tuatara": "Den snabbaste hastighet som någonsin uppnåtts av en produktionsbil uppnåddes av SSC Tuatara vid 532,93 km/h.",
  "error_message": "Ett internt fel uppstod. Försök igen senare.",
  "no_data_alert": "Inga data tillgängliga för detta fordon.",
};

