<template>
    <div class="container-fluid">
        <div class="container">
            <router-link to="/">{{ t("back") }}</router-link>
            <div class="text-center">
                <h1 class="text-white">Kontakt</h1>
                <img src="../assets/img/mail_black.png" class="img-fluid mt-5" alt="email" width="470" height="40" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style scoped>
a {
    color: white;
}

a:hover {
    text-decoration: underline;
}
</style>