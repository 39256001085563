export const lt = {
  "fast_and_free": "GREITAI IR NEMOKAMAI",
  "vehicle_valuation": "TRANSPORTO PRIEMONIŲ VERTINIMAS",
  "valuation": "VERTINIMAS",
  "vin": "VIN",
  "want_to_know_valuation": "Ar norite sužinoti savo automobilio vertę arba patikrinti transporto priemonės vertę prieš ją įsigydami? Naudokite mūsų nemokamą transporto priemonių vertinimą! Tiesiog įveskite VIN, kad gautumėte tikslią ir patikimą vertę. Su keliais papildomais žingsniais galime pateikti dar išsamesnę analizę. Tai paprasta, greita ir nemokama! Nelaukite – patikrinkite savo automobilio vertę dabar!",
  "plate": "Registracijos numeris",
  "mileage": "Rida",
  "accord_mileage_desc": "Transporto priemonės su maža rida paprastai yra mažiau nusidėvėjusios, todėl jos dažnai pasiekia didesnes kainas naudotų automobilių rinkoje.",
  "damage_history": "Įvykio istorija",
  "damage_history_desc": "Automobiliai, kurie buvo įtraukti į įvykius, paprastai turi mažesnę vertę dėl galimų struktūrinių pažeidimų.",
  "count_of_owners": "Ankstesnių savininkų skaičius",
  "count_of_owners_desc": "Transporto priemonės, turinčios mažiau savininkų, gali būti vertinamos aukščiau, nes jos paprastai geriau prižiūrimos.",
  "body_color": "Kėbulo spalva",
  "body_color_desc": "Populiarios spalvos gali padidinti transporto priemonės vertę, tuo tarpu neįprastos spalvos gali ją sumažinti.",
  "technical_condition": "Techninė būklė",
  "technical_condition_desc": "Mechaninė ir techninė transporto priemonės būklė, įskaitant variklio, pavarų dėžės ir kitų komponentų būklę, turi didelę įtaką kainai.",
  "fuel_type": "Kuro tipas",
  "fuel_type_desc": "Dabartinės tendencijos ir rinkos pageidavimai gali padaryti tam tikrus variklių tipus patrauklesnius nei kitus.",
  "vehicle_equipment": "Automobilio įranga",
  "vehicle_equipment_desc": "Papildomos funkcijos, tokios kaip navigacijos sistemos, odinės interjero detalės, aukštos kokybės garso sistemos gali padidinti transporto priemonės vertę.",
  "current_market_prices": "Dabartinės rinkos kainos",
  "current_market_prices_desc": "Rinkos kainos svyruoja atsižvelgiant į paklausą ir pasiūlą tam tikram transporto priemonės modeliui, kas daro įtaką galutinei vertinimui.",
  "how_to_valuate_vehicle_before_sell": "Kaip įvertinti transporto priemonę prieš perkant/pardavinėjant?",
  "prod_year": "Gamintojas",
  "first_reg_date": "Pirmos registracijos data",
  "contact": "Kontaktai",
  "privacy_policy": "Privatumo politika",
  "regulations": "Taisyklės ir sąlygos",
  "valuation_desc": "Transporto priemonės vertinimas buvo parengtas remiantis dideliu mūsų automobilių rinkos ekspertų patirtimi. Vertinimo procedūra naudoja dirbtinį intelektą (DI), kad palygintų kintančias transporto priemonių kainų tendencijas Europos ir Amerikos rinkose. Vertinimas atsižvelgia į tokius parametrus kaip transporto priemonės amžius ir rida. Mūsų vertinimas taip pat atsižvelgia į naujausias automobilių ir socialines problemas, tokias kaip dalių ar komponentų trūkumas ir infliacija, kurios daro įtaką naudotų transporto priemonių kainoms.",
  "min_price": "Minimali kaina",
  "med_price": "Vidutinė kaina",
  "max_price": "Maksimali kaina",
  "we_have_report_for_you": "Mes turime ataskaitą apie šią transporto priemonę!",
  "incident_history": "Įvykio istorija",
  "odometer_reading": "Ridos rodmuo",
  "vehicles_inspection": "Transporto priemonių patikrinimai",
  "archive_photos": "Archyvinių nuotraukų",
  "check_now": "PERŽIŪRĖTI ATASKAITĄ",
  "unfortunately_not_found_desc": "Deja, mes negalėjome rasti šios transporto priemonės gamybos metų, prašome jį įvesti aukščiau esančiame lauke.",
  "type_odometer": "Įveskite dabartinę transporto priemonės ridą kilometrais, kad geriau įvertintumėte jos vertę.",
  "check_another_vin": "Įvertinkite kitą transporto priemonę",
  "what_can_affet_the_price_of_vehicle": "Ką gali paveikti jūsų transporto priemonės kainą?",
  "calcualtor_of_vehicle": "Internetinė transporto priemonės vertinimo skaičiuoklė",
  "type_plate": "Įveskite transporto priemonės registracijos numerį.",
  "type_first_reg_date": "Įveskite pirmosios transporto priemonės registracijos datą. Ją galite rasti registracijos dokumente lauke B.",
  "price_odometer": "Kaina",
  "price_year": "Kaina",
  "year": "Metai",
  "regulations_admission": "<p>Šis reglamentas apibrėžia taisykles, kaip naudotis svetainė, veikiančia pavadinimu VinValuation interneto domene www.vinValuation.com (toliau – Svetainė). Šis reglamentas apima taisykles, kaip naudotis Svetainė. Jei nesutinkate su šio reglamento taikymu ar bet kuria jo nuostata, turėtumėte nedelsdami palikti šią Svetainę.</p><p>Mūsų įmonės pavadinimas yra Car Data Experts LTD. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Įmonių registracijos numeris: 10327199. Su mumis galite susisiekti el. paštu: <a href=mailto:{email}>{email}</a></p><p>Pagrindinis Svetainės tikslas yra suteikti vartotojams galimybę įvesti duomenis apie pavogtas ar sugadintas transporto priemones į Car Data duomenų bazę (Transporto priemonių duomenys) ir dalintis šiais duomenimis su kitais asmenimis, naudojančiais šią duomenų bazę.</p><p>Svetainėje teikiamos paslaugos skirtos fiziniams asmenims, juridiniams asmenims ir kitiems subjektams, turintiems gyvenamąją vietą arba registruotą biurą Europos Sąjungoje arba Jungtinėse Amerikos Valstijose.</p><p>Svetainėje teikiamos paslaugos skirtos tik asmenims, kurie yra bent 18 metų amžiaus.</p><p>Asmens duomenų tvarkymo ir slapukų naudojimo taisyklės pateiktos mūsų Privatumo politikoje, kuri yra prieinama Svetainėje.</p><p>Norint naudotis Svetainė, vartotojas turi naudoti įrenginį su interneto prieiga ir interneto naršyklę Internet Explorer 9.0 arba panašią programą. Norint naudotis kai kuriomis Svetainės funkcijomis, būtina naudoti Java ir Java Script programinę įrangą, taip pat leisti naudoti slapukus. Mes nesame atsakingi už prieigos prie mūsų Svetainėje paskelbtų duomenų trūkumą, jei vartotojo įrenginys neatitinka aukščiau nurodytų techninių reikalavimų.</p>',",
  "regulations_definition": "<p>Šiame reglamente žemiau apibrėžti terminai turi šias reikšmes:</p><p>Transporto priemonių duomenys reiškia duomenis apie pavogtas ar sugadintas transporto priemones ir vagystės ar sugadinimo aplinkybes, kuriuos sukūrė Svetainės vartotojai Automobilių duomenų duomenų bazėje ir kurie yra prieinami kitiems asmenims, naudojantiems šią duomenų bazę.</p><p>Vartotojas reiškia bet kurį fizinį asmenį, veikiantį ne verslo ar profesiniais tikslais.</p><p>Duomenų bazė reiškia mūsų Automobilių duomenų duomenų bazę apie techninę būklę ir transporto priemonių istoriją.</p><p>VIN numeris reiškia transporto priemonės identifikavimo numerį (Vehicle Identification Number), kurį automobilių pramonė naudoja konkrečiai motorinės transporto priemonės, traktoriaus, motociklo, skuterio ir mopedo versijai identifikuoti, kaip apibrėžta ISO 3833 standarte.</p>',",
  "regulations_license_desc": "<p>Laikantis kitų nuostatų, visi intelektinės nuosavybės teisės į Svetainę ir medžiagas, esančias Svetainėje, priklauso mums arba mūsų licencijų turėtojams. Laikantis žemiau pateiktos licencijos, visos intelektinės nuosavybės teisės yra saugomos.</p> <p>Vartotojai gali peržiūrėti, atsisiųsti failus talpinimui, tik savo asmeniniam naudojimui, laikydamiesi žemiau ir kitose Paslaugų teikimo sąlygose nurodytų išimčių.</p><p>Ypač draudžiama:</p><p>a. medžiagų, gautų iš Svetainės, platinimas (įskaitant platinimą kitoje svetainėje);</p><p>b. pardavimas, nuoma, skolinimas, dovanojimas, perdavimas ar sublicencijų suteikimas dėl Svetainės ir medžiagų, esančių Svetainėje;</p><p>c. bet kokių medžiagų, prieinamų Svetainėje, viešas atlikimas;</p><p>d. medžiagų, surinktų Svetainėje, reprodukcija, dubliavimas, kopijavimas ar kitaip naudojimas komerciniais tikslais;</p><p>e. bet kokių medžiagų, esančių Svetainėje, keitimas ar kitaip modifikavimas;</p><p>f. medžiagų, gautų iš Svetainės, platinimas be mūsų aiškaus raštiško sutikimo dėl aukščiau išvardytų veiksmų.</p>",
  "regulations_use_page": "<p>1. Draudžiama naudoti Svetainę bet kokiu būdu, kuris jai kenkia arba neigiamai veikia galimybę naudotis Svetainę; arba bet kokiu kitu būdu, kuris yra neteisėtas, neteisėtas, žalingas arba susijęs su bet kokiu neteisėtu, neteisėtu ar žalingu tikslu ar veiksmu. Mes pasiliekame visas teises į visą informaciją ir medžiagas, įskaitant grafines ir kitas, esančias Svetainėje.</p><p>2. Draudžiama naudoti Svetainę kopijuoti, naudoti, saugoti, perduoti, siųsti, skelbti ar platinti bet kokias medžiagas, kurios turi bet kokį šnipinėjimo programinę įrangą, virusą, Trojos arklį, kirminą, klaviatūros įrašymo programą, rootkit ar kitą žalingą programinę įrangą ar įrenginius, arba į juos nuorodu.</p><p>3. Bet koks sisteminis ar automatizuotas duomenų rinkimas iš Svetainės arba duomenų, susijusių su Svetainė, (įskaitant naudojant šias technikas: scraping, duomenų gavyba, duomenų išgavimas ir duomenų rinkimas) yra draudžiamas be mūsų raštiško sutikimo.</p><p>4. Draudžiama naudoti Svetainę perduoti ar siųsti nepageidaujamą komercinę informaciją.</p><p>5. Draudžiama naudoti Svetainę bet kokiems rinkodaros tikslams be mūsų raštiško sutikimo.</p>",
  "regulations_limited_access": "<p>1. Prieiga prie tam tikrų Svetainės išteklių yra ribojama. Mes pasiliekame teisę riboti prieigą prie kitų Svetainės išteklių arba apriboti prieigą prie visos Svetainės, atsižvelgdami į vartotojų teises, kylančias iš anksčiau sudarytų sutarčių.</p><p>2. Jei vartotojas gavo iš mūsų vartotojo vardą arba vartotojo vardą ir slaptažodį, leidžiančius pasiekti ribotus išteklius Svetainėje arba kitą turinį ar paslaugas su ribota prieiga, vartotojas privalo užtikrinti slaptažodžio ir vartotojo vardo saugumą, kad jie nebūtų atskleisti jokiai trečiajai šaliai.</p>",
  "regulations_user_posted": "<p>1. Vartotojo sukurtas turinys neturi būti neteisėtas, pažeisti socialinės coexistencijos principus, netikėtumą ar kitas nustatytas tradicijas, taip pat neturi pažeisti jokių trečiųjų šalių teisių ir nesukelti jokių pretenzijų ar teisinių veiksmų prieš vartotoją, mus ar bet kurią trečiąją šalį (bet kuriuo atveju, pagal bet kokius taikytinus įstatymus).</p><p>2. Draudžiama skelbti bet kokį turinį Svetainėje, kuris yra ar buvo nagrinėjamas bet kokiose vykstančiose ar grasinančiose teisminėse procedūrose ar panašiuose procesuose.</p><p>3. Pasiliekame teisę keisti arba pašalinti bet kokį turinį ar medžiagą, paskelbtą Svetainėje, saugomą ar ten publikuotą.</p><p>4. Atsižvelgdami į šių Sąlygų nuostatas ir taikytinus įstatymus dėl turinio ir kitų medžiagų, kurias vartotojas paskelbė Svetainėje, nesame įpareigoti stebėti Svetainėje paskelbtą ar publikuotą turinį ir medžiagas.</p><p>5. Bet kokie duomenys, kuriuos vartotojas paskelbia Svetainėje, taip pat turėtų būti saugomi jų pačių įrenginiuose papildomai apsaugai nuo praradimo.</p>",
  "regulations_placing_data_in_db": "<p>1. Vartotojas pateikia Transporto priemonės duomenis Duomenų bazėje naudodamas Svetainėje paskelbtą formą.</p><p>2. Vartotojas atsako už Transporto priemonės duomenų tikslumą, atsižvelgdamas į esamą situaciją. Bet kokių pokyčių atveju vartotojas turėtų nedelsdamas atnaujinti Duomenų bazėje pateiktus Transporto priemonės duomenis.</p><p>3. Pateikus Transporto priemonės duomenis Duomenų bazėje, vartotojas gauna patvirtinimo el. laišką adresu, kurį pateikė, patvirtinantį, kad šie duomenys buvo įtraukti į Duomenų bazę.</p><p>4. Transporto priemonės duomenys įtraukiami į Duomenų bazę ir padaromi prieinami kitoms įstaigoms, naudojančioms šią duomenų bazę, siekiant gauti informaciją apie konkrečią transporto priemonę, identifikuotą pagal jos VIN numerį.</p><p>5. Jei Duomenų bazėje pasirodo naujos informacijos apie Transporto priemonės duomenis, mes stengsimės informuoti vartotoją, kuris pateikė Transporto priemonės duomenis, per pateiktą el. pašto adresą.</p><p>6. Transporto priemonės duomenys gali būti atšaukti iš Duomenų bazės, susisiekus su mumis per kontaktinę formą Svetainėje arba rašant el. pašto adresu, pateiktu Įvade į Sąlygas ir nuostatas.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Siekiant išvengti abejonių, remiantis vartotojo sutartimi dėl transporto priemonių duomenų teikimo duomenų bazėje, mes nesame įpareigoti surasti transporto priemonę, kuriai taikomi transporto priemonių duomenys.</p><p>2. Atveju, kai vartotojai nėra vartotojai, tiek, kiek tai leidžia taikytini įstatymai, mes nedarome jokių pareiškimų, nesuteikiame jokių garantijų dėl kokybės, neatsakome už žalą ir nedarome jokių pareiškimų dėl paslaugų standartų, teikiamų svetainėje ir svetainės naudojimo (ypač mes neatsakome už visas garantijas, nedarome jokių pareiškimų, teikiamų pagal taikytinus įstatymus, dėl patenkinamos kokybės, tinkamumo tikslui ar rūpestingumo ir įgūdžių taikymo).</p><p>3. Pastebėję techninių problemų svetainėje arba pastebėję nepilną ar pasenusią informaciją, kurią teikiame, taip pat bet kokius skundus dėl svetainės (skundų), turėtumėte nedelsdami pranešti mums naudodamiesi forma Kontaktų puslapyje arba rašydami mums el. paštu, nurodytu aukščiau Įžangoje į Sąlygas ir nuostatas. Mes stengiamės greitai atsakyti.</p>',",
  "regulations_liability": "<p>1. Šiose sąlygose (a) nėra nuostatos, kuri ribotų mūsų atsakomybę už žalą, padarytą tyčia, arba tyčinį klaidinimą; (b) neriboja mūsų ar vartotojo atsakomybės jokia forma, kuri prieštarautų taikomam įstatymui; arba (c) nepašalina mūsų ar vartotojo atsakomybės, jei tokia atsakomybė negali būti pašalinta pagal taikomą įstatymą; ypač, sąlygos nepašalina mūsų ar vartotojo atsakomybės už žalą, padarytą tyčia.</p><p>2. Šiose sąlygose numatytos atsakomybės išimtys ir ribojimai:</p><p> a. priklauso nuo 1 punkte nurodytų ribojimų;</p><p> b. susiję su bet kokia atsakomybe, kuri gali kilti pagal šias sąlygas arba privatumo politiką, susijusią su šių sąlygų ir privatumo politikos objektu, įskaitant sutartinę, deliktinę ar panašią atsakomybę;</p><p> c. netaikomos vartotojams.</p><p>3. Ne vartotojų atveju, tiek, kiek leidžia taikomas įstatymas:</p><p> a. mes nesame atsakingi už jokius nuostolius, nebent žala buvo padaryta mūsų tyčia. Garantija ir bet kokia atsakomybė, kylanti iš panašių institucijų, yra pašalinama;</p><p> b. mes nesame atsakingi už jokius duomenų, duomenų bazių ar programinės įrangos nuostolius, nesvarbu, ar jie yra mūsų, ar vartotojo.</p><p>4. Mes nesame atsakingi už jokius nuostolius, kylančius dėl įvykių, kurie yra už mūsų kontrolės ribų.</p>",
  "regulations_relief": "<p>Vartotojai, kurie nėra vartotojai, atlygina mums už bet kokius nuostolius, žalą, kompensacijas, išlaidas, atsakomybes ir išlaidas (įskaitant išlaidas, susijusias su bet kokiais procesais ir bet kokias sumas, kurias mes sumokėjome trečiajai šaliai kaip susitarimą), susijusias su bet kokiu vartotojo šių sąlygų pažeidimu, ir privalo mums atlyginti mūsų patirtas išlaidas, kaip nurodyta aukščiau.</p>",
  "regulations_violations": "<p>Laikydamiesi visų kitų mūsų teisių, kylančių iš šių sąlygų, vartotojo pažeidimo atveju mes galime imtis tokių veiksmų, kuriuos laikome tinkamais reaguoti į pažeidimą, kurie gali apimti vartotojo prieigos prie svetainės sustabdymą, vartotojo prieigos prie svetainės užkardymą, prieigos prie svetainės blokavimą konkrečiam IP adresui, vartotojo interneto tiekėjo kontaktavimą, kad būtų užblokuota prieiga prie svetainės, arba teisines priemones.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Taisyklės gali būti keičiamos paskelbiant jų naują versiją Svetainėje. Jei vartotojas pateikia mums savo asmens duomenis, ypač savo el. pašto adresą, ir jei mes teikiame jam paslaugas šių Taisyklių pakeitimo metu, mes informuosime jį apie bet kokius šių Taisyklių pakeitimus el. paštu.</p><p>2. Vartotojams, kurie nėra Vartotojai, mūsų kontaktinės informacijos pakeitimas nesudaro Taisyklių pakeitimo.</p><p>3. Jei pasikeičia registracijos metu pateiktas el. pašto adresas, vartotojai privalo atnaujinti šį adresą Svetainėje.</p>",
  "regulations_transfer": "<p>Vartotojai negali perduoti, dalintis su kitais ar kitaip atsisakyti savo teisių ir pareigų, kylančių iš šių Taisyklių, taip pat negali prisiimti įsipareigojimų, susijusių su šiomis teisėmis ir pareigomis.</p>",
  "regulations_severability_clause": "<p>Jei bet kuri šių Taisyklių nuostata yra pripažinta teismo ar kitos kompetentingos institucijos negaliojančia ar neveiksminga, likusios Taisyklių nuostatos išlieka galiojančios. Jei negaliojanti ar neveiksminga nuostata būtų galiojanti po jos dalies pašalinimo, ta dalis laikoma pašalinta, o likusi nuostatos dalis laikoma galiojančia.</p>",
  "regulations_exclusion_parts": "<p>Taisyklės išskirtinai apibrėžia santykius tarp mūsų ir Svetainės vartotojų ir nesuteikia jokių teisių trečiosioms šalims, taip pat nesuteikia teisės joms remtis bet kuria iš jos nuostatų ar reikalauti šių nuostatų vykdymo. Taisyklių nuostatų vykdymui nereikia jokios trečiosios šalies sutikimo.</p>",
  "regulations_scope_contract": "<p>Šios Taisyklės, kartu su mūsų Privatumo politika ir Svetainėje nurodytomis paslaugų kainomis, sudaro susitarimą tarp mūsų ir vartotojo dėl Svetainės naudojimo ir pakeičia bet kokias ankstesnes taisykles, susijusias su Svetainės naudojimu.</p>",
  "regulations_governing_law": "<p>1. Šis reglamentas yra taikomas pagal Lenkijos teisę, o klausimais, kurie nėra reglamentuojami reglamento, taikomos 1964 m. balandžio 23 d. įstatymo, Civilinio kodekso, 2014 m. gegužės 30 d. įstatymo dėl vartotojų teisių (susijusių su vartotojais) ir 2002 m. liepos 18 d. įstatymo dėl elektroninių paslaugų teikimo nuostatos.</p><p>2. Atvejais, kai vartotojai nėra vartotojai, visi ginčai, kylantys pagal reglamentą, priklausys išimtinai Lenkijos teismų jurisdikcijai.</p><p>3. Atvejais, kai vartotojai yra vartotojai, kurių gyvenamoji vieta yra Lenkijoje sutarties dėl transporto priemonių duomenų teikimo sudarymo metu, visi ginčai, susiję su šiuo reglamentu ar privatumo politika, priklausys išimtinai Lenkijos teismų jurisdikcijai.</p>",
  "back": "Grąžinti",
  "definition": "Apibrėžimai",
  "admission": "Įžanga",
  "license_use_page": "Licencija naudotis svetaine ir joje esančiais medžiagomis",
  "use_page": "Svetainės naudojimas",
  "limited_access": "Ribotas prieinamumas",
  "user_posted_content": "Turinys, paskelbtas vartotojo",
  "placing_vehicle_data_in_db": "Transporto priemonės duomenų įvedimas į duomenų bazę",
  "disclaimer_of_liability": "Atsakomybės atsisakymas",
  "relief_from_obligations": "Išlaisvinimas nuo įsipareigojimų",
  "violations_of_regulations": "Reglamento pažeidimai",
  "transfer": "Perdavimas",
  "severability_clause": "Išsaugojimo nuostata",
  "exclusion_third_parts": "Trečiųjų šalių teisių išimtis",
  "scope_of_contract": "Sutarties apimtis",
  "changing_of_provisions_regulations": "Reglamento nuostatų pakeitimas",
  "governing_law_and_jurisdiction": "Taikoma teisė ir jurisdikcija",
  "limited_and_warranties_complaints": "Ribotos atstovybės ir garantijos bei skundų pateikimas",
  "main_tip": "Naudokite mūsų svetainę, kad įvertintumėte automobilius internetu naudodami VIN numerį. Taip greitai ir tiksliai sužinosite, kiek vertas jūsų automobilis. Tiesiog įveskite savo automobilio VIN numerį, ir mes padėsime jums įvertinti jo vertę. Nepraleiskite progos, patikrinkite dabar!",
  "enter_vin_number": "Įveskite VIN",
  "meta_title": "Internetinis automobilio vertinimo kalkuliatorius - greitai ir tiksliai įvertinkite savo automobilį.",
  "meta_desc": "Internetinis automobilio vertinimas, greitai ir tiksliai įvertinkite savo automobilio vertę. Mūsų kalkuliatorius pateiks kainą už patikrintą transporto priemonės modelio metus ir šiek tiek senesnius bei jaunesnius palyginimui.",
  "h1_title": "Internetinis automobilio vertinimas reikalauja tik VIN",
  "check_valuation": "Patikrinkite vertinimą!",
  "next": "Kitas",
  "help": "Pagalba",
  "no_data_valuation": "Nėra duomenų vertinimui",
  "check_if_stolen": "Patikrinkite, ar pavogtas",
  "decode_vin_number": "Iššifruoti VIN numerį",
  "additional_links": "Papildomi nuorodos",
  "check_vin_correct": "Patikrinkite VIN numerio galiojimą",
  "how_to_vin_valuation": "Kaip įvertinti transporto priemonę prieš perkant/pardavinėjant?",
  "calculator_valuation": "Internetinis automobilio vertinimo kalkuliatorius",
  "calculator_valuation_desc": "Yra daug metodų, kaip įvertinti automobilio vertę; greičiausias ir palyginti tikslus būdas yra patikrinti tai tokioje svetainėje kaip vinvaluation. Mūsų patentuota algoritmas, paremta dirbtiniu intelektu (DI), sujungia duomenis iš tūkstančių internetinių skelbimų, tiksliai analizuodama siūlomų transporto priemonių amžių, markę, įrangą ir būklę. Remiantis tuo, mes įvertiname, kiek turėtų būti vertas automobilis, kurį tikrinate. Jūs gaunate vertinimą per kelias akimirkas, neišeidami iš namų, visiškai nemokamai. Mums reikia tik kelių parametrų, tokių kaip VIN numeris arba transporto priemonės registracijos data. Atsakydami, gausite ne tik vertinimą už tą metus, kurį tikrinate, bet ir kelių artimų, jaunesnių ir vyresnių modelių vertinimus. Tai suteikia jums visapusišką supratimą, ar, pavyzdžiui, vis dar verta būti jos savininku, ar neprarandate per daug ir būtų geriau ją parduoti dabar.",
  "you_get_valuation_for_free": "<strong>Jūs gaunate vertinimą</strong> per kelias akimirkas, neišeidami iš namų, visiškai nemokamai. Mums reikia tik kelių parametrų, tokių kaip VIN numeris arba transporto priemonės registracijos data.",
  "in_response_you_receive_not_only_valuation": "Atsakydami, gausite ne tik vertinimą už tą metus, kurį tikrinate, bet ir kelių artimų, jaunesnių ir vyresnių vertinimus. Tai suteikia jums visapusišką supratimą, ar, pavyzdžiui, vis dar verta būti jos savininku, ar neprarandate per daug ir būtų geriau ją parduoti dabar.",
  "compare_market_price": "Palyginkite kainas rinkoje",
  "what_we_offer_free": "Tai, ką siūlome nemokamai, žinoma, galite padaryti ir rankiniu būdu. Naršykite <strong>skelbimus internete</strong> arba apsilankykite keliuose <strong>automobilių salonuose</strong>, kad sužinotumėte dabartines siūlomų transporto priemonių kainas.",
  "if_you_are_not_sure": "Jei nesate tikri, kokios informacijos reikia vertinant automobilį, galite susisiekti su automobilio vertintoju arba kreiptis į prekiautoją ar automobilių saloną. Jie taip pat galės jums padėti įvertinti jūsų automobilio vertę. Tačiau turėkite omenyje, kad kainos, kurias siūlo salonas ar prekiautojas, greičiausiai bus žymiai mažesnės. Jie taip pat turi uždirbti pelną, pirkdami automobilį iš jūsų. Nepriklausomai nuo to, kurį metodą pasirinksite, atminkite, kad automobilio vertinimas yra tik apytikslė kaina. Galutinė transporto priemonės vertė priklauso nuo daugelio veiksnių, tokių kaip techninė būklė, įranga, aptarnavimo istorija ir bendras įspūdis, kurį automobilis palieka potencialiam pirkėjui. Svarbu kruopščiai patikrinti automobilio būklę prieš parduodant ar perkant jį. Planuodami pirkti automobilį, nepamirškite patikrinti transporto priemonės istorijos.",
  "regardless_of_which_method": "Nepriklausomai nuo to, kurį metodą pasirinksite, atminkite, kad automobilio vertinimas yra tik apytikslė kaina. Galutinė transporto priemonės vertė priklauso nuo daugelio veiksnių, tokių kaip techninė būklė, įranga, aptarnavimo istorija ir bendras įspūdis, kurį automobilis palieka potencialiam pirkėjui. Svarbu kruopščiai patikrinti automobilio būklę prieš parduodant ar perkant jį.",
  "check_the_valuation": "Patikrinkite transporto priemonės istoriją",
  "you_be_may_interested": "Jums taip pat gali būti įdomu:",
  "check": "PATIKRINTI",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Gaukite pagrindinę informaciją apie savo transporto priemonę nemokamai",
  "get_basic_infotmation_about_vehicle_desc": "tokias kaip gamintojas, modelis, gamybos metai ir techniniai duomenys, įvedę VIN paieškos lauke. Išsamesniems duomenims, tokiems kaip avarijų istorija, rida ir transporto priemonės teisinė būklė, svetainė taip pat siūlo galimybę įsigyti pilną VIN ataskaitą.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Patikrinkite kelias pavogtų automobilių duomenų bazes,",
  "stolen_cars_desc": "naudodami pažangias paieškos priemones. Tiesiog įveskite transporto priemonės VIN arba registracijos numerį, ir sistema ieškos didelėje duomenų bazėje, kad patikrintų, ar automobilis buvo praneštas kaip pavogtas.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Patikrinkite VIN galiojimą.",
  "vin_correct_desc": "įrankis patikrina, ar VIN atitinka dabartinius standartus ir ar jame yra visa reikalinga informacija. Tai užtikrina, kad VIN nebuvo suklastotas ar neteisingai užrašytas.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Automobilio istorija nuo A iki Z vienoje vietoje.",
  "easy_vin_desc": "Pasitikrinkite savo žinias apie jus dominančią transporto priemonę ir apsisaugokite nuo nemalonių netikėtumų pirkdami naują automobilį. Patikrinkite VIN numerį, kai tik norite. Ataskaitos generuojamos 24 valandas per parą, 7 dienas per savaitę.",
  "find_out_the_value_of_your_car_desc": "Norite sužinoti savo automobilio vertę arba pasitikrinti savo automobilio vertę prieš pirkdami? Pasinaudokite mūsų nemokamu automobilio įvertinimu! Su VIN numeriu ir keliais papildomais žingsniais galime pateikti išsamią analizę. Tai paprasta, greita ir nemokama! Nelaukite – patikrinkite savo automobilio vertę dabar!",
  "enter_te_vin_number": "Įveskite 17 skaitmenų VIN numerį",
  "enter_mileage": "Nurodykite automobilio ridą",
  "enter_regplate": "Įveskite valstybinio numerio numerį",
  "tip": "Įdomus faktas:",
  "odometer_readings": "Transporto priemonės rida",
  "number_of_regplate": "Valstybinio numerio numeris",
  "valuation_incoming": "Citata įkeliama",
  "pl_country": "Lenkija",
  "en_country": "Didžioji Britanija",
  "de_country": "Vokietija",
  "cs_country": "Čekijos respublika",
  "lt_country": "Lietuva",
  "fr_country": "Prancūzija",
  "es_country": "Ispanija",
  "ro_country": "Rumunija",
  "nl_country": "Nyderlandai",
  "hu_country": "Vengrija",
  "bg_country": "Bulgarija",
  "da_country": "Danija",
  "el_country": "Graikija",
  "it_country": "Italija",
  "no_country": "Norvegija",
  "pt_country": "Portugalija",
  "sk_country": "Slovakija",
  "sr_country": "Serbija",
  "sv_country": "Švedija",
  "tr_country": "Turkija",
  "ar_country": "Saudo Arabija",
  "fact_model_t": "Pirmasis masinės gamybos automobilis buvo Ford Model T, kuris nuo surinkimo linijos nuriedėjo 1908 m.",
  "fact_bugatti": "Brangiausias automobilis pasaulyje yra „Bugatti La Voiture Noire“, kainuojantis maždaug 18 mln.",
  "fact_v8": "V8 variklis buvo sukurtas dar 1902 m., o jo konstrukcija buvo revoliucinė automobilių pramonėje.",
  "fact_vw_beetle": "Ilgiausiai gaminamas automobilio modelis yra „Volkswagen Beetle“, nepertraukiamai gaminamas 65 metus – nuo 1938 iki 2003 metų.",
  "fact_wipers": "Pirmuosius automobilių langų valytuvus išrado Mary Anderson 1903 m., kai pastebėjo, kad vairuotojai turi išlipti iš automobilių, kad nusišluostų langus.",
  "fact_lamborghini": "Lamborghini iš pradžių gamino traktorius ir tik vėliau pradėjo gaminti prabangius sportinius automobilius.",
  "fact_popular_color": "Populiariausia automobilių spalva pasaulyje yra balta, kurią renkasi net 39% pirkėjų.",
  "fact_electric_car": "Pirmasis elektromobilis buvo sukurtas 1832 m., gerokai anksčiau nei buvo išradę automobiliai su vidaus degimo varikliais.",
  "fact_crank_start": "Prieš išrandant elektrinį starterį, automobiliai buvo užvesti naudojant švaistiklį, kuris buvo gana pavojingas ir galėjo rimtai susižaloti.",
  "fact_ssc_tuatara": "Didžiausią greitį, kurį kada nors pasiekė serijinis automobilis, SSC Tuatara pasiekė 532,93 km/val.",
  "error_message": "Įvyko vidinė klaida. Bandykite dar kartą vėliau.",
  "no_data_alert": "Nėra duomenų apie šią transporto priemonę.",
};

