<template>
    <section>
        <div class="container-fluid w-80" style="margin-top: 75px;">
            <div class="row gx-xl-0 gx-md-5">
                <h2 class="text-white mb-5 text-center mt-5">
                    {{ t('how_to_valuate_vehicle_before_sell') }}
                </h2>
                <div class="col-xl-5 col-md-6 col-sm-12 d-flex flex-column align-items-center mt-4 gap-3">
                    <h5>
                        <span v-html="t('calcualtor_of_vehicle')"></span>
                        <!-- KALKULATOR WYCENY <br>
                        SAMOCHODU ONLINE -->
                    </h5>
                    <img :src="require('@/assets/img/value_estimate.svg')" class="img-fluid" alt="Value Estimate">
                    <p class="text-center">{{ t('calculator_valuation_desc') }}</p>
                    <p class="text-center" v-html="t('you_get_valuation_for_free')">
                    </p>
                </div>
                <div class="col-xl-2 d-md-none mt-4 d-xl-flex justify-content-center">
                    <div class="vertical-line"></div>
                </div>
                <div class="col-xl-5 col-md-6 col-sm-12 d-flex flex-column align-items-center mt-4 gap-3">
                    <h5 class="text-center">
                        <span v-html="t('compare_market_price')"></span>
                        <!-- PORÓWNAJ CENY <br>
                        NA RYNKU -->
                    </h5>
                    <img :src="require('@/assets/img/value_compare.svg')" class="img-fluid" alt="Value compare">
                    <p class="text-center">{{ t('if_you_are_not_sure') }}</p>
                    <p class="text-center">
                        {{ t('regardless_of_which_method') }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>


<style>
.container-fluid {
    background-color: #31363F;
}

p {
    font-size: 1.04em;
}

.vertical-line {
    width: 1px;
    background-color: #efefef;
    height: 95%;
}

section {
    background-color: #31363F;
}
</style>