import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/Routes.js';
import store from './store/store';
import { createGtm } from 'vue-gtm';
import VueGtag from 'vue-gtag-next';
import { i18n } from './utils/langs';
import VueRecaptcha from 'vue3-recaptcha-v2';

// Importowanie stylów
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/main.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);

// Integracja z Google Tag Manager (GTM)
app.use(
    createGtm({
        id: 'GTM-PLDH6BC',
        queryParams: false,
        defer: true,
        compatibility: false,
        nonce: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router,
        ignoredViews: [],
        trackOnNextTick: false,
    })
);

app.use(VueGtag, {
    property: {
        id: 'G-GX778Y646V',
        params: {
            send_page_view: true,
            linker: {
                domain: ['vinvaluation.com'],
            },
        },
    },
    useDebugger: false,
}, router); // Zintegruj Gtag z routerem Vue

app.use(VueRecaptcha, { siteKey: '6LfPxZIiAAAAADxtA-jjAUizfL3GfcQRJiuBR3kI' });
// app.use(VueRecaptcha, { siteKey: '6LeT7ykqAAAAAGMgreznXb9CP4XsjbBnPbYZ3Pi-' });

app.mount('#app');
