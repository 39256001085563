<template>
  <div class="container w-80 d-flex flex-column align-items-center mt-5 mb-5">
    <h2 class="text-white mb-5 text-center">
      {{ t('what_can_affet_the_price_of_vehicle') }}
    </h2>
    <div class="accordion accordion-flush mt-4" id="accordionFlushExample">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div v-for="(item, index) in computedAccordionItems" :key="index" class="col accor">
          <div class="accordion-item text-white">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#flush-collapse' + index" aria-expanded="false"
                :aria-controls="'flush-collapse' + index">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span>{{ item.title }}</span>
                </div>
              </button>
            </h2>
            <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <hr>
              <div class="accordion-body text-white">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const computedAccordionItems = computed(() => [
  { title: t('mileage'), content: t('accord_mileage_desc') },
  {
    title: t('damage_history'),
    content: t("damage_history_desc"),
  },
  {
    title: t('count_of_owners'),
    content: t('count_of_owners_desc'),
  },
  {
    title: t('body_color'),
    content: t('body_color_desc'),
  },
  {
    title: t('technical_condition'),
    content: t('technical_condition_desc'),
  },
  { title: t('fuel_type'), content: t('fuel_type_desc') },
  {
    title: t('vehicle_equipment'),
    content: t('vehicle_equipment_desc'),
  },
  {
    title: t('current_market_prices'),
    content: t('current_market_prices_desc'),
  },
]);

</script>

<style scoped>
.icon {
  width: 22px;
  height: 22px;
}

.container {
  margin-top: 75px;
  max-width: 940px;
}

.accordion-flush .accordion-item:last-child {
  border-radius: 20px !important;
}

.accordion-item {
  background: linear-gradient(121deg, #27374d 2.99%, #526d82 96.3%);
  outline: none;
  border: none;
}

.accordion-item:focus {
  background: linear-gradient(121deg, #27374d 2.99%, #526d82 96.3%);
  outline: none;
  border: none;
}

.accordion-button,
.accordion-collapse {
  border-radius: 20px !important;
  background: linear-gradient(121deg, #27374d 2.99%, #526d82 96.3%);
}

.accordion-button {
  outline: none;
}

.accor {
  margin-top: 14px;
}

.accordion-item:focus,
.accordion-item:active,
.accordion-button:focus,
.accordion-button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:focus-visible {
  outline: none !important;
}

h2 {
  font-family: Jost;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.accordion {
  max-width: 1000px;
}
</style>
