<template>
    <form @submit.prevent="onSubmit"
        class="d-flex flex-column justify-content-around p-5 align-items-center text-center">
        <transition name="fade-slide" mode="out-in">
            <div class="col-12" :key="currentStep.title">
                <h5 v-if="!isLastStepCompleted" class="form-title fw-bold text-center">{{ currentStep.title }}</h5>
                <h5 v-else class="form-title fw-bold text-center">{{ t('valuation_incoming').toUpperCase() }}</h5>
            </div>
        </transition>

        <transition name="fade-slide" mode="out-in">
            <div v-if="make" class="col-12" :key="make">
                <h5 class="make mb-3 mt-3">{{ make?.toUpperCase() }} {{ model?.toUpperCase() }}</h5>
            </div>
        </transition>
        <CircleComponent class="mt-3" v-if="!isLastStepCompleted" :currentStep="step"
            :totalSteps="stepsConfiguration?.length || 1" />
        <InputComponent v-if="!isLastStepCompleted" v-model="currentStep.modelValue" :type="currentStep.type"
            :placeholder="currentStep.placeholder" :maxlength="currentStep.maxlength" :minlength="currentStep.minlength"
            :t="t" />

        <div v-else class="col-12 mt-2">
            <FinishCircleComponent />
        </div>

        <small v-if="!isLastStepCompleted" class="fw-bold mb-3 second-placeholder">{{ currentStep.desc }}</small>

        <div class="text-center mt-2" v-else>
            <p class="mt-3 mb-2 tip">{{ t('tip') }}</p>
            <small>{{ funFact }}</small>
        </div>

        <vue-recaptcha class="grecaptcha-badge" theme="light" size="invisible" :tabindex="0"
            @widgetId="recaptchaWidget = $event" @verify="callbackVerify($event)" @expired="callbackExpired()"
            @fail="callbackFail()" />

        <button v-if="!isLastStepCompleted" class="text-center fw-bold w-sm-100 w-xl-50"
            :disabled="isRequest || currentStep.modelValue < currentStep.minlength">{{ t('next') }}</button>
    </form>
</template>

<script setup>
import CircleComponent from "@/components/form/CircleComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import useRequest from '@/composables/useRequest';
import { ref, computed, watchEffect, nextTick } from 'vue';
import useGetSteps from '@/composables/useGetSteps';
import { useStore } from 'vuex';
import { stepsConfig } from '@/utils/config.js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { facts } from '@/utils/config';
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
import FinishCircleComponent from '@/components/form/FinishCircleComponent.vue';

const localVin = ref('');
const fuel = ref('');
const capacity = ref('');
const currency = ref('');

const { t, locale } = useI18n();
const token = computed(() => store.state.token);

const store = useStore();
const router = useRouter();
const { resetRecaptcha } = useRecaptcha();

const funFact = ref(facts(t));

const recaptchaWidget = ref(null);

const callbackVerify = (response) => {
    store.commit('updateToken', response);
    sendForm();
};

const callbackExpired = () => {
    store.commit("updateToken", null);
};
const callbackFail = () => {
    console.log("fail");
};

const actionReset = () => {
    nextTick(() => {
        if (recaptchaWidget.value) {
            resetRecaptcha(recaptchaWidget.value);
        }
    });
};

const vin = computed({
    get: () => store.state.carData.vin,
    set: (value) => store.commit('updateCarData', { vin: value })
});

const odometer = computed({
    get: () => store.state.carData.currentOdometer,
    set: (value) => store.commit('updateCarData', { currentOdometer: value })
});

const plate = computed({
    get: () => store.state.carData.plate,
    set: (value) => store.commit('updateCarData', { plate: value })
});

const firstRegDate = computed({
    get: () => store.state.carData.firstRegDate,
    set: (value) => store.commit('updateCarData', { firstRegDate: value })
});

const make = computed({
    get: () => store.state.carData.make,
    set: (value) => store.commit('updateCarData', { make: value })
});

const model = computed({
    get: () => store.state.carData.model,
    set: (value) => store.commit('updateCarData', { model: value })
});

const prodYear = computed({
    get: () => store.state.carData.productionYear,
    set: (value) => store.commit('updateCarData', { productionYear: value })
});

const isLastStepCompleted = ref(false);
const isRequest = ref(false);

const stepsConfiguration = ref(stepsConfig({ localVin, odometer, plate, firstRegDate }, locale.value, t));
console.log(stepsConfiguration.value);
const { step, nextStep, currentStep } = useGetSteps(stepsConfiguration);

const isLastStep = computed(() => step.value === stepsConfiguration.value.length - 1);

const handleApiResponse = (responseData) => {
    store.commit('updateCarData', { vin: responseData.vin });
    store.commit('updateCarData', { make: responseData.make });
    store.commit('updateCarData', { model: responseData.model });
    store.commit('updateCarData', { prodYear: responseData.productionYear });
    store.commit('updateCarData', { firstRegDate: firstRegDate.value || responseData.productionYear });
};

const sendForm = async () => {
    isRequest.value = true;
    const { sendData } = useRequest();
    vin.value = localVin.value;

    try {
        const response = await sendData(vin.value ?? null, odometer.value ?? null, plate.value ?? null, firstRegDate.value ?? null, token.value);

        if (response.status === 'ok') {
            // Przypisz dane z odpowiedzi
            if (response.result?.productionYear) prodYear.value = response.result?.productionYear;
            if (response.result?.capacity) capacity.value = response.result?.capacity;
            if (response.result?.currency) currency.value = response.result?.currency;
            if (response.result?.fuel) fuel.value = response.result?.fuel;

            handleApiResponse(response.result);

            if (isLastStep.value) {
                isLastStepCompleted.value = true;
            } else {
                nextStep();
            }
        } else {
            // Obsługa błędu odpowiedzi, reset danych i przekierowanie
            store.dispatch('resetData');
            actionReset();
            store.dispatch('setError', 'error');
            // router.push('/');
            console.error('Błąd przy wysyłaniu formularza:', response.message);
        }
    } catch (error) {
        console.error('Błąd podczas wysyłania danych:', error);
        alert('Wystąpił problem z serwerem. Przekierowanie na stronę główną.');
        store.dispatch('resetData');
        store.dispatch('setError', 'error');
        // router.push('/');
    } finally {
        isRequest.value = false;
    }
};

const onSubmit = () => {
    if (token.value) {
        sendForm();
    } else {
        window.grecaptcha.execute();
    }
};

watchEffect(async () => {
    if (isLastStepCompleted.value) {
        // token && vin && make && model && productionYear && odometer
        if (!vin.value || !make.value || !model.value || !prodYear.value || !odometer.value) {
            store.dispatch('setError', 'nd');
            setTimeout(() => {
                store.dispatch('resetData');
                actionReset();
                router.push('/');
            }, 2500);
        }

        const sendValData = {
            vin: vin.value,
            make: make.value,
            model: model.value,
            productionYear: prodYear.value,
            fuel: fuel.value,
            capacity: capacity.value,
            odometer: odometer.value,
            currency: currency.value,
            token: token.value
        };

        try {
            setTimeout(async () => {
                const status = await store.dispatch("getValuationApi", sendValData);
                if (status) {
                    const mainLocale = locale.value.split('-')[0];
                    router.push(`/${mainLocale}/result`);
                }
            }, 4000);
        } catch (error) {
            console.error(error);
        }
    }
});
</script>

<style scoped>
form {
    background-color: white;
    min-height: 300px;
    border-radius: 30px;
}

.form-title,
.tip {
    color: #526D82;
}

h1 {
    font-weight: 700;
}

h5 {
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
}

button {
    background-color: #526D82;
    color: #fff;
    border-radius: 30px;
    border: none;
    padding: 15px;
}

button:hover {
    background-color: #526D82;
    border-radius: 30px;
}

button:disabled {
    opacity: .7;
}

.second-placeholder {
    font-size: 10px;
    color: #526D82;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

@media (min-width: 768px) {
    form {
        width: 370px;
    }
}
</style>