export const tr = {
  "fast_and_free": "HIZLI VE ÜCRETSİZ",
  "vehicle_valuation": "ARAÇ DEĞERLENDİRMESİ",
  "valuation": "DEĞERLENDİRME",
  "vin": "VIN",
  "want_to_know_valuation": "Arabanızın değerini öğrenmek veya bir aracı satın almadan önce değerini kontrol etmek ister misiniz? Ücretsiz araç değerlemesini kullanın! Doğru ve güvenilir bir değerleme almak için VIN'i girin. Birkaç ek adımla, daha ayrıntılı bir analiz sağlayabiliriz. Bu basit, hızlı ve ücretsiz! Beklemeyin – arabanızın değerini şimdi kontrol edin!",
  "plate": "Tescil numarası",
  "mileage": "Kilometre",
  "accord_mileage_desc": "Düşük kilometreye sahip araçlar genellikle daha az aşındığı için ikinci el araç pazarında daha yüksek fiyatlar elde ederler.",
  "damage_history": "Kaza geçmişi",
  "damage_history_desc": "Kazalara karışmış araçların potansiyel yapısal hasar nedeniyle genellikle daha düşük bir değeri vardır.",
  "count_of_owners": "Önceki sahip sayısı",
  "count_of_owners_desc": "Daha az sahibi olan araçlar genellikle daha iyi bakıldıkları için daha yüksek değerlendirilebilir.",
  "body_color": "Gövde rengi",
  "body_color_desc": "Popüler renkler aracın değerini artırabilirken, sıradışı renkler değeri düşürebilir.",
  "technical_condition": "Teknik durum",
  "technical_condition_desc": "Araçtaki mekanik ve teknik durum, motor, şanzıman ve diğer bileşenlerin durumu dahil olmak üzere fiyat üzerinde önemli bir etkiye sahiptir.",
  "fuel_type": "Yakıt türü",
  "fuel_type_desc": "Mevcut trendler ve piyasa tercihleri, belirli motor türlerini diğerlerinden daha istenen hale getirebilir.",
  "vehicle_equipment": "Araç donanımı",
  "vehicle_equipment_desc": "Navigasyon sistemleri, deri iç mekanlar, yüksek kaliteli ses sistemleri gibi ek özellikler aracın değerini artırabilir.",
  "current_market_prices": "Mevcut piyasa fiyatları",
  "current_market_prices_desc": "Piyasa fiyatları, belirli bir araç modeline olan talep ve arz temelinde dalgalanır ve nihai değerlemesini etkiler.",
  "how_to_valuate_vehicle_before_sell": "Bir aracı satın almadan/satmadan önce nasıl değerlendirirsiniz?",
  "prod_year": "Üretim yılı",
  "first_reg_date": "İlk tescil tarihi",
  "contact": "İletişim",
  "privacy_policy": "Gizlilik politikası",
  "regulations": "Şartlar ve koşullar",
  "valuation_desc": "Araç değerlemesi, otomotiv pazar uzmanlarımızın geniş deneyimine dayanarak hazırlanmıştır. Değerleme prosedürü, Avrupa ve Amerikan pazarlarındaki araç fiyatlarındaki değişen trendleri karşılaştırmak için yapay zeka (AI) kullanmaktadır. Değerleme, aracın yaşı ve kilometresi gibi parametreleri dikkate alır. Değerlememiz ayrıca, yedek parça veya bileşen eksikliği ve enflasyon gibi en son otomotiv ve sosyal sorunları da göz önünde bulundurarak, ikinci el araç fiyatlarını etkileyen faktörleri değerlendirir.",
  "min_price": "Minimum fiyat",
  "med_price": "Ortalama fiyat",
  "max_price": "Maksimum fiyat",
  "we_have_report_for_you": "Bu araç için bir raporumuz var!",
  "incident_history": "Olay geçmişi",
  "odometer_reading": "Kilometre okuması",
  "vehicles_inspection": "Araç muayeneleri",
  "archive_photos": "Arşiv fotoğrafları",
  "check_now": "RAPORU GÖRÜNTÜLE",
  "unfortunately_not_found_desc": "Üzgünüz, bu aracın üretim yılını bulamadık, lütfen yukarıdaki alana girin.",
  "type_odometer": "Araç değerinin daha iyi bir tahmini için aracın mevcut kilometre bilgisini kilometre cinsinden girin.",
  "check_another_vin": "Başka bir aracı değerle",
  "what_can_affet_the_price_of_vehicle": "Araç fiyatınızı etkileyebilecek faktörler nelerdir?",
  "calcualtor_of_vehicle": "Çevrimiçi araç değerleme hesaplayıcısı",
  "type_plate": "Araç plaka numarasını girin.",
  "type_first_reg_date": "Araç ilk tescil tarihini girin. B alanında tescil belgesinde bulabilirsiniz.",
  "price_odometer": "Fiyat",
  "price_year": "Fiyat",
  "year": "Yıl",
  "regulations_admission": "<p>Bu Yönetmelik, www.vinValuation.com internet alanında işletilen VinValuation adı altında faaliyet gösteren web sitesinin kullanım kurallarını tanımlar (bundan sonra Site olarak anılacaktır). Bu Yönetmelik, Site'nin kullanım kurallarını içermektedir. Bu Yönetmeliğin veya herhangi bir hükmünün uygulanmasını kabul etmiyorsanız, bu Site'den derhal çıkmalısınız.</p><p>Şirketimizin tam adı Car Data Experts LTD'dir. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Şirketler evi numarası: 10327199. Bize e-posta ile şu adresten ulaşabilirsiniz: <a href=mailto:{email}>{email}</a></p><p>Site'nin ana amacı, kullanıcılarına çalınmış veya hasar görmüş araçlarla ilgili verileri Car Data veritabanına (Araç Verisi) girme imkanı sağlamak ve bu verileri bu veritabanını kullanan diğer bireylerle paylaşmaktır.</p><p>Sitede sunulan hizmetler, Avrupa Birliği veya Amerika Birleşik Devletleri'nde ikamet eden veya kayıtlı ofisi bulunan bireyler, tüzel kişiler ve diğer kuruluşlar için tasarlanmıştır.</p><p>Sitedeki hizmetler, en az 18 yaşında olan bireylere yöneliktir.</p><p>Kişisel verilerin işlenmesi ve çerez adı verilen dosyaların kullanımı ile ilgili kurallar, Site'de bulunan Gizlilik Politikasında sunulmaktadır.</p><p>Site'yi kullanmak için, kullanıcının internet erişimi olan bir cihaz ve Internet Explorer 9.0 veya benzeri bir web tarayıcısı kullanması gerekmektedir. Site'nin bazı işlevlerini kullanmak için Java ve Java Script yazılımlarını kullanmak ve çerezlerin kullanılmasına izin vermek gerekmektedir. Kullanıcının cihazının yukarıda belirtilen teknik gereksinimleri karşılamaması nedeniyle, Site'mizde yayınlanan verilere erişim eksikliğinden sorumlu değiliz.</p>',",
  "regulations_definition": "<p>Bu Yönetmelikte, aşağıda tanımlanan terimler aşağıdaki anlamlara sahip olacaktır:</p><p>Araç Verisi, Çalıntı veya hasar görmüş araçlar hakkında ve çalıntı veya hasar koşulları hakkında, Site kullanıcıları tarafından oluşturulan ve bu veritabanını kullanan diğer kişilere sunulan verileri ifade eder.</p><p>Tüketici, ticari veya mesleki faaliyetlerle ilgisi olmayan amaçlarla hareket eden herhangi bir gerçek kişiyi ifade eder.</p><p>Veritabanı, araçların teknik durumu ve geçmişi hakkında bizim Araç Verisi veritabanımızdır.</p><p>VIN numarası, otomotiv endüstrisi tarafından bir motorlu aracın, traktörün, motosikletin, scooter'ın ve mopedin belirli bir örneğini tanımlamak için kullanılan araç tanımlama numarasıdır (Araç Tanımlama Numarası), ISO 3833 standardında tanımlandığı gibi.</p>',",
  "regulations_license_desc": "<p>Diğer hükümlere tabi olarak, Siteye ve Sitede bulunan materyallere ilişkin tüm fikri mülkiyet hakları bize veya lisans verenlerimize aittir. Aşağıdaki lisansa tabi olarak, tüm fikri mülkiyet hakları saklıdır.</p> <p>Kullanıcılar, yalnızca kendi kullanımları için, aşağıda ve Hizmet Şartlarının diğer bölümlerinde belirtilen istisnalara tabi olarak, dosyaları görüntüleyebilir ve önbelleğe almak için indirebilirler.</p><p>Aşağıdakiler özellikle yasaktır:</p><p>a. Siteden elde edilen materyallerin dağıtımı (başka bir web sitesinde dağıtım dahil);</p><p>b. Site ve Sitede bulunan materyallerle ilgili satış, kiralama, ödünç verme, hediye etme, devretme veya alt lisans verme;</p><p>c. Sitede mevcut olan herhangi bir materyalin halka açık performansı;</p><p>d. Sitede toplanan materyalleri ticari amaçlarla yeniden üretme, çoğaltma, kopyalama veya başka şekilde kullanma;</p><p>e. Sitede bulunan herhangi bir materyali değiştirme veya başka şekilde değiştirme;</p><p>f. Yukarıdakiler için açık yazılı iznimizi almadan Siteden elde edilen materyalleri dağıtma.</p>",
  "regulations_use_page": "<p>1. Site'i zarar verecek veya Site'nin kullanım yeteneğini olumsuz etkileyecek şekilde kullanmak; ya da herhangi bir şekilde yasadışı, kanunsuz, zararlı veya yasadışı, kanunsuz veya zararlı bir amaç veya eylemle ilgili olarak kullanmak yasaktır. Site'de mevcut olan tüm bilgi ve materyaller, grafik ve diğerleri dahil olmak üzere, tüm haklarımızı saklı tutuyoruz.</p><p>2. Site'yi herhangi bir casus yazılım, virüs, Truva atı, solucan, tuş kaydedici, rootkit veya diğer zararlı yazılımlar veya cihazlar içeren veya bunlara atıfta bulunan herhangi bir materyali kopyalamak, kullanmak, depolamak, iletmek, göndermek, yayınlamak veya dağıtmak için kullanmak yasaktır.</p><p>3. Site'den veya Site ile ilgili verilerin sistematik veya otomatik olarak toplanması (aşağıdaki teknikleri kullanarak: scraping, veri madenciliği, veri çıkarımı ve veri toplama) yazılı iznimiz olmadan yasaktır.</p><p>4. Site'yi istenmeyen ticari bilgileri iletmek veya göndermek için kullanmak yasaktır.</p><p>5. Site'yi herhangi bir pazarlama amacıyla kullanmak yazılı iznimiz olmadan yasaktır.</p>",
  "regulations_limited_access": "<p>1. Site'nin belirli kaynaklarına erişim kısıtlıdır. Kullanıcıların daha önce imzalanmış sözleşmelerden doğan haklarına tabi olarak, Site'nin diğer kaynaklarına erişimi sınırlama veya Site'nin tamamına erişimi kısıtlama hakkını saklı tutuyoruz.</p><p>2. Kullanıcı, Site'deki kısıtlı kaynaklara veya kısıtlı erişimle diğer içerik veya hizmetlere erişim sağlayan bir kullanıcı adı veya kullanıcı adı ve şifre aldığında, şifre ve kullanıcı adını üçüncü şahıslara ifşa etmeyecek şekilde güvence altına almakla yükümlüdür.</p>",
  "regulations_user_posted": "<p>1. Kullanıcı tarafından üretilen içerik yasadışı olmamalı, sosyal bir arada yaşama ilkelerini, Netiketeyi veya diğer yerleşik gelenekleri ihlal etmemeli, üçüncü şahıs haklarına tecavüz etmemeli ve kullanıcıya, bize veya herhangi bir üçüncü tarafa karşı herhangi bir talep veya yasal işlem doğurmamalıdır (her durumda, geçerli herhangi bir yasaya göre).</p><p>2. Siteye, devam eden veya tehdit edilen herhangi bir dava veya benzeri işlemlerin konusu olan içeriklerin paylaşılması yasaktır.</p><p>3. Sitede yayınlanan, depolanan veya orada bulunan herhangi bir içeriği veya materyali değiştirme veya kaldırma hakkını saklı tutarız.</p><p>4. Kullanıcının Siteye yüklediği içerik ve diğer materyallerle ilgili olarak bu Şartların ve geçerli yasanın hükümlerine tabi olarak, Sitede yayınlanan veya yüklenen içerikleri ve materyalleri izleme yükümlülüğümüz yoktur.</p><p>5. Kullanıcı tarafından Siteye yüklenen herhangi bir veri, kaybı önlemek için kendi cihazlarında da saklanmalıdır.</p>",
  "regulations_placing_data_in_db": "<p>1. Kullanıcı, Sitede yer alan formu kullanarak Veritabanında Araç Verilerini sağlar.</p><p>2. Kullanıcı, Araç Verilerinin mevcut duruma ilişkin doğruluğundan sorumludur. Herhangi bir değişiklik durumunda, kullanıcı Veritabanında sağladığı Araç Verilerini derhal güncellemelidir.</p><p>3. Araç Verilerini Veritabanına gönderdikten sonra, kullanıcı sağladığı adrese bu verilerin Veritabanına yerleştirildiğini onaylayan bir e-posta alır.</p><p>4. Araç Verileri Veritabanına yerleştirilir ve bu veritabanını kullanan diğer varlıklara, VIN numarası ile tanımlanan belirli bir araç hakkında bilgi edinmeleri amacıyla sunulur.</p><p>5. Araç Verileri ile ilgili Veritabanında yeni bilgiler ortaya çıkarsa, Araç Verilerini gönderen kullanıcıya sağladığı e-posta adresi aracılığıyla bilgi vermeye çalışacağız.</p><p>6. Araç Verileri, Sitedeki iletişim formu aracılığıyla bizimle iletişime geçerek veya Şartlar ve Koşulların Giriş kısmında sağlanan e-posta adresine yazarak Veritabanından geri çekilebilir.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Şüpheye yer bırakmamak için, Veritabanındaki Araç Verilerini sağlama kullanıcı sözleşmesine dayanarak, Araç Verilerinin ait olduğu aracı bulma yükümlülüğümüz yoktur.</p><p>2. Tüketici olmayan kullanıcılar için, geçerli yasaların izin verdiği ölçüde, kalite ile ilgili herhangi bir beyan yapmıyor, garanti vermiyor, sorumluluğu hariç tutuyor ve Site üzerinde sağlanan hizmet standartları ve Site kullanımı ile ilgili herhangi bir beyan yapmıyoruz (özellikle, tüm garantileri hariç tutuyor, geçerli yasalar tarafından sağlanan tatmin edici kalite, amaca uygunluk veya gerekli özen ve becerinin kullanımı ile ilgili herhangi bir beyan yapmıyoruz).</p><p>3. Sitede teknik sorunlar fark ettiğinizde veya tarafımızdan sağlanan eksik veya güncel olmayan bilgiler fark ettiğinizde, ayrıca Site ile ilgili herhangi bir şikayet durumunda (şikayetler), lütfen Bize Ulaşın sayfasındaki formu kullanarak veya yukarıda Genel Şartlar ve Koşulların Giriş kısmında belirtilen e-posta adresine yazarak derhal bize bildirin. Hızlı bir şekilde yanıt vermeye çalışıyoruz.</p>',",
  "regulations_liability": "<p>1. Bu Şartlar'daki hiçbir hüküm (a) kasıtlı hata veya kasıtlı yanlış beyan nedeniyle oluşan zararlara karşı sorumluluğumuzu hariç tutmaz veya sınırlamaz; (b) bizim veya kullanıcının sorumluluğunu geçerli yasaya aykırı bir şekilde sınırlamaz; veya (c) geçerli yasaya göre hariç tutulamayacak bir sorumluluğumuzu hariç tutmaz; özellikle, Şartlar kasıtlı hata nedeniyle oluşan zararlara karşı bizim veya kullanıcının sorumluluğunu hariç tutmaz.</p><p>2. Şartlar'da belirtilen sorumluluk hariç tutmaları ve sınırlamaları:</p><p> a. yukarıdaki 1. paragrafta belirtilen sınırlamalara tabidir;</p><p> b. Şartlar ve Gizlilik Politikası ile bağlantılı olarak, Şartlar ve Gizlilik Politikası'nın konusuyla ilgili olarak doğabilecek herhangi bir sorumluluğa, sözleşmesel, haksız fiil veya benzeri sorumluluklara ilişkindir;</p><p> c. Tüketicilere uygulanmaz.</p><p>3. Tüketici olmayan kullanıcılar için, geçerli yasaların izin verdiği ölçüde:</p><p> a. kasıtlı hatamız olmadıkça, herhangi bir türdeki zararlardan sorumlu değiliz. Garanti ve benzeri garanti kurumlarından doğan herhangi bir sorumluluk burada hariç tutulmuştur;</p><p> b. bizim veya kullanıcının verilerine, veri tabanlarına veya yazılımlarına, kayıplara veya zararlara karşı sorumlu değiliz.</p><p>4. Kontrolümüz dışındaki bir olay veya olaylarla bağlantılı olarak doğan herhangi bir zarardan sorumlu değiliz.</p>",
  "regulations_relief": "<p>Tüketici olmayan kullanıcılar, bu Şartlar ve Koşulların ihlali ile ilgili olarak, bize karşı herhangi bir kayıp, zarar, tazminat, maliyet, sorumluluk ve masrafları (herhangi bir dava ile ilgili masraflar ve herhangi bir üçüncü tarafa ödediğimiz uzlaşma tutarları dahil) tazmin ederler ve yukarıda belirtilen masrafları karşılamakla yükümlüdürler.</p>",
  "regulations_violations": "<p>Bu Şartlar'dan doğan diğer tüm haklarımıza tabi olarak, kullanıcı tarafından bu Şartlar'ın herhangi bir şekilde ihlali durumunda, ihlale yanıt vermek için uygun gördüğümüz önlemleri alabiliriz; bu, kullanıcının Siteye erişimini askıya almak, kullanıcının Siteye erişimini engellemek, belirli bir IP adresi için Siteye erişimi engellemek, kullanıcının internet sağlayıcısıyla iletişime geçerek Siteye erişimi engellemek veya hukuki işlem başlatmak gibi önlemleri içerebilir.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Yönetmelikler, yeni versiyonunun Site'de yayınlanmasıyla değiştirilebilir. Kullanıcı, özellikle e-posta adresi gibi kişisel verilerini bize sağlarsa ve bu Yönetmeliklerin değişimi sırasında onlara hizmet veriyorsak, bu Yönetmeliklerdeki değişiklikler hakkında e-posta ile bilgilendireceğiz.</p><p>2. Tüketici olmayan kullanıcılar için, iletişim bilgilerimizdeki bir değişiklik bu Yönetmeliklerde bir değişiklik teşkil etmez.</p><p>3. Kayıt sırasında sağlanan e-posta adresinde bir değişiklik olması durumunda, kullanıcıların bu adresi Site'de güncellemeleri gerekmektedir.</p>",
  "regulations_transfer": "<p>Kullanıcılar, bu Yönetmeliklerden doğan hak ve yükümlülüklerini devredemez, başkalarıyla paylaşamaz veya başka şekilde tasfiye edemezler, ayrıca bu hak ve yükümlülüklerle ilgili yükümlülük altına giremezler.</p>",
  "regulations_severability_clause": "<p>Bu Yönetmeliklerin herhangi bir hükmü bir mahkeme veya diğer yetkili otorite tarafından geçersiz veya etkisiz bulunursa, Yönetmeliklerin kalan hükümleri bağlayıcı kalacaktır. Geçersiz veya etkisiz bir hüküm, bir kısmının kaldırılmasından sonra geçerli olursa, o kısım kaldırılmış sayılacak ve hükmün kalan kısmı geçerli sayılacaktır.</p>",
  "regulations_exclusion_parts": "<p>Yönetmelikler, aramızdaki ilişkiyi ve Site kullanıcılarını yalnızca tanımlar ve üçüncü şahıslara herhangi bir hak vermez, onları bu Yönetmeliklerin herhangi bir hükmünü ileri sürmeye veya bu hükümlerinin uygulanmasını talep etmeye yetkilendirmez. Yönetmeliklerin hükümlerinin uygulanması, herhangi bir üçüncü şahsın rızasını gerektirmez.</p>",
  "regulations_scope_contract": "<p>Bu Yönetmelik, Gizlilik Politikamız ve Site'de belirtilen hizmet fiyatları ile birlikte, aramızda Site'nin kullanımı ile ilgili bir anlaşma teşkil eder ve Site'nin kullanımı ile ilgili önceki herhangi bir düzenlemeyi geçersiz kılar.</p>",
  "regulations_governing_law": "<p>1. Bu Yönetmelik Polonya yasalarına tabidir ve Yönetmelik ile düzenlenmemiş konularda, 23 Nisan 1964 tarihli Kanun, Medeni Kanun, 30 Mayıs 2014 tarihli Tüketici Hakları Kanunu (Tüketicilerle ilgili olarak) ve 18 Temmuz 2002 tarihli Elektronik Hizmetlerin Sunumu Hakkında Kanun hükümleri uygulanacaktır.</p><p>2. Tüketici olmayan kullanıcılar için, Yönetmelik kapsamında ortaya çıkan herhangi bir uyuşmazlık, Polonya mahkemelerinin münhasır yargı yetkisine tabi olacaktır.</p><p>3. Tüketici olan ve anlaşma imzalama anında Polonya'da ikamet eden kullanıcılar için, bu Yönetmelik veya Gizlilik Politikası ile ilgili herhangi bir uyuşmazlık, Polonya mahkemelerinin münhasır yargı yetkisine tabi olacaktır.</p>",
  "back": "İade",
  "definition": "Tanımlar",
  "admission": "Giriş",
  "license_use_page": "Siteyi ve üzerindeki materyalleri kullanma lisansı",
  "use_page": "Site Kullanımı",
  "limited_access": "Sınırlı erişim",
  "user_posted_content": "Kullanıcı tarafından gönderilen içerik",
  "placing_vehicle_data_in_db": "Araç Verilerini Veritabanına Girme",
  "disclaimer_of_liability": "Sorumluluk Reddi",
  "relief_from_obligations": "Yükümlülüklerden Kurtulma",
  "violations_of_regulations": "Yönetmelik İhlalleri",
  "transfer": "Devretme",
  "severability_clause": "Kurtarıcı Hüküm",
  "exclusion_third_parts": "Üçüncü Taraf Haklarının Hariç Tutulması",
  "scope_of_contract": "Sözleşmenin Kapsamı",
  "changing_of_provisions_regulations": "Yönetmelik Hükümlerinin Değiştirilmesi",
  "governing_law_and_jurisdiction": "Geçerli Hukuk ve Yargı Yetkisi",
  "limited_and_warranties_complaints": "Sınırlı Temsiller ve Garantiler ve Şikayetlerin İletilmesi",
  "main_tip": "Web sitemizi kullanarak VIN numarasını kullanarak araçları çevrimiçi değerlendirin. Bu şekilde, aracınızın ne kadar değerli olduğunu hızlı ve doğru bir şekilde öğrenebilirsiniz. Sadece aracınızın VIN numarasını girin ve değerini değerlendirmenize yardımcı olalım. Beklemeyin, şimdi kontrol edin!",
  "enter_vin_number": "VIN Girin",
  "meta_title": "Çevrimiçi Araç Değerleme Hesaplayıcısı - aracınızı hızlı ve doğru bir şekilde değerlendirin.",
  "meta_desc": "Çevrimiçi araç değerlemesi, aracınızın değerini hızlı ve doğru bir şekilde değerlendirin. Hesaplayıcımız, kontrol edilen araç model yılı için fiyatı ve karşılaştırma için biraz daha eski ve genç olanları sağlayacaktır.",
  "h1_title": "Çevrimiçi araç değerlemesi yalnızca VIN gerektirir",
  "check_valuation": "Değerlendirmeyi kontrol edin!",
  "next": "Sonraki",
  "help": "Yardım",
  "no_data_valuation": "Değerleme için veri yok",
  "check_if_stolen": "Çalıntı olup olmadığını kontrol et",
  "decode_vin_number": "VIN numarasını çöz",
  "additional_links": "Ek bağlantılar",
  "check_vin_correct": "VIN numarası geçerliliğini kontrol et",
  "how_to_vin_valuation": "Bir aracı satın almadan/satmadan önce nasıl değerlenir?",
  "calculator_valuation": "Çevrimiçi araç değerleme hesaplayıcısı",
  "calculator_valuation_desc": "Bir aracın değerini tahmin etmenin birçok yöntemi vardır; en hızlı ve nispeten doğru olanı, vinvaluation gibi bir web sitesinde kontrol etmektir. Yapay zeka (AI) destekli özel algoritmamız, sunulan araçların yaşını, markasını, donanımını ve durumunu hassas bir şekilde analiz ederek, binlerce çevrimiçi listelemeden veri toplar. Buna dayanarak, kontrol ettiğiniz aracın ne kadar değerinde olması gerektiğini tahmin ediyoruz. Değerlendirmeyi evden çıkmadan, tamamen ücretsiz olarak birkaç dakika içinde alırsınız. Sadece VIN numarası veya aracın tescil tarihi gibi birkaç parametreye ihtiyacımız var. Yanıt olarak, kontrol ettiğiniz yılın değerlemesini değil, aynı zamanda birkaç yakın, daha genç ve daha eski modelin değerlemesini de alacaksınız. Bu, örneğin, hala sahibi olmanın değerli olup olmadığını veya çok fazla kaybetmeyeceğinizi ve şu anda satmanın daha iyi olup olmayacağını tam olarak anlamanızı sağlar.",
  "you_get_valuation_for_free": "<strong>Değerlendirmeyi</strong> evden çıkmadan, tamamen ücretsiz olarak birkaç dakika içinde alırsınız. Sadece VIN numarası veya aracın tescil tarihi gibi birkaç parametreye ihtiyacımız var.",
  "in_response_you_receive_not_only_valuation": "Yanıt olarak, kontrol ettiğiniz yılın değerlemesini değil, aynı zamanda birkaç yakın, daha genç ve daha eski modelin değerlemesini de alacaksınız. Bu, örneğin, hala sahibi olmanın değerli olup olmadığını veya çok fazla kaybetmeyeceğinizi ve şu anda satmanın daha iyi olup olmayacağını tam olarak anlamanızı sağlar.",
  "compare_market_price": "Piyasa fiyatlarını karşılaştırın",
  "what_we_offer_free": "Ücretsiz olarak sunduğumuz şeyi elbette manuel olarak da yapabilirsiniz. <strong>İnternetteki ilanları</strong> gözden geçirin veya mevcut araç fiyatlarını öğrenmek için birkaç <strong>oto galerisine</strong> gidin.",
  "if_you_are_not_sure": "Bir aracın değerini belirlemek için hangi bilgilere ihtiyaç duyduğunuzdan emin değilseniz, bir araç değerleme uzmanıyla iletişime geçebilir veya bir bayi veya otomobil satıcısıyla irtibat kurabilirsiniz. Onlar da aracınızın değerini tahmin etmenize yardımcı olabilirler. Ancak, bayinin veya satıcının teklif edeceği fiyatın muhtemelen önemli ölçüde daha düşük olacağını unutmayın. Onlar da sizden aracı satın alarak kar elde etmelidir. Hangi yöntemi seçerseniz seçin, aracın değerlemesinin yalnızca yaklaşık bir fiyat olduğunu unutmayın. Araçtaki nihai değer, teknik durum, donanım, servis geçmişi ve aracın potansiyel bir alıcı üzerindeki genel izlenimi gibi birçok faktöre bağlıdır. Aracı satmadan veya satın almadan önce aracın durumunu iyice kontrol etmek önemlidir. Bir araç satın almayı planlarken, araç geçmişini kontrol etmeyi unutmayın.",
  "regardless_of_which_method": "Hangi yöntemi seçerseniz seçin, aracın değerlemesinin yalnızca yaklaşık bir fiyat olduğunu unutmayın. Araçtaki nihai değer, teknik durum, donanım, servis geçmişi ve aracın potansiyel bir alıcı üzerindeki genel izlenimi gibi birçok faktöre bağlıdır. Aracı satmadan veya satın almadan önce aracın durumunu iyice kontrol etmek önemlidir.",
  "check_the_valuation": "Araç geçmişini kontrol edin",
  "you_be_may_interested": "Ayrıca ilginizi çekebilir:",
  "check": "KONTROL ET",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Araç hakkında temel bilgileri ücretsiz olarak alın",
  "get_basic_infotmation_about_vehicle_desc": "VIN'i arama alanına girerek marka, model, üretim yılı ve teknik özellikler gibi. Kaza geçmişi, kilometre ve aracın yasal durumu gibi daha ayrıntılı veriler için, site ayrıca tam bir VIN raporu satın alma seçeneği sunmaktadır.",
  "stolen_cars": "Çalıntı Araçlar",
  "stolen_cars_title": "Çalıntı araçların birden fazla veritabanını kontrol edin,",
  "stolen_cars_desc": "gelişmiş arama araçlarını kullanarak. Sadece aracın VIN'ini veya kayıt numarasını girin ve sistem, aracın çalıntı olarak bildirilip bildirilmediğini kontrol etmek için geniş bir veritabanında arama yapacaktır.",
  "vin_correct": "VINDoğrula",
  "vin_correct_title": "VIN'in geçerliliğini kontrol edin.",
  "vin_correct_desc": "bu araç, VIN'in mevcut standartlara uyup uymadığını ve gerekli tüm bilgileri içerip içermediğini doğrular. Bu, VIN'in sahte olmadığını veya yanlış kaydedilmediğini garanti eder.",
  "easy_vin": "kolayVIN",
  "easy_vin_title": "Arabanın A'dan Z'ye tarihi tek yerde.",
  "easy_vin_desc": "İlgilendiğiniz araç hakkında bilginizi doğrulayın ve yeni bir araba satın alırken tatsız sürprizlerden kendinizi koruyun. VIN numarasını istediğiniz zaman kontrol edin. Raporlar günde 24 saat, haftada 7 gün üretilir.",
  "find_out_the_value_of_your_car_desc": "Arabanızın değerini bilmek veya satın almadan önce aracınızın değerini kontrol etmek mi istiyorsunuz? Ücretsiz araç değerlememizden yararlanın! Bir VIN numarası ve birkaç ek adımla ayrıntılı bir analiz sağlayabiliriz. Kolay, hızlı ve ücretsiz! Beklemeyin - hemen aracınızın değerini kontrol edin!",
  "enter_te_vin_number": "17 haneli VIN numarasını girin",
  "enter_mileage": "Araç kilometresini sağlayın",
  "enter_regplate": "Plaka numarasını giriniz",
  "tip": "Eğlenceli bilgi:",
  "odometer_readings": "Araç kilometresi",
  "number_of_regplate": "Plaka numarası",
  "valuation_incoming": "Alıntı yükleniyor",
  "pl_country": "Polonya",
  "en_country": "Büyük Britanya",
  "de_country": "Almanya",
  "cs_country": "Çek Cumhuriyeti",
  "lt_country": "Litvanya",
  "fr_country": "Fransa",
  "es_country": "İspanya",
  "ro_country": "Romanya",
  "nl_country": "Hollanda",
  "hu_country": "Macaristan",
  "bg_country": "Bulgaristan",
  "da_country": "Danimarka",
  "el_country": "Yunanistan",
  "it_country": "İtalya",
  "no_country": "Norveç",
  "pt_country": "Portekiz",
  "sk_country": "Slovakya",
  "sr_country": "Sırbistan",
  "sv_country": "İsveç",
  "tr_country": "Türkiye",
  "ar_country": "Suudi Arabistan",
  "fact_model_t": "İlk seri üretim otomobil, 1908 yılında montaj hattından çıkan Ford Model T'ydi.",
  "fact_bugatti": "Dünyanın en pahalı otomobili, yaklaşık 18 milyon dolara mal olan Bugatti La Voiture Noire'dir.",
  "fact_v8": "V8 motor 1902 yılında yaratıldı ve tasarımı otomotiv sektörü için devrim niteliğindeydi.",
  "fact_vw_beetle": "En uzun süre üretilen otomobil modeli ise 1938'den 2003'e kadar tam 65 yıl boyunca aralıksız üretilen Volkswagen Beetle'dır.",
  "fact_wipers": "İlk araba silecekleri, sürücülerin camlarını silmek için araçlarından inmek zorunda olduklarını fark eden Mary Anderson tarafından 1903 yılında icat edildi.",
  "fact_lamborghini": "Lamborghini ilk başlarda traktör üretti ve ancak daha sonra lüks spor otomobiller üretmeye başladı.",
  "fact_popular_color": "Dünyada en çok tercih edilen otomobil rengi beyaz olup, alıcıların yüzde 39'u tarafından tercih ediliyor.",
  "fact_electric_car": "İlk elektrikli otomobil, içten yanmalı motorlu otomobillerin icadından çok önce, 1832 yılında üretildi.",
  "fact_crank_start": "Elektrikli marş icat edilmeden önce arabalar krank kullanılarak çalıştırılıyordu, bu da oldukça tehlikeliydi ve ciddi yaralanmalara yol açabiliyordu.",
  "fact_ssc_tuatara": "Bir seri üretim otomobilin ulaştığı en yüksek hız rekoru 532,93 km/s ile SSC Tuatara tarafından kırıldı.",
  "error_message": "Dahili bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
  "no_data_alert": "Bu araç için veri bulunmamaktadır.",
};
