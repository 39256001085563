<template>
    <div class="container mt-5">
        <router-link :to="{ path: `/${$route.params.lang || 'en'}/` }">{{ t("back") }}</router-link>
        <h2 class="mt-3">{{ t('privacy_policy') }}</h2>

        <h4 class="mt-4 text-white">{{ t("admission") }}</h4>

        <p class="text-white" v-html="t('regulations_admission', { email: 'info@cardataexperts.com' })"></p>

        <h4 class="text-white">{{ t("definition") }}</h4>

        <p class="text-white" v-html="t('regulations_definition')"></p>

        <h4 class="text-white">{{ t("license_use_page") }}</h4>

        <p class="text-white" v-html="t('regulations_license_desc')"></p>

        <h4 class="text-white">{{ t("use_page") }}</h4>

        <p class="text-white" v-html="t('regulations_use_page')"></p>

        <h4 class="text-white">{{ t("limited_access") }}</h4>

        <p class="text-white" v-html="t('regulations_limited_access')"></p>

        <h4 class="text-white">{{ t("user_posted_content") }}</h4>

        <p class="text-white" v-html="t('regulations_user_posted')"></p>

        <h4 class="text-white">{{ t("placing_vehicle_data_in_db") }}</h4>

        <p class="text-white" v-html="t('regulations_placing_data_in_db')"></p>

        <h4 class="text-white">{{ t("limited_and_warranties_complaints") }}</h4>

        <p class="text-white" v-html="t('regulations_limited_and_warranties_complaints')"></p>

        <h4 class="text-white">{{ t("disclaimer_of_liability") }}</h4>

        <p class="text-white" v-html="t('regulations_liability')"></p>

        <h4 class="text-white">{{ t("relief_from_obligations") }}</h4>

        <p class="text-white" v-html="t('regulations_relief')"></p>

        <h4 class="text-white">{{ t("violations_of_regulations") }}</h4>

        <p class="text-white" v-html="t('reulations_violations')"></p>

        <h4 class="text-white">{{ t("changing_of_provisions_regulations") }}</h4>

        <p class="text-white" v-html="t('regulations_changing_provisions_regulations')"></p>

        <h4 class="text-white">{{ t("transfer") }}</h4>

        <p class="text-white" v-html="t('regulations_transfer')"></p>

        <h4 class="text-white">{{ t("severability_clause") }}</h4>

        <p class="text-white" v-html="t('regulations_severability_clause')"></p>

        <h4 class="text-white">{{ t("exclusion_third_parts") }}</h4>

        <p class="text-white" v-html="t('regulations_exclusion_parts')"></p>

        <h4 class="text-white">{{ t("scope_of_contract") }}</h4>

        <p class="text-white" v-html="t('regulations_scope_contract')"></p>

        <h4 class="text-white">{{ t("governing_law_and_jurisdiction") }}</h4>

        <p class="text-white" v-html="t('regulations_governing_law')"></p>
    </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style scoped>
p {
    color: #FFF;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

a:hover {
    text-decoration: underline;
}

h4 {
    color: #FFF;
    text-align: justify;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

h2 {
    color: #FFF;
    text-align: justify;
    font-family: Jost;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

a {
    color: white;
}
</style>