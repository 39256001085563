<template>
  <div class="svg-container">
    <svg :class="{ 'animated': true, 'bounceIn': animationClassActive }" width="52" height="53" viewBox="0 0 52 53"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1">
        <path id="Subtract" opacity="0.1" fill-rule="evenodd" clip-rule="evenodd"
          d="M26 52.5C40.3594 52.5 52 40.8594 52 26.5C52 12.1406 40.3594 0.5 26 0.5C11.6406 0.5 0 12.1406 0 26.5C0 40.8594 11.6406 52.5 26 52.5ZM26 46.5C37.0457 46.5 46 37.5457 46 26.5C46 15.4543 37.0457 6.5 26 6.5C14.9543 6.5 6 15.4543 6 26.5C6 37.5457 14.9543 46.5 26 46.5Z"
          fill="#526D82" />
        <circle class="progress-circle" cx="26" cy="26.5" r="20" fill="none" stroke="#526D82" stroke-width="6"
          :style="progressStyle" />
        <text x="26" y="30" text-anchor="middle" fill="#526D82" font-size="10px" font-family="Arial" dy=".3em">
          {{ percentage }}%
        </text>
      </g>
    </svg>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect, defineProps } from 'vue';

const props = defineProps({
  currentStep: Number,
  totalSteps: Number,
});

const percentage = ref(0);
const animationClassActive = ref(false);

const updatePercentage = () => {
  if (props.totalSteps === 0) {
    percentage.value = 0;
  } else {
    percentage.value = Math.floor((props.currentStep / props.totalSteps) * 100);
  }
};

const progressStyle = computed(() => {
  const dashArray = 125.6;
  const progress = (percentage.value / 100) * dashArray;
  return {
    strokeDasharray: `${progress} ${dashArray - progress}`,
    transition: 'stroke-dasharray 0.5s ease-in-out',
  };
});

watchEffect(() => {
  updatePercentage();
  animationClassActive.value = true;
  setTimeout(() => {
    animationClassActive.value = false;
  }, 500); // Czas trwania animacji
});
</script>

<style scoped>
.svg-container {
  width: 52px;
  height: 53px;
}

.progress-circle {
  stroke-dasharray: 125.6;
  transition: stroke-dasharray 0.5s ease-in-out;
}
</style>
