export const no = {
  "fast_and_free": "RASKT OG GRATIS",
  "vehicle_valuation": "KJØRETØYVERDI",
  "valuation": "VERDI",
  "vin": "VIN",
  "want_to_know_valuation": "Vil du vite verdien av bilen din eller sjekke verdien av et kjøretøy før du kjøper det? Bruk vår gratis kjøretøyverdi! Bare skriv inn VIN for å få en nøyaktig og pålitelig vurdering. Med noen få ekstra trinn kan vi gi en enda mer detaljert analyse. Det er enkelt, raskt og gratis! Ikke vent – sjekk verdien av bilen din nå!",
  "plate": "Registreringsnummer",
  "mileage": "Kilometerstand",
  "accord_mileage_desc": "Kjøretøy med lav kilometerstand er vanligvis mindre slitt, noe som er grunnen til at de ofte oppnår høyere priser i bruktbilmarkedet.",
  "damage_history": "Ulykkeshistorikk",
  "damage_history_desc": "Biler som har vært involvert i ulykker har vanligvis en lavere verdi på grunn av potensiell strukturell skade.",
  "count_of_owners": "Antall tidligere eiere",
  "count_of_owners_desc": "Kjøretøy som har hatt færre eiere kan vurderes høyere fordi de generelt er bedre vedlikeholdt.",
  "body_color": "Karosserifarge",
  "body_color_desc": "Populære farger kan øke verdien av kjøretøyet, mens uvanlige farger kan redusere den.",
  "technical_condition": "Teknisk tilstand",
  "technical_condition_desc": "Den mekaniske og tekniske tilstanden til kjøretøyet, inkludert tilstanden til motor, girkasse og andre komponenter, har en betydelig innvirkning på prisen.",
  "fuel_type": "Drivstofftype",
  "fuel_type_desc": "Nåværende trender og markedspreferanser kan gjøre visse typer motorer mer ettertraktede enn andre.",
  "vehicle_equipment": "Bilutstyr",
  "vehicle_equipment_desc": "Ekstra funksjoner som navigasjonssystemer, skinninteriør, høykvalitets lydsystemer kan øke verdien av kjøretøyet.",
  "current_market_prices": "Nåværende markedspriser",
  "current_market_prices_desc": "Markedspriser svinger basert på etterspørsel og tilbud for en gitt bilmodell, noe som påvirker den endelige vurderingen.",
  "how_to_valuate_vehicle_before_sell": "Hvordan vurdere et kjøretøy før kjøp/salg?",
  "prod_year": "Produksjonsår",
  "first_reg_date": "Dato for første registrering",
  "contact": "Kontakt",
  "privacy_policy": "Personvernerklæring",
  "regulations": "Vilkår og betingelser",
  "valuation_desc": "Kjøretøyvurderingen er utarbeidet basert på den omfattende erfaringen til våre eksperter på bilmarkedet. Vurderingsprosedyren bruker kunstig intelligens (AI) for å sammenligne endrede trender i bilpriser i europeiske og amerikanske markeder. Vurderingen tar hensyn til parametere som kjøretøyets alder og kilometerstand. Vår vurdering tar også hensyn til de nyeste bil- og samfunnsspørsmålene som mangel på deler eller komponenter og inflasjon, som påvirker prisene på brukte kjøretøy.",
  "min_price": "Minimumspris",
  "med_price": "Gjennomsnittspris",
  "max_price": "Maksimumspris",
  "we_have_report_for_you": "Vi har en rapport for dette kjøretøyet!",
  "incident_history": "Hendelseshistorikk",
  "odometer_reading": "Kilometerstand avlesning",
  "vehicles_inspection": "Kjøretøykontroller",
  "archive_photos": "Arkivbilder",
  "check_now": "VIS RAPPORT",
  "unfortunately_not_found_desc": "Dessverre kunne vi ikke finne produksjonsåret for dette kjøretøyet, vennligst skriv det inn i feltet ovenfor.",
  "type_odometer": "Skriv inn den nåværende kilometerstanden til kjøretøyet i kilometer for en bedre estimat av verdien.",
  "check_another_vin": "Vurder et annet kjøretøy",
  "what_can_affet_the_price_of_vehicle": "Hva kan påvirke prisen på kjøretøyet ditt?",
  "calcualtor_of_vehicle": "Nettbasert bilverdiberegner",
  "type_plate": "Skriv inn kjøretøyets registreringsnummer.",
  "type_first_reg_date": "Skriv inn datoen for kjøretøyets første registrering. Du kan finne den i registreringsdokumentet i felt B.",
  "price_odometer": "Pris",
  "price_year": "Pris",
  "year": "År",
  "regulations_admission": "<p>Denne forskriften definerer reglene for bruk av nettstedet som drives under navnet VinValuation på internett-domenet www.vinValuation.com (heretter kalt Nettstedet). Denne forskriften inneholder reglene for bruk av Nettstedet. Hvis du ikke godtar anvendelsen av denne forskriften eller noen av dens bestemmelser, bør du umiddelbart forlate dette Nettstedet.</p><p>Det fulle navnet på vårt selskap er Car Data Experts LTD. Enhet 4E, Enterprise Court, Farfield Park, Rotherham S63 5DB. Foretaksnummer: 10327199. Du kan kontakte oss via e-post på: <a href=mailto:{email}>{email}</a></p><p>Hovedformålet med Nettstedet er å gi brukerne muligheten til å legge inn data om stjålne eller skadde kjøretøy i Car Data-databasen (Kjøretøydatabase) og å dele disse dataene med andre enkeltpersoner som bruker denne databasen.</p><p>Tjenestene som er tilgjengelige på Nettstedet er ment for enkeltpersoner, juridiske enheter og andre enheter som har bosted eller registrert kontor i Den europeiske union eller De forente stater.</p><p>Tjenestene på Nettstedet er utelukkende rettet mot enkeltpersoner som er minst 18 år gamle.</p><p>Reglene for behandling av personopplysninger og bruk av filer kalt informasjonskapsler er presentert i vår personvernerklæring som er tilgjengelig på Nettstedet.</p><p>For å bruke Nettstedet må brukeren bruke en enhet med internettilgang og en nettleser Internet Explorer 9.0 eller et lignende program. For å bruke noen funksjoner på Nettstedet, er det nødvendig å bruke Java og Java Script-programvare, samt å tillate bruk av informasjonskapsler. Vi er ikke ansvarlige for manglende tilgang til data som er lagt ut på vårt Nettsted på grunn av at brukerens enhet ikke oppfyller de tekniske kravene spesifisert ovenfor.</p>',",
  "regulations_definition": "<p>I denne forskriften skal begrepene definert nedenfor ha følgende betydninger:</p><p>Kjøretøydata betyr data om stjålne eller skadede kjøretøy og omstendighetene rundt tyveri eller skade opprettet av brukere av nettstedet i databasen for bildata og gjort tilgjengelig for andre personer som bruker denne databasen.</p><p>Forbruker betyr enhver fysisk person som handler for formål som ikke er relatert til forretnings- eller profesjonelle aktiviteter.</p><p>Database betyr vår bildatabasen om den tekniske tilstanden og historien til kjøretøy.</p><p>VIN-nummer betyr kjøretøyidentifikasjonsnummeret (Vehicle Identification Number), brukt av bilindustrien for å identifisere en spesifikk instans av et motorvogn, traktor, motorsykkel, scooter og moped, som definert i ISO 3833-standarden.</p>',",
  "regulations_license_desc": "<p>Med forbehold om andre bestemmelser, eies alle immaterielle rettigheter til nettstedet og til materialene som finnes på nettstedet av oss eller våre lisensgivere. Med forbehold om lisensen nedenfor, er alle immaterielle rettigheter forbeholdt.</p> <p>Brukere kan se, laste ned filer for caching, utelukkende for eget bruk, med forbehold om unntakene angitt nedenfor og i andre deler av vilkårene for bruk.</p><p>Følgende er spesielt forbudt:</p><p>a. distribusjon av materialer hentet fra nettstedet (inkludert distribusjon på et annet nettsted);</p><p>b. salg, utleie, utlån, gave, overføring eller tildeling av underlisenser angående nettstedet og materialene som finnes på nettstedet;</p><p>c. offentlig fremføring av materialer tilgjengelig på nettstedet;</p><p>d. reprodusere, duplisere, kopiere eller på annen måte bruke materialer samlet på nettstedet for kommersielle formål;</p><p>e. endre eller på annen måte modifisere materialer som finnes på nettstedet;</p><p>f. distribuere materialer hentet fra nettstedet uten å innhente vårt eksplisitte skriftlige samtykke for ovennevnte.</p>",
  "regulations_use_page": "<p>1. Det er forbudt å bruke nettstedet på noen måte som er skadelig for det eller negativt påvirker muligheten til å bruke nettstedet; eller på noen annen måte som er ulovlig, ulovlig, skadelig, eller relatert til noe ulovlig, ulovlig, eller skadelig formål eller handling. Vi forbeholder oss alle rettigheter til all informasjon og materiale, inkludert grafisk og annet, tilgjengelig på nettstedet.</p><p>2. Det er forbudt å bruke nettstedet til å kopiere, bruke, lagre, overføre, sende, publisere, eller distribuere noe materiale som inneholder spyware, virus, trojaner, ormer, tastetrykklogger, rootkit, eller annen skadelig programvare eller enheter, eller referere til dem.</p><p>3. Enhver systematisk eller automatisert datainnsamling fra nettstedet eller data relatert til nettstedet (inkludert bruk av følgende teknikker: scraping, datagraving, datautvinning, og datainnsamling) er forbudt uten vårt skriftlige samtykke.</p><p>4. Det er forbudt å bruke nettstedet til å overføre eller sende uønsket kommersiell informasjon.</p><p>5. Det er forbudt å bruke nettstedet til markedsføringsformål uten vårt skriftlige samtykke.</p>",
  "regulations_limited_access": "<p>1. Tilgang til visse ressurser på nettstedet er begrenset. Vi forbeholder oss retten til å begrense tilgangen til andre ressurser på nettstedet eller å begrense tilgangen til hele nettstedet, med forbehold om brukernes rettigheter som følger av tidligere inngåtte avtaler.</p><p>2. I tilfelle brukeren får et brukernavn eller et brukernavn og passord fra oss som gir tilgang til begrensede ressurser på nettstedet eller annet innhold eller tjenester med begrenset tilgang, er brukeren forpliktet til å sikre passordet og brukernavnet slik at det ikke blir avslørt for noen tredjepart.</p>",
  "regulations_user_posted": "<p>1. Brukergenerert innhold må ikke være ulovlig, krenke prinsipper for sosial sameksistens, nettikette eller andre etablerte skikker, eller krenke rettighetene til tredjepart, eller gi opphav til krav eller rettslige skritt mot brukeren, oss eller noen tredjepart (i alle tilfeller, under gjeldende lov).</p><p>2. Det er forbudt å legge ut noe innhold på nettstedet som er eller har vært gjenstand for pågående eller truet rettssak eller lignende prosesser.</p><p>3. Vi forbeholder oss retten til å endre eller fjerne innhold eller materiale som er lagt ut på nettstedet, lagret eller publisert der.</p><p>4. Med forbehold om bestemmelsene i disse vilkårene og gjeldende lov angående innhold og annet materiale lagt ut på nettstedet av brukeren, er vi ikke forpliktet til å overvåke innholdet og materialene som er lagt ut eller publisert på nettstedet.</p><p>5. Alle data som brukeren legger ut på nettstedet bør også oppbevares på deres egne enheter for ekstra beskyttelse mot tap.</p>",
  "regulations_placing_data_in_db": "<p>1. Brukeren oppgir kjøretøydata i databasen ved å bruke skjemaet som er lagt ut på nettstedet.</p><p>2. Brukeren er ansvarlig for nøyaktigheten av kjøretøydataene i forhold til den nåværende tilstanden. Ved eventuelle endringer, bør brukeren raskt oppdatere kjøretøydataene som er oppgitt i databasen.</p><p>3. Etter å ha sendt inn kjøretøydataene til databasen, mottar brukeren en bekreftelses-e-post på adressen de oppga, som bekrefter plasseringen av disse dataene i databasen.</p><p>4. Kjøretøydataene plasseres i databasen og gjøres tilgjengelig for andre enheter som bruker denne databasen, for å skaffe informasjon om et spesifikt kjøretøy identifisert med sitt VIN-nummer.</p><p>5. Hvis ny informasjon angående kjøretøydataene dukker opp i databasen, vil vi prøve å informere brukeren som sendte inn kjøretøydataene via e-postadressen som ble oppgitt.</p><p>6. Kjøretøydataene kan trekkes tilbake fra databasen ved å kontakte oss gjennom kontaktskjemaet på nettstedet eller ved å skrive til e-postadressen som er oppgitt i innledningen til vilkårene og betingelsene.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. For å unngå tvil, basert på brukeravtalen for å gi kjøretøydata i databasen, er vi ikke forpliktet til å lokalisere kjøretøyet som kjøretøydataene gjelder.</p><p>2. I tilfelle av brukere som ikke er forbrukere, i den grad det er tillatt etter gjeldende lov, gir vi ingen representasjoner, gir ingen garantier angående kvalitet, ekskluderer ansvar, og gir ingen representasjoner angående standardene for tjenesten som tilbys på nettstedet og bruken av nettstedet (spesielt ekskluderer vi alle garantier, gir ingen representasjoner gitt av gjeldende lov angående tilfredsstillende kvalitet, egnethet for formål, eller utøvelse av nødvendig omsorg og dyktighet).</p><p>3. I tilfelle du legger merke til tekniske problemer på nettstedet eller legger merke til ufullstendige eller utdaterte opplysninger gitt av oss, samt eventuelle klager angående nettstedet (klager), bør du raskt varsle oss ved å bruke skjemaet på kontaktsiden eller ved å skrive til oss på e-postadressen angitt ovenfor i innledningen til vilkårene og betingelsene. Vi streber etter å svare raskt.</p>',",
  "regulations_liability": "<p>1. Ingen bestemmelse i disse vilkår (a) ekskluderer eller begrenser vårt ansvar for skade forårsaket av forsettlig feil eller forsettlig uriktige fremstillinger; (b) begrenser vårt eller brukerens ansvar på en måte som ville være i strid med gjeldende lov; eller (c) ekskluderer vårt eller brukerens ansvar hvis slikt ansvar ikke kan ekskluderes under gjeldende lov; spesielt ekskluderer vilkårene ikke vårt eller brukerens ansvar for skader forårsaket av forsettlig feil.</p><p>2. Unntakene og begrensningene av ansvar som er angitt i vilkårene:</p><p> a. er underlagt begrensningene spesifisert i avsnitt 1 ovenfor;</p><p> b. gjelder for ethvert ansvar som kan oppstå under vilkårene eller personvernerklæringen i forbindelse med emnet for vilkårene og personvernerklæringen, inkludert kontraktsmessig, erstatningsrettslig eller lignende ansvar;</p><p> c. gjelder ikke for forbrukere.</p><p>3. I tilfelle av ikke-forbrukerbrukere, i den grad det er tillatt av gjeldende lov:</p><p> a. vi er ikke ansvarlige for skader av noe slag med mindre skaden ble forårsaket av vår forsettlige feil. Garanti og ethvert ansvar som oppstår fra lignende institusjoner til garanti er herved ekskludert;</p><p> b. vi er ikke ansvarlige for tap eller skade på data, databaser eller programvare, enten våre eller brukerens.</p><p>4. Vi er ikke ansvarlige for skader som oppstår i forbindelse med en hendelse eller hendelser utenfor vår kontroll.</p>",
  "regulations_relief": "<p>Brukere som ikke er forbrukere holder oss skadesløse for eventuelle tap, skader, erstatninger, kostnader, forpliktelser og utgifter (inkludert kostnader relatert til eventuelle rettslige skritt og eventuelle beløp betalt av oss til en tredjepart som et forlik) relatert til ethvert brudd fra brukerens side av disse vilkårene og betingelsene, og er forpliktet til å tilbakebetale oss for kostnadene pådratt av oss som nevnt ovenfor.</p>",
  "regulations_violations": "<p>Med forbehold om alle våre andre rettigheter som følger av disse vilkårene, i tilfelle av et brudd på disse vilkårene fra brukerens side på noen måte, kan vi ta slike tiltak som vi anser passende for å svare på bruddet, som kan inkludere å suspendere brukerens tilgang til nettstedet, hindre brukeren i å få tilgang til nettstedet, blokkere tilgang til nettstedet for en spesifikk IP-adresse, kontakte brukerens internettleverandør for å blokkere tilgang til nettstedet, eller ta rettslige skritt.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Regler kan endres ved å legge ut den nye versjonen på nettstedet. Hvis brukeren gir oss sine personopplysninger, spesielt e-postadressen sin, og hvis vi gir dem tjenester under endringen av disse reglene, vil vi informere dem om eventuelle endringer i disse reglene via e-post.</p><p>2. Når det gjelder brukere som ikke er forbrukere, utgjør en endring i vår kontaktinformasjon ikke en endring av disse reglene.</p><p>3. I tilfelle av en endring i e-postadressen som ble oppgitt under registreringen, er brukerne forpliktet til å oppdatere denne adressen på nettstedet.</p>",
  "regulations_transfer": "<p>Brukere kan ikke overføre, dele med andre, eller på annen måte disponere over sine rettigheter og forpliktelser som følger av disse reglene, ei heller pådra seg forpliktelser knyttet til disse rettighetene og forpliktelsene.</p>",
  "regulations_severability_clause": "<p>Dersom en bestemmelse i disse reglene blir funnet ugyldig eller ineffektiv av en domstol eller annen kompetent myndighet, skal de gjenværende bestemmelsene i reglene forbli bindende. Hvis en ugyldig eller ineffektiv bestemmelse ville vært gyldig etter fjerning av sin del, skal den delen anses som fjernet, og den gjenværende delen av bestemmelsen skal anses som gyldig.</p>",
  "regulations_exclusion_parts": "<p>Reglene definerer eksklusivt forholdet mellom oss og brukerne av nettstedet og gir ingen rettigheter til tredjeparter, ei heller gir dem rett til å påberope seg noen av bestemmelsene eller kreve håndheving av disse bestemmelsene. Håndheving av bestemmelsene i reglene krever ikke samtykke fra noen tredjepart.</p>",
  "regulations_scope_contract": "<p>Denne regelen, sammen med vår personvernerklæring og tjenesteprisene angitt på nettstedet, utgjør en avtale mellom oss og brukeren angående bruken av nettstedet og erstatter eventuelle tidligere regler angående bruken av nettstedet.</p>",
  "regulations_governing_law": "<p>1. Denne forskriften er underlagt polsk lov, og i saker som ikke er regulert av forskriften, skal bestemmelsene i loven av 23. april 1964, sivilkodeksen, loven av 30. mai 2014, om forbrukerrettigheter (i forhold til forbrukere), og loven av 18. juli 2002, om levering av elektroniske tjenester gjelde.</p><p>2. I tilfelle av brukere som ikke er forbrukere, skal eventuelle tvister som oppstår under forskriften være underlagt den eksklusive jurisdiksjonen til polske domstoler.</p><p>3. I tilfelle av brukere som er forbrukere, hvis bosted ligger i Polen på tidspunktet for inngåelse av avtalen om levering av kjøretøydata, skal eventuelle tvister relatert til denne forskriften eller personvernerklæringen være underlagt den eksklusive jurisdiksjonen til polske domstoler.</p>",
  "back": "Returner",
  "definition": "Definisjoner",
  "admission": "Introduksjon",
  "license_use_page": "Lisens til å bruke nettstedet og materialene som er tilgjengelige på det",
  "use_page": "Bruk av nettstedet",
  "limited_access": "Begrenset tilgang",
  "user_posted_content": "Innhold lagt ut av brukeren",
  "placing_vehicle_data_in_db": "Inngåelse av kjøretøydata i databasen",
  "disclaimer_of_liability": "Ansvarsfraskrivelse",
  "relief_from_obligations": "Frigivelse fra forpliktelser",
  "violations_of_regulations": "Brudd på forskriftene",
  "transfer": "Overdragelse",
  "severability_clause": "Salvatorisk klausul",
  "exclusion_third_parts": "Eksklusjon av tredjepartsrettigheter",
  "scope_of_contract": "Omfanget av avtalen",
  "changing_of_provisions_regulations": "Endring av bestemmelsene i forskriftene",
  "governing_law_and_jurisdiction": "Gjeldende lov og jurisdiksjon",
  "limited_and_warranties_complaints": "Begrensede representasjoner og garantier og innlevering av klager",
  "main_tip": "Bruk nettstedet vårt for å evaluere biler online ved hjelp av VIN-nummeret. På denne måten vil du raskt og nøyaktig finne ut hvor mye bilen din er verdt. Bare skriv inn bilens VIN-nummer, så hjelper vi deg med å vurdere verdien. Ikke vent, sjekk det nå!",
  "enter_vin_number": "Skriv inn VIN",
  "meta_title": "Online bilvurderingskalkulator - vurder bilen din raskt og nøyaktig.",
  "meta_desc": "Online bilvurdering, vurder verdien av bilen din raskt og nøyaktig. Vår kalkulator vil gi prisen for det kontrollerte modellåret til kjøretøyet og litt eldre og yngre for sammenligning.",
  "h1_title": "Online bilvurdering krever bare VIN",
  "check_valuation": "Sjekk vurderingen!",
  "next": "Neste",
  "help": "Hjelp",
  "no_data_valuation": "Ingen data for vurdering",
  "check_if_stolen": "Sjekk om stjålet",
  "decode_vin_number": "Dekode VIN-nummer",
  "additional_links": "Ytterligere lenker",
  "check_vin_correct": "Sjekk gyldigheten av VIN-nummeret",
  "how_to_vin_valuation": "Hvordan vurdere et kjøretøy før kjøp/salg?",
  "calculator_valuation": "Online bilvurderingskalkulator",
  "calculator_valuation_desc": "Det finnes mange metoder for å estimere verdien av en bil; den raskeste og relativt nøyaktige er å sjekke den på en nettside som vinvaluation. Vår proprietære algoritme, støttet av kunstig intelligens (AI), samler data fra tusenvis av online oppføringer, og analyserer nøyaktig alder, merke, utstyr og tilstand på kjøretøyene som tilbys. Basert på dette estimerer vi hvor mye bilen du sjekker bør være verdt. Du mottar vurderingen på noen få øyeblikk uten å forlate hjemmet, helt gratis. Vi trenger bare noen få parametere som VIN-nummeret eller datoen for registrering av kjøretøyet. Som svar vil du motta ikke bare vurderingen for året du sjekker, men også for flere nærliggende, yngre og eldre modeller. Dette gir deg full innsikt i om det for eksempel fortsatt er verdt å være eier av den, eller om du ikke vil tape for mye og det ville være bedre å selge den akkurat nå.",
  "you_get_valuation_for_free": "<strong>Du mottar vurderingen</strong> på noen få øyeblikk uten å forlate hjemmet, helt gratis. Vi trenger bare noen få parametere som VIN-nummeret eller datoen for registrering av kjøretøyet.",
  "in_response_you_receive_not_only_valuation": "Som svar vil du motta ikke bare vurderingen for året du sjekker, men også flere nærliggende, yngre og eldre. Dette gir deg full innsikt i om det for eksempel fortsatt er verdt å være eier av den, eller om du ikke vil tape for mye og det ville være lurt å selge den akkurat nå.",
  "compare_market_price": "Sammenlign priser på markedet",
  "what_we_offer_free": "Det vi tilbyr gratis kan du selvfølgelig gjøre manuelt. Bla gjennom <strong>annonser på internett</strong> eller besøk noen <strong>bilforhandlere</strong> for å finne ut de nåværende prisene på tilbudte kjøretøy.",
  "if_you_are_not_sure": "Hvis du er usikker på hvilken informasjon som trengs for å verdsette en bil, kan du kontakte en bilvurderer eller ta kontakt med en forhandler eller bilforhandler. De vil også kunne hjelpe deg med å estimere verdien av bilen din. Husk imidlertid at prisen som tilbys av forhandleren eller bilforhandleren sannsynligvis vil være betydelig lavere. De må også tjene penger på å kjøpe bilen fra deg. Uansett hvilken metode du velger, husk at bilvurderingen bare er en omtrentlig pris. Den endelige verdien av kjøretøyet avhenger av mange faktorer, som teknisk tilstand, utstyr, servicehistorikk og det generelle inntrykket bilen gir på en potensiell kjøper. Det er viktig å grundig sjekke tilstanden til bilen før du selger eller kjøper den. Når du planlegger å kjøpe en bil, husk å sjekke kjøretøyhistorikken.",
  "regardless_of_which_method": "Uansett hvilken metode du velger, husk at bilvurderingen bare er en omtrentlig pris. Den endelige verdien av kjøretøyet avhenger av mange faktorer, som teknisk tilstand, utstyr, servicehistorikk og det generelle inntrykket bilen gir på en potensiell kjøper. Det er viktig å grundig sjekke tilstanden til bilen før du selger eller kjøper den.",
  "check_the_valuation": "Sjekk kjøretøyhistorikken",
  "you_be_may_interested": "Du kan også være interessert i:",
  "check": "SJEKK",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Få grunnleggende informasjon om kjøretøyet ditt gratis",
  "get_basic_infotmation_about_vehicle_desc": "som merke, modell, produksjonsår og tekniske spesifikasjoner ved å skrive inn VIN i søkefeltet. For mer detaljerte data, som ulykkeshistorikk, kilometerstand og juridisk status for kjøretøyet, tilbyr nettstedet også muligheten til å kjøpe en full VIN-rapport.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Sjekk flere databaser med stjålne biler,",
  "stolen_cars_desc": "ved å bruke avanserte søkeverktøy. Bare skriv inn VIN eller registreringsnummeret til kjøretøyet, så vil systemet søke i en stor database for å sjekke om bilen har blitt rapportert stjålet.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Sjekk gyldigheten av VIN.",
  "vin_correct_desc": "verktøyet verifiserer om VIN oppfyller gjeldende standarder og inneholder all nødvendig informasjon. Dette sikrer at VIN ikke har blitt forfalsket eller feilregistrert.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Bilens historie fra A til Å på ett sted.",
  "easy_vin_desc": "Bekreft kunnskapen din om kjøretøyet du er interessert i og spar deg selv fra ubehagelige overraskelser når du kjøper en ny bil. Sjekk VIN-nummeret når du vil. Rapporter genereres 24 timer i døgnet, 7 dager i uken.",
  "find_out_the_value_of_your_car_desc": "Vil du vite verdien av bilen din eller sjekke verdien av bilen din før du kjøper den? Dra nytte av vår gratis verdivurdering av kjøretøy! Med et VIN-nummer og noen få ekstra trinn kan vi gi en detaljert analyse. Det er enkelt, raskt og gratis! Ikke vent - sjekk verdien på bilen din nå!",
  "enter_te_vin_number": "Skriv inn et 17-sifret VIN-nummer",
  "enter_mileage": "Oppgi kjørelengde",
  "enter_regplate": "Skriv inn registreringsnummer",
  "tip": "Morsomt faktum:",
  "odometer_readings": "Kjøretøyets kjørelengde",
  "number_of_regplate": "Nummerskilt",
  "valuation_incoming": "Sitatet lastes inn",
  "pl_country": "Polen",
  "en_country": "Storbritannia",
  "de_country": "Tyskland",
  "cs_country": "Den tsjekkiske republikk",
  "lt_country": "Litauen",
  "fr_country": "Frankrike",
  "es_country": "Spania",
  "ro_country": "Romania",
  "nl_country": "Nederland",
  "hu_country": "Ungarn",
  "bg_country": "Bulgaria",
  "da_country": "Danmark",
  "el_country": "Hellas",
  "it_country": "Italia",
  "no_country": "Norge",
  "pt_country": "Portugal",
  "sk_country": "Slovakia",
  "sr_country": "Serbia",
  "sv_country": "Sverige",
  "tr_country": "Tyrkiye",
  "ar_country": "Saudi-Arabia",
  "fact_model_t": "Den første masseproduserte bilen var Ford Model T, som rullet av samlebåndet i 1908.",
  "fact_bugatti": "Den dyreste bilen i verden er Bugatti La Voiture Noire, som kostet omtrent 18 millioner dollar.",
  "fact_v8": "V8-motoren ble laget tilbake i 1902, og designet var revolusjonerende for bilindustrien.",
  "fact_vw_beetle": "Den lengstproduserte bilmodellen er Volkswagen Beetle, produsert kontinuerlig i 65 år, fra 1938 til 2003.",
  "fact_wipers": "De første vindusviskerne til bilen ble oppfunnet av Mary Anderson i 1903 etter at hun la merke til at sjåfører måtte gå ut av bilene for å tørke av vinduene.",
  "fact_lamborghini": "Lamborghini produserte opprinnelig traktorer og begynte først senere å produsere luksuriøse sportsbiler.",
  "fact_popular_color": "Den mest populære bilfargen i verden er hvit, valgt av så mange som 39 % av kjøperne.",
  "fact_electric_car": "Den første elbilen ble laget i 1832, lenge før oppfinnelsen av biler med forbrenningsmotorer.",
  "fact_crank_start": "Før den elektriske starteren ble oppfunnet, ble biler startet med sveiv, noe som var ganske farlig og kunne forårsake alvorlige skader.",
  "fact_ssc_tuatara": "Den raskeste hastigheten noensinne oppnådd av en produksjonsbil ble oppnådd av SSC Tuatara på 532,93 km/t.",
  "error_message": "Det oppsto en intern feil. Prøv igjen senere.",
  "no_data_alert": "Ingen data tilgjengelig for dette kjøretøyet.",
};
