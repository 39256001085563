export const sr = {
  "fast_and_free": "BRZO I BESPLATNO",
  "vehicle_valuation": "PROCENA VOZILA",
  "valuation": "PROCENA",
  "vin": "VIN",
  "want_to_know_valuation": "Da li želite da saznate vrednost vašeg automobila ili da proverite vrednost vozila pre nego što ga kupite? Iskoristite našu besplatnu procenu vozila! Samo unesite VIN da biste dobili tačnu i pouzdanu procenu. Uz nekoliko dodatnih koraka, možemo pružiti još detaljniju analizu. To je jednostavno, brzo i besplatno! Ne čekajte – proverite vrednost vašeg automobila sada!",
  "plate": "Registracioni broj",
  "mileage": "Kilometraža",
  "accord_mileage_desc": "Vozila sa niskom kilometražom obično su manje istrošena, zbog čega često postižu više cene na tržištu polovnih automobila.",
  "damage_history": "Istorija nesreća",
  "damage_history_desc": "Automobili koji su bili uključeni u nesreće obično imaju nižu vrednost zbog potencijalne strukturne štete.",
  "count_of_owners": "Broj prethodnih vlasnika",
  "count_of_owners_desc": "Vozila koja su imala manje vlasnika mogu biti više cenjena jer su generalno bolje održavana.",
  "body_color": "Boja karoserije",
  "body_color_desc": "Popularne boje mogu povećati vrednost vozila, dok neobične boje mogu smanjiti.",
  "technical_condition": "Tehničko stanje",
  "technical_condition_desc": "Mehaničko i tehničko stanje vozila, uključujući stanje motora, menjača i drugih komponenti, ima značajan uticaj na cenu.",
  "fuel_type": "Tip goriva",
  "fuel_type_desc": "Trenutni trendovi i tržišne preferencije mogu učiniti određene tipove motora poželjnijim od drugih.",
  "vehicle_equipment": "Oprema automobila",
  "vehicle_equipment_desc": "Dodatne karakteristike kao što su navigacioni sistemi, kožne unutrašnjosti, audio sistemi visoke kvalitete mogu povećati vrednost vozila.",
  "current_market_prices": "Trenutne tržišne cene",
  "current_market_prices_desc": "Tržišne cene variraju na osnovu potražnje i ponude za određeni model vozila, što utiče na njegovu konačnu procenu.",
  "how_to_valuate_vehicle_before_sell": "Kako proceniti vozilo pre kupovine/prodaje?",
  "prod_year": "Godina proizvodnje",
  "first_reg_date": "Datum prve registracije",
  "contact": "Kontakt",
  "privacy_policy": "Politika privatnosti",
  "regulations": "Uslovi i odredbe",
  "valuation_desc": "Procena vozila je pripremljena na osnovu opsežnog iskustva naših stručnjaka za automobilsku industriju. Procedura procene koristi veštačku inteligenciju (AI) za upoređivanje promenljivih trendova u cenama vozila na evropskom i američkom tržištu. Procena uzima u obzir parametre kao što su starost vozila i kilometraža. Naša procena takođe uzima u obzir najnovije automobilske i društvene probleme kao što su nedostatak delova ili komponenti i inflacija, koji utiču na cene polovnih vozila.",
  "min_price": "Minimalna cena",
  "med_price": "Prosečna cena",
  "max_price": "Maksimalna cena",
  "we_have_report_for_you": "Imamo izveštaj za ovo vozilo!",
  "incident_history": "Istorija incidenata",
  "odometer_reading": "Očitavanje kilometraže",
  "vehicles_inspection": "Inspekcije vozila",
  "archive_photos": "Arhivske fotografije",
  "check_now": "PREGLEDAJ IZVEŠTAJ",
  "unfortunately_not_found_desc": "Nažalost, nismo mogli pronaći godinu proizvodnje za ovo vozilo, molimo unesite je u polje iznad.",
  "type_odometer": "Unesite trenutnu kilometražu vozila u kilometrima za bolju procenu njegove vrednosti.",
  "check_another_vin": "Proceni drugo vozilo",
  "what_can_affet_the_price_of_vehicle": "Šta može uticati na cenu vašeg vozila?",
  "calcualtor_of_vehicle": "Online kalkulator procene automobila",
  "type_plate": "Unesite broj registracije vozila.",
  "type_first_reg_date": "Unesite datum prve registracije vozila. Možete ga pronaći u registracionom dokumentu u polju B.",
  "price_odometer": "Cena",
  "price_year": "Cena",
  "year": "Godina",
  "regulations_admission": "<p>Ova Uredba definiše pravila za korišćenje veb sajta koji se vodi pod imenom VinValuation na internet domeni www.vinValuation.com (u daljem tekstu: Sajt). Ova Uredba sadrži pravila za korišćenje Sajta. Ako se ne slažete sa primenom ove Uredbe ili bilo koje njene odredbe, odmah napustite ovaj Sajt.</p><p>Puno ime naše kompanije je Car Data Experts LTD. Jedinica 4E, preduzetnički sud, Farfield park, Rotherham S63 5DB. Broj kompanije: 10327199. Možete nas kontaktirati putem e-maila na: <a href=mailto:{email}>{email}</a></p><p>Glavna svrha Sajta je da pruži svojim korisnicima mogućnost da unesu podatke o ukradenim ili oštećenim vozilima u bazu podataka Car Data (Podaci o vozilu) i da dele te podatke sa drugim pojedincima koji koriste ovu bazu podataka.</p><p>Usluge dostupne na Sajtu namenjene su fizičkim licima, pravnim licima i drugim subjektima koji imaju prebivalište ili registrovanu kancelariju u Evropskoj uniji ili Sjedinjenim Američkim Državama.</p><p>Usluge na Sajtu su isključivo usmerene na pojedince koji imaju najmanje 18 godina.</p><p>Pravila za obradu ličnih podataka i korišćenje datoteka pod nazivom kolačići predstavljena su u našoj Politici privatnosti dostupnoj na Sajtu.</p><p>Da biste koristili Sajt, korisnik mora koristiti uređaj sa pristupom internetu i veb pregledač Internet Explorer 9.0 ili sličan program. Da biste koristili neke funkcionalnosti Sajta, neophodno je koristiti Java i Java Script softver, kao i omogućiti korišćenje kolačića. Nismo odgovorni za nedostatak pristupa podacima objavljenim na našem Sajtu zbog toga što uređaj korisnika ne ispunjava tehničke zahteve navedene iznad.</p>',",
  "regulations_definition": "<p>U ovoj Uredbi, termini definisani u nastavku imaju sledeća značenja:</p><p>Podaci o vozilu znače podatke o ukradenim ili oštećenim vozilima i okolnostima krađe ili oštećenja koje su kreirali korisnici Sajta u bazi podataka o Vozilima i koji su dostupni drugim osobama koje koriste ovu bazu podataka.</p><p>Korisnik znači svaka fizička osoba koja deluje u svrhe koje nisu povezane sa poslovnim ili profesionalnim aktivnostima.</p><p>Baza podataka znači našu bazu podataka o Vozilima koja se odnosi na tehničko stanje i istoriju vozila.</p><p>VIN broj znači broj identifikacije vozila (Vehicle Identification Number), koji koristi automobilska industrija za identifikaciju specifičnog primera motornih vozila, traktora, motocikla, skutera i mopeda, kako je definisano u ISO 3833 standardu.</p>',",
  "regulations_license_desc": "<p>Podložni drugim odredbama, sva prava intelektualne svojine na Sajtu i na materijalima sadržanim na Sajtu su u našem vlasništvu ili u vlasništvu naših licencodavaca. Podložni licenci u nastavku, sva prava intelektualne svojine su zadržana.</p> <p>Korisnici mogu pregledati, preuzeti datoteke za keširanje, isključivo za svoju ličnu upotrebu, podložni izuzecima navedenim u nastavku i u drugim delovima Uslova korišćenja.</p><p>Posebno su zabranjena sledeća:</p><p>a. distribucija materijala dobijenih sa Sajta (uključujući distribuciju na drugom vebsajtu);</p><p>b. prodaja, iznajmljivanje, pozajmljivanje, poklanjanje, prenos ili davanje podlicenci u vezi sa Sajtom i materijalima koji se nalaze na Sajtu;</p><p>c. javno izvođenje bilo kojih materijala dostupnih na Sajtu;</p><p>d. reprodukovanje, dupliciranje, kopiranje ili na drugi način korišćenje materijala prikupljenih na Sajtu u komercijalne svrhe;</p><p>e. menjanje ili na drugi način modifikovanje bilo kojih materijala koji se nalaze na Sajtu;</p><p>f. distribucija materijala dobijenih sa Sajta bez dobijanja našeg izričitog pisanog pristanka za navedeno.</p>",
  "regulations_use_page": "<p>1. Zabranjeno je koristiti sajt na bilo koji način koji mu šteti ili negativno utiče na mogućnost korišćenja sajta; ili na bilo koji drugi način koji je ilegalan, nezakonit, štetan, ili povezan sa bilo kojim ilegalnim, nezakonitim ili štetnim ciljem ili radnjom. Zadržavamo sva prava na sve informacije i materijale, uključujući grafičke i druge, dostupne na sajtu.</p><p>2. Zabranjeno je koristiti sajt za kopiranje, korišćenje, skladištenje, prenos, slanje, objavljivanje ili distribuciju bilo kakvih materijala koji sadrže bilo kakav špijunski softver, virus, trojanskog konja, crv, logger pritisaka tastera, rootkit ili drugi štetni softver ili uređaje, ili se na njih pozivati.</p><p>3. Svako sistematsko ili automatizovano prikupljanje podataka sa sajta ili podataka povezanih sa sajtom (uključujući korišćenje sledećih tehnika: scraping, data mining, data extraction, i data harvesting) je zabranjeno bez našeg pisanog pristanka.</p><p>4. Zabranjeno je koristiti sajt za prenos ili slanje neprošenih komercijalnih informacija.</p><p>5. Zabranjeno je koristiti sajt u bilo koje marketinške svrhe bez našeg pisanog pristanka.</p>",
  "regulations_limited_access": "<p>1. Pristup određenim resursima sajta je ograničen. Zadržavamo pravo da ograničimo pristup drugim resursima sajta ili da ograničimo pristup celom sajtu, u skladu sa pravima korisnika koja proizilaze iz prethodno zaključenih ugovora.</p><p>2. U slučaju da korisnik dobije korisničko ime ili korisničko ime i lozinku od nas koja omogućava pristup ograničenim resursima na sajtu ili drugim sadržajima ili uslugama sa ograničenim pristupom, korisnik je dužan da obezbedi lozinku i korisničko ime tako da ne budu otkriveni bilo kojoj trećoj strani.</p>",
  "regulations_user_posted": "<p>1. Sadržaj koji generišu korisnici ne sme biti ilegalan, kršiti principe društvene koegzistencije, netiket, ili druge uspostavljene običaje, niti kršiti prava trećih strana, niti dovoditi do bilo kakvih potraživanja ili pravnih radnji protiv korisnika, nas, ili bilo koje treće strane (u svakom slučaju, prema važećem zakonu).</p><p>2. Zabranjeno je postavljati bilo kakav sadržaj na Sajtu koji je ili je bio predmet bilo kakvih tekućih ili pretećih parnica ili sličnih postupaka.</p><p>3. Zadržavamo pravo da modifikujemo ili uklonimo bilo koji sadržaj ili materijal postavljen na Sajtu, sačuvan ili objavljen tamo.</p><p>4. U skladu sa odredbama ovih Uslova i važećim zakonom u vezi sa sadržajem i drugim materijalima postavljenim na Sajtu od strane korisnika, nismo obavezni da pratimo sadržaj i materijale postavljene ili objavljene na Sajtu.</p><p>5. Bilo koji podaci postavljeni od strane korisnika na Sajtu takođe bi trebali biti sačuvani na njihovim uređajima za dodatnu zaštitu od gubitka.</p>",
  "regulations_placing_data_in_db": "<p>1. Korisnik unosi podatke o vozilu u Bazu podataka koristeći obrazac postavljen na Sajtu.</p><p>2. Korisnik je odgovoran za tačnost podataka o vozilu u odnosu na trenutnu situaciju. U slučaju bilo kakvih promena, korisnik treba odmah ažurirati podatke o vozilu koje je dostavio u Bazi podataka.</p><p>3. Nakon slanja podataka o vozilu u Bazу podataka, korisnik prima potvrdu putem emaila na adresu koju je naveo, potvrđujući postavljanje ovih podataka u Bazу podataka.</p><p>4. Podaci o vozilu se postavljaju u Bazу podataka i čine dostupnim drugim entitetima koji koriste ovu bazu podataka, kako bi dobili informacije o određenom vozilu identifikovanom njegovim VIN brojem.</p><p>5. Ako se pojave nove informacije u vezi sa podacima o vozilu u Bazi podataka, pokušaćemo da obavestimo korisnika koji je dostavio podatke o vozilu putem email adrese koju je naveo.</p><p>6. Podaci o vozilu mogu biti povučeni iz Baze podataka kontaktiranjem nas putem kontakt forme na Sajtu ili slanjem emaila na adresu navedenu u Uvodu u Uslove i odredbe.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Da bi se izbegle sumnje, na osnovu korisničkog ugovora za pružanje podataka o vozilima u bazi podataka, nismo obavezni da lociramo vozilo na koje se podaci o vozilu odnose.</p><p>2. U slučaju korisnika koji nisu potrošači, u meri u kojoj to dozvoljava važeći zakon, ne dajemo nikakve izjave, ne pružamo nikakve garancije u vezi sa kvalitetom, isključujemo odgovornost i ne dajemo nikakve izjave u vezi sa standardima usluge pruženim na sajtu i korišćenjem sajta (posebno, isključujemo sve garancije, ne dajemo nikakve izjave koje pruža važeći zakon u vezi sa zadovoljavajućim kvalitetom, pogodnosti za svrhu ili izvršavanje dužne pažnje i veštine).</p><p>3. U slučaju da primetite tehničke probleme na sajtu ili primetite nepotpune ili zastarele informacije koje smo pružili, kao i bilo kakve pritužbe u vezi sa sajtom (pritužbe), trebali biste nas odmah obavestiti koristeći obrazac na stranici za kontakt ili nam pisati na adresu e-pošte navedenu iznad u uvodu u uslove i odredbe. Trudimo se da brzo odgovorimo.</p>',",
  "regulations_liability": "<p>1. Nema odredbe u ovim Uslovima (a) isključuje ili ograničava našu odgovornost za štetu prouzrokovanu namernom greškom ili namernim obmanjivanjem; (b) ograničava našu ili korisničku odgovornost na bilo koji način koji bi bio suprotan važećem zakonu; ili (c) isključuje našu ili korisničku odgovornost ako se takva odgovornost ne može isključiti prema važećem zakonu; posebno, Uslovi ne isključuju našu ili korisničku odgovornost za štete prouzrokovane namernom greškom.</p><p>2. Isključenja i ograničenja odgovornosti predviđena u Uslovima:</p><p> a. podložna su ograničenjima navedenim u stavu 1 iznad;</p><p> b. odnose se na svaku odgovornost koja može nastati prema Uslovima ili Politici privatnosti u vezi sa predmetom Uslova i Politike privatnosti, uključujući ugovornu, deliktualnu ili sličnu odgovornost;</p><p> c. ne primenjuju se na Potrošače.</p><p>3. U slučaju korisnika koji nisu Potrošači, u meri dozvoljenoj važećim zakonom:</p><p> a. nismo odgovorni za bilo kakvu štetu bilo koje vrste osim ako šteta nije prouzrokovana našom namernom greškom. Garancija i bilo kakva odgovornost koja proističe iz sličnih institucija za garanciju se ovim isključuju;</p><p> b. nismo odgovorni za bilo kakav gubitak ili štetu na bilo kojim podacima, bazama podataka ili softveru, bilo našim ili korisničkim.</p><p>4. Nismo odgovorni za bilo kakvu štetu koja nastaje u vezi sa događajem ili događajima van naše kontrole.</p>",
  "regulations_relief": "<p>Korisnici koji nisu Potrošači oslobađaju nas od bilo kakvih gubitaka, šteta, naknada, troškova, obaveza i izdataka (uključujući troškove povezane sa bilo kojim postupkom i bilo koje iznose koje smo platili bilo kojoj trećoj strani kao nagodbu) povezanih sa bilo kojim kršenjem ovih Uslova i odredbi od strane korisnika, i obavezni su da nam nadoknade troškove koje smo pretrpeli kao što je navedeno iznad.</p>",
  "regulations_violations": "<p>Podložno svim našim drugim pravima koja proizilaze iz ovih Uslova, u slučaju kršenja ovih Uslova od strane korisnika na bilo koji način, možemo preduzeti takve radnje koje smatramo prikladnim da odgovorimo na kršenje, što može uključivati obustavljanje pristupa korisnika Sajtu, sprečavanje korisnika da pristupi Sajtu, blokiranje pristupa Sajtu za određenu IP adresu, kontaktiranje korisnikovog internet provajdera da blokira pristup Sajtu, ili preduzimanje pravnih radnji.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Propisi se mogu promeniti postavljanjem nove verzije na Sajtu. Ako korisnik dostavi svoje lične podatke, posebno svoju email adresu, i ako mu pružamo usluge tokom promene ovih Propisa, obavestićemo ga o svim promenama ovih Propisa putem emaila.</p><p>2. U slučaju korisnika koji nisu Potrošači, promena naših kontakt informacija ne predstavlja promenu ovih Propisa.</p><p>3. U slučaju promene email adrese koja je data prilikom registracije, korisnici su obavezni da ažuriraju ovu adresu na Sajtu.</p>",
  "regulations_transfer": "<p>Korisnici ne smeju prenositi, deliti sa drugima ili na drugi način raspolagati svojim pravima i obavezama koje proizilaze iz ovih Propisa, niti preuzimati obaveze u vezi sa tim pravima i obavezama.</p>",
  "regulations_severability_clause": "<p>Ako bilo koja odredba ovih Propisa bude proglašena nevažećom ili neefikasnom od strane suda ili drugog nadležnog organa, preostale odredbe Propisa će ostati obavezujuće. Ako bi nevažeća ili neefikasna odredba postala važeća nakon uklanjanja njenog dela, taj deo će se smatrati uklonjenim, a preostali deo odredbe će se smatrati važećim.</p>",
  "regulations_exclusion_parts": "<p>Ovi Propisi isključivo definišu odnos između nas i korisnika Sajta i ne dodeljuju nikakva prava trećim stranama niti ih ovlašćuju da pozivaju na bilo koju odredbu ili zahtevaju sprovođenje ovih odredbi. Sprovođenje odredbi Propisa ne zahteva saglasnost bilo koje treće strane.</p>",
  "regulations_scope_contract": "<p>Ovi Propisi, zajedno sa našom Politikom privatnosti i cenama usluga navedenim na Sajtu, čine sporazum između nas i korisnika u vezi sa korišćenjem Sajta i zamenjuju sve prethodne propise koji se odnose na korišćenje Sajta.</p>",
  "regulations_governing_law": "<p>1. Ova Uredba podložna je poljskom pravu, a u pitanjima koja nisu regulisana Uredbom, primenjuju se odredbe Zakona od 23. aprila 1964. godine, Građanskog zakonika, Zakona od 30. maja 2014. godine o pravima potrošača (u vezi sa potrošačima) i Zakona od 18. jula 2002. godine o pružanju elektronskih usluga.</p><p>2. U slučaju korisnika koji nisu potrošači, svi sporovi koji proizađu iz Uredbe podložni su isključivoj nadležnosti poljskih sudova.</p><p>3. U slučaju korisnika koji su potrošači, čije je mesto prebivališta u Poljskoj u trenutku sklapanja ugovora o pružanju podataka o vozilu, svi sporovi vezani za ovu Uredbu ili Politiku privatnosti podložni su isključivoj nadležnosti poljskih sudova.</p>",
  "back": "Povratak",
  "definition": "Definicije",
  "admission": "Uvod",
  "license_use_page": "Licenca za korišćenje sajta i materijala dostupnih na njemu",
  "use_page": "Korišćenje sajta",
  "limited_access": "Ograničen pristup",
  "user_posted_content": "Sadržaj koji postavlja korisnik",
  "placing_vehicle_data_in_db": "Unos podataka o vozilu u bazu podataka",
  "disclaimer_of_liability": "Odricanje od odgovornosti",
  "relief_from_obligations": "Oslobađanje od obaveza",
  "violations_of_regulations": "Prekršaji Uredbi",
  "transfer": "Prenos",
  "severability_clause": "Klauzula o preživljavanju",
  "exclusion_third_parts": "Isključenje prava trećih strana",
  "scope_of_contract": "Obim ugovora",
  "changing_of_provisions_regulations": "Izmena odredbi Uredbe",
  "governing_law_and_jurisdiction": "Pravo koje se primenjuje i nadležnost",
  "limited_and_warranties_complaints": "Ograničene tvrdnje i garancije i podnošenje pritužbi",
  "main_tip": "Koristite naš veb sajt da procenite automobile online koristeći VIN broj. Na ovaj način, brzo i tačno ćete saznati koliko vaš automobil vredi. Samo unesite VIN broj vašeg automobila, a mi ćemo vam pomoći da procenite njegovu vrednost. Ne čekajte, proverite odmah!",
  "enter_vin_number": "Unesite VIN",
  "meta_title": "Online kalkulator za procenu automobila - brzo i tačno procenite svoj automobil.",
  "meta_desc": "Online procena automobila, brzo i tačno procenite vrednost svog automobila. Naš kalkulator će pružiti cenu za provereni model godine vozila i malo starije i mlađe za poređenje.",
  "h1_title": "Online procena automobila zahteva samo VIN",
  "check_valuation": "Proverite procenu!",
  "next": "Sledeće",
  "help": "Pomoć",
  "no_data_valuation": "Nema podataka za procenu",
  "check_if_stolen": "Proverite da li je ukraden",
  "decode_vin_number": "Dekodirajte VIN broj",
  "additional_links": "Dodatni linkovi",
  "check_vin_correct": "Proverite validnost VIN broja",
  "how_to_vin_valuation": "Kako proceniti vozilo pre kupovine/prodaje?",
  "calculator_valuation": "Online kalkulator za procenu automobila",
  "calculator_valuation_desc": "Postoji mnogo metoda za procenu vrednosti automobila; najbrža i relativno tačna je da se proveri na veb sajtu kao što je vinvaluation. Naš proprietarni algoritam, podržan veštačkom inteligencijom (AI), agregira podatke iz hiljada online oglasa, precizno analizirajući starost, marku, opremu i stanje vozila koja se nude. Na osnovu toga procenjujemo koliko bi automobil koji proveravate trebao da vredi. Vrednost dobijate za nekoliko trenutaka bez napuštanja doma, potpuno besplatno. Potrebno nam je samo nekoliko parametara kao što su VIN broj ili datum registracije vozila. Kao odgovor, dobićete ne samo procenu godine koju proveravate, već i nekoliko sličnih, mlađih i starijih modela. Ovo vam daje potpun uvid u to da li, na primer, još uvek vredi biti njegov vlasnik ili nećete izgubiti previše i bilo bi bolje da ga prodate odmah.",
  "you_get_valuation_for_free": "<strong>Vrednost dobijate</strong> za nekoliko trenutaka bez napuštanja doma potpuno besplatno. Potrebno nam je samo nekoliko parametara kao što su VIN broj ili datum registracije vozila.",
  "in_response_you_receive_not_only_valuation": "Kao odgovor, dobićete ne samo procenu godine koju proveravate, već i nekoliko sličnih, mlađih i starijih. Ovo vam daje potpun uvid u to da li, na primer, još uvek vredi biti njegov vlasnik ili nećete izgubiti previše i bilo bi dobro da ga prodate odmah.",
  "compare_market_price": "Uporedite cene na tržištu",
  "what_we_offer_free": "Ono što nudimo besplatno, možete naravno uraditi ručno. Pregledajte <strong>oglase na internetu</strong> ili posetite nekoliko <strong>salona automobila</strong> da biste saznali trenutne cene ponuđenih vozila.",
  "if_you_are_not_sure": "Ako niste sigurni koje informacije su potrebne za procenu automobila, možete kontaktirati procenitelja automobila ili se obratiti trgovcu ili auto salonu. Oni će vam takođe moći pomoći da procenite vrednost vašeg automobila. Ipak, imajte na umu da će cena koju ponudi salon ili trgovac verovatno biti znatno niža. Oni takođe moraju ostvariti profit kupovinom automobila od vas. Bez obzira na to koju metodu odaberete, zapamtite da je procena automobila samo približna cena. Konačna vrednost vozila zavisi od mnogih faktora, kao što su tehničko stanje, oprema, istorija servisa i ukupni utisak koji automobil ostavlja na potencijalnog kupca. Važno je temeljno proveriti stanje automobila pre prodaje ili kupovine.",
  "regardless_of_which_method": "Bez obzira na to koju metodu odaberete, zapamtite da je procena automobila samo približna cena. Konačna vrednost vozila zavisi od mnogih faktora, kao što su tehničko stanje, oprema, istorija servisa i ukupni utisak koji automobil ostavlja na potencijalnog kupca. Važno je temeljno proveriti stanje automobila pre prodaje ili kupovine.",
  "check_the_valuation": "Proverite istoriju vozila",
  "you_be_may_interested": "Možda će vas zanimati:",
  "check": "PROVERI",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Dobijte osnovne informacije o vašem vozilu besplatno",
  "get_basic_infotmation_about_vehicle_desc": "kao što su marka, model, godina proizvodnje i tehničke specifikacije unosom VIN-a u pretraživač. Za detaljnije podatke, kao što su istorija nesreća, pređeni kilometri i pravni status vozila, sajt takođe nudi mogućnost kupovine kompletnog VIN izveštaja.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Proverite više baza podataka o ukradenim automobilima,",
  "stolen_cars_desc": "koristeći napredne alate za pretragu. Samo unesite VIN ili registarski broj vozila, a sistem će pretražiti veliku bazu podataka da proveri da li je automobil prijavljen kao ukraden.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Proverite validnost VIN-a.",
  "vin_correct_desc": "alat proverava da li VIN ispunjava trenutne standarde i sadrži sve potrebne informacije. Ovo osigurava da VIN nije falsifikovan ili pogrešno zabeležen.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Istorija automobila od A do Š na jednom mestu.",
  "easy_vin_desc": "Proverite svoje znanje o vozilu koje vas zanima i poštedite se neprijatnih iznenađenja prilikom kupovine novog automobila. Proverite VIN broj kad god želite. Izveštaji se generišu 24 sata dnevno, 7 dana u nedelji.",
  "find_out_the_value_of_your_car_desc": "Želite da znate vrednost svog automobila ili proverite vrednost svog vozila pre nego što ga kupite? Iskoristite našu besplatnu procenu vrednosti vozila! Uz VIN broj i nekoliko dodatnih koraka, možemo da pružimo detaljnu analizu. Lako je, brzo i besplatno! Ne čekajte - proverite vrednost svog automobila sada!",
  "enter_te_vin_number": "Unesite 17-cifreni VIN broj",
  "enter_mileage": "Obezbedite kilometražu vozila",
  "enter_regplate": "Unesite broj registarske tablice",
  "tip": "Zabavna činjenica:",
  "odometer_readings": "Kilometraža vozila",
  "number_of_regplate": "Broj registarske tablice",
  "valuation_incoming": "Procena se učitava",
  "pl_country": "Poljska",
  "en_country": "Velika Britanija",
  "de_country": "Nemačka",
  "cs_country": "Češka republika",
  "lt_country": "Litvanija",
  "fr_country": "Francuska",
  "es_country": "Španija",
  "ro_country": "Rumunija",
  "nl_country": "Holandija",
  "hu_country": "Mađarska",
  "bg_country": "Bugarska",
  "da_country": "Danska",
  "el_country": "Grčka",
  "it_country": "Italija",
  "no_country": "Norveška",
  "pt_country": "Portugal",
  "sk_country": "Slovačka",
  "sr_country": "Srbija",
  "sv_country": "Švedska",
  "tr_country": "Turska",
  "ar_country": "Saudijska Arabija",
  "fact_model_t": "Prvi masovno proizveden automobil bio je Ford Model T, koji je sišao sa proizvodne trake 1908. godine.",
  "fact_bugatti": "Najskuplji automobil na svetu je Bugatti La Voiture Noire, koji košta oko 18 miliona dolara.",
  "fact_v8": "V8 motor je nastao davne 1902. godine, a njegov dizajn je bio revolucionaran za automobilsku industriju.",
  "fact_vw_beetle": "Najduže proizvođeni model automobila je Volkswagen Buba, koja se proizvodila neprekidno 65 godina, od 1938. do 2003. godine.",
  "fact_wipers": "Prve brisače za automobile izmislila je Mary Anderson 1903. godine nakon što je primetila da vozači moraju da izađu iz automobila da bi obrisali prozore.",
  "fact_lamborghini": "Lamborghini je u početku proizvodio traktore da bi tek kasnije počeo da proizvodi luksuzne sportske automobile.",
  "fact_popular_color": "Najpopularnija boja automobila na svetu je bela, koju bira čak 39 odsto kupaca.",
  "fact_electric_car": "Prvi električni automobil nastao je 1832. godine, mnogo pre pronalaska automobila sa motorima sa unutrašnjim sagorevanjem.",
  "fact_crank_start": "Pre nego što je izmišljen električni starter, automobili su pokretani pomoću ručice, što je bilo prilično opasno i moglo je da izazove ozbiljne povrede.",
  "fact_ssc_tuatara": "Najveću brzinu koju je ikada postigao serijski automobil postigao je SSC Tuatara sa 532,93 km/h.",
  "error_message": "Došlo je do interne greške. Pokušajte ponovo kasnije.",
  "no_data_alert": "Нема података за ово возило.",
};

