<template>
  <div class="col-12">
    <input :type="props.type" @input="updateValue" :value="props.modelValue" :minlength="props.minlength"
      :maxlength="props.maxlength" :placeholder="props.placeholder" class="mt-4 mb-4 p-2 w-100 text-center mx-auto" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
// import { useI18n } from 'vue-i18n';

// const { t } = useI18n();

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  maxlength: {
    type: Number,
    default: 17
  },
  minlength: {
    type: Number,
    default: 17
  },
  modelValue: {
    type: [String, Number],
    default: '',
  }
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {
  emit('update:modelValue', event.target.value);
}
</script>

<style scoped>
input {
  border-radius: 25px;
  border: 2px solid var(--Color-5, #9db2bf);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: #acacac;
  text-align: center;
  font-family: Jost;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 2px solid #9DB2BF;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  transition: 0.3s ease;
}

input:focus,
input:hover {
  border-color: #9DB2BF;
  outline: none;
  border: 2px solid var(--Color-5, #9db2bf);
}

input:focus {
  scale: 1.1;
}

</style>
