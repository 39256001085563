export const hu = {
  "fast_and_free": "GYORS ÉS INGYENES",
  "vehicle_valuation": "JÁRMŰ ÉRTÉKELÉS",
  "valuation": "ÉRTÉKELÉS",
  "vin": "VIN",
  "want_to_know_valuation": "Szeretné tudni az autója értékét, vagy ellenőrizni egy jármű értékét vásárlás előtt? Használja ingyenes járműértékelésünket! Csak adja meg a VIN-t, hogy pontos és megbízható értékelést kapjon. Néhány további lépéssel még részletesebb elemzést tudunk nyújtani. Egyszerű, gyors és ingyenes! Ne várjon – ellenőrizze az autója értékét most!",
  "plate": "Rendszám",
  "mileage": "Futott kilométer",
  "accord_mileage_desc": "A kevés kilométert futott járművek általában kevésbé kopottak, ezért gyakran magasabb árakat érnek el a használt autók piacán.",
  "damage_history": "Baleseti előzmények",
  "damage_history_desc": "A balesetekben részt vett autók általában alacsonyabb értékkel bírnak a potenciális szerkezeti károk miatt.",
  "count_of_owners": "Előző tulajdonosok száma",
  "count_of_owners_desc": "A kevesebb tulajdonossal rendelkező járművek általában magasabb értéket képviselnek, mert általában jobban karbantartottak.",
  "body_color": "Karosszéria szín",
  "body_color_desc": "A népszerű színek növelhetik a jármű értékét, míg a szokatlan színek csökkenthetik azt.",
  "technical_condition": "Műszaki állapot",
  "technical_condition_desc": "A jármű mechanikai és műszaki állapota, beleértve a motor, a váltó és egyéb alkatrészek állapotát, jelentős hatással van az árra.",
  "fuel_type": "Üzemanyag típusa",
  "fuel_type_desc": "A jelenlegi trendek és piaci preferenciák miatt egyes motor típusok kívánatosabbak lehetnek, mint mások.",
  "vehicle_equipment": "Autó felszereltség",
  "vehicle_equipment_desc": "További funkciók, mint például navigációs rendszerek, bőr belső tér, magas minőségű audiorendszerek növelhetik a jármű értékét.",
  "current_market_prices": "Jelenlegi piaci árak",
  "current_market_prices_desc": "A piaci árak ingadoznak a kereslet és kínálat alapján egy adott járműmodell esetében, befolyásolva annak végső értékelését.",
  "how_to_valuate_vehicle_before_sell": "Hogyan értékeljünk egy járművet vásárlás előtt/eladás előtt?",
  "prod_year": "Gyártási év",
  "first_reg_date": "Első forgalomba helyezés dátuma",
  "contact": "Kapcsolat",
  "privacy_policy": "Adatvédelmi irányelvek",
  "regulations": "Általános szerződési feltételek",
  "valuation_desc": "A járműértékelés a járműpiac szakértőinek széleskörű tapasztalata alapján készült. Az értékelési eljárás mesterséges intelligenciát (AI) használ a járműárak változó trendjeinek összehasonlítására az európai és amerikai piacokon. Az értékelés figyelembe veszi a jármű korát és futott kilométereit. Az értékelésünk figyelembe veszi a legfrissebb autóipari és társadalmi problémákat is, mint például az alkatrészek vagy komponensek hiánya és az infláció, amelyek befolyásolják a használt járművek árait.",
  "min_price": "Minimális ár",
  "med_price": "Átlagos ár",
  "max_price": "Maximális ár",
  "we_have_report_for_you": "Van egy jelentésünk erről a járműről!",
  "incident_history": "Esemény előzmények",
  "odometer_reading": "Futott kilométer állás",
  "vehicles_inspection": "Jármű ellenőrzések",
  "archive_photos": "Archív fényképek",
  "check_now": "JELENTÉS MEGTEKINTÉSE",
  "unfortunately_not_found_desc": "Sajnos nem tudtuk megtalálni ennek a járműnek a gyártási évét, kérjük, adja meg azt a fenti mezőben.",
  "type_odometer": "Adja meg a jármű aktuális futott kilométerét kilométerben a pontosabb értékbecslés érdekében.",
  "check_another_vin": "Értékeljen egy másik járművet",
  "what_can_affet_the_price_of_vehicle": "Mi befolyásolhatja járműve ára?",
  "calcualtor_of_vehicle": "Online autóértékesítési kalkulátor",
  "type_plate": "Adja meg a jármű nyilvántartási számát.",
  "type_first_reg_date": "Adja meg a jármű első nyilvántartásának dátumát. Ezt a nyilvántartási dokumentumban, a B mezőben találhatja.",
  "price_odometer": "Ár",
  "price_year": "Ár",
  "year": "Év",
  "regulations_admission": "<p>Ez a Szabályzat meghatározza a www.vinValuation.com internetes domain alatt működő VinValuation néven üzemeltetett weboldal használatának szabályait (a továbbiakban: Weboldal). Ez a Szabályzat tartalmazza a Weboldal használatának szabályait. Ha nem ért egyet e Szabályzat alkalmazásával vagy annak bármely rendelkezésével, azonnal hagyja el ezt a Weboldalt.</p><p>A cégünk teljes neve: Car Data Experts LTD. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Cégjegyzékszám: 10327199. Kapcsolatba léphet velünk e-mailben: <a href=mailto:{email}>{email}</a></p><p>A Weboldal fő célja, hogy lehetőséget biztosítson felhasználói számára, hogy adatokat vigyenek be a lopott vagy sérült járművekről a Car Data adatbázisba (Járműadatok), és hogy ezeket az adatokat megosszák más egyénekkel, akik ezt az adatbázist használják.</p><p>A Weboldalon elérhető szolgáltatások magánszemélyek, jogi személyek és más olyan entitások számára állnak rendelkezésre, amelyek lakóhellyel vagy bejegyzett székhellyel rendelkeznek az Európai Unióban vagy az Egyesült Államokban.</p><p>A Weboldalon elérhető szolgáltatások kizárólag olyan magánszemélyek számára irányulnak, akik legalább 18 évesek.</p><p>A személyes adatok feldolgozására és a sütik használatára vonatkozó szabályokat az Adatvédelmi Szabályzatunk tartalmazza, amely elérhető a Weboldalon.</p><p>A Weboldal használatához a felhasználónak internet-hozzáféréssel rendelkező eszközt és Internet Explorer 9.0 vagy hasonló programot kell használnia. A Weboldal egyes funkcióinak használatához szükséges Java és Java Script szoftver használata, valamint a sütik használatának engedélyezése. Nem vállalunk felelősséget a Weboldalunkon közzétett adatokhoz való hozzáférés hiányáért, ha a felhasználó eszköze nem felel meg a fent megadott műszaki követelményeknek.</p>',",
  "regulations_definition": "<p>Ez a rendelet az alábbiakban meghatározott kifejezések a következő jelentéssel bírnak:</p><p>Járműadatok olyan adatok, amelyek ellopott vagy sérült járművekről és a lopás vagy sérülés körülményeiről szólnak, amelyeket a webhely felhasználói hoztak létre az Autóadatok adatbázisban, és amelyeket más személyek számára elérhetővé tettek ezen adatbázis használatával.</p><p>Fogyasztó olyan természetes személy, aki nem üzleti vagy szakmai tevékenységek céljából jár el.</p><p>Adatbázis az autóink technikai állapotáról és történetéről szóló Autóadatok adatbázisunk.</p><p>VIN szám a jármű azonosító szám (Vehicle Identification Number), amelyet az autóipar használ egy adott motoros jármű, traktor, motorkerékpár, robogó és moped azonosítására, az ISO 3833 szabvány szerint.</p>',",
  "regulations_license_desc": "<p>A többi rendelkezés függvényében a webhelyhez és a webhelyen található anyagokhoz fűződő összes szellemi tulajdonjog a miénk vagy engedélyezőinké. Az alábbi licenc függvényében az összes szellemi tulajdonjog fenntartva.</p> <p>A felhasználók megtekinthetik, letölthetik a fájlokat gyorsítótárazás céljából, kizárólag saját használatra, a lent megjelölt kivételek és a Szolgáltatási feltételek egyéb részei szerint.</p><p>A következő különösen tilos:</p><p>a. a webhelyről származó anyagok terjesztése (beleértve a más webhelyen történő terjesztést);</p><p>b. a webhely és a webhelyen található anyagok értékesítése, bérbeadása, kölcsönzése, ajándékozása, átruházása vagy allicenc megadása;</p><p>c. a webhelyen elérhető anyagok nyilvános előadása;</p><p>d. a webhelyen gyűjtött anyagok reprodukálása, másolása vagy egyéb kereskedelmi célú felhasználása;</p><p>e. a webhelyen található anyagok megváltoztatása vagy egyéb módosítása;</p><p>f. a webhelyről származó anyagok terjesztése a fentiekhez szükséges kifejezett írásbeli hozzájárulásunk megszerzése nélkül.</p>",
  "regulations_use_page": "<p>1. Tilos a webhelyet olyan módon használni, amely károsítja azt, vagy negatívan befolyásolja a webhely használatának lehetőségét; vagy bármilyen más módon, amely illegális, jogellenes, káros, vagy bármilyen illegális, jogellenes vagy káros célra vagy cselekedetre vonatkozik. Fenntartjuk a jogot minden információ és anyag, beleértve a grafikai és egyéb anyagokat, amelyek a webhelyen elérhetők.</p><p>2. Tilos a webhelyet olyan anyagok másolására, használatára, tárolására, továbbítására, küldésére, közzétételére vagy terjesztésére használni, amelyek kémprogramot, vírust, trójai falovat, férget, billentyűleütés-nyomozót, rootkit-et vagy egyéb káros szoftvert vagy eszközöket tartalmaznak, vagy amelyekre utalnak.</p><p>3. A webhelyről vagy a webhellyel kapcsolatos adatok rendszeres vagy automatizált gyűjtése (beleértve a következő technikák használatát: scraping, adatbányászat, adatkinyerés és adatgyűjtés) tilos a mi írásbeli hozzájárulásunk nélkül.</p><p>4. Tilos a webhelyet kéretlen kereskedelmi információk továbbítására vagy küldésére használni.</p><p>5. Tilos a webhelyet bármilyen marketing célra használni a mi írásbeli hozzájárulásunk nélkül.</p>",
  "regulations_limited_access": "<p>1. A webhely bizonyos erőforrásaihoz való hozzáférés korlátozott. Fenntartjuk a jogot, hogy korlátozzuk a webhely egyéb erőforrásaihoz való hozzáférést, vagy korlátozzuk a teljes webhelyhez való hozzáférést, a felhasználók korábban megkötött megállapodásokból eredő jogainak figyelembevételével.</p><p>2. Abban az esetben, ha a felhasználó tőlünk olyan felhasználónevet vagy felhasználónevet és jelszót kap, amely lehetővé teszi a webhely korlátozott erőforrásaihoz vagy egyéb, korlátozott hozzáférésű tartalomhoz vagy szolgáltatásokhoz való hozzáférést, a felhasználónak kötelessége biztosítani a jelszó és a felhasználónév védelmét, hogy az ne kerüljön harmadik fél tudomására.</p>",
  "regulations_user_posted": "<p>1. A felhasználó által generált tartalom nem lehet illegális, nem sértheti a társadalmi együttélés elveit, a netikettet vagy más megszokott szokásokat, és nem sértheti harmadik fél jogait, valamint nem adhat alapot semmilyen követelésre vagy jogi eljárásra a felhasználó, a mi részünkről vagy bármely harmadik fél részéről (bármilyen esetben, bármely alkalmazandó jog alapján).</p><p>2. Tilos bármilyen olyan tartalmat közzétenni az oldalon, amely folyamatban lévő vagy fenyegetett jogi eljárás vagy hasonló eljárás tárgya volt vagy van.</p><p>3. Fenntartjuk a jogot, hogy módosítsuk vagy eltávolítsuk az oldalon közzétett, tárolt vagy publikált bármilyen tartalmat vagy anyagot.</p><p>4. A felhasználó által az oldalon közzétett tartalomra és egyéb anyagokra vonatkozó jelen feltételek és az alkalmazandó jogszabályok rendelkezései alapján nem vagyunk kötelesek figyelemmel kísérni az oldalon közzétett vagy publikált tartalmakat és anyagokat.</p><p>5. A felhasználó által az oldalon közzétett bármilyen adatot saját eszközeiken is meg kell őrizni a veszteségek elleni további védelem érdekében.</p>",
  "regulations_placing_data_in_db": "<p>1. A felhasználó a weboldalon közzétett űrlap segítségével járműadatokat szolgáltat az adatbázisban.</p><p>2. A felhasználó felelős a járműadatok pontosságáért a jelenlegi állapothoz képest. Bármilyen változás esetén a felhasználónak haladéktalanul frissítenie kell a járműadatokat az adatbázisban.</p><p>3. A járműadatok adatbázisba történő benyújtása után a felhasználó egy megerősítő e-mailt kap a megadott címére, amely megerősíti, hogy ezeket az adatokat az adatbázisba helyezték.</p><p>4. A járműadatok az adatbázisba kerülnek, és más entitások számára elérhetővé válnak, hogy információt nyerjenek egy adott járműről, amelyet a VIN szám azonosít.</p><p>5. Ha új információ jelenik meg a járműadatokkal kapcsolatban az adatbázisban, megpróbáljuk értesíteni a járműadatokat benyújtó felhasználót a megadott e-mail címen keresztül.</p><p>6. A járműadatok visszavonhatók az adatbázisból, ha kapcsolatba lép velünk a weboldalon található kapcsolatfelvételi űrlapon keresztül, vagy a feltételek bevezetésében megadott e-mail címre írva.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. A félreértések elkerülése érdekében, a Járműadatok adatbázisban való szolgáltatására vonatkozó felhasználói megállapodás alapján nem vagyunk kötelesek megkeresni azt a járművet, amelyre a Járműadatok vonatkoznak.</p><p>2. Azokban az esetekben, amikor a felhasználók nem Fogyasztók, a vonatkozó jogszabályok által megengedett mértékig nem vállalunk nyilatkozatokat, nem nyújtunk garanciákat a minőséggel kapcsolatban, kizárjuk a felelősséget, és nem teszünk nyilatkozatokat a Webhelyen nyújtott szolgáltatások szabványairól és a Webhely használatáról (különösen kizárjuk az összes garanciát, nem teszünk nyilatkozatokat a vonatkozó jogszabályok által nyújtott kielégítő minőségről, célnak való megfelelésről, vagy a kellő gondosság és ügyesség gyakorlásáról).</p><p>3. Ha technikai problémákat észlel a Webhelyen, vagy ha hiányosságokat vagy elavult információkat észlel, valamint bármilyen panaszt a Webhellyel kapcsolatban (panaszok), haladéktalanul értesítenie kell minket a Kapcsolat oldalon található űrlap használatával, vagy írásban a bevezetőben megadott e-mail címen. Igyekszünk gyorsan válaszolni.</p>',",
  "regulations_liability": "<p>1. Jelen Feltételekben (a) nincs olyan rendelkezés, amely kizárja vagy korlátozza a felelősségünket a szándékos hiba vagy szándékos félrevezetés által okozott kárért; (b) nem korlátozza a felelősségünket vagy a felhasználó felelősségét olyan módon, amely ellentétes lenne az alkalmazandó joggal; vagy (c) nem zárja ki a felelősségünket vagy a felhasználó felelősségét, ha az ilyen felelősség az alkalmazandó jog alapján nem zárható ki; különösen, a Feltételek nem zárják ki a felelősségünket vagy a felhasználó felelősségét a szándékos hiba által okozott károkért.</p><p>2. A Feltételekben megadott felelősségkizárások és korlátozások:</p><p> a. a fenti 1. bekezdésben megadott korlátozásoknak vannak alávetve;</p><p> b. bármilyen felelősségre vonatkoznak, amely a Feltételek vagy az Adatvédelmi Szabályzat alapján merülhet fel a Feltételek és az Adatvédelmi Szabályzat tárgyával kapcsolatban, beleértve a szerződéses, deliktuális vagy hasonló felelősséget;</p><p> c. nem vonatkoznak a Fogyasztókra.</p><p>3. Nem Fogyasztó felhasználók esetén, az alkalmazandó jog által megengedett mértékben:</p><p> a. nem vállalunk felelősséget semmilyen kárért, hacsak a kárt szándékos hibánk okozta. A garancia és bármilyen, a garanciához hasonló intézményekből eredő felelősség ezennel kizárásra kerül;</p><p> b. nem vállalunk felelősséget semmilyen adat, adatbázis vagy szoftver, akár a miénk, akár a felhasználóé, elvesztéséért vagy károsodásáért.</p><p>4. Nem vállalunk felelősséget semmilyen kárért, amely egy olyan eseménnyel vagy eseményekkel kapcsolatban merül fel, amelyek a kontrollunkon kívül esnek.</p>",
  "regulations_relief": "<p>A nem Fogyasztó felhasználók kártalanítanak minket bármilyen veszteség, kár, kártérítés, költség, felelősség és kiadás (beleértve a bármilyen eljárással kapcsolatos költségeket és bármilyen összeget, amelyet harmadik félnek kifizettünk egyezségként) tekintetében, amelyek a felhasználó ezen Feltételek és Kikötések megsértésével kapcsolatosak, és kötelesek megtéríteni számunkra a fent említett költségeket.</p>",
  "regulations_violations": "<p>Minden egyéb jogunk fenntartása mellett, ha a felhasználó bármilyen módon megsérti ezeket a Feltételeket, olyan intézkedéseket tehetünk, amelyeket megfelelőnek tartunk a megsértésre való reagálás érdekében, amelyek magukban foglalhatják a felhasználó hozzáférésének felfüggesztését a Webhelyhez, a felhasználó Webhelyhez való hozzáférésének megakadályozását, a Webhelyhez való hozzáférés blokkolását egy adott IP cím számára, a felhasználó internet szolgáltatójának értesítését a Webhelyhez való hozzáférés blokkolásáról, vagy jogi lépések megtételét.</p>",
  "regulations_changing_provisions_regulations": "<p>1. A szabályzatokat a weboldalon közzétett új verzióval lehet módosítani. Ha a felhasználó megadja nekünk a személyes adatait, különösen az e-mail címét, és ha a szabályzatok módosítása alatt szolgáltatásokat nyújtunk neki, akkor e-mailben értesítjük a szabályzatok bármilyen változásáról.</p><p>2. Azokban az esetekben, amikor a felhasználók nem Fogyasztók, a kapcsolataink megváltozása nem minősül a szabályzatok módosításának.</p><p>3. Ha a regisztráció során megadott e-mail cím megváltozik, a felhasználóknak frissíteniük kell ezt a címet a weboldalon.</p>",
  "regulations_transfer": "<p>A felhasználók nem ruházhatják át, oszthatják meg másokkal, vagy egyéb módon nem rendelkezhetnek a szabályzatokból eredő jogaikkal és kötelezettségeikkel, és nem vállalhatnak kötelezettségeket e jogokkal és kötelezettségekkel kapcsolatban.</p>",
  "regulations_severability_clause": "<p>Ha a szabályzatok bármely rendelkezését bíróság vagy más illetékes hatóság érvénytelennek vagy hatálytalannak találja, a szabályzatok fennmaradó rendelkezései továbbra is kötelező érvényűek maradnak. Ha egy érvénytelen vagy hatálytalan rendelkezés érvényes lenne a része eltávolítása után, akkor azt a részt eltávolítottnak kell tekinteni, és a rendelkezés fennmaradó része érvényesnek tekintendő.</p>",
  "regulations_exclusion_parts": "<p>A szabályzatok kizárólag a mi és a weboldal felhasználói közötti kapcsolatot határozzák meg, és nem biztosítanak jogokat harmadik felek számára, sem jogosítják fel őket a rendelkezések hivatkozására vagy a rendelkezések érvényesítésének követelésére. A szabályzatok rendelkezéseinek érvényesítése nem igényli harmadik fél beleegyezését.</p>",
  "regulations_scope_contract": "<p>Ez a szabályzat, a Magánéletvédelmi Szabályzatunkkal és a weboldalon feltüntetett szolgáltatási árakkal együtt, megállapodást képez közöttünk és a felhasználó között a weboldal használatáról, és helyettesíti a weboldal használatára vonatkozó korábbi szabályzatokat.</p>",
  "regulations_governing_law": "<p>1. Ez a rendelet a lengyel jog hatálya alá tartozik, és a rendelet által nem szabályozott kérdésekben az 1964. április 23-i törvény, a Polgári Törvénykönyv, a 2014. május 30-i törvény a fogyasztói jogokról (a Fogyasztókra vonatkozóan), valamint a 2002. július 18-i törvény az elektronikus szolgáltatások nyújtásáról rendelkezései alkalmazandók.</p><p>2. Azokban az esetekben, amikor a felhasználók nem Fogyasztók, a rendelet alapján felmerülő viták kizárólag a lengyel bíróságok hatáskörébe tartoznak.</p><p>3. Azokban az esetekben, amikor a felhasználók Fogyasztók, akiknek lakóhelye Lengyelországban található a Járműadatok nyújtására vonatkozó megállapodás megkötésekor, a rendelettel vagy az Adatvédelmi Szabályzattal kapcsolatos viták kizárólag a lengyel bíróságok hatáskörébe tartoznak.</p>",
  "back": "Vissza",
  "definition": "Meghatározások",
  "admission": "Bevezetés",
  "license_use_page": "A webhely és az ott elérhető anyagok használatának engedélyezése",
  "use_page": "A webhely használata",
  "limited_access": "Korlátozott hozzáférés",
  "user_posted_content": "A felhasználó által közzétett tartalom",
  "placing_vehicle_data_in_db": "Járműadatok bevitele az adatbázisba",
  "disclaimer_of_liability": "Felelősség kizárása",
  "relief_from_obligations": "Kötelezettségekből való mentesítés",
  "violations_of_regulations": "A szabályzat megsértése",
  "transfer": "Átruházás",
  "severability_clause": "Megtartási záradék",
  "exclusion_third_parts": "Harmadik fél jogainak kizárása",
  "scope_of_contract": "A megállapodás terjedelme",
  "changing_of_provisions_regulations": "A szabályzat rendelkezéseinek módosítása",
  "governing_law_and_jurisdiction": "Irányadó jog és joghatóság",
  "limited_and_warranties_complaints": "Korlátozott nyilatkozatok és garanciák, valamint panaszok benyújtása",
  "main_tip": "Használja weboldalunkat az autók online értékelésére a VIN szám segítségével. Így gyorsan és pontosan megtudhatja, mennyit ér az autója. Csak adja meg az autó VIN számát, és segítünk értékelni annak értékét. Ne várjon, ellenőrizze most!",
  "enter_vin_number": "VIN megadása",
  "meta_title": "Online autóértékelő kalkulátor - gyorsan és pontosan értékelje autóját.",
  "meta_desc": "Online autóértékelés, gyorsan és pontosan értékelje autója értékét. Kalkulátorunk megadja az ellenőrzött jármű modellévének árát, valamint az azt megelőző és követő éveket összehasonlítás céljából.",
  "h1_title": "Az online autóértékeléshez csak a VIN szükséges",
  "check_valuation": "Ellenőrizze az értékelést!",
  "next": "Következő",
  "help": "Segítség",
  "no_data_valuation": "Nincs adat az értékeléshez",
  "check_if_stolen": "Ellenőrizze, hogy lopott-e",
  "decode_vin_number": "VIN szám dekódolása",
  "additional_links": "További linkek",
  "check_vin_correct": "Ellenőrizze a VIN szám érvényességét",
  "how_to_vin_valuation": "Hogyan értékeljünk egy járművet vásárlás előtt/eladás előtt?",
  "calculator_valuation": "Online autóértékelő kalkulátor",
  "calculator_valuation_desc": "Sok módszer létezik egy autó értékének megbecslésére; a leggyorsabb és viszonylag pontos módszer az, ha megnézzük egy olyan weboldalon, mint a vinvaluation. Saját algoritmusunk, amelyet mesterséges intelligencia (AI) támogat, adatokat aggregál több ezer online hirdetésből, pontosan elemezve a kínált járművek korát, márkáját, felszereltségét és állapotát. Ennek alapján megbecsüljük, hogy mennyit ér az autó, amelyet ellenőriz. Az értékelést néhány pillanat alatt megkapja anélkül, hogy elhagyná otthonát, teljesen ingyen. Csak néhány paraméterre van szükségünk, például a VIN számra vagy a jármű nyilvántartási dátumára. Válaszként nemcsak az adott év értékelését kapja meg, hanem több közeli, fiatalabb és idősebb modellét is. Ez teljes betekintést nyújt abba, hogy például még mindig érdemes-e a tulajdonosának lenni, vagy hogy nem veszít túl sokat, és jobb lenne most eladni.",
  "you_get_valuation_for_free": "<strong>Az értékelést</strong> néhány pillanat alatt megkapja anélkül, hogy elhagyná otthonát, teljesen ingyen. Csak néhány paraméterre van szükségünk, például a VIN számra vagy a jármű nyilvántartási dátumára.",
  "in_response_you_receive_not_only_valuation": "Válaszként nemcsak az adott év értékelését kapja meg, hanem több közeli, fiatalabb és idősebb modellét is. Ez teljes betekintést nyújt abba, hogy például még mindig érdemes-e a tulajdonosának lenni, vagy hogy nem veszít túl sokat, és jobb lenne most eladni.",
  "compare_market_price": "Hasonlítsa össze az árakat a piacon",
  "what_we_offer_free": "Amit ingyen kínálunk, azt természetesen manuálisan is megteheti. Böngésszen <strong>hirdetéseket az interneten</strong> vagy látogasson el néhány <strong>autókereskedésbe</strong>, hogy megtudja a kínált járművek aktuális árait.",
  "if_you_are_not_sure": "Ha nem biztos abban, hogy milyen információra van szükség az autó értékének meghatározásához, akkor felveheti a kapcsolatot egy autóértékelővel, vagy elérheti egy kereskedőt vagy autókereskedést. Ők szintén segíthetnek az autója értékének megbecsülésében. Ne feledje azonban, hogy a kereskedés vagy a kereskedő által ajánlott ár valószínűleg jelentősen alacsonyabb lesz. Nekik is profitot kell termelniük az autó megvásárlásából. Függetlenül attól, hogy melyik módszert választja, ne feledje, hogy az autó értékelése csak egy hozzávetőleges ár. A jármű végső értéke számos tényezőtől függ, például a műszaki állapottól, a felszereltségtől, a szerviztörténettől és attól, hogy milyen benyomást kelt az autó egy potenciális vásárlóban. Fontos, hogy alaposan ellenőrizze az autó állapotát, mielőtt eladná vagy megvásárolná azt. Autó vásárlásakor ne felejtse el ellenőrizni a jármű történetét.",
  "regardless_of_which_method": "Függetlenül attól, hogy melyik módszert választja, ne feledje, hogy az autó értékelése csak egy hozzávetőleges ár. A jármű végső értéke számos tényezőtől függ, például a műszaki állapottól, a felszereltségtől, a szerviztörténettől és attól, hogy milyen benyomást kelt az autó egy potenciális vásárlóban. Fontos, hogy alaposan ellenőrizze az autó állapotát, mielőtt eladná vagy megvásárolná azt.",
  "check_the_valuation": "Ellenőrizze a jármű történetét",
  "you_be_may_interested": "Önnek érdekes lehet:",
  "check": "ELLENŐRIZZE",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Ingyenesen szerezzen alapinformációkat járművéről",
  "get_basic_infotmation_about_vehicle_desc": "például a gyártó, modell, gyártási év és műszaki adatok megadásával a VIN beírásával a keresőmezőbe. Részletesebb adatokért, például baleseti történelem, kilométeróra állás és a jármű jogi státusza, az oldal lehetőséget kínál egy teljes VIN-jelentés megvásárlására.",
  "stolen_cars": "LopottAutók",
  "stolen_cars_title": "Több lopott autó adatbázis ellenőrzése,",
  "stolen_cars_desc": "fejlett keresőeszközök használatával. Csak írja be a jármű VIN-jét vagy a rendszámát, és a rendszer egy hatalmas adatbázisban keres, hogy ellenőrizze, hogy az autót bejelentették-e lopottként.",
  "vin_correct": "VINHelyes",
  "vin_correct_title": "Ellenőrizze a VIN érvényességét.",
  "vin_correct_desc": "az eszköz ellenőrzi, hogy a VIN megfelel-e a jelenlegi szabványoknak és tartalmazza-e az összes szükséges információt. Ez biztosítja, hogy a VIN-t ne hamisították meg, vagy ne rögzítették helytelenül.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Az autó története A-tól Z-ig egy helyen.",
  "easy_vin_desc": "Ellenőrizze tudását az Önt érdeklő járműről, és kímélje meg magát a kellemetlen meglepetésektől új autó vásárlásakor. Ellenőrizze a VIN-számot, amikor csak akarja. A jelentések a nap 24 órájában, a hét 7 napján készülnek.",
  "find_out_the_value_of_your_car_desc": "Szeretné tudni autója értékét, vagy ellenőrizni szeretné járműve értékét vásárlás előtt? Használja ki ingyenes járműértékelésünket! VIN számmal és néhány plusz lépéssel részletes elemzést tudunk adni. Egyszerű, gyors és ingyenes! Ne várjon – ellenőrizze autója értékét most!",
  "enter_te_vin_number": "Írja be a 17 jegyű VIN-számot",
  "enter_mileage": "Adja meg a jármű futásteljesítményét",
  "enter_regplate": "Adja meg a rendszámot",
  "tip": "Érdekes tény:",
  "odometer_readings": "A jármű futásteljesítménye",
  "number_of_regplate": "Rendszám",
  "valuation_incoming": "Az idézet betöltődik",
  "pl_country": "Lengyelország",
  "en_country": "Nagy-Britannia",
  "de_country": "Németország",
  "cs_country": "A cseh köztársaság",
  "lt_country": "Litvánia",
  "fr_country": "Franciaország",
  "es_country": "Spanyolország",
  "ro_country": "Románia",
  "nl_country": "Hollandia",
  "hu_country": "Magyarország",
  "bg_country": "Bulgária",
  "da_country": "Dánia",
  "el_country": "Görögország",
  "it_country": "Olaszország",
  "no_country": "Norvégia",
  "pt_country": "Portugália",
  "sk_country": "Szlovákia",
  "sr_country": "Szerbia",
  "sv_country": "Svédország",
  "tr_country": "Türkiye",
  "ar_country": "Szaúd-Arábia",
  "fact_model_t": "Az első sorozatgyártású autó a Ford Model T volt, amely 1908-ban gördült le a futószalagról.",
  "fact_bugatti": "A világ legdrágább autója a Bugatti La Voiture Noire, amely körülbelül 18 millió dollárba került.",
  "fact_v8": "A V8-as motort még 1902-ben alkották meg, és formatervezése forradalmi volt az autóiparban.",
  "fact_vw_beetle": "A legrégebben gyártott autómodell a Volkswagen Beetle, amelyet 65 éve, 1938-tól 2003-ig gyártottak folyamatosan.",
  "fact_wipers": "Az első autós ablaktörlőt Mary Anderson találta fel 1903-ban, miután észrevette, hogy a sofőröknek ki kell szállniuk az autókból, hogy letörölhessék az ablakukat.",
  "fact_lamborghini": "A Lamborghini kezdetben traktorokat gyártott, és csak később kezdett el luxus sportautókat gyártani.",
  "fact_popular_color": "A világ legnépszerűbb autószíne a fehér, amelyet a vásárlók 39%-a választ.",
  "fact_electric_car": "Az első elektromos autót 1832-ben hozták létre, jóval a belső égésű motoros autók feltalálása előtt.",
  "fact_crank_start": "Az elektromos önindító feltalálása előtt az autókat hajtókarral indították, ami meglehetősen veszélyes volt, és súlyos sérüléseket is okozhat.",
  "fact_ssc_tuatara": "A sorozatgyártású autók valaha elért leggyorsabb sebességét az SSC Tuatara érte el, 532,93 km/h-val.",
  "error_message": "Belső hiba történt. Kérjük, próbálja újra később.",
  "no_data_alert": "Nincs adat erre a járműre.",
};
