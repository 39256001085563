<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col-12 text-center">
                <i class="bi bi-tag-fill price-icon animate__animated animate__pulse"></i>
                <h3 class="fw-bold">{{ t('valuation') }}</h3>
                <h4 class="mt-3 fw-bold">{{ carData.make }} {{ carData.model }}</h4>
                <p class="mt-4">{{ t('valuation_desc') }}</p>
            </div>
        </div>

        <div class="row d-flex justify-content-center mt-4 gap-5">
            <div class="col-12 col-md-4 col-lg-4 price price-min text-center p-4">
                <h4>{{ valuation.price_min }} <span class="currency">{{ currency }}</span></h4>
                <small>{{ t('min_price') }}</small>
            </div>
            <div class="col-12 col-md-4 col-lg-4 price price-med text-center p-4">
                <h4>{{ valuation.price_med }} <span class="currency">{{ currency }}</span></h4>
                <small>{{ t('med_price') }}</small>
            </div>
            <div class="col-12 col-md-4 col-lg-4 price price-max text-center p-4">
                <h4>{{ valuation.price_max }} <span class="currency">{{ currency }}</span></h4>
                <small>{{ t('max_price') }}</small>
            </div>
        </div>
        
        <section v-if="currency">
            <ChartComponent :currency="currency" />
        </section>


        <div class="row">
            <div class="col-12 col-md-6 d-flex justify-content-center mt-0 justify-content-md-end">
                <a href="/" class="btn btn-secondary p-3 fw-bold">{{ t('check_another_vin') }}</a>
            </div>
            
            <div class="col-12 col-md-6 d-flex justify-content-center mt-4 mt-md-0 justify-content-md-start">
                <button class="btn btn-danger p-3 fw-bold" type="button" @click="redirectToPartner">
                    {{ t("check_now") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import ChartComponent from '@/components/ChartComponent.vue';

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { getCurrency } from '@/utils/config';
import { partnerLink } from "@/utils/config";

const store = useStore();
const { t } = useI18n();

const lang = computed(() => store.state.lang);
const vin = computed(() => store.state.carData.vin);

const redirectToPartner = () => {
    window.location.href = `${partnerLink(lang.value)}/${vin.value}`;
};

const valuation = computed(() => {
    return store.state.valuation;
});

const carData = computed(() => {
    return store.state.carData
})

const currency = computed(() => {
    return getCurrency(lang.value);
})
</script>
<style scoped>
.price-icon {
    font-size: 5rem;
}

.price {
    border-radius: 30px;
    color: #fff;
    min-width: 300px;
}


.price-min {
    background-color: #526D82;
}

.price-med {
    background-color: #27374D;
}

.price-max {
    background-color: #31363F;
}

small {
    font-size: 1.1rem;
}

.btn {
    border-radius: 30px;
}

@media (min-width: 1200px) {
    .price {
        width: 20%;
    }
}
</style>