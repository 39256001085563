<template>
    <div class="footer p-3 text-center mt-5">
        <ul class="list-unstyled">
            <li>
                <router-link :to="{ path: `/${$route.params.lang || 'en'}/contact` }"
                    class="text-reset text-decoration-none">{{ t('contact') }}</router-link>
            </li>
            <li>
                <router-link :to="{ path: `/${$route.params.lang || 'en'}/privacy-policy` }"
                    class="text-reset text-decoration-none">{{ t('privacy_policy')
                    }}</router-link>
            </li>
        </ul>
        Copyright &copy; 2024 Car Data Expert
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style scoped>
.footer {
    background-color: #0D131BE8;
    color: white;
}

ul {
    padding-left: 0;
}

li {
    margin-bottom: 10px;
}

.text-reset {
    color: inherit;
}

.text-decoration-none {
    text-decoration: none;
}

a:hover {
    opacity: .6;
}
</style>
