import { ref } from "vue";
import axios from "axios";

export default function reqPrecheck() {
  const responsePrecheck = ref({});

  const sendReqPrecheck = async (vin = null, token = 'TOKEN') => {
    try {
      responsePrecheck.value = await axios.post("/api/getPrecheck", {
        vin: vin || "",
        token: token,
      });

      if (responsePrecheck.value.data.status != 'error') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  return {
    sendReqPrecheck,
    responsePrecheck,
  };
}