export const da = {
  "fast_and_free": "HURTIGT OG GRATIS",
  "vehicle_valuation": "KØRETØJSVURDERING",
  "valuation": "VURDERING",
  "vin": "VIN",
  "want_to_know_valuation": "Vil du vide værdien af din bil eller tjekke værdien af et køretøj, før du køber det? Brug vores gratis køretøjsvurdering! Indtast blot VIN for at få en præcis og pålidelig vurdering. Med et par ekstra trin kan vi give en endnu mere detaljeret analyse. Det er enkelt, hurtigt og gratis! Vent ikke – tjek værdien af din bil nu!",
  "plate": "Registreringsnummer",
  "mileage": "Kilometerstand",
  "accord_mileage_desc": "Køretøjer med lav kilometerstand er normalt mindre slidte, hvilket er grunden til, at de ofte opnår højere priser på brugtbilmarkedet.",
  "damage_history": "Uheldshistorik",
  "damage_history_desc": "Biler, der har været involveret i uheld, har normalt en lavere værdi på grund af potentiel strukturel skade.",
  "count_of_owners": "Antal tidligere ejere",
  "count_of_owners_desc": "Køretøjer, der har haft færre ejere, kan vurderes højere, fordi de generelt er bedre vedligeholdt.",
  "body_color": "Karosserifarve",
  "body_color_desc": "Populære farver kan øge værdien af køretøjet, mens usædvanlige farver kan mindske den.",
  "technical_condition": "Teknisk tilstand",
  "technical_condition_desc": "Den mekaniske og tekniske tilstand af køretøjet, herunder tilstanden af motor, transmission og andre komponenter, har en betydelig indvirkning på prisen.",
  "fuel_type": "Brændstoftype",
  "fuel_type_desc": "Nuværende tendenser og markedets præferencer kan gøre visse typer motorer mere eftertragtede end andre.",
  "vehicle_equipment": "Biludstyr",
  "vehicle_equipment_desc": "Yderligere funktioner såsom navigationssystemer, læderinteriører, højttalersystemer af høj kvalitet kan øge værdien af køretøjet.",
  "current_market_prices": "Nuværende markedspriser",
  "current_market_prices_desc": "Markedspriser svinger baseret på efterspørgsel og udbud for en given køretøjsmodel, hvilket påvirker dens endelige vurdering.",
  "how_to_valuate_vehicle_before_sell": "Hvordan vurderer man et køretøj før køb/salg?",
  "prod_year": "Produktionsår",
  "first_reg_date": "Dato for første registrering",
  "contact": "Kontakt",
  "privacy_policy": "Privatlivspolitik",
  "regulations": "Vilkår og betingelser",
  "valuation_desc": "Køretøjsvurderingen er blevet udarbejdet baseret på den omfattende erfaring fra vores eksperter inden for bilmarkedet. Vurderingsproceduren bruger kunstig intelligens (AI) til at sammenligne ændrende tendenser i køretøjspriser på de europæiske og amerikanske markeder. Vurderingen tager højde for parametre som køretøjets alder og kilometerstand. Vores vurdering tager også hensyn til de nyeste bil- og samfundsmæssige spørgsmål som mangel på dele eller komponenter og inflation, som påvirker priserne på brugte køretøjer.",
  "min_price": "Minimumspris",
  "med_price": "Gennemsnitspris",
  "max_price": "Maksimumspris",
  "we_have_report_for_you": "Vi har en rapport for dette køretøj!",
  "incident_history": "Hændelseshistorik",
  "odometer_reading": "Kilometeraflæsning",
  "vehicles_inspection": "Køretøjsinspektioner",
  "archive_photos": "Arkivbilleder",
  "check_now": "SE RAPPORT",
  "unfortunately_not_found_desc": "Desværre kunne vi ikke finde produktionsåret for dette køretøj, indtast venligst det i feltet ovenfor.",
  "type_odometer": "Indtast den aktuelle kilometerstand for køretøjet i kilometer for et bedre estimat af dets værdi.",
  "check_another_vin": "Vurder et andet køretøj",
  "what_can_affet_the_price_of_vehicle": "Hvad kan påvirke prisen på dit køretøj?",
  "calcualtor_of_vehicle": "Online bilvurderingsberegner",
  "type_plate": "Indtast køretøjets registreringsnummer.",
  "type_first_reg_date": "Indtast datoen for køretøjets første registrering. Du kan finde den i registreringsdokumentet i felt B.",
  "price_odometer": "Pris",
  "price_year": "Pris",
  "year": "År",
  "regulations_admission": "<p>Denne regulering definerer reglerne for brug af hjemmesiden, der drives under navnet VinValuation på internetdomænet www.vinValuation.com (herefter kaldet Siden). Denne regulering indeholder reglerne for brug af Siden. Hvis du ikke er enig i anvendelsen af denne regulering eller nogen af dens bestemmelser, bør du straks forlade denne Side.</p><p>Det fulde navn på vores virksomhed er Car Data Experts LTD. Enhed 4E, enterprise court, Farfield park, Rotherham S63 5DB. Virksomhedens registreringsnummer: 10327199. Du kan kontakte os via e-mail på: <a href=mailto:{email}>{email}</a></p><p>Det primære formål med Siden er at give brugerne mulighed for at indtaste data om stjålne eller beskadigede køretøjer i Car Data-databasen (Køretøjsdata) og at dele disse data med andre personer, der bruger denne database.</p><p>De tjenester, der er tilgængelige på Siden, er beregnet til enkeltpersoner, juridiske enheder og andre enheder, der har bopæl eller registreret kontor i Den Europæiske Union eller De Forenede Stater.</p><p>Tjenesterne på Siden er udelukkende rettet mod enkeltpersoner, der er mindst 18 år gamle.</p><p>Reglerne for behandling af personoplysninger og brug af filer kaldet cookies præsenteres i vores privatlivspolitik, der er tilgængelig på Siden.</p><p>For at bruge Siden skal brugeren anvende en enhed med internetadgang og en webbrowser Internet Explorer 9.0 eller et lignende program. For at bruge nogle funktionaliteter på Siden er det nødvendigt at bruge Java og Java Script-software samt at tillade brugen af cookies. Vi er ikke ansvarlige for manglende adgang til data, der er offentliggjort på vores Side, på grund af at brugerens enhed ikke opfylder de tekniske krav, der er angivet ovenfor.</p>',",
  "regulations_definition": "<p>I denne forordning skal de nedenfor definerede termer have følgende betydninger:</p><p>Køretøjsdata betyder data om stjålne eller beskadigede køretøjer og omstændighederne ved tyveri eller skade, der er oprettet af brugere af siden i databasen for bildata og gjort tilgængelige for andre personer, der bruger denne database.</p><p>Forbruger betyder enhver fysisk person, der handler for formål, der ikke vedrører erhvervsmæssige eller professionelle aktiviteter.</p><p>Database betyder vores bildata-database om den tekniske tilstand og historie for køretøjer.</p><p>VIN-nummer betyder køretøjets identifikationsnummer (Vehicle Identification Number), der bruges af bilindustrien til at identificere en specifik instans af et motorkøretøj, traktor, motorcykel, scooter og knallert, som defineret i ISO 3833-standarden.</p>',",
  "regulations_license_desc": "<p>Med forbehold for andre bestemmelser ejer vi eller vores licensgivere alle intellektuelle ejendomsretter til siden og til de materialer, der findes på siden. Med forbehold for licensen nedenfor er alle intellektuelle ejendomsretter forbeholdt.</p> <p>Brugere må se, downloade filer til caching, udelukkende til eget brug, med forbehold for de undtagelser, der er angivet nedenfor og i andre dele af servicevilkårene.</p><p>Følgende er særligt forbudt:</p><p>a. distribution af materialer opnået fra siden (herunder distribution på en anden hjemmeside);</p><p>b. salg, leje, udlån, gave, overførsel eller tildeling af underlicenser vedrørende siden og materialer, der findes på siden;</p><p>c. offentlig fremførelse af ethvert materiale tilgængeligt på siden;</p><p>d. reproduktion, duplikering, kopiering eller på anden måde brug af materialer indsamlet på siden til kommercielle formål;</p><p>e. ændring eller på anden måde modificering af ethvert materiale, der findes på siden;</p><p>f. distribution af materialer opnået fra siden uden at opnå vores eksplicitte skriftlige samtykke til ovenstående.</p>",
  "regulations_use_page": "<p>1. Det er forbudt at bruge hjemmesiden på nogen måde, der er skadelig for den eller negativt påvirker muligheden for at bruge hjemmesiden; eller på nogen anden måde, der er ulovlig, ulovlig, skadelig eller relateret til noget ulovligt, ulovligt eller skadelig formål eller handling. Vi forbeholder os alle rettigheder til al information og materialer, herunder grafiske og andre, der er tilgængelige på hjemmesiden.</p><p>2. Det er forbudt at bruge hjemmesiden til at kopiere, bruge, opbevare, transmittere, sende, offentliggøre eller distribuere materialer, der indeholder spyware, virus, trojanske heste, orme, tastetrykslogger, rootkit eller anden skadelig software eller enheder, eller henvise til dem.</p><p>3. Enhver systematisk eller automatiseret dataindsamling fra hjemmesiden eller data relateret til hjemmesiden (herunder brug af følgende teknikker: scraping, data mining, dataudvinding og datahøstning) er forbudt uden vores skriftlige samtykke.</p><p>4. Det er forbudt at bruge hjemmesiden til at transmittere eller sende uopfordret kommerciel information.</p><p>5. Det er forbudt at bruge hjemmesiden til marketingformål uden vores skriftlige samtykke.</p>",
  "regulations_limited_access": "<p>1. Adgang til visse ressourcer på hjemmesiden er begrænset. Vi forbeholder os retten til at begrænse adgangen til andre ressourcer på hjemmesiden eller at begrænse adgangen til hele hjemmesiden, underlagt brugernes rettigheder, der opstår fra tidligere indgåede aftaler.</p><p>2. I tilfælde af at brugeren får et brugernavn eller et brugernavn og adgangskode fra os, der giver adgang til begrænsede ressourcer på hjemmesiden eller andet indhold eller tjenester med begrænset adgang, er brugeren forpligtet til at sikre adgangskoden og brugernavnet, så det ikke afsløres for nogen tredjepart.</p>",
  "regulations_user_posted": "<p>1. Bruger-genereret indhold må ikke være ulovligt, krænke principperne for social sameksistens, netikette eller andre etablerede skikke, ej heller krænke tredjeparts rettigheder, eller give anledning til krav eller retssager mod brugeren, os eller nogen tredjepart (i ethvert tilfælde, under enhver gældende lov).</p><p>2. Det er forbudt at poste noget indhold på siden, der er eller har været genstand for nogen igangværende eller truede retssager eller lignende procedurer.</p><p>3. Vi forbeholder os retten til at ændre eller fjerne ethvert indhold eller materiale, der er postet på siden, opbevaret eller offentliggjort der.</p><p>4. Med forbehold for bestemmelserne i disse vilkår og gældende lov vedrørende indhold og andre materialer, der er postet på siden af brugeren, er vi ikke forpligtet til at overvåge indholdet og materialerne, der er postet eller offentliggjort på siden.</p><p>5. Alle data, der er postet af brugeren på siden, bør også opbevares på deres egne enheder for yderligere beskyttelse mod tab.</p>",
  "regulations_placing_data_in_db": "<p>1. Brugeren indsender køretøjsdata til databasen ved hjælp af den formular, der er postet på siden.</p><p>2. Brugeren er ansvarlig for nøjagtigheden af køretøjsdataene i forhold til den aktuelle tilstand. I tilfælde af ændringer skal brugeren hurtigt opdatere de indsendte køretøjsdata i databasen.</p><p>3. Efter indsendelse af køretøjsdataene til databasen modtager brugeren en bekræftelses-e-mail på den adresse, de har angivet, som bekræfter placeringen af disse data i databasen.</p><p>4. Køretøjsdataene placeres i databasen og gøres tilgængelige for andre enheder, der bruger denne database, for at få information om et specifikt køretøj identificeret ved dets VIN-nummer.</p><p>5. Hvis der opstår nye oplysninger vedrørende køretøjsdataene i databasen, vil vi forsøge at informere brugeren, der indsendte køretøjsdataene, via den angivne e-mailadresse.</p><p>6. Køretøjsdata kan trækkes tilbage fra databasen ved at kontakte os gennem kontaktformularen på siden eller ved at skrive til den angivne e-mailadresse i introduktionen til vilkårene og betingelserne.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. For at undgå tvivl, baseret på brugeraftalen for levering af køretøjsdata i databasen, er vi ikke forpligtet til at lokalisere det køretøj, som køretøjsdataene vedrører.</p><p>2. I tilfælde af brugere, der ikke er forbrugere, i det omfang det er tilladt ved gældende lov, giver vi ingen erklæringer, yder ingen garantier vedrørende kvalitet, udelukker ansvar og giver ingen erklæringer vedrørende standarderne for service, der leveres på webstedet og brugen af webstedet (især udelukker vi alle garantier, giver ingen erklæringer givet ved gældende lov vedrørende tilfredsstillende kvalitet, egnethed til formålet eller udøvelse af behørig omhu og dygtighed).</p><p>3. I tilfælde af at du bemærker tekniske problemer på webstedet eller bemærker ufuldstændige eller forældede oplysninger, som vi har givet, samt eventuelle klager vedrørende webstedet (klager), bør du straks underrette os ved at bruge formularen på kontaktsiden eller ved at skrive til os på den e-mailadresse, der er angivet ovenfor i introduktionen til vilkårene og betingelserne. Vi stræber efter at svare hurtigt.</p>',",
  "regulations_liability": "<p>1. Ingen bestemmelse i disse vilkår (a) udelukker eller begrænser vores ansvar for skader forårsaget af forsætlig fejl eller forsætlig vildledning; (b) begrænser vores eller brugerens ansvar på nogen måde, der ville være i strid med gældende lov; eller (c) udelukker vores eller brugerens ansvar, hvis et sådant ansvar ikke kan udelukkes i henhold til gældende lov; især udelukker vilkårene ikke vores eller brugerens ansvar for skader forårsaget af forsætlig fejl.</p><p>2. Udelukkelserne og begrænsningerne af ansvar, der er angivet i vilkårene:</p><p> a. er underlagt de begrænsninger, der er angivet i punkt 1 ovenfor;</p><p> b. vedrører ethvert ansvar, der måtte opstå i henhold til vilkårene eller privatlivspolitikken i forbindelse med emnet for vilkårene og privatlivspolitikken, herunder kontraktligt, erstatningsretligt eller lignende ansvar;</p><p> c. gælder ikke for forbrugere.</p><p>3. I tilfælde af ikke-forbrugerbrugere, i det omfang det er tilladt i henhold til gældende lov:</p><p> a. er vi ikke ansvarlige for nogen skader af nogen art, medmindre skaden er forårsaget af vores forsætlige fejl. Garanti og ethvert ansvar, der opstår fra lignende institutioner til garanti, er hermed udelukket;</p><p> b. er vi ikke ansvarlige for tab eller skader på nogen data, databaser eller software, uanset om det er vores eller brugerens.</p><p>4. Vi er ikke ansvarlige for nogen skader, der opstår i forbindelse med en begivenhed eller begivenheder uden for vores kontrol.</p>",
  "regulations_relief": "<p>Brugere, der ikke er forbrugere, holder os skadesløse for eventuelle tab, skader, kompensation, omkostninger, forpligtelser og udgifter (herunder omkostninger relateret til eventuelle retssager og eventuelle beløb betalt af os til en tredjepart som et forlig) relateret til enhver overtrædelse fra brugerens side af disse vilkår og betingelser, og er forpligtet til at tilbagebetale os de omkostninger, vi har afholdt som nævnt ovenfor.</p>",
  "regulations_violations": "<p>Med forbehold for alle vores andre rettigheder, der følger af disse vilkår, i tilfælde af en overtrædelse af disse vilkår fra brugerens side på nogen måde, kan vi tage de handlinger, vi anser for passende til at reagere på overtrædelsen, hvilket kan inkludere at suspendere brugerens adgang til siden, forhindre brugeren i at få adgang til siden, blokere adgangen til siden for en specifik IP-adresse, kontakte brugerens internetudbyder for at blokere adgangen til siden eller tage retlige skridt.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Reglerne kan ændres ved at placere den nye version på hjemmesiden. Hvis brugeren giver os deres personlige data, især deres e-mailadresse, og hvis vi leverer tjenester til dem under ændringen af disse regler, vil vi informere dem om eventuelle ændringer til disse regler via e-mail.</p><p>2. I tilfælde af brugere, der ikke er forbrugere, udgør en ændring af vores kontaktoplysninger ikke en ændring af disse regler.</p><p>3. I tilfælde af en ændring af den e-mailadresse, der blev givet under registreringen, er brugerne forpligtet til at opdatere denne adresse på hjemmesiden.</p>",
  "regulations_transfer": "<p>Brugere må ikke overføre, dele med andre eller på anden måde disponere over deres rettigheder og forpligtelser, der følger af disse regler, ej heller pådrage sig forpligtelser vedrørende disse rettigheder og forpligtelser.</p>",
  "regulations_severability_clause": "<p>Hvis en bestemmelse i disse regler findes ugyldig eller ineffektiv af en domstol eller anden kompetent myndighed, forbliver de resterende bestemmelser i reglerne bindende. Hvis en ugyldig eller ineffektiv bestemmelse ville være gyldig efter fjernelse af dens del, betragtes den del som fjernet, og den resterende del af bestemmelsen betragtes som gyldig.</p>",
  "regulations_exclusion_parts": "<p>Reglerne definerer udelukkende forholdet mellem os og brugerne af hjemmesiden og giver ikke rettigheder til tredjeparter, ej heller bemyndiger dem til at påberåbe sig nogen af dens bestemmelser eller kræve håndhævelse af disse bestemmelser. Håndhævelsen af bestemmelserne i reglerne kræver ikke samtykke fra nogen tredjeparter.</p>",
  "regulations_scope_contract": "<p>Disse regler, sammen med vores privatlivspolitik og de servicepriser, der er angivet på hjemmesiden, udgør en aftale mellem os og brugeren vedrørende brugen af hjemmesiden og erstatter eventuelle tidligere regler vedrørende brugen af hjemmesiden.</p>",
  "regulations_governing_law": "<p>1. Denne forordning er underlagt polsk lov, og i spørgsmål, der ikke er reguleret af forordningen, gælder bestemmelserne i loven af 23. april 1964, civilkodeksen, loven af 30. maj 2014 om forbrugerrettigheder (i forhold til forbrugere) og loven af 18. juli 2002 om levering af elektroniske tjenester.</p><p>2. I tilfælde af brugere, der ikke er forbrugere, skal enhver tvist, der opstår under forordningen, være underlagt den eksklusive jurisdiktion af polske domstole.</p><p>3. I tilfælde af brugere, der er forbrugere, hvis bopæl er beliggende i Polen på tidspunktet for indgåelsen af aftalen om levering af køretøjsdata, skal enhver tvist relateret til denne forordning eller privatlivspolitikken være underlagt den eksklusive jurisdiktion af polske domstole.</p>",
  "back": "Returner",
  "definition": "Definitioner",
  "admission": "Introduktion",
  "license_use_page": "Licens til at bruge hjemmesiden og de tilgængelige materialer",
  "use_page": "Brug af hjemmesiden",
  "limited_access": "Begrænset adgang",
  "user_posted_content": "Indhold offentliggjort af brugeren",
  "placing_vehicle_data_in_db": "Indtastning af køretøjsdata i databasen",
  "disclaimer_of_liability": "Ansvarsfraskrivelse",
  "relief_from_obligations": "Frigivelse fra forpligtelser",
  "violations_of_regulations": "Overtrædelser af forordningerne",
  "transfer": "Overdragelse",
  "severability_clause": "Salvatorisk klausul",
  "exclusion_third_parts": "Udelukkelse af tredjeparts rettigheder",
  "scope_of_contract": "Omfanget af aftalen",
  "changing_of_provisions_regulations": "Ændring af bestemmelserne i forordningerne",
  "governing_law_and_jurisdiction": "Gældende lov og jurisdiktion",
  "limited_and_warranties_complaints": "Begrænsede erklæringer og garantier og indgivelse af klager",
  "main_tip": "Brug vores hjemmeside til at vurdere biler online ved hjælp af VIN-nummeret. På denne måde kan du hurtigt og præcist finde ud af, hvor meget din bil er værd. Indtast blot din bils VIN-nummer, så hjælper vi dig med at vurdere dens værdi. Vent ikke, tjek det nu!",
  "enter_vin_number": "Indtast VIN",
  "meta_title": "Online bilvurderingsberegner - vurder hurtigt og præcist din bil.",
  "meta_desc": "Online bilvurdering, vurder hurtigt og præcist værdien af din bil. Vores beregner vil give prisen for den kontrollerede modelår af køretøjet og lidt ældre og yngre for sammenligning.",
  "h1_title": "Online bilvurdering kræver kun VIN",
  "check_valuation": "Tjek vurderingen!",
  "next": "Næste",
  "help": "Hjælp",
  "no_data_valuation": "Ingen data til vurdering",
  "check_if_stolen": "Tjek om den er stjålet",
  "decode_vin_number": "Afkod VIN-nummer",
  "additional_links": "Yderligere links",
  "check_vin_correct": "Tjek VIN-nummerets gyldighed",
  "how_to_vin_valuation": "Hvordan vurderer man et køretøj før køb/salg?",
  "calculator_valuation": "Online bilvurderingsberegner",
  "calculator_valuation_desc": "Der er mange metoder til at estimere værdien af en bil; den hurtigste og relativt nøjagtige metode er at tjekke den på en hjemmeside som vinvaluation. Vores proprietære algoritme, understøttet af kunstig intelligens (AI), aggregerer data fra tusindvis af online annoncer og analyserer præcist alderen, mærket, udstyret og tilstanden af de tilbudte køretøjer. Baseret på dette estimerer vi, hvor meget den bil, du tjekker, bør være værd. Du modtager vurderingen på få øjeblikke uden at forlade hjemmet, helt gratis. Vi har kun brug for et par parametre såsom VIN-nummeret eller datoen for registrering af køretøjet. Som svar vil du modtage ikke kun vurderingen af det år, du tjekker, men også af flere nærliggende, yngre og ældre modeller. Dette giver dig fuld indsigt i, om det for eksempel stadig er værd at være ejer af den, eller om du ikke vil miste for meget, og det ville være bedre at sælge den lige nu.",
  "you_get_valuation_for_free": "<strong>Du modtager vurderingen</strong> på få øjeblikke uden at forlade hjemmet helt gratis. Vi har kun brug for et par parametre såsom VIN-nummeret eller datoen for registrering af køretøjet.",
  "in_response_you_receive_not_only_valuation": "Som svar vil du modtage ikke kun vurderingen af det år, du tjekker, men også flere nærliggende, yngre og ældre. Dette giver dig fuld indsigt i, om det for eksempel stadig er værd at være ejer af den, eller om du ikke vil miste for meget, og det ville være godt at sælge den lige nu.",
  "compare_market_price": "Sammenlign priser på markedet",
  "what_we_offer_free": "Hvad vi tilbyder gratis, kan du selvfølgelig gøre manuelt. Gennemse <strong>annoncer på internettet</strong> eller besøg et par <strong>bilforhandlere</strong> for at finde ud af de aktuelle priser på de tilbudte køretøjer.",
  "if_you_are_not_sure": "Hvis du er usikker på, hvilke oplysninger der er nødvendige for at vurdere en bil, kan du kontakte en bilvurderer eller række ud til en forhandler eller bilforhandler. De vil også kunne hjælpe dig med at estimere værdien af din bil. Husk dog, at prisen, der tilbydes af forhandleren eller forhandleren, sandsynligvis vil være betydeligt lavere. De skal også tjene penge på at købe bilen fra dig. Uanset hvilken metode du vælger, skal du huske, at bilvurderingen kun er en omtrentlig pris. Den endelige værdi af køretøjet afhænger af mange faktorer, såsom teknisk tilstand, udstyr, servicehistorik og det samlede indtryk, bilen giver en potentiel køber. Det er vigtigt at tjekke bilens tilstand grundigt, før du sælger eller køber den. Når du planlægger at købe en bil, skal du huske at tjekke køretøjets historie.",
  "regardless_of_which_method": "Uanset hvilken metode du vælger, skal du huske, at bilvurderingen kun er en omtrentlig pris. Den endelige værdi af køretøjet afhænger af mange faktorer, såsom teknisk tilstand, udstyr, servicehistorik og det samlede indtryk, bilen giver en potentiel køber. Det er vigtigt at tjekke bilens tilstand grundigt, før du sælger eller køber den.",
  "check_the_valuation": "Tjek køretøjets historie",
  "you_be_may_interested": "Du kan også være interesseret i:",
  "check": "TJEK",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Få grundlæggende oplysninger om dit køretøj gratis",
  "get_basic_infotmation_about_vehicle_desc": "såsom mærke, model, årgang og tekniske specifikationer ved at indtaste VIN i søgefeltet. For mere detaljerede data, såsom ulykkeshistorik, kilometertal og juridisk status for køretøjet, tilbyder siden også muligheden for at købe en fuld VIN-rapport.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Tjek flere databaser over stjålne biler,",
  "stolen_cars_desc": "ved hjælp af avancerede søgeværktøjer. Indtast blot VIN eller registreringsnummeret på køretøjet, og systemet vil søge i en omfattende database for at tjekke, om bilen er blevet rapporteret stjålet.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Tjek gyldigheden af VIN.",
  "vin_correct_desc": "værktøjet verificerer, om VIN overholder de nuværende standarder og indeholder alle nødvendige oplysninger. Dette sikrer, at VIN ikke er blevet forfalsket eller forkert registreret.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Bilens historie fra A til Z på ét sted.",
  "easy_vin_desc": "Bekræft din viden om det køretøj, du er interesseret i, og spar dig selv fra ubehagelige overraskelser, når du køber en ny bil. Tjek VIN-nummeret, når du vil. Rapporter genereres 24 timer i døgnet, 7 dage om ugen.",
  "find_out_the_value_of_your_car_desc": "Vil du vide værdien af din bil eller tjekke værdien af din bil, før du køber den? Udnyt vores gratis bilvurdering! Med et VIN-nummer og et par ekstra trin kan vi give en detaljeret analyse. Det er nemt, hurtigt og gratis! Vent ikke - tjek værdien af din bil nu!",
  "enter_te_vin_number": "Indtast et 17-cifret VIN-nummer",
  "enter_mileage": "Angiv køretøjets kilometertal",
  "enter_regplate": "Indtast nummerpladenummer",
  "tip": "Sjov fakta:",
  "odometer_readings": "Køretøjets kilometertal",
  "number_of_regplate": "Nummerplade nummer",
  "valuation_incoming": "Citatet indlæses",
  "pl_country": "Polen",
  "en_country": "Storbritannien",
  "de_country": "Tyskland",
  "cs_country": "Den tjekkiske republik",
  "lt_country": "Litauen",
  "fr_country": "Frankrig",
  "es_country": "Spanien",
  "ro_country": "Rumænien",
  "nl_country": "Holland",
  "hu_country": "Ungarn",
  "bg_country": "Bulgarien",
  "da_country": "Danmark",
  "el_country": "Grækenland",
  "it_country": "Italien",
  "no_country": "Norge",
  "pt_country": "Portugal",
  "sk_country": "Slovakiet",
  "sr_country": "Serbien",
  "sv_country": "Sverige",
  "tr_country": "Tyrkiye",
  "ar_country": "Saudi-Arabien",
  "fact_model_t": "Den første masseproducerede bil var Ford Model T, som rullede af samlebåndet i 1908.",
  "fact_bugatti": "Den dyreste bil i verden er Bugatti La Voiture Noire, som kostede cirka 18 millioner dollars.",
  "fact_v8": "V8-motoren blev skabt tilbage i 1902, og dens design var revolutionerende for bilindustrien.",
  "fact_vw_beetle": "Den længst producerede bilmodel er Volkswagen Beetle, produceret uafbrudt i 65 år, fra 1938 til 2003.",
  "fact_wipers": "De første bilrudeviskere blev opfundet af Mary Anderson i 1903, efter at hun bemærkede, at chauffører skulle ud af deres biler for at tørre deres ruder.",
  "fact_lamborghini": "Lamborghini producerede oprindeligt traktorer og begyndte først senere at producere luksus sportsvogne.",
  "fact_popular_color": "Den mest populære bilfarve i verden er hvid, valgt af hele 39 % af køberne.",
  "fact_electric_car": "Den første elbil blev skabt i 1832, længe før opfindelsen af biler med forbrændingsmotorer.",
  "fact_crank_start": "Før den elektriske starter blev opfundet, blev biler startet med et håndsving, hvilket var ret farligt og kunne forårsage alvorlige skader.",
  "fact_ssc_tuatara": "Den hurtigste hastighed nogensinde opnået af en produktionsbil blev opnået af SSC Tuatara ved 532,93 km/t.",
  "error_message": "Der opstod en intern fejl. Prøv venligst igen senere.",
  "no_data_alert": "Ingen data tilgængelige for dette køretøj."
};

