export const en = {
  "fast_and_free": "FAST AND FREE",
  "vehicle_valuation": "VEHICLE EVALUATION",
  "valuation": "EVALUATION",
  "vin": "VIN",
  "want_to_know_valuation": "Do you want to know the value of your car or check the value of a vehicle before buying it? Use our free vehicle valuation! Simply enter the VIN to get an accurate and reliable valuation. With a few additional steps, we can provide an even more detailed analysis. It's easy, fast and free! Don't wait - check the value of your car now!",
  "plate": "Registration number",
  "mileage": "Mileage",
  "accord_mileage_desc": "Vehicles with low mileage tend to have less wear and tear, which is why they often fetch higher prices on the used car market.",
  "damage_history": "Accident history",
  "damage_history_desc": "Cars that have been involved in accidents typically have a lower value due to possible structural damage.",
  "count_of_owners": "Number of previous owners",
  "count_of_owners_desc": "Vehicles that have had fewer owners can be valued higher because they are usually better maintained.",
  "body_color": "Body color",
  "body_color_desc": "Popular colors can increase the value of the vehicle, while unusual colors can decrease it.",
  "technical_condition": "Technical condition",
  "technical_condition_desc": "The mechanical and technical condition of the vehicle, including the condition of the engine, transmission and other components, has a significant impact on the price.",
  "fuel_type": "Fuel type",
  "fuel_type_desc": "Current trends and market preferences may make certain types of engines more desirable than others.",
  "vehicle_equipment": "Vehicle equipment",
  "vehicle_equipment_desc": "Additional features such as navigation systems, leather upholstery, high-quality audio systems can increase the value of the vehicle.",
  "current_market_prices": "Current market prices",
  "current_market_prices_desc": "Market prices fluctuate depending on supply and demand for a particular vehicle model, which affects the final valuation.",
  "how_to_valuate_vehicle_before_sell": "How do you evaluate a vehicle before buying/selling?",
  "prod_year": "Year of construction",
  "first_reg_date": "Date of first registration",
  "contact": "Contact",
  "privacy_policy": "Privacy Policy",
  "regulations": "General terms and conditions of business",
  "valuation_desc": "The vehicle valuation was created based on the extensive experience of our experts in the automotive market. The valuation process uses artificial intelligence (AI) to compare changing trends in vehicle prices in the European and American markets. The valuation takes into account parameters such as the age and mileage of the vehicle. Our valuation also takes into account the latest automotive and social issues such as the shortage of parts or components and inflation that affect the prices of used vehicles.",
  "min_price": "Minimum price",
  "med_price": "Average price",
  "max_price": "Maximum price",
  "we_have_report_for_you": "We have a report for this vehicle!",
  "incident_history": "Incident history",
  "odometer_reading": "Mileage",
  "vehicles_inspection": "Vehicle inspections",
  "archive_photos": "Archive photos",
  "check_now": "VIEW REPORT",
  "unfortunately_not_found_desc": "Unfortunately we could not find the production year for this vehicle, please enter it in the field above.",
  "type_odometer": "Enter the vehicle's current mileage in kilometers to get a better estimate of its value.",
  "check_another_vin": "Rate another vehicle",
  "what_can_affet_the_price_of_vehicle": "What can affect the price of your vehicle?",
  "calcualtor_of_vehicle": "Online Car Valuation Calculator",
  "type_plate": "Enter the vehicle identification number.",
  "type_first_reg_date": "Enter the date of the vehicle's first registration. You can find it in the registration document in field B.",
  "price_odometer": "Price",
  "price_year": "Price",
  "year": "Year",
  "regulations_admission": "<p> This Policy defines the rules for using the website operating under the name VinValuation under the Internet domain www.vinValuation.com (hereinafter referred to as the Website). This Policy contains the rules for using the Website. If you do not agree with the application of this Policy or any of its provisions, you should leave this Website immediately.</p><p> Our company's full name is Car Data Experts LTD. Unit 4E, Companies Court, Farfield Park, Rotherham S63 5DB. Company registration number: 10327199. You can contact us via email at: <a href=mailto:{email}>{email}</a></p><p> The main purpose of the website is to provide users with the ability to enter data about stolen or damaged vehicles into the Car Data database and share that data with other people who use that database.</p><p> The services available on the Website are directed to natural persons, legal persons and other entities residing or established in the European Union or the United States of America.</p><p> The services on the website are intended exclusively for persons who are at least 18 years old.</p><p> The rules regarding the processing of personal data and the use of files called cookies are set out in our Privacy Policy, available on the website.</p><p> To use the website, the user must use a device with Internet access and a web browser Internet Explorer 9.0 or a similar program. To use some features of the website, it is necessary to use Java and Java Script software, as well as allow the use of cookies. We do not assume any responsibility for the lack of access to data published on our website due to the fact that the user's device does not meet the technical requirements specified above.</p> ',",
  "regulations_definition": "<p> In this Regulation, the following terms have the following meanings:</p><p> Vehicle data is data about stolen or damaged vehicles and the circumstances of theft or damage created by users of the website in the vehicle database and made available to other persons who use this database.</p><p> A consumer is any natural person who acts for purposes other than his or her business or professional activities.</p><p> Database is our vehicle database about the technical condition and history of vehicles.</p><p> VIN number means the Vehicle Identification Number used by the automotive industry to identify a specific instance of a motor vehicle, tractor, motorcycle, scooter and moped as defined in the ISO 3833 standard.</p> ',",
  "regulations_license_desc": "<p>Unless otherwise provided, all intellectual property rights in the Website and in the materials included on the Website belong to us or our licensors. Subject to the license below, all intellectual property rights are reserved.</p><p> Users may view and download files only for their own personal use, subject to the exceptions set out below and in other parts of the Terms of Use.</p><p> The following is particularly prohibited:</p><p> a. Distribute any materials obtained from the Website (including distribution on another website);</p><p> b. Sell, rent, lease, gift, transfer or grant sublicenses of the Website and the materials on the Website;</p><p> c. publicly perform any materials available on the Website;</p><p> d. Reproducing, duplicating, copying or otherwise using any materials collected on the Website for commercial purposes;</p><p> e. Alter or otherwise modify any materials located on the Website;</p><p> f. Distribute any materials obtained from the Site without obtaining our express written consent for the foregoing.</p>",
  "regulations_use_page": "<p>1. It is prohibited to use the Website in any way that is damaging or negatively affects the ability to use the Website; or in any other way that is illegal, unlawful, harmful, or related to any illegal, unlawful or harmful purpose or activity. We reserve all rights to all information and materials, including graphic and otherwise, available on the Website.</p><p> 2. It is prohibited to use the Website to copy, use, store, transmit, send, publish or distribute any material that contains or references any spyware, viruses, Trojan horses, worms, keystroke loggers, rootkits or other malicious software or devices.</p><p> 3. Any systematic or automated collection of data from the Website or data related to the Website (including use of the following techniques: scraping, data mining, data extraction and data harvesting) is prohibited without our written consent.</p><p> 4. It is forbidden to use the website to transmit or send unsolicited commercial information.</p><p> 5. It is forbidden to use the website for marketing purposes without our written consent.</p>",
  "regulations_limited_access": "<p>1. Access to certain resources of the Site is restricted. We reserve the right to restrict access to other resources of the Site or to restrict access to the Site as a whole, subject to the rights of users arising from previously concluded agreements.</p><p> 2. If the User receives from us a username or a username and password that allows access to restricted resources on the Website or other content or services with restricted access, the User is obliged to secure the password and username in such a way that they are not disclosed to third parties.</p>",
  "regulations_user_posted": "<p>1. User-generated content must not be illegal, violate the principles of social coexistence, netiquette or other established customs, nor must it infringe the rights of third parties or give rise to claims or legal action against the user, us or third parties (in each case, under applicable law).</p><p> 2. It is prohibited to post content on the Website that is or has been the subject of ongoing or threatened litigation or similar proceedings.</p><p> 3. We reserve the right to modify or remove any content or materials posted, stored or published on the Website.</p><p> 4. Subject to the provisions of these Terms and Conditions and applicable law regarding the content and other materials posted by users on the Site, we have no obligation to monitor the content and materials posted or published on the Site.</p><p> 5. All data posted by the User on the Website should also be kept on their own devices for additional security against loss.</p>",
  "regulations_placing_data_in_db": "<p>1. The user provides vehicle data in the database via the form provided on the website.</p><p> 2. The user is responsible for the accuracy of the vehicle data in relation to the current state of affairs. In case of changes, the user should immediately update the vehicle data provided in the database.</p><p> 3. After the vehicle data has been transmitted to the database, the user will receive a confirmation email to the address provided by him, confirming the placement of this data in the database.</p><p> 4. Vehicle data is stored in the database and made available to other entities that use this database to obtain information about a specific vehicle identified by its VIN number.</p><p> 5. If new information about the vehicle data appears in the database, we will try to inform the user who submitted the vehicle data via the email address provided.</p><p> 6. Vehicle data can be withdrawn from the database by the User contacting us via the contact form on the Website or by email to the address indicated in the introduction to the General Terms and Conditions.</p>",
  "regulations_limited_and_warranties_complaints": "<p> 1. For the avoidance of doubt, based on the User Agreement to provide Vehicle Data in the Database, we are not obliged to locate the vehicle to which the Vehicle Data relates.</p><p> 2. In the case of users who are not consumers, to the fullest extent permitted by applicable law, we make no representations, give no warranties as to quality, exclude liability and make no assurances as to the standards of the services provided on the Website and the use of the Website (in particular, we exclude all warranties, make no assurances as to applicable law as to satisfactory quality, fitness for a particular purpose or the exercise of due care and skill).</p><p> 3. In case of technical problems on the Website or if you notice any incompleteness or out-of-date information provided by us, as well as in case of complaints regarding the Website (Complaints), you should notify us immediately using the form on the Contact page or in writing to the email address indicated above in the introduction to the Terms and Conditions. We will endeavour to respond promptly.</p> ',",
  "regulations_liability": "<p>1. Nothing in these Terms will (a) exclude our liability for damages caused by intentional misconduct or intentional misrepresentation; (b) limit our or the User's liability in any way that would violate applicable law; or (c) exclude our or the User's liability where such liability cannot be excluded under applicable law; in particular, nothing in these Terms will exclude our or the User's liability for damages caused by intentional misconduct.</p><p> 2. The exclusions and limitations of liability provided for in the Conditions:</p><p> a. are subject to the limitations set out in paragraph 1 above;</p><p> b. relate to any liability that may arise under the Terms or the Privacy Policy in connection with the subject matter of the Terms and the Privacy Policy, including liability in contract, tort or similar;</p><p> c. do not apply to consumers.</p><p> 3. In the case of non-consumers, to the extent permitted by applicable law:</p><p> a. we are not liable for damages of any kind unless the damage was caused by our intentional misconduct. Warranty and any liability resulting from similar institutions for warranty are hereby excluded;</p><p> b. we will not be liable for any loss or damage to data, databases or software, whether ours or that of the user.</p><p> 4. We will not be liable for any damage arising in connection with any event or events beyond our control.</p>",
  "regulations_relief": "<p>Users who are not consumers shall indemnify us against all losses, damages, compensation, costs, liabilities and expenses (including costs relating to proceedings and amounts paid by us to third parties in settlement) arising out of any breach by the User of these Terms and Conditions and shall reimburse us for the costs set out above.</p>",
  "regulations_violations": "<p>Subject to any other rights we may have under these Terms, if a User breaches these Terms, we may take any action we deem appropriate to respond to the breach, which may include suspending the User's access to the Website, preventing the User from accessing the Website, blocking access to the Website for a particular IP address, contacting the User's Internet service provider to request that they block access to the Website, or taking legal action.</p>",
  "regulations_changing_provisions_regulations": "<p>1. The Regulations may be amended by publishing the new version on the Website. If the User provides us with his personal data, in particular his e-mail address, and we provide him with services during the modification of these Regulations, we will inform him of changes to these Regulations by e-mail.</p><p> 2. In the case of users who are not consumers, a change in our contact details does not constitute a change to these provisions.</p><p> 3. In case of a change of the e-mail address provided during registration, Users are obliged to update this address on the Website.</p>",
  "regulations_transfer": "<p>Users may not transfer, share or otherwise dispose of their rights and obligations under these Terms, nor incur any obligations with respect to these rights and obligations.</p>",
  "regulations_severability_clause": "<p>If any provision of these Rules is held by a court or other competent authority to be invalid or ineffective, the remaining provisions of the Rules will continue in full force and effect. If any invalid or ineffective provision would be valid if part of it had been deleted, that part will be deemed to be deleted, and the remaining part of the provision will continue in full force and effect.</p>",
  "regulations_exclusion_parts": "<p>The Rules define exclusively the relationship between us and the users of the Website and do not grant any rights or authorize any third party to assert any of the provisions or to demand the enforcement of these provisions. The enforcement of the provisions of the Rules does not require the consent of any third party.</p>",
  "regulations_scope_contract": "<p>This provision, together with our Privacy Policy and the service prices stated on the Website, constitutes an agreement between us and the user regarding the use of the Website and supersedes all previous provisions regarding the use of the Website.</p>",
  "regulations_governing_law": "<p>1. This Regulation is governed by Polish law, and in matters not regulated by the Regulation, the provisions of the Act of 23 April 1964, the Civil Code, the Act of 30 May 2014 on consumer rights (in relation to consumers) and the Act of 18 July 2002 on the provision of electronic services shall apply.</p><p> 2. In the case of Users who are not consumers, all disputes arising from the Regulations shall be subject to the exclusive jurisdiction of the Polish courts.</p><p> 3. In the case of Users who are consumers and whose place of residence at the time of concluding the contract for the provision of vehicle data is in Poland, all disputes related to this Regulation or the Privacy Policy shall be subject to the exclusive jurisdiction of the Polish courts.</p>",
  "back": "return",
  "definition": "Definitions",
  "admission": "introduction",
  "license_use_page": "License to use the website and the materials available on it",
  "use_page": "Use of the website",
  "limited_access": "Restricted access",
  "user_posted_content": "Content published by the user",
  "placing_vehicle_data_in_db": "Entering vehicle data into the database",
  "disclaimer_of_liability": "Disclaimer",
  "relief_from_obligations": "Release from obligations",
  "violations_of_regulations": "Violations of the regulations",
  "transfer": "Assignment",
  "severability_clause": "Severability clause",
  "exclusion_third_parts": "Exclusion of third party rights",
  "scope_of_contract": "Scope of the contract",
  "changing_of_provisions_regulations": "Amendment of the provisions of the regulations",
  "governing_law_and_jurisdiction": "Applicable law and jurisdiction",
  "limited_and_warranties_complaints": "Limited representations and warranties and filing of complaints",
  "main_tip": "Use our website to value cars online by VIN number. This will help you quickly and accurately find out how much your car is worth. Just enter your car's VIN number and we will help you evaluate its value. Don't wait, check it now!",
  "enter_vin_number": "Enter the VIN",
  "meta_title": "Online car valuation calculator - value your car quickly and accurately.",
  "meta_desc": "Online car valuation, quickly and accurately assess the value of your car. Our calculator provides the price for the checked model year of the vehicle as well as for slightly older and younger models for comparison.",
  "h1_title": "Online car valuation requires only the VIN",
  "check_valuation": "Check the rating!",
  "next": "Next",
  "help": "Help",
  "no_data_valuation": "No data for evaluation",
  "check_if_stolen": "Check if stolen",
  "decode_vin_number": "Decode VIN number",
  "additional_links": "Additional links",
  "check_vin_correct": "Check the validity of the VIN number",
  "how_to_vin_valuation": "How do you evaluate a vehicle before buying/selling?",
  "calculator_valuation": "Online car valuation calculator",
  "calculator_valuation_desc": "There are many methods to estimate the value of a car; the fastest and relatively accurate method is to check it on a website like vinvaluation. Our proprietary algorithm, powered by artificial intelligence (AI), aggregates data from thousands of online listings and precisely analyzes the age, make, equipment and condition of the vehicles on offer. Based on this, we estimate how much the car you are checking should be worth. You will receive the valuation in a few moments, without leaving home, completely free of charge. We only need a few parameters such as the VIN number or the date of vehicle registration. In response, you will receive not only the valuation of the year you are checking, but also of several nearby, younger and older models. This will give you a complete insight into whether, for example, it is still worth being the owner or whether you are not losing too much and it would be better to sell it now.",
  "you_get_valuation_for_free": "<strong>You will receive the valuation</strong> in a few moments, without leaving home, completely free of charge. We only need some parameters such as the VIN number or the date of vehicle registration.",
  "in_response_you_receive_not_only_valuation": "In response, you will not only get the rating of the year you are checking, but also several nearby ones, both younger and older. This will give you a complete insight into whether, for example, it is still worth being the owner or whether you are not losing too much and it would be good to sell it now.",
  "compare_market_price": "Compare prices on the market",
  "what_we_offer_free": "What we offer for free, you can of course also do manually. Search <strong>advertisements on the Internet</strong> or visit some <strong>car dealerships</strong> to find out the current prices of the vehicles on offer.",
  "if_you_are_not_sure": "If you are unsure of what information is needed to value a car, you can contact a car appraiser or contact a dealer or car dealership. They can also help you estimate the value of your car. However, keep in mind that the price offered by the dealership or dealer will probably be significantly lower. They also need to make a profit by purchasing the car from you. Regardless of which method you choose, remember that the vehicle appraisal is only an approximate price. The final value of the vehicle depends on many factors, such as the mechanical condition, equipment, service history and the overall impression the car makes on a potential buyer. It is important to thoroughly check the condition of the car before selling or buying it. If you are planning to buy a car, remember to check the vehicle history.",
  "regardless_of_which_method": "Whichever method you choose, remember that the vehicle valuation is only an approximate price. The final value of the vehicle depends on many factors, such as the technical condition, equipment, service history and the overall impression the car makes on a potential buyer. It is important to check the condition of the car thoroughly before selling or buying it.",
  "check_the_valuation": "Check the vehicle history",
  "you_be_may_interested": "You may also be interested in:",
  "check": "CHECK",
  "vindecoder": "VIN decoder",
  "get_basic_infotmation_about_vehicle": "Get basic information about your vehicle for free",
  "get_basic_infotmation_about_vehicle_desc": "such as make, model, year and technical specifications by entering the VIN in the search box. For more detailed data, such as accident history, mileage and legal status of the vehicle, the site also offers the option to purchase a full VIN report.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Check multiple databases of stolen cars,",
  "stolen_cars_desc": "using advanced search tools. Simply enter the vehicle's VIN or registration number and the system will search an extensive database to check if the car has been reported stolen.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Check the validity of the VIN.",
  "vin_correct_desc": "The tool checks whether the VIN complies with current standards and contains all the necessary information. This ensures that the VIN has not been faked or incorrectly recorded.",
  "easy_vin": "simpleVIN",
  "easy_vin_title": "The history of the car from A to Z in one place.",
  "easy_vin_desc": "Verify your knowledge about the vehicle you are interested in and save yourself from unpleasant surprises when buying a new car. Check the VIN number whenever you want. Reports are generated 24 hours a day, 7 days a week.",
  "find_out_the_value_of_your_car_desc": "Want to know the value of your car or check the value of your vehicle before you buy it? Take advantage of our free vehicle valuation! With a VIN number and a few extra steps, we can provide a detailed analysis. It's easy, fast and free! Don't wait - check the value of your car now!",
  "enter_te_vin_number": "Enter 17 digit VIN number",
  "enter_mileage": "Provide vehicle mileage",
  "enter_regplate": "Enter license plate number",
  "tip": "Fun fact:",
  "odometer_readings": "Vehicle mileage",
  "number_of_regplate": "License plate number",
  "valuation_incoming": "The quote is loading",
  "pl_country": "Poland",
  "en_country": "Great Britain",
  "de_country": "Germany",
  "cs_country": "The czech republic",
  "lt_country": "Lithuania",
  "fr_country": "France",
  "es_country": "Spain",
  "ro_country": "Romania",
  "nl_country": "Netherlands",
  "hu_country": "Hungary",
  "bg_country": "Bulgaria",
  "da_country": "Denmark",
  "el_country": "Greece",
  "it_country": "Italy",
  "no_country": "Norway",
  "pt_country": "Portugal",
  "sk_country": "Slovakia",
  "sr_country": "Serbia",
  "sv_country": "Sweden",
  "tr_country": "Türkiye",
  "ar_country": "Saudi Arabia",
  "fact_model_t": "The first mass-produced car was the Ford Model T, which rolled off the assembly line in 1908.",
  "fact_bugatti": "The most expensive car in the world is the Bugatti La Voiture Noire, which cost approximately $18 million.",
  "fact_v8": "The V8 engine was created back in 1902, and its design was revolutionary for the automotive industry.",
  "fact_vw_beetle": "The longest-produced car model is the Volkswagen Beetle, produced continuously for 65 years, from 1938 to 2003.",
  "fact_wipers": "The first car windshield wipers were invented by Mary Anderson in 1903 after she noticed that drivers had to get out of their cars to wipe their windows.",
  "fact_lamborghini": "Lamborghini initially produced tractors and only later began producing luxury sports cars.",
  "fact_popular_color": "The most popular car colour in the world is white, chosen by as many as 39% of buyers.",
  "fact_electric_car": "The first electric car was created in 1832, long before the invention of cars with internal combustion engines.",
  "fact_crank_start": "Before the electric starter was invented, cars were started using a crank, which was quite dangerous and could cause serious injuries.",
  "fact_ssc_tuatara": "The fastest speed ever achieved by a production car was achieved by the SSC Tuatara at 532.93 km/h.",
  "error_message": "An internal error occurred. Please try again later.",
  "no_data_alert": "No data available for this vehicle.",
}