<template>
    <div class="container-fluid d-flex flex-column align-items-center">
        <h2 class="text-white mb-5 text-center">{{ t('you_be_may_interested') }}</h2>
        <div class="partner-box-wrapper w-80">
            <div class="partner-box position-relative">
                <button class="arrow arrow-left" @click="goLeft">
                    <img :src="require('@/assets/img/left.svg')" width="20" height="20" alt="Left" />
                </button>
                <transition mode="out-in" enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut">
                    <div class="content animate__animated" :class="animationClass" :key="currentIndex">
                        <button type="button" class="btn btn-light text-center mb-3">
                            {{ currentItem.buttonText }}
                        </button>
                        <div class="text-content ps-3 pe-3 ms-3 me-3">
                            <p class="fw-bold mb-2">{{ currentItem.title }}</p>
                            <p>{{ currentItem.description }}</p>
                        </div>
                        <div class="text-center mt-3">
                            <button type="button" @click="redirectToPartner(currentItem.link)" class="btn btn-outline-light">{{ t('check')
                                }}</button>
                        </div>
                    </div>
                </transition>
                <button class="arrow arrow-right" @click="goRight">
                    <img :src="require('@/assets/img/right.svg')" width="20" height="20" alt="Right" />
                </button>
            </div>
        </div>
    </div>
</template>



<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { stepsConfigPartners } from '@/utils/config';
// import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const route = useRoute();

// const store = useStore();

const currentIndex = ref(0);
const { t } = useI18n();

// const vin = computed(() => store.state.carData.vin);
// const lang = computed(() => store.state.lang);

const items = ref(stepsConfigPartners(t, route.params.lang));

const redirectToPartner = (link) => {
    window.open(link, '_blank');
};

function goLeft() {
    if (currentIndex.value === 0) {
        currentIndex.value = items.value.length - 1;
    } else {
        currentIndex.value -= 1;
    }
}

function goRight() {
    if (currentIndex.value === items.value.length - 1) {
        currentIndex.value = 0;
    } else {
        currentIndex.value += 1;
    }
}

const currentItem = computed(() => items.value[currentIndex.value]);
</script>


<style scoped>
.container-fluid {
    margin-top: 75px;
}

h2 {
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.partner-box-wrapper {
    max-width: 1000px;
}

.partner-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(49, 54, 63, 0.60) 0%, rgba(13, 19, 27, 0.55) 100%);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    width: 100%;
    min-height: 400px;
    overflow: hidden;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.btn-light {
    color: #31363f;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-radius: 40px;
    padding: 10px;
}

.btn-light:hover {
    color: #31363f;
    background-color: #fff;
    cursor: default;
}

p {
    color: #fff;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

.btn-outline-light {
    border-radius: 45px;
    padding: 10px 16px;
}

.btn-outline-light:hover {
    background: transparent;
    color: #fff;
    opacity: 0.9;
}

.arrow {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 10px;
}

.arrow img {
    width: 30px;
    height: 30px;
}

.arrow-left {
    position: absolute;
    left: 10px;
}

.arrow-right {
    position: absolute;
    right: 10px;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-slide-enter-from {
    opacity: 0;
    transform: translateX(100%);
}

.fade-slide-leave-to {
    opacity: 0;
    transform: translateX(-100%);
}
</style>