import { ref, computed } from 'vue';

export default function useGetSteps(stepsConfig = ref([])) {
    const step = ref(0);
    const totalSteps = computed(() => stepsConfig.value.length);

    const nextStep = () => {
        if (step.value < totalSteps.value) {
            step.value += 1;
        }
    };

    const previousStep = () => {
        if (step.value > 0) {
            step.value -= 1;
        }
    };

    const canGoNext = computed(() => step.value < totalSteps.value - 1);
    const canGoPrevious = computed(() => step.value > 0);

    const currentStep = computed(() => {
        return stepsConfig.value[step.value] || {};
    });

    return {
        step,
        nextStep,
        previousStep,
        canGoNext,
        canGoPrevious,
        currentStep
    };
}
