export const es = {
  "fast_and_free": "RÁPIDO Y GRATIS",
  "vehicle_valuation": "VALORACIÓN DEL VEHÍCULO",
  "valuation": "VALORACIÓN",
  "vin": "VIN",
  "want_to_know_valuation": "¿Quieres saber el valor de tu coche o comprobar el valor de un vehículo antes de comprarlo? ¡Utiliza nuestra valoración de vehículos gratuita! Solo ingresa el VIN para obtener una valoración precisa y confiable. Con unos pocos pasos adicionales, podemos proporcionar un análisis aún más detallado. ¡Es simple, rápido y gratis! No esperes más: ¡verifica el valor de tu coche ahora!",
  "plate": "Número de matrícula",
  "mileage": "Kilometraje",
  "accord_mileage_desc": "Los vehículos con bajo kilometraje suelen estar menos desgastados, por lo que a menudo alcanzan precios más altos en el mercado de coches de segunda mano.",
  "damage_history": "Historial de accidentes",
  "damage_history_desc": "Los coches que han estado involucrados en accidentes suelen tener un valor más bajo debido a posibles daños estructurales.",
  "count_of_owners": "Número de propietarios anteriores",
  "count_of_owners_desc": "Los vehículos que han tenido menos propietarios pueden valorarse más alto porque generalmente están mejor mantenidos.",
  "body_color": "Color de la carrocería",
  "body_color_desc": "Los colores populares pueden aumentar el valor del vehículo, mientras que los colores inusuales pueden disminuirlo.",
  "technical_condition": "Condición técnica",
  "technical_condition_desc": "La condición mecánica y técnica del vehículo, incluida la del motor, la transmisión y otros componentes, tiene un impacto significativo en el precio.",
  "fuel_type": "Tipo de combustible",
  "fuel_type_desc": "Las tendencias actuales y las preferencias del mercado pueden hacer que ciertos tipos de motores sean más deseables que otros.",
  "vehicle_equipment": "Equipamiento del coche",
  "vehicle_equipment_desc": "Características adicionales como sistemas de navegación, interiores de cuero, sistemas de audio de alta calidad pueden aumentar el valor del vehículo.",
  "current_market_prices": "Precios de mercado actuales",
  "current_market_prices_desc": "Los precios de mercado fluctúan según la demanda y la oferta de un modelo de vehículo determinado, afectando su valoración final.",
  "how_to_valuate_vehicle_before_sell": "¿Cómo valorar un vehículo antes de comprar/vender?",
  "prod_year": "Año de producción",
  "first_reg_date": "Fecha de primera matrícula",
  "contact": "Contacto",
  "privacy_policy": "Política de privacidad",
  "regulations": "Términos y condiciones",
  "valuation_desc": "La valoración del vehículo se ha preparado en base a la amplia experiencia de nuestros expertos en el mercado automotriz. El procedimiento de valoración utiliza inteligencia artificial (IA) para comparar las tendencias cambiantes en los precios de los vehículos en los mercados europeo y americano. La valoración tiene en cuenta parámetros como la edad y el kilometraje del vehículo. Nuestra valoración también considera los últimos problemas automotrices y sociales, como la falta de piezas o componentes y la inflación, que afectan los precios de los vehículos usados.",
  "min_price": "Precio mínimo",
  "med_price": "Precio promedio",
  "max_price": "Precio máximo",
  "we_have_report_for_you": "¡Tenemos un informe para este vehículo!",
  "incident_history": "Historial de incidentes",
  "odometer_reading": "Lectura de kilometraje",
  "vehicles_inspection": "Inspecciones del vehículo",
  "archive_photos": "Fotos de archivo",
  "check_now": "VER INFORME",
  "unfortunately_not_found_desc": "Desafortunadamente, no pudimos encontrar el año de producción de este vehículo, por favor ingrésalo en el campo de arriba.",
  "type_odometer": "Ingresa el kilometraje actual del vehículo en kilómetros para una mejor estimación de su valor.",
  "check_another_vin": "Valorar otro vehículo",
  "what_can_affet_the_price_of_vehicle": "¿Qué puede afectar el precio de su vehículo?",
  "calcualtor_of_vehicle": "Calculadora de valoración de coches en línea",
  "type_plate": "Ingrese el número de matrícula del vehículo.",
  "type_first_reg_date": "Ingrese la fecha de la primera matrícula del vehículo. Puede encontrarla en el documento de matrícula en el campo B.",
  "price_odometer": "Precio",
  "price_year": "Precio",
  "year": "Año",
  "regulations_admission": "<p>Este Reglamento define las reglas para el uso del sitio web operado bajo el nombre VinValuation en el dominio de internet www.vinValuation.com (en adelante, el Sitio). Este Reglamento contiene las reglas para el uso del Sitio. Si no está de acuerdo con la aplicación de este Reglamento o cualquiera de sus disposiciones, debe abandonar inmediatamente este Sitio.</p><p>El nombre completo de nuestra empresa es Car Data Experts LTD. Unidad 4E enterprise court, Farfield park, Rotherham S63 5DB. Número de registro de empresas: 10327199. Puede contactarnos por correo electrónico a: <a href=mailto:{email}>{email}</a></p><p>El propósito principal del Sitio es proporcionar a sus usuarios la capacidad de ingresar datos sobre vehículos robados o dañados en la base de datos de Car Data (Datos del Vehículo) y compartir estos datos con otras personas que utilizan esta base de datos.</p><p>Los servicios disponibles en el Sitio están destinados a individuos, entidades legales y otras entidades que tengan residencia o oficina registrada en la Unión Europea o en los Estados Unidos de América.</p><p>Los servicios en el Sitio están dirigidos exclusivamente a individuos que tengan al menos 18 años de edad.</p><p>Las reglas para el procesamiento de datos personales y el uso de archivos llamados cookies se presentan en nuestra Política de Privacidad disponible en el Sitio.</p><p>Para utilizar el Sitio, el usuario debe usar un dispositivo con acceso a internet y un navegador web Internet Explorer 9.0 o un programa similar. Para utilizar algunas funcionalidades del Sitio, es necesario utilizar software Java y Java Script, así como permitir el uso de cookies. No somos responsables por la falta de acceso a los datos publicados en nuestro Sitio debido a que el dispositivo del usuario no cumple con los requisitos técnicos especificados anteriormente.</p>',",
  "regulations_definition": "<p>En este Reglamento, los términos definidos a continuación tendrán los siguientes significados:</p><p>Datos del Vehículo significa datos sobre vehículos robados o dañados y las circunstancias del robo o daño creados por usuarios del Sitio en la base de datos de Datos de Automóviles y puestos a disposición de otras personas que utilizan esta base de datos.</p><p>Consumidor significa cualquier persona natural que actúe con fines no relacionados con actividades comerciales o profesionales.</p><p>Base de Datos significa nuestra base de datos de Datos de Automóviles sobre la condición técnica e historia de los vehículos.</p><p>Número VIN significa el número de identificación del vehículo (Vehicle Identification Number), utilizado por la industria automotriz para identificar una instancia específica de un vehículo motorizado, tractor, motocicleta, scooter y ciclomotor, según lo definido en la norma ISO 3833.</p>',",
  "regulations_license_desc": "<p>Sujeto a otras disposiciones, todos los derechos de propiedad intelectual sobre el Sitio y sobre los materiales contenidos en el Sitio son de nuestra propiedad o de nuestros licenciantes. Sujeto a la licencia a continuación, todos los derechos de propiedad intelectual están reservados.</p> <p>Los usuarios pueden ver, descargar archivos para almacenamiento en caché, únicamente para su propio uso, sujeto a las excepciones indicadas a continuación y en otras partes de los Términos de Servicio.</p><p>Lo siguiente está particularmente prohibido:</p><p>a. distribución de materiales obtenidos del Sitio (incluida la distribución en otro sitio web);</p><p>b. venta, alquiler, préstamo, regalo, transferencia o concesión de sublicencias sobre el Sitio y los materiales ubicados en el Sitio;</p><p>c. representación pública de cualquier material disponible en el Sitio;</p><p>d. reproducir, duplicar, copiar o utilizar de cualquier otra manera materiales recopilados en el Sitio con fines comerciales;</p><p>e. alterar o modificar de cualquier otra manera cualquier material ubicado en el Sitio;</p><p>f. distribuir materiales obtenidos del Sitio sin obtener nuestro consentimiento explícito por escrito para lo anterior.</p>",
  "regulations_use_page": "<p>1. Está prohibido utilizar el Sitio de cualquier manera que sea perjudicial para él o que afecte negativamente la capacidad de usar el Sitio; o de cualquier otra manera que sea ilegal, ilícita, dañina, o relacionada con cualquier propósito o acción ilegal, ilícita o dañina. Nos reservamos todos los derechos sobre toda la información y materiales, incluidos gráficos y otros, disponibles en el Sitio.</p><p>2. Está prohibido utilizar el Sitio para copiar, usar, almacenar, transmitir, enviar, publicar o distribuir cualquier material que contenga spyware, virus, caballos de Troya, gusanos, registradores de teclas, rootkits u otro software o dispositivos dañinos, o hacer referencia a ellos.</p><p>3. Cualquier recopilación de datos sistemática o automatizada del Sitio o datos relacionados con el Sitio (incluyendo el uso de las siguientes técnicas: scraping, minería de datos, extracción de datos y recolección de datos) está prohibida sin nuestro consentimiento por escrito.</p><p>4. Está prohibido utilizar el Sitio para transmitir o enviar información comercial no solicitada.</p><p>5. Está prohibido utilizar el Sitio para cualquier propósito de marketing sin nuestro consentimiento por escrito.</p>",
  "regulations_limited_access": "<p>1. El acceso a ciertos recursos del Sitio está restringido. Nos reservamos el derecho de limitar el acceso a otros recursos del Sitio o de restringir el acceso a todo el Sitio, sujeto a los derechos de los usuarios que surjan de acuerdos previamente celebrados.</p><p>2. En caso de que el usuario obtenga un nombre de usuario o un nombre de usuario y contraseña de nuestra parte que permita el acceso a recursos restringidos en el Sitio u otro contenido o servicios con acceso restringido, el usuario está obligado a asegurar la contraseña y el nombre de usuario para que no sean divulgados a ningún tercero.</p>",
  "regulations_user_posted": "<p>1. El contenido generado por el usuario no debe ser ilegal, violar los principios de convivencia social, la Netiquette u otras costumbres establecidas, ni infringir los derechos de terceros, ni dar lugar a reclamaciones o acciones legales contra el usuario, nosotros o cualquier tercero (en cualquier caso, bajo cualquier ley aplicable).</p><p>2. Está prohibido publicar cualquier contenido en el Sitio que sea o haya sido objeto de litigios en curso o amenazados o procedimientos similares.</p><p>3. Nos reservamos el derecho de modificar o eliminar cualquier contenido o material publicado en el Sitio, almacenado o publicado allí.</p><p>4. Sujeto a las disposiciones de estos Términos y la ley aplicable respecto al contenido y otros materiales publicados en el Sitio por el usuario, no estamos obligados a monitorear el contenido y materiales publicados o publicados en el Sitio.</p><p>5. Cualquier dato publicado por el usuario en el Sitio también debe ser mantenido en sus propios dispositivos para una protección adicional contra la pérdida.</p>",
  "regulations_placing_data_in_db": "<p>1. El usuario proporciona Datos del Vehículo en la Base de Datos utilizando el formulario publicado en el Sitio.</p><p>2. El usuario es responsable de la precisión de los Datos del Vehículo en relación con el estado actual de las cosas. En caso de cualquier cambio, el usuario debe actualizar rápidamente los Datos del Vehículo proporcionados en la Base de Datos.</p><p>3. Después de enviar los Datos del Vehículo a la Base de Datos, el usuario recibe un correo electrónico de confirmación a la dirección que proporcionó, confirmando la colocación de estos datos en la Base de Datos.</p><p>4. Los Datos del Vehículo se colocan en la Base de Datos y se ponen a disposición de otras entidades que utilizan esta base de datos, con el fin de obtener información sobre un vehículo específico identificado por su número VIN.</p><p>5. Si aparece nueva información sobre los Datos del Vehículo en la Base de Datos, intentaremos informar al usuario que envió los Datos del Vehículo a través de la dirección de correo electrónico proporcionada.</p><p>6. Los Datos del Vehículo pueden ser retirados de la Base de Datos contactándonos a través del formulario de contacto en el Sitio o escribiendo a la dirección de correo electrónico proporcionada en la Introducción a los Términos y Condiciones.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Para evitar dudas, según el acuerdo de usuario para proporcionar Datos del Vehículo en la Base de Datos, no estamos obligados a localizar el vehículo al que se refieren los Datos del Vehículo.</p><p>2. En el caso de usuarios que no son Consumidores, en la medida permitida por la ley aplicable, no hacemos representaciones, no proporcionamos garantías sobre la calidad, excluimos responsabilidad y no hacemos representaciones sobre los estándares de servicio proporcionados en el Sitio y el uso del Sitio (en particular, excluimos todas las garantías, no hacemos representaciones proporcionadas por la ley aplicable sobre calidad satisfactoria, idoneidad para un propósito, o el ejercicio de la debida diligencia y habilidad).</p><p>3. En caso de notar problemas técnicos en el Sitio o notar información incompleta o desactualizada proporcionada por nosotros, así como cualquier queja relacionada con el Sitio (quejas), debe notificarnos de inmediato utilizando el formulario en la página de Contacto o escribiéndonos a la dirección de correo electrónico indicada anteriormente en la Introducción a los Términos y Condiciones. Nos esforzamos por responder rápidamente.</p>',",
  "regulations_liability": "<p>1. Ninguna disposición de estos Términos (a) excluye o limita nuestra responsabilidad por daños causados por culpa intencional o tergiversación intencional; (b) limita nuestra responsabilidad o la del usuario de cualquier manera que sea contraria a la ley aplicable; o (c) excluye nuestra responsabilidad o la del usuario si dicha responsabilidad no puede ser excluida bajo la ley aplicable; en particular, los Términos no excluyen nuestra responsabilidad o la del usuario por daños causados por culpa intencional.</p><p>2. Las exclusiones y limitaciones de responsabilidad previstas en los Términos:</p><p> a. están sujetas a las limitaciones especificadas en el párrafo 1 anterior;</p><p> b. se refieren a cualquier responsabilidad que pueda surgir bajo los Términos o la Política de Privacidad en relación con el objeto de los Términos y la Política de Privacidad, incluyendo responsabilidad contractual, extracontractual o similar;</p><p> c. no se aplican a los Consumidores.</p><p>3. En el caso de usuarios no Consumidores, en la medida permitida por la ley aplicable:</p><p> a. no somos responsables de ningún daño de ningún tipo a menos que el daño haya sido causado por nuestra culpa intencional. La garantía y cualquier responsabilidad que surja de instituciones similares a la garantía quedan excluidas;</p><p> b. no somos responsables de ninguna pérdida o daño a ningún dato, bases de datos o software, ya sea nuestro o del usuario.</p><p>4. No somos responsables de ningún daño que surja en relación con un evento o eventos fuera de nuestro control.</p>",
  "regulations_relief": "<p>Los usuarios que no son Consumidores nos indemnizan contra cualquier pérdida, daño, compensación, costos, responsabilidades y gastos (incluidos los costos relacionados con cualquier procedimiento y cualquier cantidad pagada por nosotros a terceros como un acuerdo) relacionados con cualquier incumplimiento por parte del usuario de estos Términos y Condiciones, y están obligados a reembolsarnos los costos incurridos por nosotros como se mencionó anteriormente.</p>",
  "regulations_violations": "<p>Sin perjuicio de todos nuestros otros derechos derivados de estos Términos, en caso de incumplimiento de estos Términos por parte del usuario de cualquier manera, podemos tomar las acciones que consideremos apropiadas para responder al incumplimiento, que pueden incluir suspender el acceso del usuario al Sitio, impedir que el usuario acceda al Sitio, bloquear el acceso al Sitio para una dirección IP específica, contactar al proveedor de Internet del usuario para bloquear el acceso al Sitio, o tomar acciones legales.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Las regulaciones pueden ser modificadas al colocar su nueva versión en el Sitio. Si el usuario nos proporciona sus datos personales, en particular su dirección de correo electrónico, y si le brindamos servicios durante el cambio de estas Regulaciones, lo informaremos de cualquier cambio en estas Regulaciones por correo electrónico.</p><p>2. En el caso de usuarios que no son Consumidores, un cambio en nuestra información de contacto no constituye un cambio en estas Regulaciones.</p><p>3. En caso de un cambio en la dirección de correo electrónico proporcionada durante el registro, los usuarios están obligados a actualizar esta dirección en el Sitio.</p>",
  "regulations_transfer": "<p>Los usuarios no pueden transferir, compartir con otros, o de otro modo disponer de sus derechos y obligaciones que surgen de estas Regulaciones, ni incurrir en obligaciones respecto a estos derechos y obligaciones.</p>",
  "regulations_severability_clause": "<p>Si alguna disposición de estas Regulaciones es considerada inválida o ineficaz por un tribunal u otra autoridad competente, las disposiciones restantes de las Regulaciones seguirán siendo vinculantes. Si una disposición inválida o ineficaz sería válida tras la eliminación de su parte, esa parte se considerará eliminada, y la parte restante de la disposición se considerará válida.</p>",
  "regulations_exclusion_parts": "<p>Las Regulaciones definen exclusivamente la relación entre nosotros y los usuarios del Sitio y no confieren derechos a terceros ni les autorizan a invocar ninguna de sus disposiciones o exigir la aplicación de estas disposiciones. La aplicación de las disposiciones de las Regulaciones no requiere el consentimiento de ningún tercero.</p>",
  "regulations_scope_contract": "<p>Esta Regulación, junto con nuestra Política de Privacidad y los precios de servicio indicados en el Sitio, constituye un acuerdo entre nosotros y el usuario respecto al uso del Sitio y reemplaza cualquier regulación anterior relacionada con el uso del Sitio.</p>",
  "regulations_governing_law": "<p>1. Este Reglamento está sujeto a la ley polaca, y en asuntos no regulados por el Reglamento, se aplicarán las disposiciones de la Ley del 23 de abril de 1964, el Código Civil, la Ley del 30 de mayo de 2014, sobre derechos del consumidor (en relación con los Consumidores), y la Ley del 18 de julio de 2002, sobre la prestación de servicios electrónicos.</p><p>2. En el caso de usuarios que no son Consumidores, cualquier disputa que surja bajo el Reglamento estará sujeta a la jurisdicción exclusiva de los tribunales polacos.</p><p>3. En el caso de usuarios que son Consumidores, cuyo lugar de residencia se encuentra en Polonia en el momento de celebrar el acuerdo para la provisión de Datos del Vehículo, cualquier disputa relacionada con este Reglamento o la Política de Privacidad estará sujeta a la jurisdicción exclusiva de los tribunales polacos.</p>",
  "back": "Devolver",
  "definition": "Definiciones",
  "admission": "Introducción",
  "license_use_page": "Licencia para usar el Sitio y los materiales disponibles en él",
  "use_page": "Uso del Sitio",
  "limited_access": "Acceso limitado",
  "user_posted_content": "Contenido publicado por el Usuario",
  "placing_vehicle_data_in_db": "Ingreso de Datos del Vehículo en la Base de Datos",
  "disclaimer_of_liability": "Descargo de responsabilidad",
  "relief_from_obligations": "Liberación de Obligaciones",
  "violations_of_regulations": "Violaciones de los Reglamentos",
  "transfer": "Cesión",
  "severability_clause": "Cláusula salvatoria",
  "exclusion_third_parts": "Exclusión de Derechos de Terceros",
  "scope_of_contract": "Ámbito del Acuerdo",
  "changing_of_provisions_regulations": "Cambio de las Disposiciones de los Reglamentos",
  "governing_law_and_jurisdiction": "Ley Aplicable y Jurisdicción",
  "limited_and_warranties_complaints": "Representaciones y Garantías Limitadas y Presentación de Quejas",
  "main_tip": "Utilice nuestro sitio web para evaluar coches en línea utilizando el número VIN. De esta manera, sabrá rápida y precisamente cuánto vale su coche. Simplemente ingrese el número VIN de su coche, y le ayudaremos a evaluar su valor. ¡No espere, verifíquelo ahora!",
  "enter_vin_number": "Ingresar VIN",
  "meta_title": "Calculadora de Valoración de Coches en Línea - evalúe rápida y precisamente su coche.",
  "meta_desc": "Valoración de coches en línea, evalúe rápida y precisamente el valor de su coche. Nuestra calculadora proporcionará el precio para el año del modelo del vehículo comprobado y ligeramente más antiguos y más nuevos para comparación.",
  "h1_title": "La valoración de coches en línea requiere solo el VIN",
  "check_valuation": "¡Verifique la valoración!",
  "next": "Siguiente",
  "help": "Ayuda",
  "no_data_valuation": "Sin datos para valoración",
  "check_if_stolen": "Verificar si está robado",
  "decode_vin_number": "Decodificar número VIN",
  "additional_links": "Enlaces adicionales",
  "check_vin_correct": "Verificar validez del número VIN",
  "how_to_vin_valuation": "¿Cómo valorar un vehículo antes de comprar/vender?",
  "calculator_valuation": "Calculadora de valoración de coches en línea",
  "calculator_valuation_desc": "Existen muchos métodos para estimar el valor de un coche; el más rápido y relativamente preciso es consultarlo en un sitio web como vinvaluation. Nuestro algoritmo propietario, respaldado por inteligencia artificial (IA), agrega datos de miles de anuncios en línea, analizando con precisión la edad, marca, equipamiento y condición de los vehículos ofrecidos. Con base en esto, estimamos cuánto debería valer el coche que estás consultando. Recibes la valoración en unos momentos sin salir de casa, completamente gratis. Solo necesitamos algunos parámetros como el número VIN o la fecha de registro del vehículo. En respuesta, recibirás no solo la valoración del año que estás consultando, sino también de varios modelos cercanos, más jóvenes y más antiguos. Esto te da una visión completa de si, por ejemplo, aún vale la pena ser su propietario o si no perderás demasiado y sería mejor venderlo ahora mismo.",
  "you_get_valuation_for_free": "<strong>Recibes la valoración</strong> en unos momentos sin salir de casa completamente gratis. Solo necesitamos algunos parámetros como el número VIN o la fecha de registro del vehículo.",
  "in_response_you_receive_not_only_valuation": "En respuesta, recibirás no solo la valoración del año que estás consultando, sino también varios modelos cercanos, más jóvenes y más antiguos. Esto te da una visión completa de si, por ejemplo, aún vale la pena ser su propietario o si no perderás demasiado y sería bueno venderlo ahora mismo.",
  "compare_market_price": "Compara precios en el mercado",
  "what_we_offer_free": "Lo que ofrecemos gratis, por supuesto, puedes hacerlo manualmente. Navega por <strong>anuncios en internet</strong> o visita algunos <strong>concesionarios</strong> para averiguar los precios actuales de los vehículos ofrecidos.",
  "if_you_are_not_sure": "Si no está seguro de qué información se necesita para valorar un coche, puede contactar a un tasador de coches o comunicarse con un concesionario o una agencia de automóviles. Ellos también podrán ayudarle a estimar el valor de su coche. Sin embargo, tenga en cuenta que el precio ofrecido por el concesionario o el vendedor probablemente será significativamente más bajo. También necesitan obtener ganancias al comprar el coche de usted. Independientemente del método que elija, recuerde que la valoración del coche es solo un precio aproximado. El valor final del vehículo depende de muchos factores, como la condición técnica, el equipamiento, el historial de servicio y la impresión general que el coche cause en un comprador potencial. Es importante revisar a fondo la condición del coche antes de venderlo o comprarlo. Al planear comprar un coche, recuerde verificar el historial del vehículo.",
  "regardless_of_which_method": "Independientemente del método que elija, recuerde que la valoración del coche es solo un precio aproximado. El valor final del vehículo depende de muchos factores, como la condición técnica, el equipamiento, el historial de servicio y la impresión general que el coche cause en un comprador potencial. Es importante revisar a fondo la condición del coche antes de venderlo o comprarlo.",
  "check_the_valuation": "Verifique el historial del vehículo",
  "you_be_may_interested": "También puede estar interesado en:",
  "check": "VERIFICAR",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Obtenga información básica sobre su vehículo de forma gratuita",
  "get_basic_infotmation_about_vehicle_desc": "como marca, modelo, año de fabricación y especificaciones técnicas ingresando el VIN en el campo de búsqueda. Para obtener datos más detallados, como historial de accidentes, kilometraje y estado legal del vehículo, el sitio también ofrece la opción de comprar un informe completo del VIN.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Verifique múltiples bases de datos de coches robados,",
  "stolen_cars_desc": "utilizando herramientas de búsqueda avanzadas. Simplemente ingrese el VIN o el número de registro del vehículo, y el sistema buscará en una vasta base de datos para comprobar si el coche ha sido reportado como robado.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Verifique la validez del VIN.",
  "vin_correct_desc": "la herramienta verifica si el VIN cumple con los estándares actuales y contiene toda la información necesaria. Esto asegura que el VIN no ha sido falsificado o registrado incorrectamente.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "La historia del coche de la A a la Z en un solo lugar.",
  "easy_vin_desc": "Comprueba tus conocimientos sobre el vehículo que te interesa y ahórrate sorpresas desagradables a la hora de comprar un coche nuevo. Consulta el número VIN cuando quieras. Los informes se generan las 24 horas del día, los 7 días de la semana.",
  "find_out_the_value_of_your_car_desc": "¿Quiere saber el valor de su automóvil o comprobar el valor de su vehículo antes de comprarlo? ¡Aprovecha nuestra valoración gratuita de vehículos! Con un número VIN y algunos pasos adicionales, podemos proporcionar un análisis detallado. ¡Es fácil, rápido y gratis! ¡No esperes más y comprueba el valor de tu coche ahora!",
  "enter_te_vin_number": "Ingrese el número VIN de 17 dígitos",
  "enter_mileage": "Proporcionar kilometraje del vehículo",
  "enter_regplate": "Ingrese el número de placa",
  "tip": "Dato curioso:",
  "odometer_readings": "Kilometraje del vehículo",
  "number_of_regplate": "Número de matrícula",
  "valuation_incoming": "La cotización se está cargando",
  "pl_country": "Polonia",
  "en_country": "Gran Bretaña",
  "de_country": "Alemania",
  "cs_country": "La República Checa",
  "lt_country": "Lituania",
  "fr_country": "Francia",
  "es_country": "España",
  "ro_country": "Rumania",
  "nl_country": "Países Bajos",
  "hu_country": "Hungría",
  "bg_country": "Bulgaria",
  "da_country": "Dinamarca",
  "el_country": "Grecia",
  "it_country": "Italia",
  "no_country": "Noruega",
  "pt_country": "Portugal",
  "sk_country": "Eslovaquia",
  "sr_country": "Serbia",
  "sv_country": "Suecia",
  "tr_country": "Turquía",
  "ar_country": "Arabia Saudita",
  "fact_model_t": "El primer automóvil producido en serie fue el Ford Modelo T, que salió de la línea de montaje en 1908.",
  "fact_bugatti": "El coche más caro del mundo es el Bugatti La Voiture Noire, que costó aproximadamente 18 millones de dólares.",
  "fact_v8": "El motor V8 fue creado en 1902 y su diseño fue revolucionario para la industria automotriz.",
  "fact_vw_beetle": "El modelo de automóvil que se produjo durante más tiempo es el Volkswagen Beetle, que se fabricó de forma continua durante 65 años, desde 1938 hasta 2003.",
  "fact_wipers": "Los primeros limpiaparabrisas de automóvil fueron inventados por Mary Anderson en 1903 después de notar que los conductores tenían que salir de sus automóviles para limpiar las ventanas.",
  "fact_lamborghini": "Lamborghini inicialmente producía tractores y sólo más tarde comenzó a producir coches deportivos de lujo.",
  "fact_popular_color": "El color de coche más popular en el mundo es el blanco, elegido por hasta un 39% de los compradores.",
  "fact_electric_car": "El primer coche eléctrico se creó en 1832, mucho antes de la invención de los coches con motores de combustión interna.",
  "fact_crank_start": "Antes de que se inventara el arranque eléctrico, los coches arrancaban mediante una manivela, lo que era bastante peligroso y podía causar lesiones graves.",
  "fact_ssc_tuatara": "La velocidad más rápida jamás alcanzada por un automóvil de producción la alcanzó el SSC Tuatara, con 532,93 km/h.",
  "error_message": "Se produjo un error interno. Inténtalo de nuevo más tarde.",
  "no_data_alert": "No hay datos disponibles para este vehículo.",
};
