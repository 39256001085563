import { createI18n } from "vue-i18n";
import { en } from "../translations/en.js";
import { pl } from "../translations/pl.js";
import { ar } from "../translations/ar.js";
import { bg } from "../translations/bg.js";
import { cs } from "../translations/cs.js";
import { da } from "../translations/da.js";
import { de } from "../translations/de.js";
import { el } from "../translations/el.js";
import { es } from "../translations/es.js";
import { fr } from "../translations/fr.js";
import { hu } from "../translations/hu.js";
import { it } from "../translations/it.js";
import { lt } from "../translations/lt.js";
import { nl } from "../translations/nl.js";
import { no } from "../translations/no.js";
import { pt } from "../translations/pt.js";
import { ro } from "../translations/ro.js";
import { ru } from "../translations/ru.js";
import { sk } from "../translations/sk.js";
import { sr } from "../translations/sr.js";
import { sv } from "../translations/sv.js";
import { tr } from "../translations/tr.js";
// import { uk } from "../translations/uk.js";

export const i18n = createI18n({
  legacy: false,
  locale: navigator.language,
  messages: {
    en,
    pl,
    ar,
    bg,
    cs,
    da,
    de,
    el,
    es,
    fr,
    hu,
    it,
    lt,
    nl,
    no,
    pt,
    ro,
    ru,
    sk,
    sr,
    sv,
    tr,
    // uk,
  },
  fallbackLocale: "en",
});
