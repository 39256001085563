<template>
  <div class="container-fluid p-0 overflow-x-hidden position-absolute top-0 left-0">
    <div v-if="errorMessage == 'error'" class="alert alert-danger text-center text-danger fw-bold" role="alert">
      {{ t('error_message') }}
    </div>

    <div v-else-if="errorMessage == 'nd'" class="alert alert-info text-center text-info fw-bold" role="alert">
      {{ t('no_data_alert') }}
    </div>
    <nav>
      <div class="row mb-4 mt-5">
        <div class="col-12 d-flex justify-content-center">
          <a href="/"><img :src="require('@/assets/img/logo.svg')" class="img-fluid" alt="Logo"></a>
        </div>

        <div class="col-12">
          <LanguageSelectorComponent />
        </div>
      </div>
    </nav>

    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <section v-if="showPrecheck" class="precheck">
        <PrecheckComponent :precheckData="precheckData" />
      </section>
    </main>

    <footer>
      <FooterComponent />
    </footer>
  </div>
</template>

<script setup>
import FooterComponent from '@/components/FooterComponent.vue';
import PrecheckComponent from '@/components/PrecheckComponent.vue';

import { watchEffect, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import LanguageSelectorComponent from './components/LanguageSelectorComponent.vue';

const store = useStore();
const showPrecheck = ref(false);

const hasError = computed(() => store.state.errorMessage);

const { t } = useI18n();

const errorMessage = computed(() => store.state.errorMessage);

const vin = computed(() => {
  return store.state.carData.vin;
});

const hasSent = ref(false);

const precheckData = computed(() => {
  return store.state.precheck;
});

watchEffect(async () => {
  if (vin.value && vin.value.length === 17 && !hasSent.value && !hasError.value) {
    hasSent.value = true;
    setTimeout(async () => {
      store.dispatch("getPrecheckApi", {
        vin: vin.value,
        token: '',
      });
    }, 5000);
  }

  if (precheckData.value?.odometers || precheckData.value?.damages || precheckData.value?.events || precheckData.value?.photos) {
    showPrecheck.value = true;
  }
});
</script>

<style scoped>
.container-fluid {
  background-color: #222831;
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

/* Przy wychodzeniu (fade-out) */
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to {
  opacity: 0;
}
</style>