import { createRouter, createWebHistory } from 'vue-router'
import { i18n } from "@/utils/langs"

// Importuj swoje komponenty
import HomeView from '@/views/HomeView.vue';
import Result from '@/views/ResultView.vue'
import Contact from '@/views/ContactView.vue';
import Regulations from '@/views/RegulationsView.vue';

const routes = [
  {
    path: '/:lang(en|ar|bg|cs|da|de|el|es|fr|hu|it|lt|nl|no|pl|pt|ro|ru|sk|sr|sv|tr|uk)?',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/:lang(en|ar|bg|cs|da|de|el|es|fr|hu|it|lt|nl|no|pl|pt|ro|ru|sk|sr|sv|tr|uk)?/result',
    name: 'Result',
    component: Result,
  },
  {
    path: '/:lang(en|ar|bg|cs|da|de|el|es|fr|hu|it|lt|nl|no|pl|pt|ro|ru|sk|sr|sv|tr|uk)?/privacy-policy',
    name: 'Regulations',
    component: Regulations,
  },
  {
    path: '/:lang(en|ar|bg|cs|da|de|el|es|fr|hu|it|lt|nl|no|pl|pt|ro|ru|sk|sr|sv|tr|uk)?/contact',
    name: 'Contact',
    component: Contact,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || navigator.language;
  i18n.global.locale.value = lang;

  next();
});

export default router;
