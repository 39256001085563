export const cs = {
  "fast_and_free": "RYCHLE A ZDARMA",
  "vehicle_valuation": "OCENĚNÍ VOZIDLA",
  "valuation": "OCENĚNÍ",
  "vin": "VIN",
  "want_to_know_valuation": "Chcete znát hodnotu svého auta nebo zkontrolovat hodnotu vozidla před jeho zakoupením? Využijte naše bezplatné ocenění vozidla! Stačí zadat VIN a získáte přesné a spolehlivé ocenění. S několika dalšími kroky můžeme poskytnout ještě podrobnější analýzu. Je to jednoduché, rychlé a zdarma! Nečekejte – zkontrolujte hodnotu svého auta nyní!",
  "plate": "Registrační číslo",
  "mileage": "Najeté kilometry",
  "accord_mileage_desc": "Vozidla s nízkým počtem najetých kilometrů jsou obvykle méně opotřebovaná, a proto často dosahují vyšších cen na trhu s ojetými vozy.",
  "damage_history": "Historie nehod",
  "damage_history_desc": "Auta, která byla zapojena do nehod, obvykle mají nižší hodnotu kvůli možnému strukturálnímu poškození.",
  "count_of_owners": "Počet předchozích majitelů",
  "count_of_owners_desc": "Vozidla, která měla méně majitelů, mohou být oceněna výše, protože jsou obecně lépe udržována.",
  "body_color": "Barva karoserie",
  "body_color_desc": "Oblíbené barvy mohou zvýšit hodnotu vozidla, zatímco neobvyklé barvy ji mohou snížit.",
  "technical_condition": "Technický stav",
  "technical_condition_desc": "Mechanický a technický stav vozidla, včetně stavu motoru, převodovky a dalších komponentů, má významný vliv na cenu.",
  "fuel_type": "Typ paliva",
  "fuel_type_desc": "Současné trendy a preference na trhu mohou učinit určité typy motorů žádoucími více než jiné.",
  "vehicle_equipment": "Vybavení auta",
  "vehicle_equipment_desc": "Další funkce, jako jsou navigační systémy, kožené interiéry, vysoce kvalitní audio systémy, mohou zvýšit hodnotu vozidla.",
  "current_market_prices": "Aktuální tržní ceny",
  "current_market_prices_desc": "Tržní ceny kolísají na základě poptávky a nabídky pro daný model vozidla, což ovlivňuje jeho konečné ocenění.",
  "how_to_valuate_vehicle_before_sell": "Jak ocenit vozidlo před koupí/prodejem?",
  "prod_year": "Rok výroby",
  "first_reg_date": "Datum první registrace",
  "contact": "Kontakt",
  "privacy_policy": "Zásady ochrany osobních údajů",
  "regulations": "Obchodní podmínky",
  "valuation_desc": "Ocenění vozidla bylo připraveno na základě rozsáhlých zkušeností našich odborníků na automobilový trh. Ocenění využívá umělou inteligenci (AI) k porovnání měnících se trendů v cenách vozidel na evropských a amerických trzích. Ocenění zohledňuje parametry, jako je věk vozidla a najeté kilometry. Naše ocenění také zohledňuje nejnovější automobilové a sociální otázky, jako je nedostatek dílů nebo komponentů a inflace, které ovlivňují ceny ojetých vozidel.",
  "min_price": "Minimální cena",
  "med_price": "Průměrná cena",
  "max_price": "Maximální cena",
  "we_have_report_for_you": "Máme zprávu pro toto vozidlo!",
  "incident_history": "Historie incidentů",
  "odometer_reading": "Stav najetých kilometrů",
  "vehicles_inspection": "Inspekce vozidla",
  "archive_photos": "Archivní fotografie",
  "check_now": "ZOBRAZIT ZPRÁVU",
  "unfortunately_not_found_desc": "Bohužel jsme nemohli najít rok výroby tohoto vozidla, prosím zadejte ho do pole výše.",
  "type_odometer": "Zadejte aktuální počet najetých kilometrů vozidla v kilometrech pro lepší odhad jeho hodnoty.",
  "check_another_vin": "Oceňte další vozidlo",
  "what_can_affet_the_price_of_vehicle": "Co může ovlivnit cenu vašeho vozidla?",
  "calcualtor_of_vehicle": "Online kalkulačka ocenění automobilů",
  "type_plate": "Zadejte registrační číslo vozidla.",
  "type_first_reg_date": "Zadejte datum první registrace vozidla. Můžete ho najít v registračním dokumentu v poli B.",
  "price_odometer": "Cena",
  "price_year": "Cena",
  "year": "Rok",
  "regulations_admission": "<p>Tento předpis definuje pravidla pro používání webové stránky provozované pod názvem VinValuation na internetové doméně www.vinValuation.com (dále jen Stránka). Tento předpis obsahuje pravidla pro používání Stránky. Pokud nesouhlasíte s použitím tohoto předpisu nebo jakékoli jeho části, měli byste okamžitě opustit tuto Stránku.</p><p>Celý název naší společnosti je Car Data Experts LTD. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Číslo společnosti: 10327199. Můžete nás kontaktovat e-mailem na: <a href=mailto:{email}>{email}</a></p><p>Hlavním účelem Stránky je poskytnout jejím uživatelům možnost zadat údaje o odcizených nebo poškozených vozidlech do databáze Car Data (Údaje o vozidlech) a sdílet tyto údaje s ostatními jednotlivci používajícími tuto databázi.</p><p>Služby dostupné na Stránce jsou určeny jednotlivcům, právnickým osobám a dalším subjektům, které mají bydliště nebo registrovanou kancelář v Evropské unii nebo ve Spojených státech amerických.</p><p>Služby na Stránce jsou určeny výhradně jednotlivcům, kteří jsou alespoň 18 let.</p><p>Pravidla pro zpracování osobních údajů a používání souborů nazývaných cookies jsou uvedena v naší Zásadě ochrany osobních údajů dostupné na Stránce.</p><p>Pro používání Stránky musí uživatel používat zařízení s přístupem k internetu a webový prohlížeč Internet Explorer 9.0 nebo podobný program. Pro používání některých funkcionalit Stránky je nutné používat software Java a Java Script, stejně jako povolit používání cookies. Neodpovídáme za nedostatek přístupu k údajům zveřejněným na naší Stránce z důvodu, že zařízení uživatele nesplňuje výše uvedené technické požadavky.</p>',",
  "regulations_definition": "<p>V této vyhlášce mají níže definované pojmy následující významy:</p><p>Údaje o vozidle znamenají údaje o odcizených nebo poškozených vozidlech a okolnostech odcizení nebo poškození vytvořené uživateli webu v databázi Údajů o automobilech a zpřístupněné dalším osobám, které tuto databázi používají.</p><p>Spotřebitel znamená jakoukoli fyzickou osobu jednající pro účely nesouvisející s podnikatelskými nebo profesionálními činnostmi.</p><p>Databáze znamená naši databázi Údajů o automobilech týkající se technického stavu a historie vozidel.</p><p>Číslo VIN znamená identifikační číslo vozidla (Vehicle Identification Number), které používá automobilový průmysl k identifikaci konkrétní instance motorového vozidla, traktoru, motocyklu, skútru a mopedu, jak je definováno ve standardu ISO 3833.</p>',",
  "regulations_license_desc": "<p>Pokud není stanoveno jinak, všechna práva duševního vlastnictví k webu a materiálům obsaženým na webu vlastníme my nebo naši licencovatelé. S výhradou níže uvedené licence jsou všechna práva duševního vlastnictví vyhrazena.</p> <p>Uživatelé mohou prohlížet, stahovat soubory pro ukládání do mezipaměti, výhradně pro svou vlastní potřebu, s výhradou výjimek uvedených níže a v dalších částech Podmínek služby.</p><p>Následující je zvlášť zakázáno:</p><p>a. distribuce materiálů získaných z webu (včetně distribuce na jiném webu);</p><p>b. prodej, pronájem, půjčování, darování, převod nebo udělování sublicencí týkajících se webu a materiálů umístěných na webu;</p><p>c. veřejné provádění jakýchkoli materiálů dostupných na webu;</p><p>d. reprodukce, duplikace, kopírování nebo jinak používání materiálů shromážděných na webu pro obchodní účely;</p><p>e. změna nebo jiná úprava jakýchkoli materiálů umístěných na webu;</p><p>f. distribuce materiálů získaných z webu bez získání našeho výslovného písemného souhlasu pro výše uvedené.</p>",
  "regulations_use_page": "<p>1. Je zakázáno používat webové stránky jakýmkoli způsobem, který by jim mohl ublížit nebo negativně ovlivnit schopnost používat webové stránky; nebo jakýmkoli jiným způsobem, který je nezákonný, protiprávní, škodlivý nebo související s jakýmkoli nezákonným, protiprávním nebo škodlivým účelem či činností. Vyhrazujeme si všechna práva k veškerým informacím a materiálům, včetně grafických a dalších, dostupným na webových stránkách.</p><p>2. Je zakázáno používat webové stránky k kopírování, používání, ukládání, přenosu, zasílání, publikování nebo distribuci jakýchkoli materiálů, které obsahují jakýkoli spyware, virus, trojského koně, červ, logger stisků kláves, rootkit nebo jiný škodlivý software či zařízení, nebo na ně odkazovat.</p><p>3. Jakékoli systematické nebo automatizované shromažďování dat z webových stránek nebo dat souvisejících s webovými stránkami (včetně použití následujících technik: scraping, data mining, data extraction a data harvesting) je zakázáno bez našeho písemného souhlasu.</p><p>4. Je zakázáno používat webové stránky k přenosu nebo zasílání nevyžádaných obchodních informací.</p><p>5. Je zakázáno používat webové stránky k jakýmkoli marketingovým účelům bez našeho písemného souhlasu.</p>",
  "regulations_limited_access": "<p>1. Přístup k určitým zdrojům webových stránek je omezen. Vyhrazujeme si právo omezit přístup k dalším zdrojům webových stránek nebo omezit přístup k celému webu, s ohledem na práva uživatelů vyplývající z dříve uzavřených smluv.</p><p>2. V případě, že uživatel získá uživatelské jméno nebo uživatelské jméno a heslo od nás, které umožňuje přístup k omezeným zdrojům na webových stránkách nebo jinému obsahu či službám s omezeným přístupem, je uživatel povinen zabezpečit heslo a uživatelské jméno tak, aby nebylo zpřístupněno žádné třetí straně.</p>",
  "regulations_user_posted": "<p>1. Obsah generovaný uživateli nesmí být nezákonný, porušovat principy sociálního soužití, netiketu nebo jiné zavedené zvyky, ani porušovat práva třetích stran, ani vyvolávat jakékoli nároky nebo právní kroky proti uživateli, nám nebo jakékoli třetí straně (v každém případě, podle jakéhokoli platného práva).</p><p>2. Je zakázáno zveřejňovat jakýkoli obsah na webu, který je nebo byl předmětem jakéhokoli probíhajícího nebo hrozícího soudního řízení nebo podobných řízení.</p><p>3. Vyhrazujeme si právo upravit nebo odstranit jakýkoli obsah nebo materiál zveřejněný na webu, uložený nebo publikovaný tam.</p><p>4. S výhradou ustanovení těchto podmínek a platného práva týkajícího se obsahu a dalších materiálů zveřejněných na webu uživateli, nejsme povinni monitorovat obsah a materiály zveřejněné nebo publikované na webu.</p><p>5. Jakákoli data zveřejněná uživateli na webu by měla být také uchovávána na jejich vlastních zařízeních pro dodatečnou ochranu proti ztrátě.</p>",
  "regulations_placing_data_in_db": "<p>1. Uživatel poskytuje údaje o vozidle v databázi pomocí formuláře zveřejněného na webu.</p><p>2. Uživatel nese odpovědnost za přesnost údajů o vozidle ve vztahu k aktuálnímu stavu věcí. V případě jakýchkoli změn by měl uživatel okamžitě aktualizovat údaje o vozidle poskytnuté v databázi.</p><p>3. Po odeslání údajů o vozidle do databáze obdrží uživatel potvrzovací e-mail na adresu, kterou poskytl, potvrzující umístění těchto údajů do databáze.</p><p>4. Údaje o vozidle jsou umístěny v databázi a zpřístupněny dalším subjektům využívajícím tuto databázi, aby získaly informace o konkrétním vozidle identifikovaném jeho VIN číslem.</p><p>5. Pokud se v databázi objeví nové informace týkající se údajů o vozidle, pokusíme se informovat uživatele, který údaje o vozidle odeslal, prostřednictvím e-mailové adresy, kterou poskytl.</p><p>6. Údaje o vozidle mohou být staženy z databáze kontaktováním nás prostřednictvím kontaktního formuláře na webu nebo napsáním na e-mailovou adresu uvedenou v úvodu k podmínkám a pravidlům.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Pro vyloučení pochybností, na základě uživatelské smlouvy o poskytování údajů o vozidlech v databázi, nejsme povinni lokalizovat vozidlo, kterého se údaje o vozidle týkají.</p><p>2. V případě uživatelů, kteří nejsou spotřebiteli, v rozsahu povoleném platným právem, neposkytujeme žádná prohlášení, neposkytujeme žádné záruky týkající se kvality, vylučujeme odpovědnost a neposkytujeme žádná prohlášení týkající se standardů služeb poskytovaných na webu a používání webu (zejména vylučujeme všechny záruky, neposkytujeme žádná prohlášení podle platného práva týkající se uspokojivé kvality, vhodnosti pro účel nebo vykonávání náležité péče a dovednosti).</p><p>3. V případě, že si všimnete technických problémů na webu nebo si všimnete neúplných nebo zastaralých informací poskytnutých námi, stejně jako jakýchkoli stížností týkajících se webu (stížnosti), měli byste nás okamžitě informovat pomocí formuláře na stránce Kontakty nebo nám napsat na e-mailovou adresu uvedenou výše v Úvodu k podmínkám. Snažíme se odpovědět co nejdříve.</p>',",
  "regulations_liability": "<p>1. Žádné ustanovení v těchto Podmínkách (a) nevylučuje ani neomezuje naši odpovědnost za škodu způsobenou úmyslnou chybou nebo úmyslným uvedením v omyl; (b) neomezuje naši nebo uživatelovu odpovědnost jakýmkoli způsobem, který by byl v rozporu s platným právem; nebo (c) nevylučuje naši nebo uživatelovu odpovědnost, pokud taková odpovědnost nemůže být podle platného práva vyloučena; zejména, Podmínky nevylučují naši nebo uživatelovu odpovědnost za škody způsobené úmyslnou chybou.</p><p>2. Vyloučení a omezení odpovědnosti uvedená v Podmínkách:</p><p> a. podléhají omezením uvedeným v odstavci 1 výše;</p><p> b. se vztahují na jakoukoli odpovědnost, která může vzniknout podle Podmínek nebo Zásady ochrany osobních údajů v souvislosti s předmětem Podmínek a Zásady ochrany osobních údajů, včetně smluvní, deliktní nebo podobné odpovědnosti;</p><p> c. se nevztahují na spotřebitele.</p><p>3. V případě uživatelů, kteří nejsou spotřebiteli, v rozsahu povoleném platným právem:</p><p> a. neneseme odpovědnost za žádné škody jakéhokoli druhu, pokud škoda nebyla způsobena naší úmyslnou chybou. Záruka a jakákoli odpovědnost vyplývající z podobných institucí k záruce jsou tímto vyloučeny;</p><p> b. neneseme odpovědnost za jakoukoli ztrátu nebo škodu na jakýchkoli datech, databázích nebo softwaru, ať už našich nebo uživatelových.</p><p>4. Nenese odpovědnost za žádné škody vzniklé v souvislosti s událostí nebo událostmi, které jsou mimo naši kontrolu.</p>",
  "regulations_relief": "<p>Uživatelé, kteří nejsou spotřebiteli, nás odškodňují za jakékoli ztráty, škody, náhrady, náklady, závazky a výdaje (včetně nákladů spojených s jakýmikoli řízeními a jakýchkoli částek, které jsme zaplatili jakékoli třetí straně jako vyrovnání) související s jakýmkoli porušením těchto Podmínek a jsou povinni nám uhradit náklady, které jsme vynaložili, jak je uvedeno výše.</p>",
  "regulations_violations": "<p>V souladu se všemi našimi dalšími právy vyplývajícími z těchto Podmínek, v případě porušení těchto Podmínek uživatelem jakýmkoli způsobem, můžeme podniknout takové kroky, které považujeme za vhodné k reakci na porušení, což může zahrnovat pozastavení přístupu uživatele na Stránku, zabránění uživateli v přístupu na Stránku, blokování přístupu na Stránku pro konkrétní IP adresu, kontaktování poskytovatele internetových služeb uživatele za účelem zablokování přístupu na Stránku nebo zahájení právních kroků.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Pravidla mohou být změněna zveřejněním jejich nové verze na webu. Pokud nám uživatel poskytne své osobní údaje, zejména svou e-mailovou adresu, a pokud mu během změny těchto pravidel poskytujeme služby, budeme ho o jakýchkoli změnách těchto pravidel informovat e-mailem.</p><p>2. V případě uživatelů, kteří nejsou spotřebiteli, změna našich kontaktních údajů nepředstavuje změnu těchto pravidel.</p><p>3. V případě změny e-mailové adresy poskytnuté při registraci jsou uživatelé povinni tuto adresu na webu aktualizovat.</p>",
  "regulations_transfer": "<p>Uživatelé nesmí převádět, sdílet s ostatními ani jinak nakládat se svými právy a povinnostmi vyplývajícími z těchto pravidel, ani na sebe brát povinnosti týkající se těchto práv a povinností.</p>",
  "regulations_severability_clause": "<p>Pokud je jakékoli ustanovení těchto pravidel shledáno soudem nebo jiným příslušným orgánem jako neplatné nebo neúčinné, zbývající ustanovení pravidel zůstávají závazná. Pokud by neplatné nebo neúčinné ustanovení bylo platné po odstranění jeho části, tato část se považuje za odstraněnou a zbývající část ustanovení se považuje za platnou.</p>",
  "regulations_exclusion_parts": "<p>Pravidla výhradně definují vztah mezi námi a uživateli webu a nepřiznávají žádná práva třetím stranám ani je neopravňují k uplatnění jakýchkoli ustanovení nebo požadování vynucení těchto ustanovení. Vynucení ustanovení pravidel nevyžaduje souhlas žádné třetí strany.</p>",
  "regulations_scope_contract": "<p>Tato pravidla spolu s naší politikou ochrany soukromí a cenami služeb uvedenými na webu tvoří dohodu mezi námi a uživatelem ohledně používání webu a nahrazují jakákoli předchozí pravidla týkající se používání webu.</p>",
  "regulations_governing_law": "<p>1. Tento předpis se řídí polským právem a v záležitostech, které nejsou tímto předpisem upraveny, se použijí ustanovení zákona ze dne 23. dubna 1964, občanského zákoníku, zákona ze dne 30. května 2014, o právech spotřebitelů (ve vztahu ke spotřebitelům) a zákona ze dne 18. července 2002, o poskytování elektronických služeb.</p><p>2. V případě uživatelů, kteří nejsou spotřebiteli, se jakékoli spory vyplývající z tohoto předpisu podřizují výlučné pravomoci polských soudů.</p><p>3. V případě uživatelů, kteří jsou spotřebiteli a jejichž místo bydliště se nachází v Polsku v době uzavření smlouvy o poskytování údajů o vozidle, se jakékoli spory související s tímto předpisem nebo s politikou ochrany osobních údajů podřizují výlučné pravomoci polských soudů.</p>",
  "back": "Vrátit",
  "definition": "Definice",
  "admission": "Úvod",
  "license_use_page": "Licence k používání webu a materiálů, které jsou na něm k dispozici",
  "use_page": "Používání webu",
  "limited_access": "Omezený přístup",
  "user_posted_content": "Obsah zveřejněný uživatelem",
  "placing_vehicle_data_in_db": "Zadání údajů o vozidle do databáze",
  "disclaimer_of_liability": "Zřeknutí se odpovědnosti",
  "relief_from_obligations": "Uvolnění od povinností",
  "violations_of_regulations": "Porušení předpisů",
  "transfer": "Postoupení",
  "severability_clause": "Zachovávací klauzule",
  "exclusion_third_parts": "Vyloučení práv třetích stran",
  "scope_of_contract": "Rozsah smlouvy",
  "changing_of_provisions_regulations": "Změna ustanovení předpisů",
  "governing_law_and_jurisdiction": "Rozhodné právo a jurisdikce",
  "limited_and_warranties_complaints": "Omezená prohlášení a záruky a podání stížností",
  "main_tip": "Použijte naše webové stránky k online hodnocení automobilů pomocí VIN čísla. Tímto způsobem rychle a přesně zjistíte, kolik vaše auto stojí. Stačí zadat VIN číslo vašeho automobilu a my vám pomůžeme posoudit jeho hodnotu. Nečekejte, zkontrolujte to nyní!",
  "enter_vin_number": "Zadejte VIN",
  "meta_title": "Online kalkulačka ocenění automobilů - rychle a přesně posuďte své auto.",
  "meta_desc": "Online ocenění automobilu, rychle a přesně posuďte hodnotu svého automobilu. Naše kalkulačka poskytne cenu pro kontrolovaný modelový rok vozidla a mírně starší a mladší pro srovnání.",
  "h1_title": "Online ocenění automobilu vyžaduje pouze VIN",
  "check_valuation": "Zkontrolujte ocenění!",
  "next": "Další",
  "help": "Pomoc",
  "no_data_valuation": "Žádná data pro ocenění",
  "check_if_stolen": "Zkontrolujte, zda je odcizené",
  "decode_vin_number": "Dekódujte VIN číslo",
  "additional_links": "Další odkazy",
  "check_vin_correct": "Zkontrolujte platnost VIN čísla",
  "how_to_vin_valuation": "Jak ocenit vozidlo před koupí/prodejem?",
  "calculator_valuation": "Online kalkulačka ocenění automobilů",
  "calculator_valuation_desc": "Existuje mnoho metod, jak odhadnout hodnotu automobilu; nejrychlejší a relativně přesná je zkontrolovat to na webu jako vinvaluation. Náš proprietární algoritmus, podporovaný umělou inteligencí (AI), agreguje data z tisíců online inzerátů, přesně analyzující věk, značku, výbavu a stav nabízených vozidel. Na základě toho odhadujeme, kolik by mělo auto, které kontrolujete, stát. Ocenění obdržíte během několika okamžiků, aniž byste opustili domov, zcela zdarma. Potřebujeme pouze několik parametrů, jako je VIN číslo nebo datum registrace vozidla. V odpovědi obdržíte nejen ocenění roku, který kontrolujete, ale také několika blízkých, mladších a starších modelů. To vám poskytne úplný přehled o tom, zda je například stále výhodné být jeho majitelem, nebo zda příliš neztratíte a bylo by lepší ho prodat právě teď.",
  "you_get_valuation_for_free": "<strong>Ocenění obdržíte</strong> během několika okamžiků, aniž byste opustili domov, zcela zdarma. Potřebujeme pouze několik parametrů, jako je VIN číslo nebo datum registrace vozidla.",
  "in_response_you_receive_not_only_valuation": "V odpovědi obdržíte nejen ocenění roku, který kontrolujete, ale také několik blízkých, mladších a starších. To vám poskytne úplný přehled o tom, zda je například stále výhodné být jeho majitelem, nebo zda příliš neztratíte a bylo by dobré ho prodat právě teď.",
  "compare_market_price": "Porovnejte ceny na trhu",
  "what_we_offer_free": "Co nabízíme zdarma, můžete samozřejmě udělat ručně. Procházejte <strong>inzeráty na internetu</strong> nebo navštivte několik <strong>autosalónů</strong>, abyste zjistili aktuální ceny nabízených vozidel.",
  "if_you_are_not_sure": "Pokud si nejste jisti, jaké informace jsou potřebné k ocenění auta, můžete kontaktovat odhadce automobilů nebo se obrátit na prodejce nebo autosalon. Ti vám také pomohou odhadnout hodnotu vašeho auta. Mějte však na paměti, že cena nabízená autosalonem nebo prodejcem bude pravděpodobně výrazně nižší. Také musí vydělat zisk tím, že od vás auto koupí. Bez ohledu na to, kterou metodu si vyberete, pamatujte, že ocenění auta je pouze přibližná cena. Konečná hodnota vozidla závisí na mnoha faktorech, jako je technický stav, výbava, servisní historie a celkový dojem, který auto zanechává na potenciálního kupce. Je důležité důkladně zkontrolovat stav auta před jeho prodejem nebo koupí. Při plánování koupě auta nezapomeňte zkontrolovat historii vozidla.",
  "regardless_of_which_method": "Bez ohledu na to, kterou metodu si vyberete, pamatujte, že ocenění auta je pouze přibližná cena. Konečná hodnota vozidla závisí na mnoha faktorech, jako je technický stav, výbava, servisní historie a celkový dojem, který auto zanechává na potenciálního kupce. Je důležité důkladně zkontrolovat stav auta před jeho prodejem nebo koupí.",
  "check_the_valuation": "Zkontrolujte historii vozidla",
  "you_be_may_interested": "Můžete mít také zájem o:",
  "check": "ZKONTROLOVAT",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Získejte základní informace o vašem vozidle zdarma",
  "get_basic_infotmation_about_vehicle_desc": "jako je značka, model, rok výroby a technické specifikace zadáním VIN do vyhledávacího pole. Pro podrobnější údaje, jako je historie nehod, počet najetých kilometrů a právní stav vozidla, nabízí stránka také možnost zakoupit úplnou zprávu o VIN.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Zkontrolujte více databází odcizených aut,",
  "stolen_cars_desc": "použitím pokročilých vyhledávacích nástrojů. Stačí zadat VIN nebo registrační číslo vozidla a systém prohledá rozsáhlou databázi, aby zjistil, zda bylo auto nahlášeno jako odcizené.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Zkontrolujte platnost VIN.",
  "vin_correct_desc": "nástroj ověřuje, zda VIN vyhovuje aktuálním standardům a obsahuje všechny potřebné informace. To zajišťuje, že VIN nebyl padělán nebo nesprávně zaznamenán.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Historie vozu od A do Z na jednom místě.",
  "easy_vin_desc": "Ověřte si své znalosti o vozidle, o které máte zájem, a ušetřete se nepříjemných překvapení při nákupu nového vozu. Zkontrolujte číslo VIN, kdykoli budete chtít. Přehledy jsou generovány 24 hodin denně, 7 dní v týdnu.",
  "find_out_the_value_of_your_car_desc": "Chcete znát hodnotu svého vozu nebo zkontrolovat hodnotu svého vozidla před jeho koupí? Využijte naše bezplatné ocenění vozu! S číslem VIN a několika kroky navíc vám můžeme poskytnout podrobnou analýzu. Je to snadné, rychlé a zdarma! Nečekejte - zkontrolujte hodnotu svého vozu hned teď!",
  "enter_te_vin_number": "Zadejte 17místné číslo VIN",
  "enter_mileage": "Poskytněte ujeté kilometry vozidla",
  "enter_regplate": "Zadejte SPZ",
  "tip": "Zajímavý fakt:",
  "odometer_readings": "Počet najetých kilometrů vozidla",
  "number_of_regplate": "Číslo SPZ",
  "valuation_incoming": "Nabídka se načítá",
  "pl_country": "Polsko",
  "en_country": "Velká Británie",
  "de_country": "Německo",
  "cs_country": "Česká republika",
  "lt_country": "Litva",
  "fr_country": "Francie",
  "es_country": "Španělsko",
  "ro_country": "Rumunsko",
  "nl_country": "Nizozemí",
  "hu_country": "Maďarsko",
  "bg_country": "Bulharsko",
  "da_country": "Dánsko",
  "el_country": "Řecko",
  "it_country": "Itálie",
  "no_country": "Norsko",
  "pt_country": "Portugalsko",
  "sk_country": "Slovensko",
  "sr_country": "Srbsko",
  "sv_country": "Švédsko",
  "tr_country": "Türkiye",
  "ar_country": "Saúdská Arábie",
  "fact_model_t": "Prvním sériově vyráběným vozem byl Ford Model T, který sjel z montážní linky v roce 1908.",
  "fact_bugatti": "Nejdražším autem na světě je Bugatti La Voiture Noire, které stálo přibližně 18 milionů dolarů.",
  "fact_v8": "Motor V8 byl vytvořen již v roce 1902 a jeho konstrukce byla pro automobilový průmysl revoluční.",
  "fact_vw_beetle": "Nejdéle vyráběným modelem vozu je Volkswagen Beetle, vyráběný nepřetržitě 65 let, od roku 1938 do roku 2003.",
  "fact_wipers": "První stěrače předního skla vynalezla Mary Anderson v roce 1903 poté, co si všimla, že řidiči musí vystoupit z auta, aby si otřeli okna.",
  "fact_lamborghini": "Lamborghini zpočátku vyrábělo tahače a teprve později začalo vyrábět luxusní sportovní vozy.",
  "fact_popular_color": "Nejoblíbenější barvou auta na světě je bílá, kterou volí až 39 % kupujících.",
  "fact_electric_car": "První elektromobil vznikl v roce 1832, tedy dávno před vynálezem vozů se spalovacími motory.",
  "fact_crank_start": "Než byl vynalezen elektrický startér, auta se startovala pomocí kliky, což bylo dost nebezpečné a mohlo to způsobit vážná zranění.",
  "fact_ssc_tuatara": "Nejvyšší rychlosti, jaké kdy sériový vůz dosáhl, dosáhl SSC Tuatara 532,93 km/h.",
  "error_message": "Došlo k vnitřní chybě. Zkuste to znovu později.",
  "no_data_alert": "Nejsou k dispozici žádné údaje o tomto vozidle."
};

