<template>
  <div class="container text-end">
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img :src="require(`@/assets/img/languages/${activeLang}.png`)" class="img-fluid" :alt="activeLang" width="24"
          height="24" />
      </button>
      <ul class="dropdown-menu">
        <li v-for="(lang, index) in countries" :key="index" class="animate__animated animate__fadeInDown">
          <button class="dropdown-item" type="button" @click="setLang(lang)">
            <img :src="require(`@/assets/img/languages/${lang}.png`)" class="img-fluid"
              :class="{ active: activeLang === lang }" :alt="lang" width="24" height="24" />
            <span class="ms-3 p-2">{{ t(`${lang}_country`) }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getCountryCode } from '../utils/config';

const store = useStore();
const router = useRouter();
const { locale, t } = useI18n();

const activeLang = ref(navigator.language.split('-')[0]);

watchEffect(() => {
  if (router.currentRoute.value.params.lang != undefined && router.currentRoute.value.params.lang != '') {
    activeLang.value = router.currentRoute.value.params.lang;
    store.commit('updateLang', activeLang.value);
  }
});

const countries = {
  pl: 'pl',
  en: 'en',
  de: 'de',
  cs: 'cs',
  lt: 'lt',
  fr: 'fr',
  es: 'es',
  ro: 'ro',
  nl: 'nl',
  hu: 'hu',
  bg: 'bg',
  da: 'da',
  el: 'el',
  it: 'it',
  no: 'no',
  pt: 'pt',
  sk: 'sk',
  sr: 'sr',
  sv: 'sv',
  tr: 'tr',
  ar: 'ar',
};

const setLang = (lang) => {
  activeLang.value = lang;
  locale.value = lang;
  store.commit('updateLang', lang);
  store.commit('updateCountryCode', getCountryCode(lang));

  router.push({ path: `/${lang}`, query: router.currentRoute.value.query }, { scroll: false });
};

onMounted(async () => {
  // await store.dispatch('SET_LANG');
  // const lang = store.state.lang;
  // activeLang.value = lang;
});
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.active {
  transform: scale(1.1);
}

ul {
  background-color: #526D82;
  margin: 0;
  padding: 0;
}

/* ul>li>button:hover {
  background-color: #526D82;
  opacity: .7;
} */


ul>li>button {
  background-color: #273B4D;
  /* Kolor domyślny */
  color: white;
  padding: 10px 20px;
  border: none;
  outline: none;
  /* border-radius: 8px; */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

ul>li>button:hover {
  background-color: #526D82;
  /* Zmieniony kolor przy hover */
  opacity: 0.9;
  box-shadow: 0 0 20px rgba(82, 109, 130, 0.6), 0 0 40px rgba(82, 109, 130, 0.4), 0 0 60px rgba(82, 109, 130, 0.3);
}

ul>li>button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(82, 109, 130, 0.4), transparent 70%);
  transition: width 0.4s ease, height 0.4s ease, top 0.4s ease, left 0.4s ease;
  z-index: -1;
  transform: translate(-50%, -50%);
}

ul>li>button:hover::before {
  width: 400%;
  height: 400%;
  background: radial-gradient(circle, rgba(82, 109, 130, 0.5), transparent 80%);
  top: 50%;
  left: 50%;
}
</style>
