import { ref } from "vue";
import axios from "axios";

export default function reqCarValuation() {
  const responseValuation = ref({});

  const sendReqValuation = async (data) => {
    try {

      responseValuation.value = await axios.post("/api/getValuation", {
        ...data
      });

      if (responseValuation.value.data.status != "error") {
        return true;
      } else {
        return {
          status: 'error',
          message: 'Request failed',
        };
      }
    } catch (error) {
      console.error(error);
    }
    return {
      status: 'error',
      message: 'Request failed',
    };
  };

  return {
    sendReqValuation,
    responseValuation,
  };
}
