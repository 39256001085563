export const pt = {
  "fast_and_free": "RÁPIDO E GRÁTIS",
  "vehicle_valuation": "VALORAÇÃO DE VEÍCULO",
  "valuation": "VALORAÇÃO",
  "vin": "VIN",
  "want_to_know_valuation": "Você quer saber o valor do seu carro ou verificar o valor de um veículo antes de comprá-lo? Use nossa valoração de veículo gratuita! Basta inserir o VIN para obter uma valoração precisa e confiável. Com alguns passos adicionais, podemos fornecer uma análise ainda mais detalhada. É simples, rápido e grátis! Não espere – verifique o valor do seu carro agora!",
  "plate": "Número de registro",
  "mileage": "Quilometragem",
  "accord_mileage_desc": "Veículos com baixa quilometragem geralmente estão menos desgastados, por isso costumam alcançar preços mais altos no mercado de usados.",
  "damage_history": "Histórico de acidentes",
  "damage_history_desc": "Carros que estiveram envolvidos em acidentes geralmente têm um valor menor devido a possíveis danos estruturais.",
  "count_of_owners": "Número de proprietários anteriores",
  "count_of_owners_desc": "Veículos que tiveram menos proprietários podem ser avaliados mais altos porque geralmente são melhor mantidos.",
  "body_color": "Cor da carroceria",
  "body_color_desc": "Cores populares podem aumentar o valor do veículo, enquanto cores incomuns podem diminuí-lo.",
  "technical_condition": "Condição técnica",
  "technical_condition_desc": "A condição mecânica e técnica do veículo, incluindo a condição do motor, transmissão e outros componentes, tem um impacto significativo no preço.",
  "fuel_type": "Tipo de combustível",
  "fuel_type_desc": "Tendências atuais e preferências de mercado podem tornar certos tipos de motores mais desejáveis do que outros.",
  "vehicle_equipment": "Equipamentos do carro",
  "vehicle_equipment_desc": "Recursos adicionais, como sistemas de navegação, interiores de couro, sistemas de áudio de alta qualidade, podem aumentar o valor do veículo.",
  "current_market_prices": "Preços de mercado atuais",
  "current_market_prices_desc": "Os preços de mercado flutuam com base na demanda e oferta para um determinado modelo de veículo, afetando sua valoração final.",
  "how_to_valuate_vehicle_before_sell": "Como avaliar um veículo antes de comprar/vender?",
  "prod_year": "Ano de produção",
  "first_reg_date": "Data do primeiro registro",
  "contact": "Contato",
  "privacy_policy": "Política de privacidade",
  "regulations": "Termos e condições",
  "valuation_desc": "A valoração do veículo foi preparada com base na ampla experiência de nossos especialistas do mercado automotivo. O procedimento de valoração utiliza inteligência artificial (IA) para comparar as tendências em mudança nos preços de veículos nos mercados europeu e americano. A valoração leva em conta parâmetros como a idade e quilometragem do veículo. Nossa valoração também considera as questões automotivas e sociais mais recentes, como a falta de peças ou componentes e a inflação, que afetam os preços dos veículos usados.",
  "min_price": "Preço mínimo",
  "med_price": "Preço médio",
  "max_price": "Preço máximo",
  "we_have_report_for_you": "Temos um relatório para este veículo!",
  "incident_history": "Histórico de incidentes",
  "odometer_reading": "Leitura de quilometragem",
  "vehicles_inspection": "Inspeções de veículos",
  "archive_photos": "Fotos de arquivo",
  "check_now": "VER RELATÓRIO",
  "unfortunately_not_found_desc": "Infelizmente, não conseguimos encontrar o ano de produção deste veículo, por favor insira-o no campo acima.",
  "type_odometer": "Insira a quilometragem atual do veículo em quilômetros para uma melhor estimativa de seu valor.",
  "check_another_vin": "Avaliar outro veículo",
  "what_can_affet_the_price_of_vehicle": "O que pode afetar o preço do seu veículo?",
  "calcualtor_of_vehicle": "Calculadora de avaliação de carros online",
  "type_plate": "Insira o número de registro do veículo.",
  "type_first_reg_date": "Insira a data do primeiro registro do veículo. Você pode encontrá-la no documento de registro no campo B.",
  "price_odometer": "Preço",
  "price_year": "Preço",
  "year": "Ano",
  "regulations_admission": "<p>Este Regulamento define as regras para o uso do site operado sob o nome VinValuation no domínio da internet www.vinValuation.com (doravante referido como o Site). Este Regulamento contém as regras para o uso do Site. Se você não concorda com a aplicação deste Regulamento ou qualquer uma de suas disposições, deve imediatamente sair deste Site.</p><p>O nome completo da nossa empresa é Car Data Experts LTD. Unidade 4E enterprise court, Farfield park, Rotherham S63 5DB. Número da empresa na câmara de comércio: 10327199. Você pode nos contatar por e-mail em: <a href=mailto:{email}>{email}</a></p><p>O principal objetivo do Site é fornecer aos seus usuários a capacidade de inserir dados sobre veículos roubados ou danificados no banco de dados Car Data (Dados do Veículo) e compartilhar esses dados com outras pessoas que utilizam esse banco de dados.</p><p>Os serviços disponíveis no Site são destinados a indivíduos, entidades jurídicas e outras entidades que tenham residência ou escritório registrado na União Europeia ou nos Estados Unidos da América.</p><p>Os serviços no Site são direcionados exclusivamente a indivíduos que tenham pelo menos 18 anos de idade.</p><p>As regras para o processamento de dados pessoais e o uso de arquivos chamados cookies estão apresentadas em nossa Política de Privacidade disponível no Site.</p><p>Para usar o Site, o usuário deve utilizar um dispositivo com acesso à internet e um navegador web Internet Explorer 9.0 ou um programa similar. Para usar algumas funcionalidades do Site, é necessário utilizar software Java e Java Script, bem como permitir o uso de cookies. Não somos responsáveis pela falta de acesso aos dados postados em nosso Site devido ao dispositivo do usuário não atender aos requisitos técnicos especificados acima.</p>',",
  "regulations_definition": "<p>Neste Regulamento, os termos definidos abaixo terão os seguintes significados:</p><p>Dados do Veículo significam dados sobre veículos roubados ou danificados e as circunstâncias do roubo ou dano criados por usuários do Site no banco de dados de Dados de Carros e disponibilizados a outras pessoas que utilizam este banco de dados.</p><p>Consumidor significa qualquer pessoa física agindo para fins não relacionados a atividades comerciais ou profissionais.</p><p>Banco de Dados significa nosso banco de dados de Dados de Carros sobre a condição técnica e a história dos veículos.</p><p>Número VIN significa o número de identificação do veículo (Vehicle Identification Number), usado pela indústria automotiva para identificar uma instância específica de um veículo motorizado, trator, motocicleta, scooter e ciclomotor, conforme definido na norma ISO 3833.</p>',",
  "regulations_license_desc": "<p>Salvo disposições em contrário, todos os direitos de propriedade intelectual sobre o Site e sobre os materiais contidos no Site são de nossa propriedade ou de nossos licenciadores. Sujeito à licença abaixo, todos os direitos de propriedade intelectual estão reservados.</p> <p>Os usuários podem visualizar, baixar arquivos para cache, exclusivamente para seu próprio uso, sujeito às exceções indicadas abaixo e em outras partes dos Termos de Serviço.</p><p>As seguintes ações são particularmente proibidas:</p><p>a. distribuição de materiais obtidos no Site (incluindo distribuição em outro site);</p><p>b. venda, aluguel, empréstimo, doação, transferência ou concessão de sublicenças relacionadas ao Site e materiais localizados no Site;</p><p>c. apresentação pública de quaisquer materiais disponíveis no Site;</p><p>d. reproduzir, duplicar, copiar ou de outra forma usar materiais coletados no Site para fins comerciais;</p><p>e. alterar ou modificar de outra forma quaisquer materiais localizados no Site;</p><p>f. distribuir materiais obtidos no Site sem obter nosso consentimento por escrito explícito para o acima.</p>",
  "regulations_use_page": "<p>1. É proibido usar o Site de qualquer maneira que seja prejudicial a ele ou que afete negativamente a capacidade de usar o Site; ou de qualquer outra forma que seja ilegal, ilícita, prejudicial ou relacionada a qualquer propósito ou ação ilegal, ilícita ou prejudicial. Reservamos todos os direitos sobre todas as informações e materiais, incluindo gráficos e outros, disponíveis no Site.</p><p>2. É proibido usar o Site para copiar, usar, armazenar, transmitir, enviar, publicar ou distribuir quaisquer materiais que contenham spyware, vírus, cavalos de Tróia, worms, registradores de teclas, rootkits ou outros softwares ou dispositivos prejudiciais, ou referir-se a eles.</p><p>3. É proibida qualquer coleta sistemática ou automatizada de dados do Site ou dados relacionados ao Site (incluindo o uso das seguintes técnicas: scraping, mineração de dados, extração de dados e coleta de dados) sem nosso consentimento por escrito.</p><p>4. É proibido usar o Site para transmitir ou enviar informações comerciais não solicitadas.</p><p>5. É proibido usar o Site para quaisquer fins de marketing sem nosso consentimento por escrito.</p>",
  "regulations_limited_access": "<p>1. O acesso a certos recursos do Site é restrito. Reservamos o direito de limitar o acesso a outros recursos do Site ou de restringir o acesso ao Site inteiro, sujeito aos direitos dos usuários decorrentes de acordos previamente celebrados.</p><p>2. No caso de o usuário obter um nome de usuário ou um nome de usuário e senha de nossa parte que permita acesso a recursos restritos no Site ou a outros conteúdos ou serviços com acesso restrito, o usuário é obrigado a proteger a senha e o nome de usuário para que não sejam divulgados a terceiros.</p>",
  "regulations_user_posted": "<p>1. O conteúdo gerado pelo usuário não deve ser ilegal, violar princípios de convivência social, Netiqueta ou outros costumes estabelecidos, nem infringir direitos de terceiros, nem dar origem a quaisquer reivindicações ou ações legais contra o usuário, nós ou qualquer terceiro (em qualquer caso, sob qualquer lei aplicável).</p><p>2. É proibido postar qualquer conteúdo no Site que seja ou tenha sido objeto de qualquer litígio em andamento ou ameaçado ou procedimentos semelhantes.</p><p>3. Reservamo-nos o direito de modificar ou remover qualquer conteúdo ou material postado no Site, armazenado ou publicado lá.</p><p>4. Sujeito às disposições destes Termos e à legislação aplicável em relação ao conteúdo e outros materiais postados no Site pelo usuário, não somos obrigados a monitorar o conteúdo e os materiais postados ou publicados no Site.</p><p>5. Quaisquer dados postados pelo usuário no Site também devem ser mantidos em seus próprios dispositivos para proteção adicional contra perda.</p>",
  "regulations_placing_data_in_db": "<p>1. O usuário fornece Dados do Veículo na Base de Dados usando o formulário postado no Site.</p><p>2. O usuário é responsável pela precisão dos Dados do Veículo em relação ao estado atual das coisas. Em caso de quaisquer alterações, o usuário deve atualizar prontamente os Dados do Veículo fornecidos na Base de Dados.</p><p>3. Após enviar os Dados do Veículo para a Base de Dados, o usuário recebe um e-mail de confirmação no endereço que forneceu, confirmando a colocação desses dados na Base de Dados.</p><p>4. Os Dados do Veículo são colocados na Base de Dados e disponibilizados a outras entidades que utilizam essa base de dados, a fim de obter informações sobre um veículo específico identificado pelo seu número VIN.</p><p>5. Se novas informações sobre os Dados do Veículo aparecerem na Base de Dados, tentaremos informar o usuário que enviou os Dados do Veículo através do endereço de e-mail fornecido.</p><p>6. Os Dados do Veículo podem ser retirados da Base de Dados entrando em contato conosco através do formulário de contato no Site ou escrevendo para o endereço de e-mail fornecido na Introdução aos Termos e Condições.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Para evitar dúvidas, com base no contrato de usuário para fornecimento de Dados do Veículo no Banco de Dados, não somos obrigados a localizar o veículo ao qual os Dados do Veículo se referem.</p><p>2. No caso de usuários que não são Consumidores, na medida permitida pela legislação aplicável, não fazemos declarações, não fornecemos garantias quanto à qualidade, excluímos responsabilidade e não fazemos declarações sobre os padrões de serviço fornecidos no Site e o uso do Site (em particular, excluímos todas as garantias, não fazemos declarações fornecidas pela legislação aplicável sobre qualidade satisfatória, adequação para o propósito ou o exercício de devida diligência e habilidade).</p><p>3. No caso de notar problemas técnicos no Site ou notar informações incompletas ou desatualizadas fornecidas por nós, bem como quaisquer reclamações relacionadas ao Site (reclamações), você deve nos notificar prontamente usando o formulário na página de Contato ou escrevendo para nós no endereço de e-mail indicado acima na Introdução aos Termos e Condições. Nos esforçamos para responder prontamente.</p>',",
  "regulations_liability": "<p>1. Nenhuma disposição nestes Termos (a) exclui ou limita nossa responsabilidade por danos causados por culpa intencional ou má-fé intencional; (b) limita nossa ou a responsabilidade do usuário de qualquer forma que contrarie a lei aplicável; ou (c) exclui nossa ou a responsabilidade do usuário se tal responsabilidade não puder ser excluída sob a lei aplicável; em particular, os Termos não excluem nossa ou a responsabilidade do usuário por danos causados por culpa intencional.</p><p>2. As exclusões e limitações de responsabilidade previstas nos Termos:</p><p> a. estão sujeitas às limitações especificadas no parágrafo 1 acima;</p><p> b. referem-se a qualquer responsabilidade que possa surgir sob os Termos ou Política de Privacidade em conexão com o objeto dos Termos e Política de Privacidade, incluindo responsabilidade contratual, extracontratual ou similar;</p><p> c. não se aplicam a Consumidores.</p><p>3. No caso de usuários não Consumidores, na medida permitida pela lei aplicável:</p><p> a. não somos responsáveis por quaisquer danos de qualquer tipo, a menos que o dano tenha sido causado por nossa culpa intencional. Garantia e qualquer responsabilidade decorrente de instituições similares à garantia estão aqui excluídas;</p><p> b. não somos responsáveis por qualquer perda ou dano a quaisquer dados, bancos de dados ou software, sejam nossos ou do usuário.</p><p>4. Não somos responsáveis por quaisquer danos decorrentes de um evento ou eventos além do nosso controle.</p>",
  "regulations_relief": "<p>Usuários que não são Consumidores nos indenizam contra quaisquer perdas, danos, compensações, custos, responsabilidades e despesas (incluindo custos relacionados a quaisquer processos e quaisquer quantias pagas por nós a terceiros como um acordo) relacionadas a qualquer violação por parte do usuário destes Termos e Condições, e são obrigados a nos reembolsar pelos custos incorridos por nós conforme mencionado acima.</p>",
  "regulations_violations": "<p>Sujeito a todos os nossos outros direitos decorrentes destes Termos, no caso de uma violação destes Termos pelo usuário de qualquer forma, podemos tomar as ações que considerarmos apropriadas para responder à violação, o que pode incluir suspender o acesso do usuário ao Site, impedir o usuário de acessar o Site, bloquear o acesso ao Site para um endereço IP específico, contatar o provedor de Internet do usuário para bloquear o acesso ao Site, ou tomar medidas legais.</p>",
  "regulations_changing_provisions_regulations": "<p>1. As regulamentações podem ser alteradas ao colocar sua nova versão no Site. Se o usuário nos fornecer seus dados pessoais, em particular seu endereço de e-mail, e se nós prestarmos serviços a eles durante a alteração dessas Regulamentações, iremos informá-los sobre quaisquer mudanças nessas Regulamentações por e-mail.</p><p>2. No caso de usuários que não são Consumidores, uma mudança em nossas informações de contato não constitui uma alteração dessas Regulamentações.</p><p>3. No caso de alteração do endereço de e-mail fornecido durante o registro, os usuários são obrigados a atualizar esse endereço no Site.</p>",
  "regulations_transfer": "<p>Os usuários não podem transferir, compartilhar com outros ou de outra forma dispor de seus direitos e obrigações decorrentes dessas Regulamentações, nem incorrer em obrigações relacionadas a esses direitos e obrigações.</p>",
  "regulations_severability_clause": "<p>Se qualquer disposição dessas Regulamentações for considerada inválida ou ineficaz por um tribunal ou outra autoridade competente, as disposições restantes das Regulamentações permanecerão vinculativas. Se uma disposição inválida ou ineficaz se tornaria válida após a remoção de sua parte, essa parte será considerada removida, e a parte restante da disposição será considerada válida.</p>",
  "regulations_exclusion_parts": "<p>As Regulamentações definem exclusivamente a relação entre nós e os usuários do Site e não conferem direitos a terceiros, nem os autorizam a invocar qualquer uma de suas disposições ou exigir a aplicação dessas disposições. A aplicação das disposições das Regulamentações não requer o consentimento de qualquer terceiro.</p>",
  "regulations_scope_contract": "<p>Esta Regulamentação, juntamente com nossa Política de Privacidade e os preços dos serviços indicados no Site, constitui um acordo entre nós e o usuário sobre o uso do Site e substitui quaisquer regulamentações anteriores relacionadas ao uso do Site.</p>",
  "regulations_governing_law": "<p>1. Este Regulamento está sujeito à lei polonesa e, em questões não regulamentadas pelo Regulamento, as disposições da Lei de 23 de abril de 1964, do Código Civil, da Lei de 30 de maio de 2014, sobre direitos do consumidor (em relação aos Consumidores) e da Lei de 18 de julho de 2002, sobre a prestação de serviços eletrônicos se aplicarão.</p><p>2. No caso de usuários que não são Consumidores, quaisquer disputas decorrentes do Regulamento estarão sujeitas à jurisdição exclusiva dos tribunais poloneses.</p><p>3. No caso de usuários que são Consumidores, cujo local de residência está localizado na Polônia no momento da celebração do contrato para a prestação de Dados do Veículo, quaisquer disputas relacionadas a este Regulamento ou à Política de Privacidade estarão sujeitas à jurisdição exclusiva dos tribunais poloneses.</p>",
  "back": "Retornar",
  "definition": "Definições",
  "admission": "Introdução",
  "license_use_page": "Licença para usar o Site e os materiais disponíveis nele",
  "use_page": "Uso do Site",
  "limited_access": "Acesso limitado",
  "user_posted_content": "Conteúdo postado pelo Usuário",
  "placing_vehicle_data_in_db": "Inserindo Dados do Veículo no Banco de Dados",
  "disclaimer_of_liability": "Isenção de Responsabilidade",
  "relief_from_obligations": "Liberação de Obrigações",
  "violations_of_regulations": "Violação dos Regulamentos",
  "transfer": "Cessão",
  "severability_clause": "Cláusula Salvatória",
  "exclusion_third_parts": "Exclusão de Direitos de Terceiros",
  "scope_of_contract": "Escopo do Acordo",
  "changing_of_provisions_regulations": "Mudança das Disposições dos Regulamentos",
  "governing_law_and_jurisdiction": "Lei Aplicável e Jurisdição",
  "limited_and_warranties_complaints": "Representações e Garantias Limitadas e Registro de Reclamações",
  "main_tip": "Use nosso site para avaliar carros online usando o número VIN. Desta forma, você descobrirá rápida e precisamente quanto vale seu carro. Basta inserir o número VIN do seu carro e nós o ajudaremos a avaliar seu valor. Não espere, verifique agora!",
  "enter_vin_number": "Inserir VIN",
  "meta_title": "Calculadora de Avaliação de Carros Online - avalie seu carro rápida e precisamente.",
  "meta_desc": "Avaliação de carro online, avalie rápida e precisamente o valor do seu carro. Nossa calculadora fornecerá o preço para o ano do modelo do veículo verificado e para modelos ligeiramente mais antigos e mais novos para comparação.",
  "h1_title": "A avaliação de carro online requer apenas o VIN",
  "check_valuation": "Verifique a avaliação!",
  "next": "Próximo",
  "help": "Ajuda",
  "no_data_valuation": "Sem dados para avaliação",
  "check_if_stolen": "Verifique se foi roubado",
  "decode_vin_number": "Decodificar número VIN",
  "additional_links": "Links adicionais",
  "check_vin_correct": "Verifique a validade do número VIN",
  "how_to_vin_valuation": "Como avaliar um veículo antes de comprar/vender?",
  "calculator_valuation": "Calculadora de avaliação de carro online",
  "calculator_valuation_desc": "Existem muitos métodos para estimar o valor de um carro; o mais rápido e relativamente preciso é verificá-lo em um site como vinvaluation. Nosso algoritmo proprietário, suportado por inteligência artificial (IA), agrega dados de milhares de anúncios online, analisando precisamente a idade, marca, equipamentos e condição dos veículos oferecidos. Com base nisso, estimamos quanto o carro que você está verificando deve valer. Você recebe a avaliação em poucos momentos sem sair de casa, completamente grátis. Precisamos apenas de alguns parâmetros, como o número VIN ou a data de registro do veículo. Em resposta, você receberá não apenas a avaliação do ano que está verificando, mas também de vários modelos próximos, mais novos e mais antigos. Isso lhe dá uma visão completa sobre se, por exemplo, ainda vale a pena ser seu proprietário ou se você não perderá muito e seria melhor vendê-lo agora.",
  "you_get_valuation_for_free": "<strong>Você recebe a avaliação</strong> em poucos momentos sem sair de casa completamente grátis. Precisamos apenas de alguns parâmetros, como o número VIN ou a data de registro do veículo.",
  "in_response_you_receive_not_only_valuation": "Em resposta, você receberá não apenas a avaliação do ano que está verificando, mas também vários modelos próximos, mais novos e mais antigos. Isso lhe dá uma visão completa sobre se, por exemplo, ainda vale a pena ser seu proprietário ou se você não perderá muito e seria bom vendê-lo agora.",
  "compare_market_price": "Compare preços no mercado",
  "what_we_offer_free": "O que oferecemos gratuitamente você pode, claro, fazer manualmente. Navegue por <strong>anúncios na internet</strong> ou visite algumas <strong>concessionárias</strong> para descobrir os preços atuais dos veículos oferecidos.",
  "if_you_are_not_sure": "Se você não tiver certeza sobre quais informações são necessárias para avaliar um carro, pode entrar em contato com um avaliador de carros ou procurar um revendedor ou concessionária de automóveis. Eles também poderão ajudá-lo a estimar o valor do seu carro. No entanto, tenha em mente que o preço oferecido pela concessionária ou revendedor provavelmente será significativamente mais baixo. Eles também precisam lucrar com a compra do carro de você. Independentemente do método que você escolher, lembre-se de que a avaliação do carro é apenas um preço aproximado. O valor final do veículo depende de muitos fatores, como condição técnica, equipamentos, histórico de serviços e a impressão geral que o carro causa em um potencial comprador. É importante verificar minuciosamente a condição do carro antes de vendê-lo ou comprá-lo. Ao planejar comprar um carro, lembre-se de verificar o histórico do veículo.",
  "regardless_of_which_method": "Independentemente do método que você escolher, lembre-se de que a avaliação do carro é apenas um preço aproximado. O valor final do veículo depende de muitos fatores, como condição técnica, equipamentos, histórico de serviços e a impressão geral que o carro causa em um potencial comprador. É importante verificar minuciosamente a condição do carro antes de vendê-lo ou comprá-lo.",
  "check_the_valuation": "Verifique o histórico do veículo",
  "you_be_may_interested": "Você também pode estar interessado em:",
  "check": "VERIFICAR",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Obtenha informações básicas sobre seu veículo gratuitamente",
  "get_basic_infotmation_about_vehicle_desc": "como marca, modelo, ano de fabricação e especificações técnicas, inserindo o VIN no campo de busca. Para dados mais detalhados, como histórico de acidentes, quilometragem e situação legal do veículo, o site também oferece a opção de comprar um relatório completo do VIN.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Verifique múltiplos bancos de dados de carros roubados,",
  "stolen_cars_desc": "usando ferramentas de busca avançadas. Basta inserir o VIN ou número de registro do veículo, e o sistema pesquisará um vasto banco de dados para verificar se o carro foi relatado como roubado.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Verifique a validade do VIN.",
  "vin_correct_desc": "a ferramenta verifica se o VIN está em conformidade com os padrões atuais e contém todas as informações necessárias. Isso garante que o VIN não foi falsificado ou registrado incorretamente.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "A história do carro de A a Z em um só lugar.",
  "easy_vin_desc": "Verifique seu conhecimento sobre o veículo de seu interesse e evite surpresas desagradáveis na hora de comprar um carro novo. Verifique o número VIN sempre que quiser. Os relatórios são gerados 24 horas por dia, 7 dias por semana.",
  "find_out_the_value_of_your_car_desc": "Quer saber o valor do seu carro ou verificar o valor do seu veículo antes de comprá-lo? Aproveite a nossa avaliação gratuita de veículos! Com um número VIN e alguns passos extras, podemos fornecer uma análise detalhada. É fácil, rápido e grátis! Não espere - verifique já o valor do seu carro!",
  "enter_te_vin_number": "Insira o número VIN de 17 dígitos",
  "enter_mileage": "Fornecer quilometragem do veículo",
  "enter_regplate": "Insira o número da placa",
  "tip": "Curiosidade:",
  "odometer_readings": "Quilometragem do veículo",
  "number_of_regplate": "Número da placa do veículo",
  "valuation_incoming": "A citação está carregando",
  "pl_country": "Polônia",
  "en_country": "Grã-Bretanha",
  "de_country": "Alemanha",
  "cs_country": "A República Checa",
  "lt_country": "Lituânia",
  "fr_country": "França",
  "es_country": "Espanha",
  "ro_country": "Romênia",
  "nl_country": "Holanda",
  "hu_country": "Hungria",
  "bg_country": "Bulgária",
  "da_country": "Dinamarca",
  "el_country": "Grécia",
  "it_country": "Itália",
  "no_country": "Noruega",
  "pt_country": "Portugal",
  "sk_country": "Eslováquia",
  "sr_country": "Sérvia",
  "sv_country": "Suécia",
  "tr_country": "Turquia",
  "ar_country": "Arábia Saudita",
  "fact_model_t": "O primeiro carro produzido em massa foi o Ford Modelo T, que saiu da linha de montagem em 1908.",
  "fact_bugatti": "O carro mais caro do mundo é o Bugatti La Voiture Noire, que custou aproximadamente US$ 18 milhões.",
  "fact_v8": "O motor V8 foi criado em 1902 e seu design foi revolucionário para a indústria automotiva.",
  "fact_vw_beetle": "O modelo de carro mais antigo produzido é o Fusca, produzido continuamente por 65 anos, de 1938 a 2003.",
  "fact_wipers": "Os primeiros limpadores de para-brisa de carro foram inventados por Mary Anderson em 1903, depois que ela percebeu que os motoristas tinham que sair dos carros para limpar os vidros.",
  "fact_lamborghini": "A Lamborghini inicialmente produzia tratores e só mais tarde começou a produzir carros esportivos de luxo.",
  "fact_popular_color": "A cor de carro mais popular no mundo é o branco, escolhida por até 39% dos compradores.",
  "fact_electric_car": "O primeiro carro elétrico foi criado em 1832, muito antes da invenção dos carros com motores de combustão interna.",
  "fact_crank_start": "Antes da invenção da partida elétrica, os carros eram ligados por meio de uma manivela, o que era muito perigoso e podia causar ferimentos graves.",
  "fact_ssc_tuatara": "A velocidade mais rápida já alcançada por um carro de produção foi alcançada pelo SSC Tuatara, com 532,93 km/h.",
  "error_message": "Ocorreu um erro interno. Tente novamente mais tarde.",
  "no_data_alert": "Não há dados disponíveis para este veículo.",
};

