export const ro = {
  "fast_and_free": "RAPID ȘI GRATUIT",
  "vehicle_valuation": "EVALUAREA VEHICULULUI",
  "valuation": "EVALUARE",
  "vin": "VIN",
  "want_to_know_valuation": "Doriți să aflați valoarea mașinii dumneavoastră sau să verificați valoarea vehiculului înainte de cumpărare? Folosiți evaluarea noastră gratuită a vehiculului! Pur și simplu introduceți VIN pentru a obține o evaluare precisă și de încredere. Cu câțiva pași suplimentari, putem oferi o analiză și mai detaliată. Este simplu, rapid și gratuit! Nu așteptați – verificați valoarea mașinii dumneavoastră acum!",
  "plate": "Număr de înmatriculare",
  "mileage": "Kilometraj",
  "accord_mileage_desc": "Vehiculele cu un kilometraj redus sunt, de obicei, mai puțin uzate, ceea ce le face să atingă prețuri mai mari pe piața mașinilor second-hand.",
  "damage_history": "Istoricul daunelor",
  "damage_history_desc": "Vehiculele care au fost implicate în accidente au de obicei o valoare mai mică din cauza posibilelor daune structurale.",
  "count_of_owners": "Număr de proprietari anteriori",
  "count_of_owners_desc": "Vehiculele care au avut mai puțini proprietari pot fi evaluate mai mult, deoarece de obicei au fost mai bine întreținute.",
  "body_color": "Culoarea caroseriei",
  "body_color_desc": "Culorile populare pot crește valoarea vehiculului, în timp ce culorile neobișnuite o pot scădea.",
  "technical_condition": "Starea tehnică",
  "technical_condition_desc": "Starea mecanică și tehnică a vehiculului, inclusiv motorul, transmisia și alte componente, influențează semnificativ prețul.",
  "fuel_type": "Tipul de combustibil",
  "fuel_type_desc": "Tendințele actuale și preferințele pieței pot face anumite tipuri de motoare mai dezirabile decât altele.",
  "vehicle_equipment": "Dotările vehiculului",
  "vehicle_equipment_desc": "Funcțiile suplimentare, cum ar fi sistemele de navigație, interioarele din piele și sistemele audio de înaltă calitate, pot crește valoarea vehiculului.",
  "current_market_prices": "Prețurile actuale de pe piață",
  "current_market_prices_desc": "Prețurile de pe piață variază în funcție de cererea și oferta pentru un anumit model de vehicul, ceea ce influențează evaluarea finală.",
  "how_to_valuate_vehicle_before_sell": "Cum să evaluezi un vehicul între cumpărare/vânzare?",
  "prod_year": "Anul de producție",
  "first_reg_date": "Data primei înmatriculări",
  "contact": "Contact",
  "privacy_policy": "Politica de confidențialitate",
  "regulations": "Termeni și condiții",
  "valuation_desc": "Evaluarea vehiculului a fost pregătită pe baza experienței vaste a experților noștri în piața auto. Procedura de evaluare utilizează inteligența artificială (AI) pentru a compara tendințele în schimbare ale prețurilor vehiculelor de pe piețele europene și americane. Evaluarea ia în considerare parametri precum vârsta și kilometrajul vehiculului. De asemenea, evaluarea noastră ține cont de ultimele probleme auto și sociale, cum ar fi lipsa pieselor de schimb sau componentelor și inflația, care influențează prețurile mașinilor second-hand.",
  "min_price": "Preț minim",
  "med_price": "Preț mediu",
  "max_price": "Preț maxim",
  "we_have_report_for_you": "Avem un raport pentru acest vehicul!",
  "incident_history": "Istoricul incidentelor",
  "odometer_reading": "Citirea kilometrajului",
  "vehicles_inspection": "Inspecția vehiculului",
  "archive_photos": "Fotografii din arhivă",
  "check_now": "VEZI RAPORTUL",
  "unfortunately_not_found_desc": "Din păcate, nu am putut găsi anul de fabricație al acestui vehicul, vă rugăm să îl introduceți în câmpul de mai sus.",
  "check_another_vin": "Evaluează alt vehicul",
  "what_can_affet_the_price_of_vehicle": "Ce poate afecta prețul mașinii tale?",
  "calcualtor_of_vehicle": "Calculator online de evaluare a vehiculelor",
  "type_plate": "Introduceți numărul de înmatriculare al vehiculului.",
  "type_first_reg_date": "Introduceți data primei înmatriculări a vehiculului. O puteți găsi în documentul de înmatriculare, în câmpul B.",
  "price_odometer": "Preț",
  "price_year": "Preț",
  "year": "An",
  "enter_te_vin_number": "Introduceți numărul VIN din 17 cifre",
  "enter_mileage": "Furnizați kilometrajul vehiculului",
  "enter_regplate": "Introduceți numărul plăcuței de înmatriculare",
  "odometer_readings": "Kilometrajul vehiculului",
  "number_of_regplate": "Numărul de înmatriculare",
  "type_odometer": "Introduceți kilometrajul actual al vehiculului pentru o evaluare mai precisă a valorii sale.",
  "check": "VERIFICAȚI",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Obțineți informații de bază despre vehiculul dumneavoastră gratuit",
  "get_basic_infotmation_about_vehicle_desc": "cum ar fi marca, modelul, anul fabricației și specificațiile tehnice, introducând VIN-ul în câmpul de căutare. Pentru date mai detaliate, cum ar fi istoricul accidentelor, kilometrajul și statutul juridic al vehiculului, site-ul oferă și posibilitatea achiziționării unui raport complet VIN.",
  "stolen_cars": "StolenCars",
  "stolen_cars_title": "Verificați mai multe baze de date cu vehicule furate,",
  "stolen_cars_desc": "folosind instrumente moderne de căutare. Pur și simplu introduceți VIN-ul sau numărul de înmatriculare al vehiculului, iar sistemul va căuta într-o bază de date extinsă pentru a verifica dacă vehiculul a fost declarat furat.",
  "vin_correct": "VINCorrect",
  "vin_correct_title": "Verificați validitatea VIN-ului.",
  "vin_correct_desc": "instrumentul verifică dacă VIN-ul întrebuințează standardele curente și dacă conține toate informațiile necesare. Acest lucru asigură că VIN-ul nu a fost falsificat sau înregistrat greșit.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "Istoria mașinii de la A la Z într-un singur loc.",
  "easy_vin_desc": "Verificați-vă cunoștințele despre vehiculul care vă interesează și feriți-vă de surprize neplăcute atunci când cumpărați o mașină nouă. Verificați numărul VIN oricând doriți. Rapoartele sunt generate 24 de ore pe zi, 7 zile pe săptămână.",
  "no_data_alert": "Nu există date pentru acest vehicul.",
}
