<template>
    <div>
        <header>
            <div class="container-fluid header-section d-flex align-items-center justify-content-center w-80">
                <div class="row">
                    <div
                        class="col-xl-6 col-md-12 d-flex flex-column justify-content-center align-items-center align-items-lg-start p-5">
                        <h1 class="text-center text-lg-start">{{ t('fast_and_free') }}</h1>
                        <h1 class="text-center text-lg-start">{{ t(('vehicle_valuation')) }}</h1>
                        <p class="text-center text-lg-start">{{ t('find_out_the_value_of_your_car_desc') }}</p>
                    </div>
                    <div
                        class="col-xl-6 col-md-12 d-flex flex-column align-items-center justify-content-center form-container">
                        <FormComponent />
                    </div>
                </div>
            </div>
        </header>

        <AccordionComponent :class="['animate__animated', isVisible ? 'animate__fadeInUp' : '']" />
        <div ref="sectionRef" :class="['slide-up', { 'visible': isVisiblePriceSection }]">
            <HowToPriceVehicleComponent />
        </div>
        
        <PartnersComponent />

        <div class="row mt-5">
            <div class="col-12 d-flex justify-content-center">
                <img class="img-fluid picture" :src="require('@/assets/img/picture2.webp')" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import FormComponent from '@/components/form/FormComponent.vue';

import AccordionComponent from '@/components/AccordionComponent.vue';
import HowToPriceVehicleComponent from '@/components/HowToPriceVehicleComponent.vue';
import PartnersComponent from '@/components/PartnersComponent.vue';

import { useIntersectionObserver } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';

const sectionRef = ref(null);
const isVisiblePriceSection = ref(false)

const { t } = useI18n();

const isVisible = ref(false);

onMounted(() => {
    isVisible.value = true;

    useIntersectionObserver(
        sectionRef,
        ([{ isIntersecting }]) => {
            if (isIntersecting) {
                isVisiblePriceSection.value = true
            }
        },
    )
})
</script>

<style scoped>
.fade-in {
    opacity: 0;
    transition: opacity 0.8s ease-in;
}

.fade-in.visible {
    opacity: 1;
}

.slide-up {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-up.visible {
    opacity: 1;
    transform: translateY(0);
}

.container-fluid {
    background-color: #222831;
}

.header-section {
    border-radius: 30px;
    min-height: 500px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.header-section::before {
    content: '';
    background-image: url('@/assets/img/img_hd.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.picture {
    width: 100%;
    max-width: 1000px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 30px;
}

@media (max-width: 1199px) {
    .form-container {
        padding-bottom: 48px;
    }
}
</style>