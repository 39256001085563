export const it = {
  "fast_and_free": "VELOCE E GRATUITO",
  "vehicle_valuation": "VALUTAZIONE DEL VEICOLO",
  "valuation": "VALUTAZIONE",
  "vin": "VIN",
  "want_to_know_valuation": "Vuoi conoscere il valore della tua auto o controllare il valore di un veicolo prima di acquistarlo? Usa la nostra valutazione gratuita del veicolo! Basta inserire il VIN per ottenere una valutazione accurata e affidabile. Con alcuni passaggi aggiuntivi, possiamo fornire un'analisi ancora più dettagliata. È semplice, veloce e gratuito! Non aspettare – controlla il valore della tua auto ora!",
  "plate": "Numero di registrazione",
  "mileage": "Chilometraggio",
  "accord_mileage_desc": "I veicoli con basso chilometraggio sono solitamente meno usurati, motivo per cui spesso raggiungono prezzi più elevati nel mercato delle auto usate.",
  "damage_history": "Storia degli incidenti",
  "damage_history_desc": "Le auto che sono state coinvolte in incidenti di solito hanno un valore inferiore a causa di potenziali danni strutturali.",
  "count_of_owners": "Numero di proprietari precedenti",
  "count_of_owners_desc": "I veicoli che hanno avuto meno proprietari possono essere valutati più in alto perché sono generalmente meglio mantenuti.",
  "body_color": "Colore della carrozzeria",
  "body_color_desc": "I colori popolari possono aumentare il valore del veicolo, mentre i colori insoliti possono diminuirlo.",
  "technical_condition": "Condizione tecnica",
  "technical_condition_desc": "La condizione meccanica e tecnica del veicolo, inclusa la condizione del motore, della trasmissione e di altri componenti, ha un impatto significativo sul prezzo.",
  "fuel_type": "Tipo di carburante",
  "fuel_type_desc": "Le attuali tendenze e preferenze di mercato possono rendere certi tipi di motori più desiderabili di altri.",
  "vehicle_equipment": "Dotazione dell'auto",
  "vehicle_equipment_desc": "Caratteristiche aggiuntive come sistemi di navigazione, interni in pelle, impianti audio di alta qualità possono aumentare il valore del veicolo.",
  "current_market_prices": "Prezzi di mercato attuali",
  "current_market_prices_desc": "I prezzi di mercato fluttuano in base alla domanda e all'offerta per un determinato modello di veicolo, influenzando la sua valutazione finale.",
  "how_to_valuate_vehicle_before_sell": "Come valutare un veicolo prima di acquistare/vendere?",
  "prod_year": "Anno di produzione",
  "first_reg_date": "Data di prima registrazione",
  "contact": "Contatto",
  "privacy_policy": "Informativa sulla privacy",
  "regulations": "Termini e condizioni",
  "valuation_desc": "La valutazione del veicolo è stata preparata sulla base dell'ampia esperienza dei nostri esperti di mercato automobilistico. La procedura di valutazione utilizza intelligenza artificiale (AI) per confrontare le tendenze in cambiamento nei prezzi dei veicoli nei mercati europei e americani. La valutazione tiene conto di parametri come l'età e il chilometraggio del veicolo. La nostra valutazione considera anche le ultime questioni automobilistiche e sociali come la mancanza di pezzi o componenti e l'inflazione, che influenzano i prezzi dei veicoli usati.",
  "min_price": "Prezzo minimo",
  "med_price": "Prezzo medio",
  "max_price": "Prezzo massimo",
  "we_have_report_for_you": "Abbiamo un rapporto per questo veicolo!",
  "incident_history": "Storia degli incidenti",
  "odometer_reading": "Lettura del chilometraggio",
  "vehicles_inspection": "Ispezioni del veicolo",
  "archive_photos": "Foto d'archivio",
  "check_now": "VISUALIZZA RAPPORTO",
  "unfortunately_not_found_desc": "Sfortunatamente, non siamo riusciti a trovare l'anno di produzione per questo veicolo, per favore inseriscilo nel campo sopra.",
  "type_odometer": "Inserisci il chilometraggio attuale del veicolo in chilometri per una stima migliore del suo valore.",
  "check_another_vin": "Valuta un altro veicolo",
  "what_can_affet_the_price_of_vehicle": "Cosa può influenzare il prezzo del tuo veicolo?",
  "calcualtor_of_vehicle": "Calcolatore di valutazione auto online",
  "type_plate": "Inserisci il numero di registrazione del veicolo.",
  "type_first_reg_date": "Inserisci la data della prima registrazione del veicolo. Puoi trovarla nel documento di registrazione nel campo B.",
  "price_odometer": "Prezzo",
  "price_year": "Prezzo",
  "year": "Anno",
  "regulations_admission": "<p>Questo Regolamento definisce le regole per l'uso del sito web operato con il nome VinValuation all'indirizzo internet www.vinValuation.com (di seguito denominato il Sito). Questo Regolamento contiene le regole per l'uso del Sito. Se non sei d'accordo con l'applicazione di questo Regolamento o con una qualsiasi delle sue disposizioni, dovresti immediatamente lasciare questo Sito.</p><p>Il nome completo della nostra azienda è Car Data Experts LTD. Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Numero di registrazione delle aziende: 10327199. Puoi contattarci via email a: <a href=mailto:{email}>{email}</a></p><p>Lo scopo principale del Sito è fornire ai suoi utenti la possibilità di inserire dati su veicoli rubati o danneggiati nel database Car Data (Dati del Veicolo) e di condividere questi dati con altre persone che utilizzano questo database.</p><p>I servizi disponibili sul Sito sono destinati a persone fisiche, enti giuridici e altre entità che hanno una residenza o una sede legale nell'Unione Europea o negli Stati Uniti d'America.</p><p>I servizi sul Sito sono rivolti esclusivamente a persone che hanno almeno 18 anni.</p><p>Le regole per il trattamento dei dati personali e l'uso di file chiamati cookie sono presentate nella nostra Informativa sulla Privacy disponibile sul Sito.</p><p>Per utilizzare il Sito, l'utente deve utilizzare un dispositivo con accesso a internet e un browser web Internet Explorer 9.0 o un programma simile. Per utilizzare alcune funzionalità del Sito, è necessario utilizzare software Java e Java Script, oltre a consentire l'uso di cookie. Non siamo responsabili per la mancanza di accesso ai dati pubblicati sul nostro Sito a causa del dispositivo dell'utente che non soddisfa i requisiti tecnici specificati sopra.</p>',",
  "regulations_definition": "<p>In questo Regolamento, i termini definiti di seguito avranno i seguenti significati:</p><p>Dati del Veicolo significa dati su veicoli rubati o danneggiati e le circostanze di furto o danno creati dagli utenti del Sito nel database Dati Auto e resi disponibili ad altre persone che utilizzano questo database.</p><p>Consumatore significa qualsiasi persona fisica che agisce per scopi non correlati ad attività commerciali o professionali.</p><p>Database significa il nostro database Dati Auto sulle condizioni tecniche e la storia dei veicoli.</p><p>Numero VIN significa il numero di identificazione del veicolo (Vehicle Identification Number), utilizzato dall'industria automobilistica per identificare un'istanza specifica di un veicolo a motore, trattore, motocicletta, scooter e ciclomotore, come definito nello standard ISO 3833.</p>',",
  "regulations_license_desc": "<p>Fatto salvo quanto diversamente disposto, tutti i diritti di proprietà intellettuale sul Sito e sui materiali contenuti nel Sito sono di nostra proprietà o dei nostri licenziatari. Fatta salva la licenza di seguito, tutti i diritti di proprietà intellettuale sono riservati.</p> <p>Gli utenti possono visualizzare, scaricare file per la memorizzazione nella cache, esclusivamente per uso personale, fatte salve le eccezioni indicate di seguito e in altre parti dei Termini di Servizio.</p><p>Le seguenti attività sono particolarmente vietate:</p><p>a. distribuzione di materiali ottenuti dal Sito (inclusa la distribuzione su un altro sito web);</p><p>b. vendita, noleggio, prestito, donazione, trasferimento o concessione di sublicenze riguardanti il Sito e i materiali presenti sul Sito;</p><p>c. esecuzione pubblica di qualsiasi materiale disponibile sul Sito;</p><p>d. riproduzione, duplicazione, copia o utilizzo in altro modo di materiali raccolti sul Sito per scopi commerciali;</p><p>e. alterazione o modifica di qualsiasi materiale presente sul Sito;</p><p>f. distribuzione di materiali ottenuti dal Sito senza ottenere il nostro esplicito consenso scritto per quanto sopra.</p>",
  "regulations_use_page": "<p>1. È vietato utilizzare il Sito in qualsiasi modo che sia dannoso per esso o che influisca negativamente sulla possibilità di utilizzare il Sito; o in qualsiasi altro modo che sia illegale, illecita, dannoso, o relativo a qualsiasi scopo o azione illegale, illecita o dannosa. Ci riserviamo tutti i diritti su tutte le informazioni e i materiali, inclusi grafica e altri, disponibili sul Sito.</p><p>2. È vietato utilizzare il Sito per copiare, utilizzare, memorizzare, trasmettere, inviare, pubblicare o distribuire materiali che contengono spyware, virus, cavalli di Troia, worm, keylogger, rootkit o altri software o dispositivi dannosi, o fare riferimento a essi.</p><p>3. È vietata qualsiasi raccolta sistematica o automatizzata di dati dal Sito o dati relativi al Sito (incluso l'uso delle seguenti tecniche: scraping, data mining, estrazione di dati e raccolta di dati) senza il nostro consenso scritto.</p><p>4. È vietato utilizzare il Sito per trasmettere o inviare informazioni commerciali non richieste.</p><p>5. È vietato utilizzare il Sito per qualsiasi scopo di marketing senza il nostro consenso scritto.</p>",
  "regulations_limited_access": "<p>1. L'accesso a determinate risorse del Sito è limitato. Ci riserviamo il diritto di limitare l'accesso ad altre risorse del Sito o di restringere l'accesso all'intero Sito, soggetto ai diritti degli utenti derivanti da accordi precedentemente conclusi.</p><p>2. Nel caso in cui l'utente ottenga un nome utente o un nome utente e una password da noi che consentono l'accesso a risorse riservate sul Sito o ad altri contenuti o servizi con accesso limitato, l'utente è obbligato a proteggere la password e il nome utente in modo che non vengano divulgati a terzi.</p>",
  "regulations_user_posted": "<p>1. I contenuti generati dagli utenti non devono essere illegali, violare i principi di convivenza sociale, la Netiquette o altre consuetudini stabilite, né ledere diritti di terzi, né dare origine a richieste o azioni legali contro l'utente, noi o qualsiasi terza parte (in ogni caso, ai sensi di qualsiasi legge applicabile).</p><p>2. È vietato pubblicare qualsiasi contenuto sul Sito che sia o sia stato oggetto di contenzioso in corso o minacciato o di procedimenti simili.</p><p>3. Ci riserviamo il diritto di modificare o rimuovere qualsiasi contenuto o materiale pubblicato sul Sito, memorizzato o pubblicato lì.</p><p>4. Fatte salve le disposizioni di questi Termini e della legge applicabile riguardanti i contenuti e altri materiali pubblicati sul Sito dall'utente, non siamo obbligati a monitorare i contenuti e i materiali pubblicati o pubblicati sul Sito.</p><p>5. Qualsiasi dato pubblicato dall'utente sul Sito dovrebbe essere mantenuto anche sui propri dispositivi per ulteriore protezione contro la perdita.</p>",
  "regulations_placing_data_in_db": "<p>1. L'utente fornisce i Dati del Veicolo nel Database utilizzando il modulo pubblicato sul Sito.</p><p>2. L'utente è responsabile dell'accuratezza dei Dati del Veicolo in relazione allo stato attuale delle cose. In caso di modifiche, l'utente deve aggiornare tempestivamente i Dati del Veicolo forniti nel Database.</p><p>3. Dopo aver inviato i Dati del Veicolo al Database, l'utente riceve un'email di conferma all'indirizzo fornito, che conferma l'inserimento di questi dati nel Database.</p><p>4. I Dati del Veicolo vengono inseriti nel Database e resi disponibili ad altre entità che utilizzano questo database, al fine di ottenere informazioni su un veicolo specifico identificato dal suo numero VIN.</p><p>5. Se nuove informazioni riguardanti i Dati del Veicolo appaiono nel Database, cercheremo di informare l'utente che ha inviato i Dati del Veicolo tramite l'indirizzo email fornito.</p><p>6. I Dati del Veicolo possono essere ritirati dal Database contattandoci tramite il modulo di contatto sul Sito o scrivendo all'indirizzo email fornito nell'Introduzione ai Termini e Condizioni.</p>",
  "regulations_limited_and_warranties_complaints": "<p>1. Per evitare dubbi, sulla base dell'accordo utente per la fornitura di Dati del Veicolo nel Database, non siamo obbligati a localizzare il veicolo a cui si riferiscono i Dati del Veicolo.</p><p>2. Nel caso di utenti che non sono Consumatori, nella misura consentita dalla legge applicabile, non facciamo dichiarazioni, non forniamo garanzie riguardo alla qualità, escludiamo la responsabilità e non facciamo dichiarazioni riguardo agli standard di servizio forniti sul Sito e all'uso del Sito (in particolare, escludiamo tutte le garanzie, non facciamo dichiarazioni fornite dalla legge applicabile riguardo alla qualità soddisfacente, idoneità per lo scopo o all'esercizio della dovuta diligenza e competenza).</p><p>3. In caso di problemi tecnici sul Sito o di incompletezza o informazioni obsolete fornite da noi, così come eventuali reclami riguardanti il Sito (reclami), dovresti informarci prontamente utilizzando il modulo nella pagina di Contatto o scrivendoci all'indirizzo email indicato sopra nell'Introduzione ai Termini e Condizioni. Ci impegniamo a rispondere prontamente.</p>',",
  "regulations_liability": "<p>1. Nessuna disposizione di questi Termini (a) esclude o limita la nostra responsabilità per danni causati da colpa intenzionale o falsa rappresentazione intenzionale; (b) limita la nostra o la responsabilità dell'utente in modo contrario alla legge applicabile; o (c) esclude la nostra o la responsabilità dell'utente se tale responsabilità non può essere esclusa ai sensi della legge applicabile; in particolare, i Termini non escludono la nostra o la responsabilità dell'utente per danni causati da colpa intenzionale.</p><p>2. Le esclusioni e le limitazioni di responsabilità previste nei Termini:</p><p> a. sono soggette alle limitazioni specificate nel paragrafo 1 sopra;</p><p> b. riguardano qualsiasi responsabilità che possa sorgere ai sensi dei Termini o della Politica sulla Privacy in relazione all'oggetto dei Termini e della Politica sulla Privacy, inclusa la responsabilità contrattuale, extracontrattuale o simile;</p><p> c. non si applicano ai Consumatori.</p><p>3. Nel caso di utenti non Consumatori, nella misura consentita dalla legge applicabile:</p><p> a. non siamo responsabili per danni di alcun tipo a meno che il danno non sia stato causato dalla nostra colpa intenzionale. La garanzia e qualsiasi responsabilità derivante da istituzioni simili alla garanzia sono qui escluse;</p><p> b. non siamo responsabili per alcuna perdita o danno a dati, database o software, sia nostri che dell'utente.</p><p>4. Non siamo responsabili per danni derivanti in relazione a un evento o eventi al di fuori del nostro controllo.</p>",
  "regulations_relief": "<p>Gli utenti che non sono Consumatori ci indennizzano contro qualsiasi perdita, danno, risarcimento, costo, responsabilità e spese (inclusi i costi relativi a qualsiasi procedimento e qualsiasi somma pagata da noi a terzi come risarcimento) relativi a qualsiasi violazione da parte dell'utente di questi Termini e Condizioni, e sono obbligati a rimborsarci per i costi sostenuti da noi come sopra menzionato.</p>",
  "regulations_violations": "<p>Fatti salvi tutti i nostri altri diritti derivanti da questi Termini, in caso di violazione di questi Termini da parte dell'utente in qualsiasi modo, possiamo intraprendere le azioni che riteniamo appropriate per rispondere alla violazione, che possono includere la sospensione dell'accesso dell'utente al Sito, impedire all'utente di accedere al Sito, bloccare l'accesso al Sito per un indirizzo IP specifico, contattare il fornitore di servizi Internet dell'utente per bloccare l'accesso al Sito, o intraprendere azioni legali.</p>",
  "regulations_changing_provisions_regulations": "<p>1. Le normative possono essere modificate pubblicando la loro nuova versione sul Sito. Se l'utente ci fornisce i propri dati personali, in particolare il proprio indirizzo email, e se forniamo servizi durante la modifica di queste Normative, li informeremo di eventuali cambiamenti a queste Normative via email.</p><p>2. Nel caso di utenti che non sono Consumatori, un cambiamento delle nostre informazioni di contatto non costituisce una modifica di queste Normative.</p><p>3. In caso di cambiamento dell'indirizzo email fornito durante la registrazione, gli utenti sono tenuti ad aggiornare questo indirizzo sul Sito.</p>",
  "regulations_transfer": "<p>Gli utenti non possono trasferire, condividere con altri o in altro modo disporre dei propri diritti e obblighi derivanti da queste Normative, né assumere obblighi riguardo a questi diritti e obblighi.</p>",
  "regulations_severability_clause": "<p>Se una qualsiasi disposizione di queste Normative viene ritenuta invalida o inefficace da un tribunale o da un'altra autorità competente, le restanti disposizioni delle Normative rimarranno vincolanti. Se una disposizione invalida o inefficace sarebbe valida dopo la rimozione di una sua parte, quella parte sarà considerata rimossa e la parte rimanente della disposizione sarà considerata valida.</p>",
  "regulations_exclusion_parts": "<p>Le Normative definiscono esclusivamente il rapporto tra noi e gli utenti del Sito e non conferiscono diritti a terzi né li autorizzano a invocare alcuna delle sue disposizioni o a richiederne l'applicazione. L'applicazione delle disposizioni delle Normative non richiede il consenso di alcun terzo.</p>",
  "regulations_scope_contract": "<p>Questa Normativa, insieme alla nostra Informativa sulla Privacy e ai prezzi dei servizi indicati sul Sito, costituisce un accordo tra noi e l'utente riguardo all'uso del Sito e sostituisce qualsiasi normativa precedente riguardante l'uso del Sito.</p>",
  "regulations_governing_law": "<p>1. Questo Regolamento è soggetto alla legge polacca e, per quanto non regolato dal Regolamento, si applicano le disposizioni della Legge del 23 aprile 1964, il Codice Civile, la Legge del 30 maggio 2014, sui diritti dei consumatori (in relazione ai Consumatori), e la Legge del 18 luglio 2002, sulla fornitura di servizi elettronici.</p><p>2. Nel caso di utenti che non sono Consumatori, eventuali controversie derivanti dal Regolamento saranno soggette alla giurisdizione esclusiva dei tribunali polacchi.</p><p>3. Nel caso di utenti che sono Consumatori, il cui luogo di residenza si trova in Polonia al momento della conclusione dell'accordo per la fornitura di Dati del Veicolo, eventuali controversie relative a questo Regolamento o alla Politica sulla Privacy saranno soggette alla giurisdizione esclusiva dei tribunali polacchi.</p>",
  "back": "Restituisci",
  "definition": "Definizioni",
  "admission": "Introduzione",
  "license_use_page": "Licenza per utilizzare il Sito e i materiali disponibili su di esso",
  "use_page": "Utilizzo del Sito",
  "limited_access": "Accesso limitato",
  "user_posted_content": "Contenuto pubblicato dall'Utente",
  "placing_vehicle_data_in_db": "Inserimento dei Dati del Veicolo nel Database",
  "disclaimer_of_liability": "Dichiarazione di non responsabilità",
  "relief_from_obligations": "Liberazione dagli Obblighi",
  "violations_of_regulations": "Violazioni del Regolamento",
  "transfer": "Cessione",
  "severability_clause": "Clausola Salvatoria",
  "exclusion_third_parts": "Esclusione dei Diritti di Terzi",
  "scope_of_contract": "Ambito dell'Accordo",
  "changing_of_provisions_regulations": "Modifica delle Disposizioni del Regolamento",
  "governing_law_and_jurisdiction": "Legge Applicabile e Giurisdizione",
  "limited_and_warranties_complaints": "Rappresentazioni e Garanzie Limitate e Presentazione di Reclami",
  "main_tip": "Utilizza il nostro sito web per valutare le auto online utilizzando il numero VIN. In questo modo, scoprirai rapidamente e con precisione quanto vale la tua auto. Basta inserire il numero VIN della tua auto e ti aiuteremo a valutarne il valore. Non aspettare, controllalo ora!",
  "enter_vin_number": "Inserisci VIN",
  "meta_title": "Calcolatore di Valutazione Auto Online - valuta rapidamente e con precisione la tua auto.",
  "meta_desc": "Valutazione auto online, valuta rapidamente e con precisione il valore della tua auto. Il nostro calcolatore fornirà il prezzo per l'anno modello controllato del veicolo e quelli leggermente più vecchi e più giovani per confronto.",
  "h1_title": "La valutazione dell'auto online richiede solo il VIN",
  "check_valuation": "Controlla la valutazione!",
  "next": "Avanti",
  "help": "Aiuto",
  "no_data_valuation": "Nessun dato per la valutazione",
  "check_if_stolen": "Controlla se rubato",
  "decode_vin_number": "Decodifica numero VIN",
  "additional_links": "Link aggiuntivi",
  "check_vin_correct": "Controlla la validità del numero VIN",
  "how_to_vin_valuation": "Come valutare un veicolo prima di acquistare/vendere?",
  "calculator_valuation": "Calcolatore di valutazione auto online",
  "calculator_valuation_desc": "Ci sono molti metodi per stimare il valore di un'auto; il più veloce e relativamente accurato è controllarlo su un sito web come vinvaluation. Il nostro algoritmo proprietario, supportato dall'intelligenza artificiale (AI), aggrega dati da migliaia di annunci online, analizzando precisamente l'età, la marca, l'equipaggiamento e le condizioni dei veicoli offerti. Basandoci su questo, stimiamo quanto dovrebbe valere l'auto che stai controllando. Ricevi la valutazione in pochi momenti senza lasciare casa, completamente gratis. Abbiamo solo bisogno di alcuni parametri come il numero VIN o la data di registrazione del veicolo. In risposta, riceverai non solo la valutazione dell'anno che stai controllando, ma anche di diversi modelli vicini, più giovani e più vecchi. Questo ti dà una visione completa su se, ad esempio, vale ancora la pena essere il suo proprietario o se non perderai troppo e sarebbe meglio venderla subito.",
  "you_get_valuation_for_free": "<strong>Ricevi la valutazione</strong> in pochi momenti senza lasciare casa completamente gratis. Abbiamo solo bisogno di alcuni parametri come il numero VIN o la data di registrazione del veicolo.",
  "in_response_you_receive_not_only_valuation": "In risposta, riceverai non solo la valutazione dell'anno che stai controllando, ma anche diversi modelli vicini, più giovani e più vecchi. Questo ti dà una visione completa su se, ad esempio, vale ancora la pena essere il suo proprietario o se non perderai troppo e sarebbe meglio venderla subito.",
  "compare_market_price": "Confronta i prezzi sul mercato",
  "what_we_offer_free": "Quello che offriamo gratuitamente puoi ovviamente farlo manualmente. Sfoglia <strong>annunci su internet</strong> o visita alcuni <strong>concessionari di auto</strong> per scoprire i prezzi attuali dei veicoli offerti.",
  "if_you_are_not_sure": "Se non sei sicuro di quali informazioni siano necessarie per valutare un'auto, puoi contattare un perito automobilistico o rivolgerti a un concessionario o a un'agenzia automobilistica. Saranno anche in grado di aiutarti a stimare il valore della tua auto. Tuttavia, tieni presente che il prezzo offerto dal concessionario o dal rivenditore sarà probabilmente significativamente più basso. Devono anche realizzare un profitto acquistando l'auto da te. Indipendentemente dal metodo che scegli, ricorda che la valutazione dell'auto è solo un prezzo approssimativo. Il valore finale del veicolo dipende da molti fattori, come le condizioni tecniche, l'equipaggiamento, la storia dei servizi e l'impressione generale che l'auto fa su un potenziale acquirente. È importante controllare accuratamente le condizioni dell'auto prima di venderla o acquistarla. Quando pianifichi di acquistare un'auto, ricorda di controllare la storia del veicolo.",
  "regardless_of_which_method": "Indipendentemente dal metodo che scegli, ricorda che la valutazione dell'auto è solo un prezzo approssimativo. Il valore finale del veicolo dipende da molti fattori, come le condizioni tecniche, l'equipaggiamento, la storia dei servizi e l'impressione generale che l'auto fa su un potenziale acquirente. È importante controllare accuratamente le condizioni dell'auto prima di venderla o acquistarla.",
  "check_the_valuation": "Controlla la storia del veicolo",
  "you_be_may_interested": "Potresti essere interessato anche a:",
  "check": "CONTROLLA",
  "vindecoder": "VINdecoder",
  "get_basic_infotmation_about_vehicle": "Ottieni informazioni di base sul tuo veicolo gratuitamente",
  "get_basic_infotmation_about_vehicle_desc": "come marca, modello, anno di fabbricazione e specifiche tecniche inserendo il VIN nel campo di ricerca. Per dati più dettagliati, come la storia degli incidenti, il chilometraggio e lo stato legale del veicolo, il sito offre anche l'opzione di acquistare un rapporto VIN completo.",
  "stolen_cars": "AutoRubate",
  "stolen_cars_title": "Controlla più database di auto rubate,",
  "stolen_cars_desc": "utilizzando strumenti di ricerca avanzati. Basta inserire il VIN o il numero di registrazione del veicolo, e il sistema cercherà in un vasto database per verificare se l'auto è stata segnalata come rubata.",
  "vin_correct": "VINCorretto",
  "vin_correct_title": "Controlla la validità del VIN.",
  "vin_correct_desc": "lo strumento verifica se il VIN è conforme agli standard attuali e contiene tutte le informazioni necessarie. Questo garantisce che il VIN non sia stato falsificato o registrato in modo errato.",
  "easy_vin": "easyVIN",
  "easy_vin_title": "La storia dell'auto dalla A alla Z in un unico luogo.",
  "easy_vin_desc": "Verifica la tua conoscenza del veicolo che ti interessa e risparmia spiacevoli sorprese al momento dell'acquisto di una nuova auto. Controlla il numero VIN quando vuoi. I report vengono generati 24 ore al giorno, 7 giorni alla settimana.",
  "find_out_the_value_of_your_car_desc": "Vuoi conoscere il valore della tua auto o verificare il valore del tuo veicolo prima di acquistarlo? Approfitta della nostra valutazione gratuita del veicolo! Con un numero VIN e alcuni passaggi aggiuntivi, possiamo fornire un'analisi dettagliata. È facile, veloce e gratuito! Non aspettare: verifica subito il valore della tua auto!",
  "enter_te_vin_number": "Inserisci il numero VIN di 17 cifre",
  "enter_mileage": "Fornire il chilometraggio del veicolo",
  "enter_regplate": "Inserisci il numero di targa",
  "tip": "Fatto curioso:",
  "odometer_readings": "Chilometraggio del veicolo",
  "number_of_regplate": "Numero di targa",
  "valuation_incoming": "Il preventivo si sta caricando",
  "pl_country": "Polonia",
  "en_country": "Gran Bretagna",
  "de_country": "Germania",
  "cs_country": "La Repubblica Ceca",
  "lt_country": "Lituania",
  "fr_country": "Francia",
  "es_country": "Spagna",
  "ro_country": "Romania",
  "nl_country": "Paesi Bassi",
  "hu_country": "Ungheria",
  "bg_country": "Bulgaria",
  "da_country": "Danimarca",
  "el_country": "Grecia",
  "it_country": "Italia",
  "no_country": "Norvegia",
  "pt_country": "Portogallo",
  "sk_country": "Slovacchia",
  "sr_country": "Serbia",
  "sv_country": "Svezia",
  "tr_country": "Turchia",
  "ar_country": "Arabia Saudita",
  "fact_model_t": "La prima automobile prodotta in serie fu la Ford Modello T, uscita dalla catena di montaggio nel 1908.",
  "fact_bugatti": "L'auto più costosa al mondo è la Bugatti La Voiture Noire, costata circa 18 milioni di dollari.",
  "fact_v8": "Il motore V8 fu creato nel 1902 e il suo design fu rivoluzionario per l'industria automobilistica.",
  "fact_vw_beetle": "Il modello di automobile prodotto più a lungo è il Maggiolino Volkswagen, prodotto ininterrottamente per 65 anni, dal 1938 al 2003.",
  "fact_wipers": "I primi tergicristalli per auto furono inventati da Mary Anderson nel 1903, dopo aver notato che gli automobilisti dovevano scendere dalle auto per pulire i finestrini.",
  "fact_lamborghini": "Inizialmente la Lamborghini produceva trattori e solo in seguito iniziò a produrre auto sportive di lusso.",
  "fact_popular_color": "Il colore più diffuso al mondo per le auto è il bianco, scelto dal 39% degli acquirenti.",
  "fact_electric_car": "La prima auto elettrica fu creata nel 1832, molto prima dell'invenzione delle automobili con motore a combustione interna.",
  "fact_crank_start": "Prima dell'invenzione dell'avviamento elettrico, le auto venivano avviate tramite una manovella, un metodo piuttosto pericoloso che poteva causare gravi lesioni.",
  "fact_ssc_tuatara": "La velocità più elevata mai raggiunta da un'auto di serie è stata raggiunta dalla SSC Tuatara, che ha raggiunto i 532,93 km/h.",
  "error_message": "Si è verificato un errore interno. Riprova più tardi.",
  "no_data_alert": "Nessun dato disponibile per questo veicolo.",
};
