<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-lg-6">
                <canvas id="nearOdometerChart"></canvas>
            </div>

            <div class="col-lg-6">
                <canvas id="nearYearChart"></canvas>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watchEffect, nextTick, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Chart } from 'chart.js/auto';

const props = defineProps({
    currency: String,
});

const store = useStore();
const { t } = useI18n();

const chartCurrency = ref(props.currency);
const labelsChartOdometer = ref([]);
const labelsChartPrices = ref([]);
const labelsChartColors = ref([]);
const labelsNearYearChartYears = ref([]);
const labelsNearYearChartPrices = ref([]);
const labelsNearYearChartColors = ref([]);

const renderChart = (ctx) => {
    if (ctx) {
        const chartStatus = Chart.getChart(ctx.id); // <canvas> id

        if (chartStatus) {
            chartStatus.destroy(); // Usuń poprzedni wykres, jeśli istnieje
        }

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ctx.id === 'nearOdometerChart' ? labelsChartOdometer.value : labelsNearYearChartYears.value,
                datasets: [
                    {
                        label: ctx.id === 'nearOdometerChart' ? t('price_odometer') : t('price_year'),
                        data: ctx.id === 'nearOdometerChart' ? labelsChartPrices.value : labelsNearYearChartPrices.value,
                        backgroundColor: ctx.id === 'nearOdometerChart' ? labelsChartColors.value : labelsNearYearChartColors.value,
                        borderColor: ['#4aa2ef'],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: ctx.id === 'nearOdometerChart' ? t('mileage') : t('year'),
                        },
                    },
                    y: {
                        min:
                            Math.floor(
                                (ctx.id === 'nearOdometerChart'
                                    ? labelsChartPrices.value[labelsChartPrices.value.length - 1] * 0.9
                                    : labelsNearYearChartPrices.value[labelsNearYearChartPrices.value.length - 1] * 1.1) / 500
                            ) * 500,
                        max:
                            Math.ceil(
                                (ctx.id === 'nearOdometerChart'
                                    ? labelsChartPrices.value[0] * 1.03
                                    : labelsNearYearChartPrices.value[0] * 0.7) / 500
                            ) * 500,
                        ticks: {
                            callback: (label) => `${label} ${chartCurrency.value}`,
                        },
                    },
                },
            },
        });
    }
};

const odometerData = computed(() => store.state.nearOdometerData);
const yearData = computed(() => store.state.nearYearData);
const currentOdometer = computed(() => store.state.carData.currentOdometer);
const prodYear = computed(() => store.state.carData.prodYear);

const prepareOdometerData = (data) => {
    const odometers = [];
    const prices = [];
    const colors = [];
    let added = false;
    for (const values of data) {
        for (const [type, value] of Object.entries(values)) {
            if (type === 'odometer_avr') {
                if (
                    !added &&
                    currentOdometer.value > 0 &&
                    parseInt(value) / currentOdometer.value > 0.98 &&
                    parseInt(value) / currentOdometer.value < 1.02
                ) {
                    added = true;
                    odometers.push(`${currentOdometer.value} km`);
                    colors.push('#26be00');
                } else {
                    odometers.push(`${value} km`);
                    colors.push('#3763f4');
                }
            } else if (type === 'price_avr') {
                prices.push(value);
            }
        }
    }

    return { odometers, prices, colors };
};

const prepareYearData = (data) => {
    const years = [];
    const prices = [];
    const colors = [];
    for (const values of data) {
        for (const [type, value] of Object.entries(values)) {
            if (type === 'year') {
                colors.push(parseInt(value) === parseInt(prodYear.value) ? '#26be00' : '#3763f4');
                years.push(value);
            } else if (type === 'price') {
                prices.push(value);
            }
        }
    }
    return { years, prices, colors };
};

watchEffect(() => {
    nextTick(() => {
        if (odometerData.value && Object.keys(odometerData.value).length !== 0) {
            const { odometers, prices, colors } = prepareOdometerData(odometerData.value);
            labelsChartOdometer.value = odometers;
            labelsChartPrices.value = prices;
            labelsChartColors.value = colors;
            const nearOdometerChart = document.getElementById('nearOdometerChart');
            renderChart(nearOdometerChart);
        } else {
            const chartStatus = Chart.getChart('nearOdometerChart');
            if (chartStatus) {
                chartStatus.destroy();
            }
        }

        if (yearData.value && Object.keys(yearData.value).length !== 0) {
            const { years, prices, colors } = prepareYearData(yearData.value);
            labelsNearYearChartYears.value = years;
            labelsNearYearChartPrices.value = prices;
            labelsNearYearChartColors.value = colors;
            const nearYearChart = document.getElementById('nearYearChart');
            renderChart(nearYearChart);
        } else {
            const chartStatus = Chart.getChart('nearYearChart');
            if (chartStatus) {
                chartStatus.destroy();
            }
        }
    });
});

</script>

<style scoped>
.dark {
    background-color: #2d2c36;
    animation: pulse-bg-dark 1s infinite;
}

.light {
    background-color: #eee;
    animation: pulse-bg-light 1s infinite;
}

@keyframes pulse-bg-dark {
    0% {
        background-color: #2d2c36;
    }

    50% {
        background-color: #2a2a36;
    }

    100% {
        background-color: #2d2c36;
    }
}

@keyframes pulse-bg-light {
    0% {
        background-color: #eee;
    }

    50% {
        background-color: #eaeaea;
    }

    100% {
        background-color: #eee;
    }
}
</style>